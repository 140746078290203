import type { AddDashboardWarning } from '..';
import { maybeMap } from '../../lib';
import type { DashboardV47 } from './47';

function isStringEmpty(name: string) {
    // Written this way instead of /^\s*$/ so it matches the regex in the json
    // schema
    return !/[^\s]+/.test(name);
}

export interface DashboardV48 extends Omit<DashboardV47, 'schema_version'> {
    readonly schema_version: '48';
}

export function up(prev: DashboardV47): DashboardV48 {
    return {
        ...prev,
        schema_version: '48',
    };
}

export function down(prev: DashboardV48, warn: AddDashboardWarning): DashboardV47 {
    const tiles = maybeMap(prev.tiles, (tile) => {
        if (isStringEmpty(tile.title)) {
            warn((t) => t.down.v48.tilesCannotHaveSpaces);
            return { ...tile, title: 'Defaulted title', hideTitle: true };
        }
    });

    return {
        ...prev,
        tiles,
        schema_version: '47',
    };
}
