import * as tileV1 from '../tile/1';
import type { IDashboardV2 } from './2';

export interface IPageV3 {
    name: string;
    id: string;
}

export interface IDashboardV3 extends Omit<IDashboardV2, 'schema_version' | 'tiles'> {
    schema_version: '3';
    tiles: readonly tileV1.ITileV1[];
    pages: readonly IPageV3[];
}

/**
 * Adds pages and updates tiles to v1
 */
export function up(prev: IDashboardV2): IDashboardV3 {
    const pageId = crypto.randomUUID();
    const allParameterVariableNames = prev.parameters
        .map((p) => {
            if (p.type === 'duration') {
                return [p.beginVariableName, p.endVariableName];
            }
            return [p.variableName];
        })
        .flat();

    return {
        ...prev,
        schema_version: '3',
        tiles: prev.tiles.map((t) => tileV1.up(t, pageId, allParameterVariableNames)),
        pages: [{ name: 'Page 1', id: pageId }],
    };
}
