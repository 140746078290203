import { assertNever, formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { IVisualOptionsV26 } from './26';
import type { ITileV27 } from './27';

export interface IVisualOptionsV28 extends Omit<IVisualOptionsV26, 'legendLocation'> {
    readonly legendLocation: 'bottom' | 'left' | 'right';
}

export interface ITileV28 extends Omit<ITileV27, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV28>;
}

export function down(prev: ITileV28, warn: AddDashboardWarning): ITileV27 {
    switch (prev.visualOptions.legendLocation) {
        case undefined:
            return prev as ITileV27;
        case 'left':
        case 'right':
            return prev as ITileV27;
        case 'bottom': {
            // No warning needed because "down" is the default location, unless there's multiple panels
            if (prev.visualOptions.multipleYAxes?.showMultiplePanels) {
                warn((t) => formatLiterals(t.down.v50.legendLocationBottomRemoved, { tileName: prev.title }));
            }

            const { legendLocation, ...shared } = prev.visualOptions;
            return { ...prev, visualOptions: shared };
        }
        default:
            assertNever(prev.visualOptions.legendLocation);
    }
}

export function up(prev: ITileV27): ITileV28 {
    //  Enabling multiple panels caused the legend to be positioned different up util version 50
    if (prev.visualOptions.legendLocation === undefined && prev.visualOptions.multipleYAxes?.showMultiplePanels) {
        return { ...prev, visualOptions: { ...prev.visualOptions, legendLocation: 'right' } };
    }

    return prev;
}
