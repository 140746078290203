import type { AddDashboardWarning } from '..';
import type { ITileV2 } from './2';
import type { ITileV13, IVisualOptionsV13 } from './13';

export interface IVisualOptionsV14 extends IVisualOptionsV13 {
    readonly plotly__version: '1' | '2';
}

export interface ITileV14 extends Omit<ITileV2, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV14>;
}

export function up(prev: ITileV13): ITileV14 {
    return prev;
}

export function down(prev: ITileV14, addWarning: AddDashboardWarning): ITileV13 {
    if (!prev.visualOptions.plotly__version) {
        return prev as ITileV13;
    }

    const { plotly__version: _, ...visualOptions } = prev.visualOptions;
    addWarning((t) => t.down.v24.plotlyRemoved);

    return {
        ...prev,
        visualType: 'table',
        visualOptions,
    };
}
