/// <reference types="@kusto/language-service-next" />
import '@kusto/language-service-next/bridge.min';
import '@kusto/language-service-next/Kusto.Language.Bridge.min.js';

import { KustoInputParameterSchema, KustoType, ScalarParameter, TabularParameter } from '../schemaTypes.ts';
import { getDefaultDotNetType } from '../typeConverters.ts';
import { convertEntityDefaultValueToJsonPresentation, listToArray, Maybe, omitEmptyValues } from './utils.ts';

import Parameter = Kusto.Language.Symbols.Parameter;
import TableSymbol = Kusto.Language.Symbols.TableSymbol;
import TypeSymbol = Kusto.Language.Symbols.TypeSymbol;

export function functionParameters(paramsString: string): KustoInputParameterSchema[] {
    const parameterList = Parameter.ParseList(paramsString);
    const parameterArray = listToArray(parameterList);
    return parameterArray.map(parse);
}

function parse(parameter: Parameter): KustoInputParameterSchema {
    return parameter.IsTabular ? tabularParser(parameter) : scalarParser(parameter);
}

function scalarParser(parameter: Parameter): ScalarParameter {
    const name = parameter.Name;
    const defaultValue = parameter.DefaultValue?.toString();
    const type = getParameterType(parameter)?.Name;

    return scalarParameterMaker(name, type, defaultValue);
}

function tabularParser(parameter: Parameter): TabularParameter {
    const name = parameter.Name ?? '';
    const type = getParameterType(parameter);
    const columnList = (type as TableSymbol).Columns;
    const columnArray = listToArray(columnList);
    const columns = columnArray.map((column) => scalarParameterMaker(column.Name, column.Type?.Name));

    return {
        Name: name,
        Columns: columns,
    };
}

function getParameterType(parameter: Parameter): TypeSymbol | undefined {
    // Declared types are an array for special case built-in functions.
    // Only the first one matters for our case.
    return parameter.DeclaredTypes?.getItem(0);
}

function scalarParameterMaker(name: Maybe<string>, type: Maybe<string>, defaultValue?: Maybe<string>): ScalarParameter {
    const parameter = {
        Name: name ?? '',
        Type: type && getDefaultDotNetType(type as KustoType),
        CslType: type,
        CslDefaultValue: defaultValue && convertEntityDefaultValueToJsonPresentation(defaultValue, type as KustoType),
    };

    return omitEmptyValues(parameter);
}
