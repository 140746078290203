import React from 'react';

export interface UseOnDismissedReturn {
    open: boolean;
    startClosing(): void;
    /**
     * Used to add a transitionend listener. Can't use the `onTransitionEnd`
     * prop because there's no `onTransitionCancel` prop in our version of React.
     */
    ref: React.RefObject<HTMLDivElement>;
}

/**
 * Creates the missing "onDismissed" callback for Fluent 9 Dialogs
 *
 * Might be able to avoid in favor of Fluent 9 motion callback when it's
 * available. Tracking issue for motion callbacks in <Dialog />:
 * https://github.com/microsoft/fluentui/issues/30695
 *
 * @example
 * ```tsx
 * const MyDialog: React.FC<{ onDismissed: () => void }> = ({ onDismissed }) => {
 *   const { open, startClosing, ref }  = useOnDialogDismissed(onDismissed);
 *
 *   return (
 *     <Dialog
 *       open={open}
 *       onOpenChange={(_event, data) => {
 *         if (!data.open) {
 *           startClosing();
 *         }
 *       }}
 *     >
 *       <DialogSurface backdrop={{ ref }}>
 *         <DialogBody>
 *           <DialogTitle>Use Kusto dashboards!</DialogTitle>
 *           <DialogActions>
 *             <DialogTrigger>
 *               <Button appearance="primary">Yes, absolutely</Button>
 *             </DialogTrigger>
 *           </DialogActions>
 *         </DialogBody>
 *       </DialogSurface>
 *     </Dialog>
 *   );
 * };
 * ```
 */
export function useOnDialogDismissed(onDismissed: undefined | (() => void)): UseOnDismissedReturn {
    const [open, setOpen] = React.useState<boolean>(true);
    const ref = React.useRef<HTMLDivElement>(null);
    const ref2 = React.useRef<undefined | (() => void)>(undefined);

    const startClosing = React.useCallback(() => {
        setOpen(false);
        if (ref2.current) {
            ref2.current();
            ref2.current = undefined;
        }

        if (!onDismissed) {
            return;
        }

        if (!ref.current) {
            onDismissed();
            return;
        }

        const el = ref.current;

        // Avoid passing extra args because typescript doesn't guarantee it's actually safe
        const _onDismissed = () => onDismissed();

        const controller = new AbortController();
        const signal = controller.signal;

        // Not totally sure why sometimes the animation gets canceled. Maybe
        // <Dialog /> is using timing to end the animation, instead of the end
        // event?
        el.addEventListener('transitionend', _onDismissed, { signal });
        el.addEventListener('transitioncancel', _onDismissed, { signal });

        ref2.current = _onDismissed;
    }, [onDismissed]);

    return {
        open,
        startClosing,
        ref,
    };
}
