import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import type { IParameterV3 } from '../parameter/3';
import * as tileV14 from '../tile/14';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV23 } from './23';

export interface IDashboardV24 {
    schema_version: '24';

    title: string;
    autoRefresh?: AutoRefreshConfigV1_2;

    tiles: readonly tileV14.ITileV14[];
    pages: readonly IPageV3[];
    dataSources: readonly UDataSourceV4[];
    parameters: readonly IParameterV3[];
}

export function up(prev: IDashboardV23): IDashboardV24 {
    return {
        ...prev,
        schema_version: '24',
        tiles: prev.tiles.map(tileV14.up),
    };
}

export function down(prev: IDashboardV24, addWarning: AddDashboardWarning): IDashboardV23 {
    return {
        ...prev,
        schema_version: '23',
        tiles: prev.tiles.map((t) => tileV14.down(t, addWarning)),
    };
}
