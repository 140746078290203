import type { ITileV4 } from '../tile/4';
import * as tileV4 from '../tile/4';
import type { IDashboardV5 } from './5';

export interface IDashboardV6 extends Omit<IDashboardV5, 'schema_version' | 'tiles'> {
    schema_version: '6';
    tiles: readonly ITileV4[];
}

/**
 * Updates tiles to v4
 */
export function up(prev: IDashboardV5): IDashboardV6 {
    return {
        ...prev,
        schema_version: '6',
        tiles: prev.tiles.map((t) => tileV4.up(t)),
    };
}
