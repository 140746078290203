import type { UDataSourceV4 } from '../dataSource/4';
import * as parameterV8 from '../parameter/8';
import type { SharedQueryV3 } from '../query/3';
import * as tileV25 from '../tile/25';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV38 } from './38';

/**
 * Cleanup migration
 */
export interface IDashboardV39 {
    readonly schema_version: '39';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV25.ITileV25[];
    readonly pages: readonly IPageV3[];
    readonly baseQueries: readonly SharedQueryV3[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly parameterV8.IParameterV8[];
}

export function up(prev: IDashboardV38): IDashboardV39 {
    return {
        schema_version: '39',
        title: prev.title,
        autoRefresh: prev.autoRefresh,
        tiles: prev.tiles.map(tileV25.up),
        pages: prev.pages,
        baseQueries: prev.sharedQueries,
        dataSources: prev.dataSources,
        parameters: prev.parameters.map(parameterV8.up),
    };
}

export function down(prev: IDashboardV39): IDashboardV38 {
    return {
        schema_version: '38',
        title: prev.title,
        autoRefresh: prev.autoRefresh,
        tiles: prev.tiles.map(tileV25.down),
        pages: prev.pages,
        sharedQueries: prev.baseQueries,
        dataSources: prev.dataSources,
        parameters: prev.parameters.map(parameterV8.down),
    };
}
