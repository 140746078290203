import type { ITileV5 } from '../tile/5';
import * as tileV5 from '../tile/5';
import type { IDashboardV6 } from './6';

export interface IDashboardV7 extends Omit<IDashboardV6, 'schema_version' | 'tiles'> {
    schema_version: '7';
    tiles: readonly ITileV5[];
}

/**
 * Updates tiles to v5
 */
export function up(prev: IDashboardV6): IDashboardV7 {
    return {
        ...prev,
        schema_version: '7',
        tiles: prev.tiles.map((t) => tileV5.up(t)),
    };
}
