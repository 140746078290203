import type { AddDashboardWarning } from '../..';
import type { DashboardV44 } from '../44';
import { patchV44 } from './corruptionPatches';

/**
 * Nothing new here. This version patches corruptions.
 *
 * Actually, in hindsight this was a botched migration. See below.
 */
export interface DashboardV45 extends Omit<DashboardV44, 'schema_version'> {
    readonly schema_version: '45';
}

export function up(prev: DashboardV44, addWarning: AddDashboardWarning): DashboardV45 {
    /**
     * The line below was incorrect. We should have been spreading the patched dashboard
     * onto the return object. Keeping things as-is for recording purposes and we have now
     * applied the patches meant for v44 in v49's migration.
     *
     * @example
     * return {
     *  ...patchV44(prev, addWarning),
     *  schema_version: '45',
     * };
     */
    patchV44(prev, addWarning);

    return {
        ...prev,
        schema_version: '45',
    };
}

export function down(prev: DashboardV45): DashboardV44 {
    return {
        ...prev,
        schema_version: '44',
    };
}
