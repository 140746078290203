import { LegendVisibility } from '@kusto/charting';
import * as client from '@kusto/client';
import { assertNever, ok, Result, UField } from '@kusto/utils';
import type * as Fwk from '@kusto/visual-fwk';
import { HeuristicsProps } from '@kusto/visual-fwk';
import {
    ExtendedVisualizationOptions,
    HighchartsAndMapChartProps,
    kustoHeuristics,
    KustoHeuristicsErr,
    KustoHeuristicsOk,
} from '@kusto/visualizations';

import { defaultVisualOptions } from '../../charting';
import { KweRtdVisualContext } from '../../context';
import { Interaction } from '../interaction';
import type { RtdHighchartsType } from './highCharts';
import type { AnomalyChartModelDef, HighChartsModelDef } from './model';

export interface RtdHighchartsHeuristicsOk {
    xColumn: undefined | string;
    yColumns: undefined | string[];
    seriesColumns: undefined | string[];
    kustoHeuristics: KustoHeuristicsOk;
}

export interface HeuristicsSuccess
    extends Interaction.HeuristicsSuccess<RtdHighchartsHeuristicsOk, KustoHeuristicsErr> {
    Visualization: HighchartsAndMapChartProps['Visualization'];
    visualizationOptions: HighchartsAndMapChartProps['visualizationOptions'];
}

export type Heuristics = null | HeuristicsSuccess;

function kustoLikeVisualTypeFromRtdType(type: RtdHighchartsType): {
    Visualization: HighchartsAndMapChartProps['Visualization'];
    Kind: client.Kind;
} {
    let Visualization: HighchartsAndMapChartProps['Visualization'];
    let Kind: client.Kind;

    switch (type) {
        case 'area':
            Visualization = 'areachart';
            Kind = null;
            break;
        case 'bar':
            Visualization = 'barchart';
            Kind = null;
            break;
        case 'column':
            Visualization = 'columnchart';
            Kind = null;
            break;
        case 'line':
            Visualization = 'linechart';
            Kind = null;
            break;
        case 'scatter':
            Visualization = 'scatterchart';
            Kind = null;
            break;
        case 'stackedarea':
            Visualization = 'areachart';
            Kind = 'stacked';
            break;
        case 'stackedbar':
            Visualization = 'barchart';
            Kind = 'stacked';
            break;
        case 'stackedcolumn':
            Visualization = 'columnchart';
            Kind = 'stacked';
            break;
        case 'stacked100area':
            Visualization = 'areachart';
            Kind = 'stacked100';
            break;
        case 'stacked100bar':
            Visualization = 'barchart';
            Kind = 'stacked100';
            break;
        case 'stacked100column':
            Visualization = 'columnchart';
            Kind = 'stacked100';
            break;
        case 'timechart':
            Visualization = 'timechart';
            Kind = null;
            break;
        case 'anomalychart':
            Visualization = 'anomalychart';
            Kind = null;
            break;
        default:
            assertNever(type);
    }

    return { Visualization, Kind };
}

export function convertLegendTypes(hideLegend?: boolean): LegendVisibility {
    switch (hideLegend) {
        case true:
            return 'hidden';
        case false:
            return 'visible';
        case undefined:
            return null;
    }
}

/**
 * Guess anomaly column
 */
function isAnomalyColumn(field: UField) {
    // Check if data is series formatted
    if (field.type !== 'dynamic' || field.values.length === 0) {
        return false;
    }

    const firstValue = field.values[0];

    // Series formatted anomaly columns only contain -1, 0, or 1
    return Array.isArray(firstValue) && firstValue.every((x) => x === 0 || x === 1 || x === -1);
}

function buildVisualizationOptions(
    queryResult: Fwk.DataVisualPropsQueryResult,
    { visualOptions, visualType }: Fwk.HeuristicsProps<HighChartsModelDef>,
    anomalyColumns: undefined | readonly string[]
): { Visualization: HighchartsAndMapChartProps['Visualization']; visualizationOptions: ExtendedVisualizationOptions } {
    const { Visualization, Kind } = kustoLikeVisualTypeFromRtdType(visualType as RtdHighchartsType);

    return {
        Visualization,
        visualizationOptions: {
            ...defaultVisualOptions,
            Visualization,
            Title: null,
            XColumn: visualOptions.xColumn,
            // Cast to remove readonly
            Series: visualOptions.seriesColumns as null | string[],
            YColumns: visualOptions.yColumns as null | string[],
            XTitle: visualOptions.xColumnTitle ?? null,
            XAxis: visualOptions.xAxisScale ?? null,
            Legend: convertLegendTypes(visualOptions.hideLegend),
            YSplit: null,
            Accumulate: false,
            IsQuerySorted: queryResult.sorted ?? false,
            Kind,
            VerticalLine: visualOptions.verticalLine,
            MultipleYAxes: visualOptions.multipleYAxes,
            AnomalyColumns: anomalyColumns ? (anomalyColumns as string[]) : null,
        },
    };
}

function selectSeriesOrYColumns(
    metaData: Kusto.Charting.IChartMetaData,
    columns: readonly client.KustoColumn[],
    metadataCollection: 'DataIndexes' | 'SeriesIndexes',
    columnsNamesSorted: string[] = []
): undefined | string[] {
    const collection = metaData[metadataCollection];
    if (!collection) {
        return undefined;
    }
    const enumerator = collection.GetEnumerator();
    const columnNames: string[] = [];
    /**
     * Should call moveNext before we start, since the numerator is placed before the first element after init.
     * Can checkout this for ref: https://docs.microsoft.com/dotnet/api/system.collections.ienumerator.movenext?view=net-6.0
     */
    while (enumerator.moveNext()) {
        const index = enumerator.Current;
        // Argument column gets mixed in here for some reason. Haven't dug deep
        // into why, could be a bug or I may not understand what DataIndexes or
        // SeriesIndex represents
        if (index !== metaData.ArgumentDataColumnIndex) {
            columnNames.push(columns[index].field);
        }
    }

    const indexMap = new Map(columnsNamesSorted.map((name, index) => [name, index]));
    columnNames.sort((a, b) => {
        const indexA = indexMap.get(a) ?? columnsNamesSorted.length;
        const indexB = indexMap.get(b) ?? columnsNamesSorted.length;
        return indexA - indexB;
    });

    return columnNames;
}

function buildHeuristics(
    ctx: Pick<KweRtdVisualContext, 'strings' | 'telemetry'>,
    props: HeuristicsProps<HighChartsModelDef>,
    anomalyColumns: undefined | readonly string[]
) {
    if (!props.queryResult) {
        return null;
    }

    const { Visualization, visualizationOptions } = buildVisualizationOptions(props.queryResult, props, anomalyColumns);
    const columns = client.clientColumnsFromKweColumns(props.queryResult.dataFrame.fields);
    const rows = client.queryAreaRowObjectsFromDataFrame(props.queryResult.dataFrame);

    const hResult = kustoHeuristics(columns, rows, Visualization, visualizationOptions, ctx.strings, ctx.telemetry);

    let result: Result<RtdHighchartsHeuristicsOk, KustoHeuristicsErr>;

    if (hResult.kind === 'ok') {
        let xColumn: undefined | string;
        let yColumns: undefined | string[];
        let seriesColumns: undefined | string[];

        if (hResult.value.metaData) {
            xColumn =
                hResult.value.metaData.ArgumentDataColumnIndex === -1
                    ? undefined
                    : columns[hResult.value.metaData.ArgumentDataColumnIndex].headerName;
            yColumns = selectSeriesOrYColumns(
                hResult.value.metaData,
                columns,
                'DataIndexes',
                visualizationOptions.YColumns ?? []
            );
            seriesColumns = selectSeriesOrYColumns(hResult.value.metaData, columns, 'SeriesIndexes');
            // If Y-columns was inferred (YColumns value is null), we need to update the visual options with the actual values
            visualizationOptions.YColumns = yColumns ?? null;
        }
        result = ok({ kustoHeuristics: hResult.value, xColumn, yColumns, seriesColumns });
    } else {
        result = hResult;
    }

    return {
        visualizationOptions,
        Visualization,
        columns,
        result,
    };
}

export function heuristics(
    ctx: Pick<KweRtdVisualContext, 'strings' | 'telemetry'>
): Fwk.VisualConfigHeuristicsFnc<HighChartsModelDef, null | HeuristicsSuccess> {
    return (props) => {
        return buildHeuristics(ctx, props, undefined);
    };
}

export function anomalyChartHeuristics(
    ctx: Pick<KweRtdVisualContext, 'strings' | 'telemetry'>
): Fwk.VisualConfigHeuristicsFnc<AnomalyChartModelDef, null | HeuristicsSuccess> {
    return (props) => {
        const anomalyColumns =
            props.queryResult && props?.visualOptions?.anomalyColumns?.length
                ? props.visualOptions.anomalyColumns
                : props.queryResult
                ? props.queryResult.dataFrame.fields.filter((col) => isAnomalyColumn(col)).map((x) => x.name)
                : undefined;

        return buildHeuristics(ctx, props, anomalyColumns);
    };
}
