import type { IParameterV0 } from './0';

export interface IParamValueV1<T> {
    /**
     * The value selected by this parameter
     */
    value: T;

    /**
     * A custom string to use when displaying this value
     */
    displayText?: string;
}

export interface IParameterBaseV1<T = unknown, K extends string = string> {
    type: K;
    id: string;
    displayName: string;
    defaultValue: IParamValueV1<T>;
}

/* Duration */

export type TimeUnitsV1 = 'months' | 'weeks' | 'days' | 'hours' | 'minutes';

export interface IDynamicRangeV1 {
    count: number;
    unit: TimeUnitsV1;
}

export interface IFixedRangeV1 {
    start: string;
    end: string;
}

export interface DurationFixedV1 {
    type: 'fixed';
    range: IFixedRangeV1;
}

export interface DurationDynamicV1 {
    type: 'dynamic';
    range: IDynamicRangeV1;
}

export type DurationV1 = DurationFixedV1 | DurationDynamicV1;

export interface DurationParamV1 extends IParameterBaseV1<DurationV1, 'duration'> {
    beginVariableName: string;
    endVariableName: string;
}

/* Primitive */

export interface PrimitiveParameterTypesV1 {
    string: string;
    int: number;
    double: number;
    long: string;
    decimal: string;
    bool: boolean;
    datetime: string;
}

export interface PrimitiveParameterStaticOptionsV1<T> {
    type: 'static';
    options: Array<IParamValueV1<T>>;
}

export interface PrimitiveParameterQueryOptionsV1 {
    type: 'query';
    query: string;
    dataSourceId: string;
    /**
     * Added later, optional for backwards compatibility, because they cannot be
     * inferred without running a query, and thus, cannot be added with a migration
     */
    columns?: {
        value: string;
        label?: string;
    };
}

export type PrimitiveParameterTypeV1 = keyof PrimitiveParameterTypesV1;

export interface IPrimitiveParameterV1<K extends PrimitiveParameterTypeV1 = PrimitiveParameterTypeV1>
    extends IParameterBaseV1<PrimitiveParameterTypesV1[K], K> {
    /**
     * The name of the parameter to be referenced in a query
     */
    variableName: string;

    dataSource: PrimitiveParameterStaticOptionsV1<PrimitiveParameterTypesV1[K]> | PrimitiveParameterQueryOptionsV1;
}

/* --- */

export type IParameterV1 = { $schema: '1.0' } & (IPrimitiveParameterV1 | DurationParamV1);

export type ParameterTypeV1 = IParameterV1['type'];

export function up({
    id,
    name,
    displayName,
    query,
    databaseSourceId,
    options,
    defaultValue,
}: IParameterV0): IParameterV1 {
    const dataSource: PrimitiveParameterStaticOptionsV1<string> | PrimitiveParameterQueryOptionsV1 =
        query?.trim() && databaseSourceId?.trim()
            ? {
                  type: 'query',
                  query,
                  dataSourceId: databaseSourceId,
              }
            : {
                  type: 'static',
                  options:
                      options?.map(
                          (o): IParamValueV1<string> => ({
                              value: o.key,
                              displayText: o.value,
                          })
                      ) ?? [],
              };

    return {
        $schema: '1.0',
        type: 'string',
        id,
        dataSource,
        variableName: name,
        displayName: displayName,
        defaultValue: {
            value: defaultValue.key,
            displayText: defaultValue.value,
        },
    };
}
