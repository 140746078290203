import type { AddDashboardWarning } from '..';
import { TableSchemaV2, tableSchemaV2Down, tableSchemaV2Up } from '../value/2';
import type { QueryPropertyInlineV1, QueryPropertyV1, SharedQueryV1 } from './1';
import type { SharedQueryV2 } from './2';

export interface QueryPropertyRefV3 {
    readonly kind: 'ref';
    /**
     * RFC 4122 uuid
     *
     * @see {@link https://datatracker.ietf.org/doc/html/rfc4122}
     */
    readonly baseQueryId: string;
}

export type QueryPropertyV3 = QueryPropertyRefV3 | QueryPropertyInlineV1;

export function upQueryProperty(prev: QueryPropertyV1): QueryPropertyV3 {
    if (prev.kind === 'ref') {
        return {
            kind: 'ref',
            baseQueryId: prev.sharedQueryId,
        };
    }
    return prev;
}

export function downQueryProperty(prev: QueryPropertyV3): QueryPropertyV1 {
    if (prev.kind === 'ref') {
        return {
            kind: 'ref',
            sharedQueryId: prev.baseQueryId,
        };
    }
    return prev;
}

export interface SharedQueryV3 extends SharedQueryV1 {
    readonly schema: TableSchemaV2;
}

export function up(prev: SharedQueryV2): SharedQueryV3 {
    return {
        ...prev,
        schema: tableSchemaV2Up(prev.schema),
    };
}

export function down(prev: SharedQueryV3, addWarning: AddDashboardWarning): SharedQueryV2 {
    return { ...prev, schema: tableSchemaV2Down(prev.schema, addWarning, prev.variableName) };
}
