import type { ITileV2 } from './2';
import type { ITileV10, IVisualOptionsV10 } from './10';

export type PieLabelOptionsV11 = 'percentage' | 'value' | 'name';

export interface IVisualOptionsV11 extends IVisualOptionsV10 {
    readonly labelDisabled: boolean;
    readonly tooltipDisabled: boolean;
    readonly pie__label: readonly PieLabelOptionsV11[];
    readonly pie__tooltip: readonly PieLabelOptionsV11[];
    readonly pie__orderBy: 'none' | 'name' | 'size';
    readonly pie__kind: 'pie' | 'donut';
}

export interface ITileV11 extends Omit<ITileV2, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV11>;
}

export function up(prev: ITileV10): ITileV11 {
    const visualOptions: Partial<IVisualOptionsV11> = { ...prev.visualOptions };
    return { ...prev, visualOptions };
}

export function down(prev: ITileV11): ITileV10 {
    // Removing the new options
    const { labelDisabled, tooltipDisabled, pie__label, pie__tooltip, pie__orderBy, pie__kind, ...prevVisualOptions } =
        prev.visualOptions;
    const visualOptions: Partial<IVisualOptionsV10> = { ...prevVisualOptions };
    return { ...prev, visualOptions };
}
