import uniq from 'lodash/uniq';

import { formatLiterals } from '@kusto/utils';

import type { PatchOne } from '../types';
import { clean, corrupted } from '../utils';

// Example input:
// "visualOptions": {
//     "seriesColumns": {
//         "type": "specified",
//         "value": [
//             null
//         ]
//     }
// }
//
// Schema error:
// {
//     "message": "must be array,null",
//     "instancePath": "/tiles/14/visualOptions/seriesColumns",
//     "schemaPath": "#/properties/visualOptions/properties/seriesColumns/type",
//     "keyword": "type",
//     "params": {
//       "type": [
//         "array",
//         "null"
//       ]
//     }
// }
export const seriesColumnsMustBeArrayOrNullConfig: PatchOne<'tiles'> = {
    kind: 'patch-one',
    corruption(tile) {
        const { seriesColumns } = tile.visualOptions;

        if (seriesColumns === undefined || seriesColumns === null || Array.isArray(seriesColumns)) {
            return clean;
        }

        return corrupted(undefined);
    },
    patch(tile, _, addWarning) {
        const { seriesColumns } = tile.visualOptions;

        // Need to cast because somehow seriesColumns is using our old Infer<T> interface
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const casted = seriesColumns as any;

        if (casted.type === 'specified') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const columns: Array<string | boolean | number> = (casted.value as any[]).filter((value) => {
                switch (typeof value) {
                    case 'boolean':
                    case 'string':
                    case 'number':
                        return true;
                    default:
                        return false;
                }
            });

            if (columns.length) {
                const patchedSeriesColumns = uniq(
                    columns.map<string>((value) => {
                        if (typeof value !== 'string') {
                            const newValue = String(value);
                            addWarning((t) =>
                                formatLiterals(t.up.v44Patches.tile.seriesColumnsMustBeArrayOrNull.castedToString, {
                                    tileTitle: tile.title,
                                    oldValue: newValue, // technically `newValue` is the same thing here since we need to stringify for formatLiterals
                                    newValue,
                                })
                            );
                            return newValue;
                        }

                        return value;
                    })
                );

                return {
                    ...tile,
                    visualOptions: {
                        ...tile.visualOptions,
                        seriesColumns: patchedSeriesColumns,
                    },
                };
            }
        }

        // let's just assume we should infer the seriesColumns as a base case
        addWarning((t) =>
            formatLiterals(t.up.v44Patches.tile.seriesColumnsMustBeArrayOrNull.castedToInfer, { tileTitle: tile.title })
        );

        return {
            ...tile,
            visualOptions: {
                ...tile.visualOptions,
                seriesColumns: null,
            },
        };
    },
};
