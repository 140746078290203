import * as parameterV3 from '../parameter/3';
import type { IDashboardV17 } from './17';

export interface IDashboardV18 extends Omit<IDashboardV17, 'schema_version' | 'pinnedParameters' | 'parameters'> {
    schema_version: '18';
    parameters: readonly parameterV3.IParameterV3[];
}

export function up({ pinnedParameters, ...prev }: IDashboardV17): IDashboardV18 {
    const parameters = prev.parameters.map((prevParam) => parameterV3.up(prevParam, pinnedParameters));

    return {
        ...prev,
        schema_version: '18',
        parameters,
    };
}

export function down(prev: IDashboardV18): IDashboardV17 {
    const pinnedParameters: string[] = [];

    for (const param of prev.parameters) {
        const isPinned =
            param.showOnPages.kind === 'all'
                ? true
                : /**
                   * As long as `pageIds` is not empty
                   * then we can consider it pinned
                   */
                  param.showOnPages.pageIds.length > 0;

        if (isPinned) {
            pinnedParameters.push(param.id);
        }
    }

    return {
        ...prev,
        schema_version: '17',
        pinnedParameters,
        parameters: prev.parameters.map(parameterV3.down),
    };
}
