import type { AddDashboardWarning } from '..';
import type { ITileV7 } from '../tile/7';
import * as tileV8 from '../tile/8';
import type { IDashboardV14 } from './14';

export interface IDashboardV15 extends Omit<IDashboardV14, 'schema_version' | 'tiles'> {
    schema_version: '15';
    tiles: readonly tileV8.ITileV8[];
}

export function up(prev: IDashboardV14): IDashboardV15 {
    return {
        ...prev,
        schema_version: '15',
        tiles: prev.tiles.map((t) => tileV8.up(t)),
    };
}

export function down(prev: IDashboardV15, addWarning: AddDashboardWarning): IDashboardV14 {
    const removedHorizontalLines: string[] = [];
    const tiles: readonly ITileV7[] = prev.tiles.map((t) => {
        const res = tileV8.down(t);
        if (Object.keys(res.removedHorizontalLines).length > 0) {
            const aggregatedMessage = Object.keys(res.removedHorizontalLines).map(
                (colName) => `column: ${colName}, values: ${res.removedHorizontalLines[colName].join(', ')}`
            );
            removedHorizontalLines.push(`${res.data.title}: ${aggregatedMessage}`);
        }
        return res.data;
    });

    if (removedHorizontalLines.length > 0) {
        addWarning((t) => t.down.v15.removedHorizontalLinesConfig + removedHorizontalLines.join('\n'));
    }
    return {
        ...prev,
        schema_version: '14',
        tiles,
    };
}
