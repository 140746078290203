import type { AddDashboardWarning } from '..';
import { downQueryProperty, QueryPropertyV1, upQueryProperty } from '../query/1';
import type { BasicParameterTypesV1 } from '../value/1';
import type { BasicParamV2 } from './2';
import type { BasicParamV3, DurationParamV3, IParameterBaseV3 } from './3';
import type { IParameterV4 } from './4';

export declare namespace BasicParamV5 {
    export interface DropdownQueryDataSource {
        readonly kind: 'query';
        readonly query: QueryPropertyV1;
        readonly columns: {
            readonly value?: string;
            readonly label?: string;
        };
    }

    export type DropdownDataSource<T> = BasicParamV2.DropdownStaticDataSource<T> | DropdownQueryDataSource;

    export interface IDropdown<T, K> extends BasicParamV3.IBasic<T, K> {
        readonly selectionType: 'single' | 'single-all' | 'multi';
        readonly dataSource: DropdownDataSource<T>;
    }
}

export type TBasicParamV5<T, K> = BasicParamV3.IFreetext<T, K> | BasicParamV5.IDropdown<T, K>;

export type UBasicParamV5 =
    | TBasicParamV5<BasicParameterTypesV1['string'], 'string'>
    | TBasicParamV5<BasicParameterTypesV1['int32'], 'int32'>
    | TBasicParamV5<BasicParameterTypesV1['int64'], 'int64'>
    | TBasicParamV5<BasicParameterTypesV1['float64'], 'float64'>
    | TBasicParamV5<BasicParameterTypesV1['decimal'], 'decimal'>
    | TBasicParamV5<BasicParameterTypesV1['bool'], 'bool'>
    | TBasicParamV5<BasicParameterTypesV1['datetime'], 'datetime'>;

export declare namespace DataSourceParamV5 {
    export interface IParam extends IParameterBaseV3 {
        readonly kind: 'dataSource';
        readonly defaultValue: {
            /**
             * dupe `kind` here to reflect the
             * the JSON schema as its needed to
             * conditionally require properties
             * for data source parameters
             */
            readonly kind: 'dataSource';
            readonly dataSourceId: string;
        };
        readonly dataSource: {
            /**
             * Dupe `kind` here to reflect
             * the JSON schema. Only helpful
             * for customers directly editing
             * the JSON file to avoid
             * being confused about seeing
             * repeated `dataSource` property.
             */
            readonly kind: 'dataSource';
            readonly values:
                | {
                      /**
                       * Select all data sources
                       * to be used for the parameter
                       * values
                       */
                      readonly kind: 'all';
                  }
                | {
                      /**
                       * List of manually selected
                       * data source IDs that
                       * are available to viewers.
                       * This lets editors only
                       * select certain options
                       * that make sense for their
                       * dashboard.
                       */
                      readonly kind: 'selection';
                      /**
                       * Array of Data Source IDs
                       */
                      readonly value: readonly string[];
                  };
        };
    }
}

export type IParameterV5 = UBasicParamV5 | DurationParamV3.IParam | DataSourceParamV5.IParam;

/** helper type */
export type IParameterV5WithoutDataSourceParam = UBasicParamV5 | DurationParamV3.IParam;

export function up(prev: IParameterV4): IParameterV5 {
    if ('dataSource' in prev && prev.dataSource.kind === 'query') {
        return {
            ...prev,
            dataSource: {
                ...prev.dataSource,
                query: upQueryProperty(prev.dataSource.query),
            },
        };
    }

    return prev as IParameterV5;
}

/**
 * @param prev Make sure we don't pass along DataSourceParams here because there's no way to directly migrate them down
 */
export function down(
    prev: UBasicParamV5 | DurationParamV3.IParam,
    parameters: readonly IParameterV5[],
    addWarning: AddDashboardWarning
): IParameterV4 {
    if ('dataSource' in prev && prev.dataSource.kind === 'query') {
        return {
            ...prev,
            dataSource: {
                ...prev.dataSource,
                query: downQueryProperty(prev.dataSource.query, parameters, addWarning),
            },
        };
    }

    return prev as IParameterV4;
}
