import * as Fwk from '@kusto/visual-fwk';

export const highchartsModel = {
    multipleYAxes: {
        base: {
            id: Fwk.BASE_Y_AXIS_ID,
            label: '',
            columns: [],
            yAxisMaximumValue: null,
            yAxisMinimumValue: null,
            yAxisScale: 'linear',
            horizontalLines: [],
        },
        additional: [],
        showMultiplePanels: false,
    },
    hideLegend: false,
    legendLocation: Fwk.DEFAULT_LEGEND_LOCATION,
    xColumnTitle: '',
    xColumn: null,
    yColumns: null,
    seriesColumns: null,
    xAxisScale: 'linear',
    verticalLine: '',
    crossFilter: [],
    crossFilterDisabled: false,
    drillthrough: [],
    drillthroughDisabled: false,
} satisfies Fwk.UnknownVisualOptions;

export type HighChartsModelDef = keyof typeof highchartsModel;

export const anomalyChartModel = {
    ...highchartsModel,
    anomalyColumns: [],
} satisfies Fwk.UnknownVisualOptions;

export type AnomalyChartModelDef = keyof typeof anomalyChartModel;
