import type { IDashboardV37 } from './37';

/**
 * Matching "up" migration corrects invalid dashboards created by new runtime validations. New versions indicate this fix has been applied.
 */
export interface IDashboardV38 extends Omit<IDashboardV37, 'schema_version'> {
    readonly schema_version: '38';
}

/**
 * Corrects time range parameter fixed range default values when the start is
 * after the end
 */
export function up(prev: IDashboardV37): IDashboardV38 {
    for (const parameter of prev.parameters) {
        const defaultValue = parameter.defaultValue;
        // Mutation here is a little scary, but, I'm _pretty_ sure it's safe?
        // Biggest risk is that it might stop being safe in the future.
        //
        // Upside is that this code is going to get run a lot, and this is very fast.
        if (defaultValue.kind === 'fixed' && defaultValue.end < defaultValue.start) {
            defaultValue.end = defaultValue.start;
        }
    }

    return {
        ...prev,
        schema_version: '38',
    };
}

/**
 * Intentionally does nothing but change the schema_version
 */
export function down(prev: IDashboardV38): IDashboardV37 {
    return {
        ...prev,
        schema_version: '37',
    };
}
