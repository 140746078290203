import type { AddDashboardWarning } from '../../../index';
import type { DashboardV44 } from '../../44';
import { dashboardPatches } from './dashboard';
import { patchDataSources } from './dataSources';
import { patchParameters } from './parameters';
import { patchTiles } from './tiles';
import { patchDashboard } from './utils';

export function patchV44(dashboard: DashboardV44, addWarning: AddDashboardWarning): DashboardV44 {
    const res1 = patchDashboard<'44'>(dashboard, dashboardPatches, addWarning);
    const res2 = patchDataSources<'44'>(res1, addWarning);
    const res3 = patchParameters<'44'>(res2, addWarning);
    return patchTiles<'44'>(res3, addWarning);
}
