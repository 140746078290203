import type { ITileV2 } from './2';
import type { BaseYAxisConfigV6, YAxisConfigV6 } from './6';
import type { ITileV7, IVisualOptionsV7 } from './7';

const BASE_Y_AXIS_ID = '-1';

const convertStringToNumber = (value?: string): number | null => {
    if (value === '' || value === undefined) {
        return null;
    }
    const num = parseFloat(value);

    if (Number.isNaN(num)) {
        return null;
    }

    return num;
};

type HorizontalLineValue = number | null;
export interface YAxisConfigV8 extends YAxisConfigV6 {
    readonly horizontalLines: readonly HorizontalLineValue[];
}

export interface MultipleYAxesConfigV8 {
    base: YAxisConfigV8;
    additional: readonly YAxisConfigV8[];
}

export interface IVisualOptionsV8 extends Omit<IVisualOptionsV7, 'multipleYAxes'> {
    multipleYAxes: MultipleYAxesConfigV8;
}

export interface ITileV8 extends Omit<ITileV2, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV8>;
}

export function up(prev: ITileV7): ITileV8 {
    const { multipleYAxes, ...noMigrateVisualOptions } = prev.visualOptions;
    const visualOptions: Partial<IVisualOptionsV8> = noMigrateVisualOptions;

    if (multipleYAxes) {
        const { horizontalLine, ...nonHorizontalLineProperties } = multipleYAxes.base;
        const prevAdditionalAxes = multipleYAxes.additional;
        const horizontalLineAsNumber = convertStringToNumber(horizontalLine);
        const updatedBaseAxis: YAxisConfigV8 = {
            ...nonHorizontalLineProperties,
            horizontalLines: horizontalLineAsNumber !== null ? [horizontalLineAsNumber] : [],
        };
        const updatedAdditionalAxes: YAxisConfigV8[] = prevAdditionalAxes.map((axis) => ({
            ...axis,
            horizontalLines: [],
        }));
        visualOptions.multipleYAxes = { base: updatedBaseAxis, additional: updatedAdditionalAxes };
    }

    return { ...prev, visualOptions };
}

export function down(prev: ITileV8): { data: ITileV7; removedHorizontalLines: Record<string, number[]> } {
    const { multipleYAxes, ...noMigrateVisualOptions } = prev.visualOptions;
    const visualOptions: Partial<IVisualOptionsV7> = noMigrateVisualOptions;
    const removedHorizontalLines: Record<string, number[]> = {};
    if (multipleYAxes) {
        const { horizontalLines, ...nonHorizontalLinesProperties } = multipleYAxes.base;
        const prevAdditionalAxes = multipleYAxes.additional;
        const updatedBaseAxis: BaseYAxisConfigV6 = {
            ...nonHorizontalLinesProperties,
            horizontalLine: horizontalLines[0]?.toString() ?? '',
        };
        if (horizontalLines.length > 1) {
            removedHorizontalLines[BASE_Y_AXIS_ID] = [];
            horizontalLines.forEach((val, index) => {
                if (index === 0 || val === null) {
                    return;
                }
                removedHorizontalLines[BASE_Y_AXIS_ID].push(val);
            });
        }
        const updatedAdditionalAxes: YAxisConfigV6[] = prevAdditionalAxes.map((axis) => {
            const { horizontalLines: axisHorizontalLines, ...baseConfig } = axis;
            if (axisHorizontalLines.length > 0) {
                const columnName = axis.columns[0];
                const linesWithValue: number[] = axisHorizontalLines.filter((line) => line !== null) as number[];
                removedHorizontalLines[columnName] = [...linesWithValue];
            }
            return baseConfig;
        });
        visualOptions.multipleYAxes = { base: updatedBaseAxis, additional: updatedAdditionalAxes };
    }

    return { data: { ...prev, visualOptions }, removedHorizontalLines };
}
