import React from 'react';
import { observer } from 'mobx-react-lite';

import { Theme } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import { HighchartsAndMapChart } from '@kusto/visualizations';

import { RtdVisualTypes } from '../../charting';
import { standardSizes } from '../../constants';
import { KweRtdVisualContext } from '../../context';
import { useCrossFilterEvents } from '../interaction/useCrossFilterEvents';
import { useDrillthroughEvents } from '../interaction/useDrillthroughEvents';
import { getHeuristicsErrMessage, mergeChartEvents } from '../util';
import { anomalyChartHeuristics, Heuristics, heuristics } from './heuristics';
import { createAnomalyInputLayout, createInputLayout } from './inputLayout';
import { anomalyChartModel, AnomalyChartModelDef, highchartsModel, HighChartsModelDef } from './model';

export function createComponent(
    ctx: KweRtdVisualContext
): React.FC<Fwk.IDataVisualProps<HighChartsModelDef, Heuristics>> {
    return observer(function RtdHighchartsVisual(props) {
        const crossFilterEvents = useCrossFilterEvents(
            ctx,
            props.visualOptions,
            props.heuristics,
            props.dashboard,
            props.queryResult
        );
        const drillthroughEvents = useDrillthroughEvents(ctx, props.visualOptions, props.heuristics, props.dashboard);
        const interactiveEvents = React.useMemo(
            () => mergeChartEvents(crossFilterEvents, drillthroughEvents),
            [crossFilterEvents, drillthroughEvents]
        );

        if (!props.heuristics) {
            ctx.telemetry.exception('Highcharts heuristics should only be null if there is no query result.', {
                isQueryResultEmpty: !props.queryResult,
            });
            return null;
        }

        const heuristics = props.heuristics.result;

        if (heuristics.kind === 'err') {
            const { err } = heuristics;
            return props.formatMessage(getHeuristicsErrMessage(err));
        }

        const heuristicsResult = heuristics.value;

        return (
            <HighchartsAndMapChart
                {...ctx.chartProps}
                azureMapSubscriptionKey={ctx.azureMapSubscriptionKey}
                strings={ctx.strings}
                telemetry={ctx.telemetry}
                theme={props.isDarkTheme ? Theme.Dark : Theme.Light}
                heuristics={heuristicsResult.kustoHeuristics}
                Visualization={props.heuristics.Visualization}
                visualizationOptions={props.heuristics.visualizationOptions}
                legendPosition={props.visualOptions.legendLocation}
                enableInteractiveLegend={props.featureFlags.EnableInteractiveLegend}
                enableSharedCrosshair={props.featureFlags.EnableSharedCrosshair}
                sharedCrosshair={props.dashboard?.sharedCrosshair}
                disableAnimation
                timezone={props.timeZone}
                locale={props.locale}
                formatMessage={props.formatMessage}
                events={{
                    ...ctx.chartEvents,
                    ...interactiveEvents,
                }}
            />
        );
    });
}

export type RtdHighchartsType = Exclude<
    RtdVisualTypes,
    'table' | 'map' | 'card' | 'multistat' | 'markdown' | 'pie' | 'heatmap' | 'plotly' | 'funnel'
>;

export function highchartsHeuristicsConfig(
    ctx: Pick<KweRtdVisualContext, 'strings' | 'telemetry'>
): Readonly<Fwk.ResolvedHeuristicsTypeConfig<HighChartsModelDef, Heuristics>> {
    return {
        model: highchartsModel,
        heuristics: heuristics(ctx),
    };
}

export function anomalyChartConfig(ctx: KweRtdVisualContext): Fwk.VisualTypeConfig<AnomalyChartModelDef, Heuristics> {
    return {
        label: ctx.strings.rtdProvider.visuals.highcharts.visualTypeLabels.anomalychart,
        iconName: 'AnalyticsQuery',
        config: {
            Component: createComponent(ctx),
            inputLayout: createAnomalyInputLayout(ctx),
            model: anomalyChartModel,
            heuristics: anomalyChartHeuristics(ctx),
            ...standardSizes,
        },
    };
}

export function highchartsConfigs(
    ctx: KweRtdVisualContext
): Readonly<Record<Exclude<RtdHighchartsType, 'anomalychart'>, Fwk.VisualTypeConfig<HighChartsModelDef, Heuristics>>> {
    const config = {
        Component: createComponent(ctx),
        inputLayout: createInputLayout(ctx),
        ...highchartsHeuristicsConfig(ctx),
        ...standardSizes,
    };

    const visualTypeLabels = ctx.strings.rtdProvider.visuals.highcharts.visualTypeLabels;

    return {
        area: {
            label: visualTypeLabels.area,
            iconName: 'LineChart',
            config,
        },
        bar: {
            label: visualTypeLabels.bar,
            iconName: 'BarChartHorizontal',
            config,
        },
        column: {
            label: visualTypeLabels.column,
            iconName: 'BarChartVertical',
            config,
        },
        line: {
            label: visualTypeLabels.line,
            iconName: 'LineChart',
            config,
        },
        scatter: {
            label: visualTypeLabels.scatter,
            iconName: 'ScatterChart',
            config,
        },
        stackedarea: {
            label: visualTypeLabels.stackedarea,
            iconName: 'AreaChart',
            config,
        },
        stackedbar: {
            label: visualTypeLabels.stackedbar,
            iconName: 'StackedBarChart',
            config,
        },
        stackedcolumn: {
            label: visualTypeLabels.stackedcolumn,
            iconName: 'StackedColumnChart2',
            config,
        },
        stacked100area: {
            label: visualTypeLabels.stacked100area,
            iconName: 'AreaChart',
            config,
        },
        stacked100bar: {
            label: visualTypeLabels.stacked100bar,
            iconName: 'dashboards-Stacked100BarChart',
            config,
        },
        stacked100column: {
            label: visualTypeLabels.stacked100column,
            iconName: 'StackColumnChart',
            config,
        },
        timechart: {
            label: visualTypeLabels.timechart,
            iconName: 'Clock',
            config,
        },
    };
}
