import { assertUnreachable } from '../../../../../../common/error';
import type { PatchOne } from '../types';
import { clean, resultFrom, StableArrayDeleter } from '../utils';

// {"instancePath":"/parameters/8/defaultValue/values/0","message":"The value must be a number"}
// {"instancePath":"/parameters/4/defaultValue/values/2","message":"The value must be a string"}

/**
 * We expect the default values to either be `string`, `number`, or `boolean`
 * but somehow our default values are either: `Object`, `Array`, or `null`
 * so we should just delete those bad values because there's no way
 * to keep them around (e.g. cast to a string or number) without it making
 * much sense
 */
export const defaultValuesTypeNotAllowedConfig: PatchOne<'parameters', number[]> = {
    kind: 'patch-one',
    corruption(parameter) {
        if (parameter.defaultValue.kind !== 'values') {
            return clean;
        }

        const badDefaultValueIndexes: number[] = [];

        for (let i = 0; i < parameter.defaultValue.values.length; i++) {
            const val = parameter.defaultValue.values[i] as unknown;

            switch (typeof val) {
                case 'string':
                case 'boolean':
                case 'number':
                    continue;
                default:
                    badDefaultValueIndexes.push(i);
            }
        }

        return resultFrom(badDefaultValueIndexes);
    },
    patch(parameter, badDefaultValueIndexes) {
        if (parameter.defaultValue.kind === 'values') {
            const patchedValues = [...parameter.defaultValue.values];
            const deleter = new StableArrayDeleter(patchedValues);

            for (const targetIndex of badDefaultValueIndexes) {
                deleter.delete(targetIndex);
            }

            return {
                ...parameter,
                defaultValue: {
                    ...parameter.defaultValue,
                    values: patchedValues,
                },
                // casting because TS can't infer this is correct
            } as typeof parameter;
        }

        if (process.env.NODE_ENV !== 'production') {
            assertUnreachable('Mismatch on kind for parameter defaultValue');
        }
        return parameter;
    },
};
