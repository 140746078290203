import type { AddDashboardWarning } from '../index';
import * as tileV30 from '../tile/30';
import type { DashboardV52 } from './52';

export interface DashboardV53 extends Omit<DashboardV52, 'schema_version' | 'tiles'> {
    readonly schema_version: '53';
    readonly tiles: readonly tileV30.ITileV30[];
}

export function up(prev: DashboardV52): DashboardV53 {
    return {
        ...prev,
        schema_version: '53',
    };
}

export function down(prev: DashboardV53, addWarning: AddDashboardWarning): DashboardV52 {
    return {
        ...prev,
        tiles: prev.tiles.map((t) => tileV30.down(t, addWarning)),
        schema_version: '52',
    };
}
