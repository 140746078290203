import React from 'react';
import { Badge, MessageBarIntent, tokens } from '@fluentui/react-components';

const intentToColorMap: { [intent in MessageBarIntent]: string } = {
    success: tokens.colorStatusSuccessForeground3,
    error: tokens.colorStatusDangerForeground1,
    warning: tokens.colorStatusWarningBackground3,
    info: tokens.colorNeutralForeground4,
};

export interface PillProps {
    intent: MessageBarIntent;
    text: string;
}

export function Pill({ intent, text }: PillProps) {
    const backgroundColor = intentToColorMap[intent];

    return (
        <Badge appearance="filled" size="small" style={{ backgroundColor }} data-testid="status-pill">
            {text}
        </Badge>
    );
}
