import type { ITileV7 } from '../tile/7';
import type { IDashboardV11 } from './11';

export interface IDashboardV12 extends Omit<IDashboardV11, 'schema_version'> {
    schema_version: '12';
}

/**
 * This 👇 interface was getting mixed into the visual options
 * ```typescript
 * export interface ETPVisual {
 *   readonly options: ETPVisualOptions;
 *   readonly visualType: string;
 *   readonly configurationOpen: boolean;
 * }
 * ```
 * This function deletes the extra properties
 */
function correctTileCorruption(t: ITileV7): ITileV7 {
    const options: Partial<Record<string, unknown>> = t.visualOptions;

    delete options['options'];
    delete options['visualType'];
    delete options['configurationOpen'];

    return t;
}

export function up(prev: IDashboardV11): IDashboardV12 {
    return {
        ...prev,
        tiles: prev.tiles.map(correctTileCorruption),
        schema_version: '12',
    };
}

export function down(prev: IDashboardV12): IDashboardV11 {
    return { ...prev, schema_version: '11' };
}
