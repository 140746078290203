import { isValidUUID } from '@kusto/utils';

import type { PatchOne } from '../types';
import { resultFrom } from '../utils';

// {
//     "message": "must match format \"uuid\"",
//     "instancePath": "/tiles/0/visualOptions/colorRules/0/id",
//     "schemaPath": "#/$defs/colorRule/properties/id/format",
//     "keyword": "format",
//     "params": {
//       "format": "uuid"
//     }
// }
//
// We had a short issue where our PII redactor was messing with dashboard state.
// so we need to generate a new ID

export interface Data {
    id: string;
    targetIndex: number;
}

export const badColorRulesIdFormatConfig: PatchOne<'tiles', Data[]> = {
    kind: 'patch-one',
    corruption(tile) {
        const data: Data[] = [];
        const colorRules = tile.visualOptions.colorRules;

        if (colorRules?.length) {
            for (let i = 0; i < colorRules.length; i++) {
                if (!isValidUUID(colorRules[i].id)) {
                    data.push({ id: colorRules[i].id, targetIndex: i });
                }
            }
        }

        return resultFrom(data);
    },
    patch(tile, badColorRuleIndexes) {
        let patchedColorRules = [...tile.visualOptions.colorRules];

        for (const { id, targetIndex } of badColorRuleIndexes) {
            patchedColorRules = patchedColorRules.map((colorRule, index) => {
                if (colorRule.id === id && index === targetIndex) {
                    return {
                        ...colorRule,
                        id: crypto.randomUUID(),
                    };
                }

                return colorRule;
            });
        }

        return {
            ...tile,
            visualOptions: {
                ...tile.visualOptions,
                colorRules: patchedColorRules,
            },
        };
    },
};
