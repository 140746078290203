import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import type { IParameterV6 } from '../parameter/6';
import type { SharedQueryV2 } from '../query/2';
import * as tileV18 from '../tile/18';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV29 } from './29';

export interface IDashboardV30 {
    readonly schema_version: '30';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV18.ITileV18[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly SharedQueryV2[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly IParameterV6[];
}

export function up(prev: IDashboardV29): IDashboardV30 {
    return {
        ...prev,
        schema_version: '30',
        tiles: prev.tiles.map((t) => tileV18.up(t)),
    };
}

export function down(prev: IDashboardV30, addWarning: AddDashboardWarning): IDashboardV29 {
    return {
        ...prev,
        schema_version: '29',
        tiles: prev.tiles.map((t) => tileV18.down(t, addWarning)),
    };
}
