import type { AddDashboardWarning } from '..';
import type { UDataSourceV3 } from '../dataSource/3';
import type { IParameterV3 } from '../parameter/3';
import * as tileV13 from '../tile/13';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV21 } from './21';

export interface IDashboardV22 {
    schema_version: '22';

    title: string;
    autoRefresh?: AutoRefreshConfigV1_2;

    tiles: readonly tileV13.ITileV13[];
    pages: readonly IPageV3[];
    dataSources: readonly UDataSourceV3[];
    parameters: readonly IParameterV3[];
}

export function up(prev: IDashboardV21): IDashboardV22 {
    return {
        ...prev,
        schema_version: '22',
        tiles: prev.tiles.map(tileV13.up),
    };
}

export function down(prev: IDashboardV22, addWarning: AddDashboardWarning): IDashboardV21 {
    return {
        ...prev,
        schema_version: '21',
        tiles: prev.tiles.map((t) => tileV13.down(t, addWarning)),
    };
}
