import type { AddDashboardWarning } from '..';
import * as tileV24 from '../tile/24';
import type { IDashboardV36 } from './36';

export interface IDashboardV37 extends Omit<IDashboardV36, 'tiles' | 'schema_version'> {
    readonly schema_version: '37';
    readonly tiles: readonly tileV24.ITileV24[];
}

export function up(prev: IDashboardV36): IDashboardV37 {
    return {
        ...prev,
        schema_version: '37',
        tiles: prev.tiles.map(tileV24.up),
    };
}

export function down(prev: IDashboardV37, _addWarning: AddDashboardWarning): IDashboardV36 {
    return {
        ...prev,
        schema_version: '36',
        tiles: prev.tiles.map(tileV24.down),
    };
}
