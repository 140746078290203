import type { ITileV0 } from './0';
import type { ColorRulePreSchema } from './preSchema';

export type CanInferV1<T> =
    | { readonly type: 'infer'; readonly value?: undefined }
    | { readonly type: 'specified'; readonly value: T };

export interface IVisualOptionsV1 {
    xColumn: CanInferV1<string>;
    yColumn: CanInferV1<string>;
    yColumns: CanInferV1<string[]>;
    seriesColumns: CanInferV1<string[]>;

    hideLegend: boolean;
    hideDataLabels: boolean;
    hideTileTitle: boolean;
    yAxisRight: boolean;

    yAxisMinimumValue: CanInferV1<number>;
    yAxisMaximumValue: CanInferV1<number>;

    xColumnTitle: string;
    yColumnTitle: string;

    xAxisScale: 'linear' | 'log';
    yAxisScale: 'linear' | 'log';
    legendLocation: 'left' | 'right';

    colorRulesDisabled: boolean;
    colorRules: ColorRulePreSchema[];
    colorStyle: ColorRulePreSchema.ColorStyle;

    /* Line chart */
    /**
     * show/hide line specific indications (pinpoint) on the line
     */
    line__hidePinpointTooltips: boolean;

    /* Map chart */
    map__bubbleFormat: 'dot' | 'bubble' | 'heatmap' | 'pieChart';
    map__minBubbleSizeColumn: CanInferV1<string>;
    map__latitudeColumn: CanInferV1<string>;
    map__longitudeColumn: CanInferV1<string>;

    /* Multi stat chart */
    /**
     * vertical => single column
     * horizontal => single row
     */
    multiStat__displayOrientation: 'vertical' | 'horizontal';
    multiStat__textSize: 'small' | 'large' | 'auto';
    multiStat__labelColumn: CanInferV1<string>;
    multiStat__valueColumn: CanInferV1<string>;
    multiStat__slot: {
        readonly width: number;
        readonly height: number;
    };

    /* Heat map chart */
    heatMap__dataColumn: CanInferV1<string>;

    /* Table */
    table__enableRenderLinks: boolean;
    table__renderLinksForColumns: readonly string[];
}

export interface ITileV1 {
    $schema: '1';
    id: string;
    title: string;
    query: string;
    layout: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    pageId: string;
    visualType: string;
    dataSourceId?: string;
    usedParamVariables: string[];
    visualOptions: Partial<IVisualOptionsV1>;
}

const visualOptionsKeysMinusXColumn = [
    'yColumn',
    'yColumns',
    'seriesColumns',
    'hideLegend',
    'hideDataLabels',
    'hideTileTitle',
    'yAxisRight',
    'yAxisMinimumValue',
    'yAxisMaximumValue',
    'xColumnTitle',
    'yColumnTitle',
    'xAxisScale',
    'yAxisScale',
    'legendLocation',
    'colorRulesDisabled',
    'colorRules',
    'colorStyle',
    'line__hidePinpointTooltips',
    'map__bubbleFormat',
    'map__minBubbleSizeColumn',
    'map__latitudeColumn',
    'map__longitudeColumn',
    'multiStat__displayOrientation',
    'multiStat__textSize',
    'multiStat__labelColumn',
    'multiStat__valueColumn',
    'multiStat__slot',
    'heatMap__dataColumn',
    'table__enableRenderLinks',
    'table__renderLinksForColumns',
] as const;

export function up(prev: ITileV0, pageId: string, allParameterNames: string[]): ITileV1 {
    const visualOptions: Partial<IVisualOptionsV1> = {};

    // // CanInfer<string | undefined> changed to null | string
    if (prev.xColumn) {
        visualOptions.xColumn =
            prev.xColumn.type === 'specified' && prev.xColumn.value
                ? { type: 'specified', value: prev.xColumn.value }
                : { type: 'infer' };
    }

    for (const key of visualOptionsKeysMinusXColumn) {
        if (prev[key]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (visualOptions[key] as any) = prev[key];
        }
    }

    return {
        $schema: '1',
        id: prev.id,
        // None of these defaults should be required in practice, but we things should
        // still work if they aren't.
        title: prev.title ?? '',
        query: prev.query ?? '',
        layout: {
            x: prev.x ?? 0,
            y: prev.y ?? 0,
            width: prev.w ?? 4,
            height: prev.h ?? 4,
        },
        pageId,
        visualType: prev.visualType,
        dataSourceId: prev.dataSourceId,
        // Some tiles were created before we tracked consumed parameters
        usedParamVariables: prev.activeParameters ?? allParameterNames,
        visualOptions,
    };
}
