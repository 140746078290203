import type { UDataSourceV2 } from '../dataSource/2';
import type { IParameterV2 } from '../parameter/2';
import * as tileV6 from '../tile/6';
import type { DashboardConfigOptionsV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV9 } from './9';
import type { IDashboardPreSchema } from './preSchema';

export interface IDashboardV10
    extends Omit<IDashboardPreSchema, 'schema_version' | 'tiles' | 'parameters' | 'dataSources'> {
    schema_version: '10';

    pinnedParameters: string[];
    configuration?: DashboardConfigOptionsV1_2;

    tiles: readonly tileV6.ITileV6[];
    parameters: readonly IParameterV2[];
    dataSources: readonly UDataSourceV2[];
    pages: readonly IPageV3[];
}

export function up(prev: IDashboardV9): IDashboardV10 {
    return {
        ...prev,
        schema_version: '10',
        tiles: prev.tiles.map((t) => tileV6.up(t)),
    };
}

export function down(prev: IDashboardV10): IDashboardV9 {
    return {
        ...prev,
        schema_version: '9',
        tiles: prev.tiles.map((t) => tileV6.down(t)),
    };
}
