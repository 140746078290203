import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { ITileV25, IVisualOptionsV25 } from './25';

export interface IVisualOptionsV26 extends Omit<IVisualOptionsV25, 'plotly__version'> {
    /**
     * We're dropping support for v1 since no one is using it
     * and to align with KE since they don't support v1.
     *
     * The tile's plotly__version will stay as `undefined` or `2`.
     * There's no UI/UX impact if the customer's visual stays on v2
     * even after a down migration here because there's no breaking
     * changes to how Plotly's visuals renders. Instead, it's a library
     * api change but that's not exposed to the customer so this is safe to do.
     */
    readonly plotly__version: '2';
}

export interface ITileV26 extends Omit<ITileV25, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV26>;
}

export function up(prev: ITileV25, addWarning: AddDashboardWarning): ITileV26 {
    if (prev.visualOptions.plotly__version !== '1') {
        return prev as ITileV26;
    }

    addWarning((t) => formatLiterals(t.up.v40, { tileTitle: prev.title }));

    return {
        ...prev,
        visualOptions: {
            ...prev.visualOptions,
            plotly__version: '2',
        },
    };
}
