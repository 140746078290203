// We don't `import * as Highcharts` because a Parcel bug will cause Highlight to be removed from this file if we do
import { tokens as tokensV8 } from '@fluentui/tokens';
import Highcharts from 'highcharts';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqueId from 'lodash/uniqueId';

import { ArgumentColumnType, enhanceDataWithAnomalyDataFromColumns } from '@kusto/charting';
import { assertNever, KweException } from '@kusto/utils';
import type { YAxisConfig } from '@kusto/visual-fwk';

import { NUMERIC_ABBREVIATIONS } from '../constants';
import { DataItemWithRow, InternalChartProps, KustoHeuristicsOk, YAxisSeriesMap } from '../types';
import { convertXAxisToNumber, isArgumentNumeric } from '../utils/charting';
import { ExtendedVisualizationType, PieLabelOptions, PieTooltipOptions } from '../utils/visualization';
import {
    buildChartLoadCallback,
    buildPointMouseCallbacks,
    buildTooltipPointFormatter,
    getCrosshairConfig,
    isCrosshairSupported,
} from './crosshairUtils.ts';
import { getDataFromDataItems } from './getDataFromDataItems';
import {
    buildYSplitAxesConfig,
    getVerticalLineValue,
    getYAxisChartingConfig,
    getYAxisPlotLinesConfig,
    isAnomalySeries,
    referenceLinesColor,
    sharedTooltipMaxNumOfSeries,
} from './highChartOptionsUtils';
import { darkTheme } from './theme';
import { PointOptionsObject } from './types';

/**
 * The subset of `Highcharts.SeriesOptionsType['type']` that we use
 */
type HighchartsChartType = 'bar' | 'column' | 'line' | 'pie' | 'area' | 'scatter';

function buildPieChartTooltips(disabled: boolean, options?: readonly PieTooltipOptions[]) {
    const enabled = !disabled && options?.length !== 0;
    let pointFormat = '';
    let headerFormat;
    const includesName = options?.includes('name');
    const includesValue = options?.includes('value');
    const includesPercentage = options?.includes('percentage');
    if (options === undefined) {
        headerFormat = '{point.key}';
        pointFormat = '<br><span style="color:{point.color}">\u25CF</span>  {series.name}: <b>{point.y}</b>';
    }
    if (includesName) {
        headerFormat = '{point.key}';
    }
    if (includesValue || includesPercentage) {
        pointFormat += '<br><span style="color:{point.color}">\u25CF</span>  {series.name}: ';
    }

    if (includesValue) {
        pointFormat += '<b>{point.y}</b>';
        if (includesPercentage) {
            pointFormat += ', ';
        }
    }
    if (includesPercentage) {
        pointFormat += '<b>{point.percentage:.1f} %</b>';
    }

    return {
        enabled,
        pointFormat,
        headerFormat,
    };
}

function buildPieChartDataLabels(
    disabled: boolean,
    options?: readonly PieLabelOptions[]
): Highcharts.SeriesPieDataLabelsOptionsObject {
    const enabled = !disabled && options?.length !== 0;
    let format = '';
    const includesName = options?.includes('name');
    const includesValue = options?.includes('value');
    const includesPercentage = options?.includes('percentage');
    if (options === undefined) {
        format = '{point.name}<br>{point.percentage:.1f} %';
    }
    if (includesName) {
        format += '{point.name}';
    }
    if (includesValue || includesPercentage) {
        format += '<br>';
    }
    if (includesValue) {
        format += '{point.y}';
        if (includesPercentage) {
            format += ', ';
        }
    }
    if (includesPercentage) {
        let percentageFormat = '{point.percentage:.1f} %';
        if (includesValue) {
            // If percentage is preceded by value, make it bold
            percentageFormat = `<b>${percentageFormat}</b>`;
        }
        format += percentageFormat;
    }
    return {
        enabled,
        format,
    };
}

function getXAxisPlotLinesConfig(value?: number, label?: string): Highcharts.XAxisPlotLinesOptions[] {
    if (value === undefined) {
        return [];
    }

    return [
        {
            value,
            width: 1.5,
            color: referenceLinesColor,
            label: {
                style: { display: 'none', color: referenceLinesColor },
                text: label,
                rotation: 0,
                textAlign: 'center',
                y: -1,
            },
            zIndex: 3,
            events: {
                mouseover: function () {
                    this.label.element.style.display = 'block';
                },
                mouseout: function () {
                    this.label.element.style.display = 'none';
                },
            },
        },
    ];
}

/**
 * Build a mapping from series name to axis index, in case ysplit == axes.
 * Should only be called if ysplit == axes and anomalyColumns is not falsy.
 *
 * For regular charts this is be a one-to-one mapping.
 * For anomaly charts, series names look as follows:
 *  for the actual data:  seriesCol:seriesName:valueColumn
 *  for the anomaly data: seriesCol:seriesName:anomalyColumn(anomaly)
 *
 * for example, this how a chart with 2 regular and 2 anomaly series might look like:
 *  sid:TS1:num
 *  sid:TS1:anomalies(anomaly)
 *  sid:TS2:num
 *  sid:TS2:anomalies(anomaly)
 *
 * for this input the output should be
 * {
 *      sid:TS1:num: 0,
 *      sid:TS1:anomalies(anomaly): 0
 *       sid:TS2:num: 1,
 *      sid:TS2:anomalies(anomaly) => 1
 * }
 */
function createYsplitMapping(seriesNames: string[]) {
    // sid:TS1:anomalies(anomaly) => sid:TS1, sid:TS1:num => sid:TS1
    const baseSeriesNames = seriesNames.map((name) => name.substring(0, name.lastIndexOf(':')));
    // If there are anomaly columns, there will be base name duplicates (one the actual data, the other the anomaly data)
    const uniqueBaseSeriesNames = Array.from(new Set(baseSeriesNames));

    // we're giving a unique index for each axis we want to see in the end result
    const baseToIndex = new Map(uniqueBaseSeriesNames.map((name, index) => [name, index]));
    const mapping = new Map(seriesNames.map((name, index) => [name, baseToIndex.get(baseSeriesNames[index])]));

    return mapping;
}
function getYAxisIndex(
    additionalYAxes: YAxisConfig[],
    mappedYAxesColumnNames: { [id: string]: string },
    yColumnName: string | null
) {
    const index = additionalYAxes.findIndex((config) => {
        const columnName = mappedYAxesColumnNames[config.id];
        return columnName === yColumnName;
    });
    return index ?? 0;
}

const mapYAxesToColumnName = (additionalYAxes: YAxisConfig[]) => {
    const mappedAxes: { [id: string]: string } = {};
    additionalYAxes.forEach((yAxis) => {
        if (yAxis.columns.length > 0) {
            mappedAxes[yAxis.id] = yAxis.columns[0];
        }
    });
    return mappedAxes;
};

function findHighchartsType(Visualization: ExtendedVisualizationType): HighchartsChartType {
    switch (Visualization) {
        case null:
        case 'ladderchart':
        case 'timeline':
        case 'pivotchart':
        case 'card':
        case 'table':
        case 'timepivot':
        case 'multistat':
        case 'map':
        case 'plotly':
            throw new KweException('unsupported visualization type ' + Visualization);
        case 'areachart':
        case 'stackedareachart':
        case 'heatmap':
            return 'area';
        case 'barchart':
            return 'bar';
        case 'columnchart':
            return 'column';
        case 'piechart':
            return 'pie';
        case 'scatterchart':
            return 'scatter';
        case 'timechart':
        case 'linechart':
        case 'anomalychart':
            return 'line';
        default:
            assertNever(Visualization);
    }
}

/**
 * This method assigns a color to each data item based on the sorted x-value. This is important
 * so that any kind of re-ordering done by the user is ignored and that the colors are picked in
 * the same manner.
 *
 * Note: only exported for testing
 */
export function getColoredData(innerSeriesData: PointOptionsObject<DataItemWithRow>[], colors: readonly string[]) {
    const itemKey = (item: PointOptionsObject<DataItemWithRow>) => item.x ?? item.name ?? '';

    // TODO(kusto-data): This .sort() doesn't cover all edge cases.
    // We should consider using Intl.Collator but we will probably need to handle
    // stringified big-ints properly which requires more thought around this.
    const sortedXValues = [...new Set(innerSeriesData.map(itemKey))].sort();
    const sortedXValuesMapped = Object.fromEntries(sortedXValues.map((val, i) => [val, i]));

    // Adding the color for each point since Highcharts expects it that way
    return innerSeriesData.map((item) => {
        const xValueIndex = sortedXValuesMapped[itemKey(item)];
        return {
            color: colors[xValueIndex % colors.length],
            ...item,
        };
    });
}

/**
 * Pie charts differ from other visualizations in that the colors are per data point rather than per series.
 * We're assigning colors to data points by alphabetic order so that slices have consistent colors in multiple query runs.
 * This way colors are assigned by alphabetical order of the category name, but the actual data needs to be sorted by value
 * such that the pie will be have largest slice first (and the smallest slice last).
 *
 * Note: exported for testing only
 */
export function getPieChartDataWithColors(
    strings: InternalChartProps['strings'],
    seriesData: PointOptionsObject<DataItemWithRow>[],
    colors: readonly string[],
    orderByProperty: 'none' | 'name' | 'size',
    topNSlices: number | null | undefined
) {
    if (topNSlices && seriesData.length > topNSlices) {
        const fieldToSortBy = orderByProperty === 'name' ? 'name' : 'y';
        const sortedDataBySize = orderBy(seriesData, 'y', 'desc');
        const topNData = sortedDataBySize.slice(0, topNSlices);
        const othersData = sortedDataBySize.slice(topNSlices);

        let data = orderBy(topNData, fieldToSortBy, 'asc'); // Top N slices sorted by "sort by" value

        // Add in an "Others" slice that combines the rest of the slices
        data.push({
            ...othersData[0],
            name: strings.others,
            y: othersData.reduce((sum, curr) => sum + curr.y, 0),
        });
        data = getColoredData(data, colors);

        return data;
    }

    let data: PointOptionsObject<DataItemWithRow>[] = getColoredData(seriesData, colors);

    // Order the items based on the prop
    if (orderByProperty !== 'none') {
        data = orderBy(
            data,
            (item) => {
                if (orderByProperty === 'name') {
                    return item.x ?? item.name;
                }
                return item.y;
            },
            'asc'
        );
    }

    return data;
}

function buildLegend(
    hideLegend: boolean,
    legendPosition: InternalChartProps['legendPosition'] = 'bottom',
    enableInteractiveLegend: InternalChartProps['enableInteractiveLegend']
): Highcharts.LegendOptions {
    if (hideLegend || enableInteractiveLegend) {
        return { enabled: false };
    }

    if (legendPosition === 'bottom') {
        return {
            enabled: true,
            layout: 'horizontal',
            verticalAlign: 'bottom',
            maxHeight: 60,
            align: 'center',
            padding: 0,
        };
    }

    // left/right
    return {
        enabled: true,
        layout: 'vertical',
        verticalAlign: 'top',
        align: legendPosition,
        padding: 0,
        // Must be set so that `maxHeight` is cleared when we switch from bottom to right
        maxHeight: undefined,
    };
}

/**
 * Additions to this list need to be manually validated because Highcharts
 * doesn't document it.
 */
const noSortRequiredCharts: HighchartsChartType[] = ['scatter', 'pie', 'bar', 'scatter'];

/**
 * @param internalChartProps Chart props
 * @param heuristics
 */
function correctDataItems(
    chartType: HighchartsChartType,
    internalChartProps: InternalChartProps,
    heuristics: KustoHeuristicsOk
): readonly DataItemWithRow[] {
    const { Visualization } = internalChartProps.visualizationOptions;

    let dataItems = heuristics.dataItems;

    const argumentType = heuristics.argumentType!;

    // HighCharts requires us to sort the points by the x column in ascending
    // order for most charts. If we don't sort, we get error #15 (1) which is
    // misleading.
    //
    // Error 15 says we only need to sort a few chart types, but this isn't true.
    // (1)https://assets.highcharts.com/errors/15/
    //
    // Column charts are _not_ included in the charts highcharts documents as
    // requiring sorted, but appears to as well
    // https://github.com/highcharts/highcharts/issues/18299
    //
    // ExtendedVisualizationOptions.IsQuerySorted is ignored because it could
    // have been sorted by any column, and in any direction.
    if (isArgumentNumeric(argumentType) && !noSortRequiredCharts.includes(chartType)) {
        dataItems = sortBy(dataItems, (item) => convertXAxisToNumber(item, argumentType));
    }

    // if this is an anomaly chart we need to massage the data a bit.
    if (Visualization === 'anomalychart' && internalChartProps.visualizationOptions.AnomalyColumns) {
        dataItems = enhanceDataWithAnomalyDataFromColumns(
            dataItems,
            internalChartProps.visualizationOptions.AnomalyColumns,
            internalChartProps.visualizationOptions.YColumns
        );
    }
    return dataItems;
}

/**
 * Check if data items represent a single metric chart
 * @param argumentType
 * @param dataItems
 */
export function isSingleMetric(
    argumentType: ArgumentColumnType | undefined,
    dataItems: readonly DataItemWithRow[]
): boolean {
    return getYColumnsNumber(argumentType, dataItems) === 1;
}

/**
 * Get the number of existing Y-columns
 * @param argumentType
 * @param dataItems
 */
export const getYColumnsNumber = (
    argumentType: ArgumentColumnType | undefined,
    dataItems: readonly DataItemWithRow[]
): number => {
    const { groupedBySeries } = getDataFromDataItems(argumentType!, dataItems);
    const valueItems: YAxisSeriesMap = {};
    Object.values(groupedBySeries).forEach((val) => (valueItems[val[0].custom.dataItem.ValueName ?? ''] = []));

    return Object.keys(valueItems).length;
};

/**
 * Build Y-axis : series names map
 * @param props Chart props
 * @param heuristics
 */
export function getYAxisSeriesMap(props: InternalChartProps, heuristics: KustoHeuristicsOk): YAxisSeriesMap {
    const result: YAxisSeriesMap = {};
    const chartType = findHighchartsType(props.visualizationOptions.Visualization);
    const dataItems = correctDataItems(chartType, props, heuristics);

    const { groupedBySeries, seriesNames } = getDataFromDataItems(heuristics.argumentType!, dataItems);
    const additionalYAxes = props.visualizationOptions.MultipleYAxes?.additional;

    // build map - yAxis name: [corresponding series names]
    if (!additionalYAxes || additionalYAxes.length === 0) {
        seriesNames.forEach((seriesName) => {
            const yAxisName = groupedBySeries[seriesName][0].custom.dataItem.ValueName;
            if (yAxisName !== null) {
                if (!result[yAxisName]) {
                    result[yAxisName] = [];
                }
                result[yAxisName].push(seriesName);
            }
        });
    } else {
        // Custom Y-Axes handling
        const additionalYAxesNames = additionalYAxes.map((yAxisConfig) => yAxisConfig.columns[0]);
        const selectedColumnsNames = props.visualizationOptions.YColumns ?? [];
        const basePanelId = uniqueId();
        seriesNames.forEach((seriesName) => {
            const yAxisName = groupedBySeries[seriesName][0].custom.dataItem.ValueName;
            const isSelected = selectedColumnsNames.findIndex((colName) => colName === yAxisName) > -1;
            if (yAxisName !== null && isSelected) {
                const panelId = additionalYAxesNames.indexOf(yAxisName) > -1 ? yAxisName : basePanelId;
                if (!result[panelId]) {
                    result[panelId] = [];
                }
                result[panelId].push(seriesName);
            }
        });
    }

    Object.keys((key: string) => (result[key] = uniq(result[key]))); // Remove duplicate series names
    return result;
}

/**
 * Build metric : series names map
 * @param argumentType
 * @param dataItems
 */
export function getMetricSeriesMap(
    argumentType: ArgumentColumnType | undefined,
    dataItems: readonly DataItemWithRow[]
): YAxisSeriesMap {
    const { seriesNames } = getDataFromDataItems(argumentType!, dataItems);
    const singleMetricResult: YAxisSeriesMap = {};
    seriesNames.forEach((seriesName) => (singleMetricResult[seriesName] = [seriesName]));
    return singleMetricResult;
}

/**
 * Build Y-axis : series names map for ysplit=panels
 * @param props Chart props
 * @param heuristics
 */
export function getYAxisSeriesMapForPanels(props: InternalChartProps, heuristics: KustoHeuristicsOk): YAxisSeriesMap {
    const chartType = findHighchartsType(props.visualizationOptions.Visualization);
    const dataItems = correctDataItems(chartType, props, heuristics);

    return isSingleMetric(heuristics.argumentType!, dataItems)
        ? getMetricSeriesMap(heuristics.argumentType!, dataItems)
        : getYAxisSeriesMap(props, heuristics);
}

function seriesOptions(
    props: InternalChartProps,
    {
        allYAxisConfig,
        matchAxesColors,
    }: {
        allYAxisConfig: Highcharts.YAxisOptions[];
        matchAxesColors: boolean;
    }
): Highcharts.PlotSeriesOptions {
    const { onPointClick } = props;

    return {
        point: {
            events: {
                ...buildPointMouseCallbacks(props),
            },
        },
        allowPointSelect: false,
        // in turboThreshold only  one dimensional arrays of numbers, or two dimensional arrays with x and y values are allowed
        // when introducing cross-filter, our data is now a PointOptionsObject[] so entering turbo mode causes no chart to be displayed.
        turboThreshold: 0,
        animation:
            props.animationDuration === 0
                ? false
                : {
                      duration: props.animationDuration,
                  },
        stacking:
            props.visualizationOptions.Kind === 'stacked'
                ? 'normal'
                : props.visualizationOptions.Kind === 'stacked100'
                ? 'percent'
                : undefined,
        events: {
            click:
                onPointClick &&
                ((event: Highcharts.SeriesClickEventObject) => {
                    // Non-null assertion because it should be
                    // impossible for data item to not be present
                    // Added while enabling lints

                    const row = event.point.options.custom!.dataItem.row;
                    // row is only present on dataItem if dataItems.length === rows.length
                    if (row) {
                        onPointClick(row);
                    }
                }),
            ...(allYAxisConfig.length > 1 && !matchAxesColors
                ? {
                      mouseOver: function () {
                          this.yAxis.update({
                              labels: {
                                  style: {
                                      fontWeight: 'bold',
                                      color: props.isDarkTheme ? 'white' : 'black',
                                  },
                              },
                              title: {
                                  style: { fontWeight: 'bold', color: props.isDarkTheme ? 'white' : 'black' },
                              },
                          });
                      },
                      mouseOut: function () {
                          this.yAxis.update({
                              labels: {
                                  style: {
                                      fontWeight: undefined,
                                      color: props.isDarkTheme ? '#A19F9D' : '#666666',
                                  },
                              },
                              title: {
                                  style: {
                                      fontWeight: 'normal',
                                      color: props.isDarkTheme ? '#A19F9D' : '#666666',
                                  },
                              },
                          });
                      },
                  }
                : {
                      mouseOver: undefined,
                      mouseOut: undefined,
                  }),
        },
    };
}

/**
 * Convert Kusto chart props to highcharts options format.
 * @param props Chart props
 */
export function createHighchartsOptions(props: InternalChartProps, heuristics: KustoHeuristicsOk): Highcharts.Options {
    const { Kind } = props.visualizationOptions;
    const { onHighChartsDragXEnd } = props;

    const chartType = findHighchartsType(props.visualizationOptions.Visualization);
    const dataItems = correctDataItems(chartType, props, heuristics);

    const { pointOptions, groupedBySeries, seriesNames } = getDataFromDataItems(heuristics.argumentType!, dataItems);

    const xAxisType: Highcharts.AxisTypeValue =
        props.visualizationOptions.XAxis === 'log'
            ? 'logarithmic'
            : heuristics.argumentType === ArgumentColumnType.DateTime
            ? 'datetime'
            : heuristics.argumentType === ArgumentColumnType.Numeric
            ? 'linear'
            : 'category';

    const xCategories: string[] | undefined =
        xAxisType === 'category' ? uniq(pointOptions.map((point) => point.name) as string[]) : undefined;

    const mainYAxis = props.visualizationOptions.MultipleYAxes?.base;
    const additionalYAxes = props.visualizationOptions.MultipleYAxes?.additional;

    const verticalLineValue = getVerticalLineValue(props.visualizationOptions.VerticalLine, heuristics.argumentType);
    const xAxisPlotLinesConfig = getXAxisPlotLinesConfig(verticalLineValue, props.visualizationOptions.VerticalLine);

    // Filter out yAxes that are not selected in YColumns, and filter out the base yaxis
    const filteredMultipleYAxes =
        additionalYAxes && props.visualizationOptions.YColumns
            ? additionalYAxes.filter((yAxis) => {
                  if (yAxis.columns.length > 0) {
                      // Currently supporting 1 column for each axis
                      const yAxisColumn = yAxis.columns[0];
                      // Check if the column in the yaxis i also selected in the y columns data
                      const isYColumnSelected =
                          // Added while enabling lints

                          props.visualizationOptions.YColumns!.findIndex((colName) => colName === yAxisColumn) > -1;
                      return isYColumnSelected;
                  }
                  return false;
              })
            : [];

    // Mapping an axis to its column name.
    const mappedYAxesColumnNames = mapYAxesToColumnName(filteredMultipleYAxes);

    // Match series and axis colors if there's only one unique value in the series column and ysplit is off
    const matchAxesColors =
        !props.visualizationOptions.YSplit &&
        filteredMultipleYAxes.length > 0 &&
        seriesNames.length === filteredMultipleYAxes.length + 1;
    const mainAxisColor = matchAxesColors ? props.colors[0] : undefined;
    const mainYAxisConfig: Highcharts.YAxisOptions = {
        ...getYAxisChartingConfig(props.visualizationOptions, mainYAxis),
        opposite: false,
        title: { text: mainYAxis?.label },
        plotLines: getYAxisPlotLinesConfig(mainYAxis?.horizontalLines.map((line) => line.value) ?? [], mainAxisColor),
    };

    const additionalYAxesConfig: Highcharts.YAxisOptions[] = filteredMultipleYAxes.map((yAxis, index) => {
        // Adding (+1) to the index since the main axis is not included here
        const colorIndex = (index + 1) % props.colors.length;
        const axisColor = matchAxesColors ? props.colors[colorIndex] : undefined;
        const text = yAxis.label === '' ? mappedYAxesColumnNames[yAxis.id] : yAxis.label;
        const plotLinesConfig = getYAxisPlotLinesConfig(
            yAxis.horizontalLines.map((line) => line.value),
            axisColor
        );
        return {
            ...getYAxisChartingConfig(props.visualizationOptions, yAxis),
            title: { text },
            opposite: true,
            plotLines: plotLinesConfig,
        };
    });

    // The order is important! the first yAxisConfig should remain the main one.
    let allYAxisConfig: Highcharts.YAxisOptions[] =
        props.visualizationOptions.YSplit === 'axes'
            ? buildYSplitAxesConfig(seriesNames, props.visualizationOptions)
            : [mainYAxisConfig, ...additionalYAxesConfig].map((axis) =>
                  Highcharts.merge(axis, props.isDarkTheme ? darkTheme.yAxis : undefined)
              );

    if (matchAxesColors) {
        allYAxisConfig = allYAxisConfig.map((axis, index) => {
            const colorIndex = index % props.colors.length;
            const axisColor = props.colors[colorIndex];
            return {
                ...axis,
                title: {
                    ...axis.title,
                    style: { color: axisColor },
                },
                labels: {
                    ...axis.labels,
                    style: { color: axisColor },
                },
            };
        });
    }

    allYAxisConfig = allYAxisConfig.map((axis, index) => {
        const colorIndex = index % props.colors.length;
        const axisColor = matchAxesColors ? props.colors[colorIndex] : props.isDarkTheme ? '#A19F9D' : '#666666';
        return {
            ...axis,
            title: {
                ...axis.title,
                style: { color: axisColor },
            },
            labels: {
                ...axis.labels,
                style: { color: axisColor },
            },
        };
    });

    const pieChartDataLabels = buildPieChartDataLabels(
        !!props.visualizationOptions.LabelDisabled,
        props.visualizationOptions.LabelOptions
    );

    const pieChartTooltips = buildPieChartTooltips(
        !!props.visualizationOptions.TooltipDisabled,
        props.visualizationOptions.TooltipOptions
    );

    const ysplitMapping =
        props.visualizationOptions.YSplit && props.visualizationOptions.AnomalyColumns
            ? createYsplitMapping(seriesNames)
            : null;

    const legend = buildLegend(
        props.hideLegend || props.visualizationOptions.Legend === 'hidden',
        props.legendPosition,
        props.enableInteractiveLegend
    );

    // building the actual object now that we converted all the relevant parts.
    const options: Highcharts.Options = {
        lang: {
            accessibility: {
                defaultChartTitle: props.chartTitle ?? 'Chart',
            },
            // Using English numeric abbreviations
            // k - thousands
            // M - millions
            // B - billions
            // T - trillions
            // instead of the defaults which relate to
            // kilo, mega, giga, etc.
            numericSymbols: NUMERIC_ABBREVIATIONS,
        },
        boost: { enabled: !!props.highPerformanceMode, seriesThreshold: 1 },
        chart: {
            events: {
                ...buildChartLoadCallback(props, xAxisType),
            },
            // Invalid as a background color, which causes it to be ignored, but
            // also used as svg "fill" property, which we need to be valid.
            backgroundColor: 'none',
            style: {
                fontFamily: tokensV8.fontFamilyBase,
            },
            ...(onHighChartsDragXEnd && {
                zoomType: 'x',
                events: {
                    selection: function (event) {
                        const axis = event.xAxis[0];
                        onHighChartsDragXEnd(axis.min, axis.max);
                        return false;
                    },
                },
            }),
        },
        plotOptions: {
            pie: {
                dataLabels: pieChartDataLabels,
                colors: props.colors as string[],
                showInLegend: props.visualizationOptions.Legend === 'visible',
                tooltip: pieChartTooltips,
            },
            scatter: {
                tooltip:
                    // work around following highcharts issue https://github.com/highcharts/highcharts/issues/3552
                    // in scatter chart, highcharts for some reason won't format datetime values.
                    heuristics.argumentType === ArgumentColumnType.DateTime
                        ? {
                              pointFormat:
                                  'x: <b>{point.x:%A, %b %e, %Y, %H:%M:%S:%L}</b><br/>y: <b>{point.y}</b><br/>',
                          }
                        : heuristics.argumentType === ArgumentColumnType.TimeSpan
                        ? {
                              // scatterchart refers to x field and not name field. we need to override this behavior otherwise it will always be 0
                              pointFormatter: function () {
                                  return `x: <b>${this.name}</b><br/>y: <b>${this.y}</b><br/>`;
                              },
                          }
                        : undefined,
            },
            series: seriesOptions(props, { allYAxisConfig, matchAxesColors }),
        },
        series: seriesNames.map((seriesName, index) => {
            const numOfSeries = seriesNames.length;
            // anomalies should be shown as markers on an existing series thus they should be a scatter plot.
            const isAnomalyColumn = isAnomalySeries(seriesName);
            // Add 1 to the yAxisIndex because the first index in the "allYAxisConfig" is the main one.
            const yColumnName = groupedBySeries[seriesName][0].custom.dataItem.ValueName;
            const yAxisIndex =
                props.visualizationOptions.YSplit === 'axes'
                    ? // in anomaly chart, only the non-anomaly columns have their own y axis as anomaly columns get rendered on the same axis as the non-anomaly columns.
                      props.visualizationOptions.Visualization === 'anomalychart' &&
                      props.visualizationOptions.AnomalyColumns
                        ? ysplitMapping?.get(seriesName)
                        : // this isn't an anomaly chart - so each series gets its own y axis.
                          index
                    : // if there's a dashboard-set multiple axes, compute them here.
                    additionalYAxesConfig.length > 0
                    ? getYAxisIndex(filteredMultipleYAxes, mappedYAxesColumnNames, yColumnName) + 1
                    : 0;
            const seriesColor = matchAxesColors ? props.colors[(yAxisIndex ?? index) % props.colors.length] : undefined;

            const pieConfig: Partial<Highcharts.SeriesPieOptions> = {};
            if (chartType === 'pie' && Kind === 'donut') {
                // Using an object here because Highcharts
                // expects if `size` and `innerSize` are defined
                // then it needs to have the value of type "string"
                // and not be `undefined` as a value for the properties
                let innerSize: string;

                if (index === 0) {
                    innerSize = '50%';
                } else {
                    innerSize = `${100 * (index / (index + 1))}%`;
                }

                pieConfig.innerSize = innerSize;

                const sizeValue = (100 / numOfSeries) * (index + 1);
                pieConfig.size =
                    // Fix a bug that size 100% causes the chart to overflow.
                    sizeValue === 100 ? null : `${sizeValue}%`;
            } else if (chartType === 'pie') {
                // Reset 'Kind' to default (pie)
                pieConfig.innerSize = undefined;
                pieConfig.size = null;
            }

            let data = groupedBySeries[seriesName];

            if (chartType === 'pie') {
                data = getPieChartDataWithColors(
                    props.strings,
                    groupedBySeries[seriesName],
                    props.colors,
                    props.visualizationOptions.PieOrderBy ?? 'size',
                    props.visualizationOptions.TopNSlices
                );
            }

            return {
                ...pieConfig,
                name: seriesName,
                type: isAnomalyColumn ? 'scatter' : chartType,
                yAxis: yAxisIndex,
                data,
                color: isAnomalyColumn ? 'red' : seriesColor,
                // Cast added because it's hard/impossible to make it type safe
                // while it's a union of all series types TODO: Create each
                // series type separately so we can have type safety
            } as Highcharts.SeriesOptionsType;
        }),

        xAxis: {
            type: xAxisType,
            title: { text: props.visualizationOptions.XTitle },
            plotLines: xAxisPlotLinesConfig,
            categories: xCategories,
            ...getCrosshairConfig(props.visualizationOptions, props.isDarkTheme),
        },
        yAxis: allYAxisConfig,
        colors: props.colors as string[],
        legend,
        title: { text: props.visualizationOptions.Title ?? undefined },
        credits: { enabled: false },
        tooltip: {
            shared:
                props.showAllSeriesOnHover ||
                (seriesNames.length <= sharedTooltipMaxNumOfSeries && isCrosshairSupported(props.visualizationOptions)),
            outside: true,
            enabled:
                !props.visualizationOptions.TooltipDisabled && props.visualizationOptions.TooltipOptions?.length !== 0,
            backgroundColor: props.isDarkTheme ? '#040404' : 'white',
            borderRadius: 4,
            borderColor: 'transparent',
            ...buildTooltipPointFormatter(props),
        },
        time: { timezone: props.timezone },
    };

    return Highcharts.merge(options, props.isDarkTheme ? darkTheme : undefined);
}
