import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import { BaseQueryV4, baseQueryV4Down, baseQueryV4Up } from '../query/4';
import type { DashboardV46 } from './46';

export interface DashboardV47 extends Omit<DashboardV46, 'schema_version' | 'baseQueries'> {
    readonly schema_version: '47';
    readonly baseQueries: readonly BaseQueryV4[];
}

export function up(prev: DashboardV46): DashboardV47 {
    return {
        ...prev,
        schema_version: '47',
        baseQueries: prev.baseQueries.map((b) => baseQueryV4Up(b)),
    };
}

export function down(prev: DashboardV47, warn: AddDashboardWarning): DashboardV46 {
    if (prev.baseQueries.length !== 0) {
        warn((t) => formatLiterals(t.down.v47.schemasDefaulted));
    }

    return {
        ...prev,
        schema_version: '46',
        baseQueries: prev.baseQueries.map((q) => baseQueryV4Down(q)),
    };
}
