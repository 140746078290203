import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import * as parameterV6 from '../parameter/6';
import type { SharedQueryV2 } from '../query/2';
import type { ITileV17 } from '../tile/17';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV28 } from './28';

export interface IDashboardV29 {
    readonly schema_version: '29';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly ITileV17[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly SharedQueryV2[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly parameterV6.IParameterV6[];
}

export function up(prev: IDashboardV28): IDashboardV29 {
    return {
        ...prev,
        schema_version: '29',
        parameters: prev.parameters.map((t) => parameterV6.up(t)),
    };
}

export function down(prev: IDashboardV29, addWarning: AddDashboardWarning): IDashboardV28 {
    return {
        ...prev,
        schema_version: '28',
        parameters: prev.parameters.map((t) => parameterV6.down(t, addWarning)),
    };
}
