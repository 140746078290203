import type { VisualConfig } from '@kusto/visual-fwk';

import { RtdVisualTypes } from '../charting';
import { KweRtdVisualContext } from '../context';
import { funnelVisualConfig } from './funnel';
import { heatmapVisualConfig } from './heatmap';
import { anomalyChartConfig, highchartsConfigs } from './highCharts/highCharts';
import { mapVisualConfig } from './map';
import { markdownVisualConfig } from './MarkdownVisual';
import { pieVisualConfig } from './pie';
import { plotlyVisualConfig } from './Plotly';
import { statConfigs } from './stat';
import { tableVisualConfig } from './table/table';

export type { SupportedPlotlyVersion } from './Plotly';

export function rtdVisualsConfig(ctx: KweRtdVisualContext): VisualConfig<RtdVisualTypes>['visuals'] {
    return {
        ...statConfigs(ctx),
        ...highchartsConfigs(ctx),
        anomalychart: anomalyChartConfig(ctx),
        pie: pieVisualConfig(ctx),
        heatmap: heatmapVisualConfig(ctx),
        funnel: funnelVisualConfig(ctx),
        map: mapVisualConfig(ctx),
        markdown: markdownVisualConfig(ctx.strings),
        plotly: plotlyVisualConfig(ctx),
        table: tableVisualConfig(ctx),
    };
}

export { highchartsHeuristicsConfig } from './highCharts/highCharts';
export { statHeuristicsConfig, multiStatHeuristicsConfig } from './stat';
export { pieHeuristicsConfig } from './pie';
