import type { DashboardMigrationCopy } from '..';
import type { Migrations } from '../../migrate';
import * as v0 from './0';
import * as v1 from './1-0';
import * as v1_1 from './1-1';
import * as v1_2 from './1-2';
import * as v2 from './2';
import * as v3 from './3';
import * as v4 from './4';
import * as v5 from './5';
import * as v6 from './6';
import * as v7 from './7';
import * as v8 from './8';
import * as v9 from './9';
import * as v10 from './10';
import * as v11 from './11';
import * as v12 from './12';
import * as v13 from './13';
import * as v14 from './14';
import * as v15 from './15';
import * as v16 from './16';
import * as v17 from './17';
import * as v18 from './18';
import * as v19 from './19';
import * as v20 from './20';
import * as v21 from './21';
import * as v22 from './22';
import * as v23 from './23';
import * as v24 from './24';
import * as v25 from './25';
import * as v26 from './26';
import * as v27 from './27';
import * as v28 from './28';
import * as v29 from './29';
import * as v30 from './30';
import * as v31 from './31';
import * as v32 from './32';
import * as v33 from './33';
import * as v34 from './34';
import * as v35 from './35';
import * as v36 from './36';
import * as v37 from './37';
import * as v38 from './38';
import * as v39 from './39';
import * as v40 from './40';
import * as v41 from './41';
import * as v42 from './42';
import * as v43 from './43';
import * as v44 from './44';
import * as v45 from './45';
import * as v46 from './46';
import * as v47 from './47';
import * as v48 from './48';
import * as v49 from './49';
import * as v50 from './50';
import * as v51 from './51';
import * as v52 from './52';
import * as v53 from './53';
import * as v54 from './54';
import * as preSchema from './preSchema';

export type IDashboardVersioned =
    | v0.IDashboardV0
    | v1.IDashboardV1
    | v1_1.IDashboardV1_1
    | v1_2.IDashboardV1_2
    | v2.IDashboardV2
    | v3.IDashboardV3
    | v4.IDashboardV4
    | v5.IDashboardV5
    | v6.IDashboardV6
    | v7.IDashboardV7
    | v8.IDashboardV8
    | v9.IDashboardV9
    | v10.IDashboardV10
    | v11.IDashboardV11
    | v12.IDashboardV12
    | v13.IDashboardV13
    | v14.IDashboardV14
    | v15.IDashboardV15
    | v16.IDashboardV16
    | v17.IDashboardV17
    | v18.IDashboardV18
    | v19.IDashboardV19
    | v20.IDashboardV20
    | v21.IDashboardV21
    | v22.IDashboardV22
    | v23.IDashboardV23
    | v24.IDashboardV24
    | v25.IDashboardV25
    | v26.IDashboardV26
    | v27.IDashboardV27
    | v28.IDashboardV28
    | v29.IDashboardV29
    | v30.IDashboardV30
    | v31.IDashboardV31
    | v32.IDashboardV32
    | v33.IDashboardV33
    | v34.IDashboardV34
    | v35.IDashboardV35
    | v36.IDashboardV36
    | v37.IDashboardV37
    | v38.IDashboardV38
    | v39.IDashboardV39
    | v40.IDashboardV40
    | v41.IDashboardV41
    | v42.IDashboardV42
    | v43.IDashboardV43
    | v44.DashboardV44
    | v45.DashboardV45
    | v46.DashboardV46
    | v47.DashboardV47
    | v48.DashboardV48
    | v49.DashboardV49
    | v50.DashboardV50
    | v51.DashboardV51
    | v52.DashboardV52
    | v53.DashboardV53
    | v54.DashboardV54;

export type DashboardAnyVersion = preSchema.IDashboardPreSchema | IDashboardVersioned;
export type DashboardVersion = IDashboardVersioned['schema_version'];
export type DashboardAtVersion<T extends DashboardVersion> = Extract<
    IDashboardVersioned,
    { readonly schema_version: T }
>;
export const dashboardVersions: readonly DashboardVersion[] = [
    '0',
    '1.0',
    '1.1',
    '1.2',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
];

export const dashboardMigrations: Migrations<IDashboardVersioned, DashboardMigrationCopy> = {
    migrations: {
        '0': { up: v1.up },
        '1.0': { up: v1_1.up },
        '1.1': { up: v1_2.up },
        '1.2': { up: v2.up },
        '2': { up: v3.up },
        '3': { up: v4.up },
        '4': { up: v5.up },
        '5': { up: v6.up },
        '6': { up: v7.up },
        '7': { up: v8.up },
        '8': { up: v9.up },
        '9': { up: v10.up },
        '10': { up: v11.up, down: v10.down },
        '11': { down: v11.down, up: v12.up },
        '12': { down: v12.down, up: v13.up },
        '13': { down: v13.down, up: v14.up },
        '14': { down: v14.down, up: v15.up },
        '15': { down: v15.down, up: v16.up },
        '16': { down: v16.down, up: v17.up },
        '17': { down: v17.down, up: v18.up },
        '18': { down: v18.down, up: v19.up },
        '19': { down: v19.down, up: v20.up },
        '20': { down: v20.down, up: v21.up },
        '21': { down: v21.down, up: v22.up },
        '22': { down: v22.down, up: v23.up },
        '23': { down: v23.down, up: v24.up },
        '24': { down: v24.down, up: v25.up },
        '25': { down: v25.down, up: v26.up },
        '26': { down: v26.down, up: v27.up },
        '27': { down: v27.down, up: v28.up },
        '28': { down: v28.down, up: v29.up },
        '29': { down: v29.down, up: v30.up },
        '30': { down: v30.down, up: v31.up },
        '31': { down: v31.down, up: v32.up },
        '32': { down: v32.down, up: v33.up },
        '33': { down: v33.down, up: v34.up },
        '34': { down: v34.down, up: v35.up },
        '35': { down: v35.down, up: v36.up },
        '36': { down: v36.down, up: v37.up },
        '37': { down: v37.down, up: v38.up },
        '38': { down: v38.down, up: v39.up },
        '39': { down: v39.down, up: v40.up },
        '40': { down: v40.down, up: v41.up },
        '41': { down: v41.down, up: v42.up },
        '42': { down: v42.down, up: v43.up },
        '43': { down: v43.down, up: v44.up },
        '44': { down: v44.down, up: v45.up },
        '45': { down: v45.down, up: v46.up },
        '46': { down: v46.down, up: v47.up },
        '47': { down: v47.down, up: v48.up },
        '48': { down: v48.down, up: v49.up },
        '49': { down: v49.down, up: v50.up },
        '50': { down: v50.down, up: v51.up },
        '51': { down: v51.down, up: v52.up },
        '52': { down: v52.down, up: v53.up },
        '53': { down: v53.down, up: v54.up },
        '54': { down: v54.down },
    },
    order: dashboardVersions,
};
