import { findQuery, QueryIndex } from '../versions/51';
import type { QueryPartsV1 } from './1';
import type { BaseQueryV4 } from './4';

export interface QueryV5 extends QueryPartsV1 {
    /**
     * GUID of the query in the top-level `queries` property.
     */
    readonly id: string;
}

export function baseQueryV5Up(prev: BaseQueryV4, queries: QueryV5[]): BaseQueryV5 {
    const queryId = crypto.randomUUID();

    queries.push({
        ...prev,
        id: queryId,
    });

    return {
        id: prev.id,
        variableName: prev.variableName,
        queryId,
    };
}

export interface BaseQueryV5 {
    /**
     * RFC 4122 uuid
     *
     * @see {@link https://datatracker.ietf.org/doc/html/rfc4122}
     */
    readonly id: string;
    /**
     * GUID of the query in the top-level `queries` property.
     */
    readonly queryId: string;
    /**
     * Must be a valid kusto variable
     *
     * @see {@link {https://docs.microsoft.com/en-us/azure/data-explorer/kusto/query/schema-entities/entity-names}
     */
    readonly variableName: string;
}

export function baseQueryV5Down(prev: BaseQueryV5, queries: QueryIndex): BaseQueryV4 {
    const { queryId, ...baseQueryV5WithoutQueryId } = prev;

    const query = findQuery(queryId, queries);

    const { id: _, ...queryWithoutId } = query;

    return {
        ...baseQueryV5WithoutQueryId,
        ...(queryWithoutId as QueryPartsV1),
    };
}

export type QueryRefV5 =
    | {
          kind: 'query';
          queryId: string;
      }
    | {
          kind: 'baseQuery';
          baseQueryId: string;
      };
