import type { PatchEntire } from '../types';
import { clean, corrupted } from '../utils';

// {
//     "message":"must NOT have fewer than 1 characters","instancePath":"/title",
//     "schemaPath":"#/properties/title/minLength",
//     "keyword":"minLength",
//     "params":{"limit":1}
// }
export const emptyTitleConfig: PatchEntire = {
    kind: 'patch-entire',
    corruption(dashboard) {
        if (dashboard.title === '') {
            return corrupted(undefined);
        }
        return clean;
    },
    patch(dashboard) {
        return {
            ...dashboard,
            title: ' ', // equivalent of an empty title is just a single space char
        };
    },
};
