import type { Mutable } from '@kusto/utils';

import type { CanInferV1 } from './1';
import type { ITileV2 } from './2';
import type { ITileV9, IVisualOptionsV9 } from './9';

export type MapGeoTypeV10 = 'infer' | 'geoPoint' | 'numeric';

export interface IVisualOptionsV10 extends IVisualOptionsV9 {
    readonly map__type: 'bubble';
    readonly map__labelColumn: CanInferV1<string>;
    readonly map__sizeColumn: CanInferV1<string>;
    readonly map__sizeDisabled: boolean;
    readonly map__geoType: MapGeoTypeV10;
    readonly map__geoPointColumn: CanInferV1<string>;
}

export interface ITileV10 extends Omit<ITileV2, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV10>;
}

export function up(prev: ITileV9): ITileV10 {
    const visualOptions: Mutable<Partial<IVisualOptionsV10>> = { ...prev.visualOptions };
    const isMapVisualType = prev.visualType === 'map';
    if (isMapVisualType) {
        // If we are migrating existing tile, size was always enabled in the previous version
        visualOptions.map__sizeDisabled = !isMapVisualType;
    }
    return { ...prev, visualOptions };
}

export function down(prev: ITileV10): ITileV9 {
    // Removing the new options
    const {
        map__type,
        map__labelColumn,
        map__sizeColumn,
        map__sizeDisabled,
        map__geoType,
        map__geoPointColumn,
        ...prevVisualOptions
    } = prev.visualOptions;
    const visualOptions: Partial<IVisualOptionsV9> = { ...prevVisualOptions };
    return { ...prev, visualOptions };
}
