import { QueryRefV5, QueryV5 } from '../query/5';
import type { BasicParameterTypesV2 } from '../value/2';
import { BaseQueryIndex, findBaseQuery, findQuery, QueryIndex } from '../versions/51';
import type { BasicParamV2 } from './2';
import { DataSourceParamV9, DurationParamV9 } from './9';
import type { BasicParamV10, ParameterV10 } from './10';

export declare namespace BasicParamV11 {
    export interface DropdownQueryDataSource extends Omit<BasicParamV10.DropdownQueryDataSource, 'query'> {
        queryRef: QueryRefV5;
    }

    export type DropdownDataSource<T> = BasicParamV2.DropdownStaticDataSource<T> | DropdownQueryDataSource;

    export interface Dropdown<T, K> extends Omit<BasicParamV10.Dropdown<T, K>, 'dataSource'> {
        dataSource: DropdownDataSource<T>;
    }
}

export type TBasicParamV11<T, K> = BasicParamV10.Freetext<T, K> | BasicParamV11.Dropdown<T, K>;

export type UBasicParamV11 = {
    [Tag in keyof BasicParameterTypesV2]: TBasicParamV11<BasicParameterTypesV2[Tag], Tag>;
}[keyof BasicParameterTypesV2];

export type ParameterV11 = UBasicParamV11 | DurationParamV9.IParam | DataSourceParamV9.IParam;

export function parameterV11Up(prev: ParameterV10, queries: QueryV5[]): ParameterV11 {
    if (
        prev.kind !== 'dataSource' &&
        prev.kind !== 'duration' &&
        prev.selectionType !== 'freetext' &&
        prev.dataSource.kind === 'query'
    ) {
        const { query: _, ...dataSourceWithoutQuery } = prev.dataSource;

        if (prev.dataSource.query.kind === 'ref') {
            return {
                ...prev,
                dataSource: {
                    ...dataSourceWithoutQuery,
                    queryRef: {
                        kind: 'baseQuery',
                        baseQueryId: prev.dataSource.query.baseQueryId,
                    },
                },
            };
        } else {
            const { kind: _, ...queryWithoutKind } = prev.dataSource.query;

            const query = {
                id: crypto.randomUUID(),
                ...queryWithoutKind,
            };
            queries.push(query);

            return {
                ...prev,
                dataSource: {
                    ...dataSourceWithoutQuery,
                    queryRef: {
                        kind: 'query',
                        queryId: query.id,
                    },
                },
            };
        }
    } else {
        // Adding the `as` because TypeScript can't understand that the above `if` precludes all basic
        // params that have queries as results and so don't have `queryRef` in them
        return prev as ParameterV11;
    }
}

export function parameterV11Down(prev: ParameterV11, queries: QueryIndex, baseQueries: BaseQueryIndex): ParameterV10 {
    if (prev.kind === 'dataSource' || prev.kind === 'duration' || prev.selectionType === 'freetext') {
        return prev;
    }

    if (prev.dataSource.kind === 'query') {
        const queryRef = prev.dataSource.queryRef;
        const baseQuery = queryRef.kind === 'baseQuery' ? findBaseQuery(queryRef.baseQueryId, baseQueries) : undefined;
        const queryId = queryRef.kind === 'baseQuery' ? baseQuery!.queryId : queryRef.queryId;

        const query = findQuery(queryId, queries);

        const { queryRef: _, ...dataSourceWithoutQueryRef } = prev.dataSource;
        const { id: _2, ...queryWithoutId } = query;

        return {
            ...prev,
            dataSource: {
                ...dataSourceWithoutQueryRef,
                query: { ...queryWithoutId, kind: 'inline' },
            },
        };
    } else {
        // Adding the `as` because TypeScript can't understand that the above `if` precludes params
        // that are queries and so don't have `query` in them
        return prev as ParameterV10;
    }
}
