import type { VisualFwkFeatureFlag } from '@kusto/visual-fwk';

export const queryFeatureFlags = [
    'ShowConnectionButtons',
    'QueryResultsSearch',
    'Timezone',
    'HighlightUrlColumns',
    'statsv2',
    'RefreshConnection',
    'SkipMonacoFocusOnInit',
    'ShowPageHeader',
    'queryRecommendations',
    'cheackClusterHealth',
    'TridnetView',
    'QueryVisualOptions',
    'ClusterAccount',
    'IFrameAuth',
    'AddVisual',
    'EnableMarkdownImageOverflow',
    'EnableInteractiveLegend',
    'EnableSharedCrosshair',
    // Temporary flag to allow test the new query-tabs. After feature verified, remove flag and old TabHeader implementation
    'NewTabs',
    'EnableRoamingProfile',
    'DataProfilePinned',
    'GridWithExpandRewrite',
    // enable AG grid debug mode. for query param usage only.
    'GridDebugMode',
] as const;

export type QueryFeatureFlag = (typeof queryFeatureFlags)[number] | VisualFwkFeatureFlag;
