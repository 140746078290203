import React, { useCallback } from 'react';
import { Field, FieldProps } from '@fluentui/react-components';
import { Controller, ControllerProps, Path, UseControllerProps } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TFormFieldsBase = Record<string, any>;

type RenderFn<TFormFields extends TFormFieldsBase, TFieldName extends Path<TFormFields>> = ControllerProps<
    TFormFields,
    TFieldName
>['render'];

export interface RHFFieldProps<TFormFields extends TFormFieldsBase, TFieldName extends Path<TFormFields>> {
    /** RHF controller definition */
    controllerProps: UseControllerProps<TFormFields, TFieldName>;
    /** Props of the Fluent `<Field />` wrapper around the `<Component />` */
    fieldProps?: FieldProps;
    /** Custom `<Component />` that would be rendered inside the `<Field />` */
    Component: React.FunctionComponent<Parameters<RenderFn<TFormFields, TFieldName>>[0]>;
}

/**
 * Fluent `<Field />` wrapped inside React-Hook-Form `<Controller />`.
 * Used to support validation and error messages using the RHF library.
 * Accept via props the `<Component />` to be rendered inside the `<Field />`.
 */
export const RHFField = <TFormFields extends TFormFieldsBase, TFieldName extends Path<TFormFields>>({
    controllerProps,
    fieldProps,
    Component,
}: RHFFieldProps<TFormFields, TFieldName>) => {
    const render = useCallback<RenderFn<TFormFields, TFieldName>>(
        (componentProps) => {
            const { error } = componentProps.fieldState;
            return (
                <Field
                    {...(error ? { validationState: 'error', validationMessage: error.message } : {})}
                    {...fieldProps}
                >
                    <Component {...componentProps} />
                </Field>
            );
        },
        [fieldProps, Component]
    );

    return <Controller {...controllerProps} render={render} />;
};
