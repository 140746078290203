import { createPatcherForArrayLikeProperty } from '../utils';
import { badColorRulesIdFormatConfig } from './badColorRulesIdFormat';
import { badHorizontalRulesIdFormatConfig } from './badHorizontalRulesIdFormat';
import { emptyTileTitleConfig } from './emptyTitle';
import { layoutTooWideConfig } from './layoutTooWide';
import { multipleYAxesBaseColumnNotStringConfig } from './multipleYAxesBaseColumnNotString';
import { seriesColumnsMustBeArrayOrNullConfig } from './seriesColumnsMustBeArrayOrNull';
import { yColumnsMustBeArrayOrNullConfig } from './yColumnsMustBeArrayOrNull';

export const tilePatches = [
    badColorRulesIdFormatConfig,
    badHorizontalRulesIdFormatConfig,
    emptyTileTitleConfig,
    layoutTooWideConfig,
    multipleYAxesBaseColumnNotStringConfig,
    yColumnsMustBeArrayOrNullConfig,
    seriesColumnsMustBeArrayOrNullConfig,
];

export const patchTiles = createPatcherForArrayLikeProperty('tiles', tilePatches);
