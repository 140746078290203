/**
 * If a migration is unlikely to be required for most dashboards, this utility
 * will only make a copy of the provided array if required.
 *
 * Only works if `T` does not extend `undefined`
 */
export function maybeMap<T>(arr: readonly T[], callbackfn: (value: T) => void | undefined | T): readonly T[] {
    for (let i = 0; i < arr.length; i++) {
        const maybeUpdated = callbackfn(arr[i]);
        if (maybeUpdated) {
            const newArr = [...arr.slice(0, i), maybeUpdated, ...arr.slice(i + 1)];
            for (let j = i + 1; j < arr.length; j++) {
                const _maybeUpdated = callbackfn(arr[j]);
                if (_maybeUpdated) {
                    newArr[j] = _maybeUpdated;
                }
            }
            return newArr;
        }
    }
    return arr;
}
