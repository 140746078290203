import { KweException } from '@kusto/utils';

import type { IDataSourceV1 } from './1';

// `extends` is used instead of a scope config property so scope specific config
// can be narrowed without deconstructing

export interface IDataSourceV2 {
    kind: string;
    id: string;
    name: string;
    /**
     * Unique identifies scope in visual config. Allows 2 scopes to share the
     * same scope config type.
     */
    scopeId: string;
}

export interface IManualKustoDataSourceV2 extends IDataSourceV2 {
    kind: 'manual-kusto';
    clusterUri: string;
    database: string;
}

export interface ILogAnalyticsDataSourceV2 extends IDataSourceV2 {
    kind: 'log-analytics';
    subscription: string;
    resourceGroup: string;
    workspace: string;
}

export interface IAppInsightsDataSourceV2 extends IDataSourceV2 {
    kind: 'app-insights';
    subscription: string;
    resourceGroup: string;
    application: string;
}

export type UDataSourceV2 = IManualKustoDataSourceV2 | ILogAnalyticsDataSourceV2 | IAppInsightsDataSourceV2;

export function up(prev: IDataSourceV1): UDataSourceV2 {
    const shared = {
        id: prev.id,
        name: prev.title,
        scopeId: prev.type,
    };

    switch (prev.type) {
        case 'appinsights':
            return {
                ...shared,
                kind: 'app-insights',
                subscription: prev.connectionFields[0],
                resourceGroup: prev.connectionFields[1],
                application: prev.database,
            };
        case 'loganalytics':
            return {
                ...shared,
                kind: 'log-analytics',
                subscription: prev.connectionFields[0],
                resourceGroup: prev.connectionFields[1],
                workspace: prev.database,
            };
        case 'kusto':
            return {
                ...shared,
                kind: 'manual-kusto',
                clusterUri: prev.connectionFields[0],
                database: prev.database,
            };
        default:
            throw new KweException(`Unexpected data source type ${prev.type}`);
    }
}
