import React from 'react';
import { Body1Strong, Button } from '@fluentui/react-components';
import { ArrowShuffle20Regular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';

import { MonacoEditorJSON } from '@kusto/ui-components';
import { formatLiterals, Theme } from '@kusto/utils';

import { useDataExplorationContext } from '../../../../context/DataExplorationContext';
import {
    useEmptyCellCountForColumn,
    useSampleValue,
    useScopedRowCount,
    type DataProfileTimeRange,
} from '../../../../DataExplorationApi';
import { useStrings } from '../../../hooks/useStrings';
import { ColumnInfoStatusWrapper } from './ColumnInfoStatusWrapper';

import styles from './SelectedColumnInfo.module.scss';

interface SelectedColumnSampleValueProps {
    selectedColumnName: string;
    title: React.ReactNode;
    queryText: string;
    timeRange: DataProfileTimeRange;
    wordWrap?: boolean;
}

const useEmptyCellRatio = (queryText: string, selectedColumnName: string, timeRange: DataProfileTimeRange) => {
    const { data: emptyCount } = useEmptyCellCountForColumn(queryText, selectedColumnName, timeRange);
    const { data: scopedRowCount } = useScopedRowCount(queryText, timeRange);

    return emptyCount !== undefined && scopedRowCount ? emptyCount / scopedRowCount : undefined;
};

export const SelectedColumnSampleValue: React.FC<SelectedColumnSampleValueProps> = observer(
    function SelectedColumnSampleValue({ selectedColumnName, queryText, title, timeRange, wordWrap }) {
        const { t, theme } = useDataExplorationContext();
        const {
            data: sampleValue,
            mutate,
            isLoading,
            error,
        } = useSampleValue(queryText, selectedColumnName, timeRange);
        const emptyCellPercentage = useEmptyCellRatio(queryText, selectedColumnName, timeRange);
        const { strings, locale } = useStrings();

        const percentFormatter = new Intl.NumberFormat(locale, {
            style: 'percent',
            maximumFractionDigits: 2,
            maximumSignificantDigits: 2,
        });

        return (
            <>
                <div className={styles.columnInfoHeader}>
                    {title}
                    <div className={styles.sampleValueHeader}>
                        <Body1Strong>{t.dataExploration.schemaInsights.sampledValue}</Body1Strong>
                        <Button
                            size="small"
                            appearance="transparent"
                            icon={<ArrowShuffle20Regular />}
                            onClick={() => mutate()}
                        >
                            {t.dataExploration.schemaInsights.sampleAgain}
                        </Button>
                    </div>
                </div>
                <ColumnInfoStatusWrapper isLoading={isLoading} errorMessage={error?.message}>
                    <div className={styles.sampleValueContentArea}>
                        <div className={styles.sampleValueEditor}>
                            <MonacoEditorJSON
                                json={JSON.stringify(sampleValue)}
                                isDark={theme === Theme.Dark}
                                editorOptions={{
                                    lineNumbers: 'off',
                                    wordWrap: wordWrap ? 'on' : 'off',
                                    folding: false,
                                }}
                            />
                        </div>
                        {emptyCellPercentage !== undefined && (
                            <Body1Strong className={styles.emptyCellPercentage}>
                                {formatLiterals(
                                    strings.dataExplorationModal.schemaColumns.columnInfo.emptyCellPercentage,
                                    { percentage: percentFormatter.format(emptyCellPercentage) }
                                )}
                            </Body1Strong>
                        )}
                    </div>
                </ColumnInfoStatusWrapper>
            </>
        );
    }
);
