import type { ReadonlyRecord } from '@kusto/utils';

export const visualFwkFlags = {
    Visuals: [
        'cross-filter--series--point',
        'EnableMarkdownImageOverflow',
        'EnableInteractiveLegend',
        'EnableSharedCrosshair',
        'show-anomaly-columns-visual-option',
    ],
} as const satisfies ReadonlyRecord<string, readonly (readonly [string, string] | string)[]>;

type FilterDescriptions<T> = T extends readonly [string, string] ? T[0] : T;

export type VisualFwkFeatureFlag = FilterDescriptions<(typeof visualFwkFlags)[keyof typeof visualFwkFlags][number]>;
