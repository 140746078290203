import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { QueryPropertyV3 } from '../query/3';
import type { BasicParameterTypesV2 } from '../value/2';
import type { BasicParamV2 } from './2';
import type { BasicParamV8 } from './8';
import type { BasicParamV9, DataSourceParamV9, DurationParamV9, IParameterBaseV9, IParameterV9 } from './9';

export declare namespace BasicParamV10 {
    export interface SelectionValue<T> {
        kind: 'value';
        value: T;
    }
    export interface SelectionValues<T> {
        kind: 'values';
        values: T[];
    }
    export interface SelectionAll {
        kind: 'all';
    }

    export interface Base<K> extends IParameterBaseV9 {
        kind: K;
        variableName: string;
    }

    export interface Freetext<T, K> extends Base<K> {
        selectionType: 'freetext';
        defaultValue: SelectionAll | SelectionValue<T>;
    }

    export interface DropdownQueryDataSource {
        kind: 'query';
        /**
         * @see {@link QueryProperty}
         */
        query: QueryPropertyV3;
        /**
         * If true, selection should be reset when it's no longer in list of options
         */
        autoReset?: boolean;

        columns: {
            /**
             * Inferred if not present
             */
            value?: string;
            /**
             * If label is not present value is used instead
             */
            label?: string;
        };
    }

    export type DropdownDataSource<T> = BasicParamV2.DropdownStaticDataSource<T> | DropdownQueryDataSource;

    export type DropdownSelection<T> =
        | { kind: 'no-selection' | 'query-result' }
        | SelectionAll
        | SelectionValue<T>
        | SelectionValues<T>;

    export interface Dropdown<T, K> extends Base<K> {
        /**
         * - `scalar` - Only one selection
         * - `array` - Many selections; discrete selections, explicit
         */
        selectionType: 'scalar' | 'array';
        /**
         * ## no-selection
         * When this is a parameters value, the dependents of the parameter will not
         * run. Users will need to change the selected value before dependents run.
         *
         * ## query-result
         * When this is a parameters value, the parameter will be updated using the
         * first available query result.
         *
         * ## query-result
         * Default on query result
         *
         * ## All
         * Serializes as either null or every available option, depending on the parameter config
         */
        defaultValue: DropdownSelection<T>;

        /**
         * If true an option labeled "all" will be added to the list of options.
         * As of writing, the option will be represented as "null" when running
         * queries.
         */
        includeAllOption: boolean;
        /**
         * If true, "all" selections are serialized as "null". If false, "all"
         * is serialized as every value in dropdown.
         *
         * "false" causes cause the parameters value to update when the options
         * chance, and makes the value unavailable when the options haven't
         * loaded yet.
         *
         * Should only be on parameters with "array" selection type
         */
        allIsNull?: boolean;

        dataSource: DropdownDataSource<T>;
    }
}

export type TBasicParamV10<T, K> = BasicParamV10.Freetext<T, K> | BasicParamV10.Dropdown<T, K>;

export type UBasicParamV10 = {
    [Tag in keyof BasicParameterTypesV2]: TBasicParamV10<BasicParameterTypesV2[Tag], Tag>;
}[keyof BasicParameterTypesV2];

export type ParameterV10 = UBasicParamV10 | DurationParamV9.IParam | DataSourceParamV9.IParam;

function upGeneric<T, K>(prev: BasicParamV9.IFreetext<T, K>): BasicParamV10.Freetext<T, K> {
    if (prev.defaultValue.kind === 'values') {
        return {
            ...prev,
            defaultValue: { kind: 'value', value: prev.defaultValue.values[0] },
        };
    }
    return prev as BasicParamV10.Freetext<T, K>;
}

/**
 * ## Additions
 *
 * ### all is null
 * Allows parameters to be configured so "all" is not serialized as "null" when set to false
 *
 * ### auto-reset
 * Parameter value is reset when the selection is no longer in the list of options
 *
 * ### default first value
 * Parameter has no value until it's query returns a result
 *
 * ### No selection
 * Parameter has no value until the user selects one
 */
export function parameterV10Up(prev: IParameterV9): ParameterV10 {
    if (prev.kind !== 'dataSource' && prev.kind !== 'duration' && prev.selectionType === 'freetext') {
        // Assign to `BasicParamV9.IFreetext<any, any>` instead of cast so we
        // catch as some mistakes
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _prev: BasicParamV9.IFreetext<any, any> = prev;
        return upGeneric(_prev);
    }

    return prev;
}

function downGeneric<T, K>(
    prev: BasicParamV10.Dropdown<T, K>,
    warn: AddDashboardWarning
): BasicParamV9.IDropdown<T, K> {
    if (prev.allIsNull === false) {
        warn((t) => formatLiterals(t.down.v44.parameterAllIsNullRemoved, { parameterName: prev.displayName }));
    }

    let defaultValue: BasicParamV2.ValueSelection<T>;

    switch (prev.defaultValue.kind) {
        case 'no-selection':
            warn((t) => formatLiterals(t.down.v44.parameterNoSelectionRemoved, { parameterName: prev.displayName }));
            defaultValue = { kind: 'all' };
            break;
        case 'query-result':
            warn((t) => formatLiterals(t.down.v44.parameterQueryResultRemoved, { parameterName: prev.displayName }));
            defaultValue = { kind: 'all' };
            break;
        case 'all':
        case 'value':
        case 'values':
            defaultValue = prev.defaultValue;
    }

    let dataSource: BasicParamV2.DropdownStaticDataSource<T> | BasicParamV8.DropdownQueryDataSource;

    if (prev.dataSource.kind === 'static' || prev.dataSource.autoReset === undefined) {
        dataSource = prev.dataSource;
    } else {
        if (prev.dataSource.autoReset === true) {
            warn((t) => formatLiterals(t.down.v44.parameterAutoResetRemoved, { parameterName: prev.displayName }));
        }
        dataSource = {
            kind: prev.dataSource.kind,
            query: prev.dataSource.query,
            columns: prev.dataSource.columns,
        };
    }

    return {
        id: prev.id,
        displayName: prev.displayName,
        description: prev.description,
        showOnPages: prev.showOnPages,

        kind: prev.kind,
        defaultValue,
        dataSource,
        selectionType: prev.selectionType,
        includeAllOption: prev.includeAllOption,
        variableName: prev.variableName,
    };
}

export function parameterV10Down(prev: ParameterV10, warn: AddDashboardWarning): IParameterV9 {
    if (prev.kind === 'dataSource' || prev.kind === 'duration' || prev.selectionType === 'freetext') {
        return prev;
    }

    // Assign to `BasicParamV9.IFreetext<any, any>` instead of cast so we
    // catch as some mistakes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _prev: BasicParamV10.Dropdown<any, any> = prev;

    return downGeneric(_prev, warn);
}
