import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { ITileV20, IVisualOptionsV20 } from './20';

export interface TableRenderLinkConfigV21 {
    /**
     * The column we treat as the URL for the link (e.g. https://microsoft.com).
     */
    readonly urlColumn: string;
    /**
     * Optional.
     * When `undefined`, we render the link defined on `urlColumn`.
     * When `string`, we wrap the `displayColumn` with the
     * link from `urlColumn`.
     */
    readonly displayColumn?: string;
    readonly disabled: boolean;
}

export interface IVisualOptionsV21 extends Omit<IVisualOptionsV20, 'table__renderLinksForColumns'> {
    readonly table__renderLinks: readonly TableRenderLinkConfigV21[];
}

export interface ITileV21 extends Omit<ITileV20, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV21>;
}

export function up(prev: ITileV20): ITileV21 {
    if (prev.visualOptions.table__renderLinksForColumns === undefined) {
        return prev as ITileV21;
    }

    const { table__renderLinksForColumns: renderLinksForColumns, ...prevVisualOptions } = prev.visualOptions;

    const nextRenderLinks: TableRenderLinkConfigV21[] = renderLinksForColumns.map((columnName) => ({
        urlColumn: columnName,
        displayColumn: undefined,
        disabled: false,
    }));

    return {
        ...prev,
        visualOptions: {
            ...prevVisualOptions,
            table__renderLinks: nextRenderLinks,
        },
    };
}

export function down(prev: ITileV21, addWarning: AddDashboardWarning): ITileV20 {
    const { table__renderLinks: renderLinks, ...prevVisualOptions } = prev.visualOptions;

    const nextVisualOptions: ITileV20['visualOptions'] = prevVisualOptions;

    if (renderLinks) {
        const nextLinks: string[] = [];

        for (const renderLink of renderLinks) {
            if (renderLink.disabled || !renderLink.urlColumn) {
                addWarning((t) => formatLiterals(t.down.v34.renderLinkRemoved, { tileName: prev.title }));
                continue;
            }

            if (renderLink.urlColumn && renderLink.displayColumn) {
                addWarning((t) => formatLiterals(t.down.v34.renderLinkDowngraded, { tileName: prev.title }));
            }

            nextLinks.push(renderLink.urlColumn);
        }

        nextVisualOptions.table__renderLinksForColumns = nextLinks;
    }

    return {
        ...prev,
        visualOptions: nextVisualOptions,
    };
}
