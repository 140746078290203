import type { UDataSourceV2 } from '../dataSource/2';
import * as dataSourceV2 from '../dataSource/2';
import type { IDashboardV7 } from './7';

export interface IDashboardV8 extends Omit<IDashboardV7, 'schema_version' | 'dataSources'> {
    schema_version: '8';
    dataSources: readonly UDataSourceV2[];
}

/**
 * Updates tiles to v2
 */
export function up(prev: IDashboardV7): IDashboardV8 {
    return {
        ...prev,
        schema_version: '8',
        dataSources: prev.dataSources.map(dataSourceV2.up),
    };
}
