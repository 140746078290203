import groupBy from 'lodash/groupBy';
import type { RequiredDeep } from 'type-fest';

import type { AddDashboardWarning, DashboardAtVersion } from '../../../index';
import type {
    ArrayLikeProperties,
    Clean,
    Corrupted,
    CorruptionResult,
    PatchDashboardVersion,
    PatchEntire,
    PatchOne,
    PatchVersion,
    UPatch,
} from './types';

export function corrupted<Data>(data: Data): Corrupted<Data> {
    return {
        kind: 'corrupted',
        data,
    };
}

export const clean: Clean = { kind: 'clean' };
/**
 * Infers the CorruptionResult based on if the data array has values or not
 */
export function resultFrom<Data extends Array<unknown>>(data: Data): CorruptionResult<Data> {
    return data.length ? corrupted(data) : clean;
}

/**
 * This handles deletion by mutating on an array such that the
 * target index will always be for the intended item and
 * not off by N, where N is the number of items deleted
 * so far.
 */
export class StableArrayDeleter {
    private state = { numItemsDeleted: 0 };

    public constructor(private arr: Array<unknown>) {}

    /**
     * Mutates the original array when deleting
     * @param originalIndex The original index for the item you want to delete
     */
    public delete = (originalIndex: number): void => {
        // we subtract by numItemsDeleted to adjust the index so that it's targeting the right one
        const adjIndex = originalIndex - this.state.numItemsDeleted;
        const alreadyDeleted = this.arr.at(adjIndex) === undefined;

        // The target item was deleted already so let's not do anything
        if (alreadyDeleted) {
            return;
        }

        this.arr.splice(adjIndex, 1);
        this.state.numItemsDeleted += 1;
    };
}

/**
 * If your patches are for the top level dashboard properties, you can
 * use this fn instead of writing your own
 */
export function patchDashboard<Version extends PatchVersion = PatchVersion>(
    dashboard: PatchDashboardVersion,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    patches: PatchEntire<any>[],
    addWarning: AddDashboardWarning
): DashboardAtVersion<Version> {
    let innerDashboard: PatchDashboardVersion = dashboard;
    for (const patch of patches) {
        const res = patch.corruption(innerDashboard);

        if (res.kind === 'corrupted') {
            innerDashboard = patch.patch(innerDashboard as RequiredDeep<PatchDashboardVersion>, res.data, addWarning);
        }
    }
    return innerDashboard as DashboardAtVersion<Version>;
}

/**
 * This is a helper method to apply patches for all the array like
 * properties on a dashboard like "dataSources" or "tiles", etc. If
 * you only have 1 patch you can just apply it by hand.
 *
 * @param property
 * @param patches
 * @returns patcher function to call that will apply your patches for you
 */
export function createPatcherForArrayLikeProperty<Property extends ArrayLikeProperties = ArrayLikeProperties>(
    property: Property,
    patches: UPatch<Property>[]
) {
    return function patcher<Version extends PatchVersion = PatchVersion>(
        dashboard: PatchDashboardVersion,
        addWarning: AddDashboardWarning
    ): DashboardAtVersion<Version> {
        let innerDashboard: PatchDashboardVersion = dashboard;

        const groupedByKind = groupBy(patches, 'kind') as {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ['patch-entire']: undefined | Array<PatchEntire<any>>;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ['patch-one']: undefined | Array<PatchOne<Property, any>>;
        };

        if (groupedByKind['patch-entire']) {
            for (const patchEntire of groupedByKind['patch-entire']) {
                const res = patchEntire.corruption(innerDashboard);

                if (res.kind === 'corrupted') {
                    innerDashboard = patchEntire.patch(
                        innerDashboard as RequiredDeep<PatchDashboardVersion>,
                        res.data,
                        addWarning
                    );
                }
            }
        }

        if (groupedByKind['patch-one']) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let values = innerDashboard[property] as any[];
            let valuesChanged = false;

            for (let i = 0; i < values.length; i++) {
                const value = values[i];

                for (const patchOne of groupedByKind['patch-one']) {
                    const res = patchOne.corruption(value);

                    if (res.kind === 'corrupted') {
                        const newValue = patchOne.patch(value, res.data, addWarning);

                        values = [...values.slice(0, i), newValue, ...values.slice(i + 1)];
                        valuesChanged = true;
                    }
                }
            }

            // Purposefully waiting till here to re-assign a new object for the
            // dashboard since `values` could be getting re-created many times
            // before we're done with it. This is just a minor perf tweak to
            // save some time only shallow copying the dashboard once here
            if (valuesChanged) {
                innerDashboard = {
                    ...innerDashboard,
                    [property]: values,
                };
            }
        }

        return innerDashboard as DashboardAtVersion<Version>;
    };
}
