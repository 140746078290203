import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import type { IParameterV5 } from '../parameter/5';
import * as queryV2 from '../query/2';
import type { ITileV16 } from '../tile/16';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV26 } from './26';

export interface IDashboardV27 {
    readonly schema_version: '27';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly ITileV16[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly queryV2.SharedQueryV2[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly IParameterV5[];
}

export function up(prev: IDashboardV26): IDashboardV27 {
    return {
        ...prev,
        schema_version: '27',
        sharedQueries: prev.sharedQueries.map((q) => queryV2.up(q)),
    };
}

export function down(prev: IDashboardV27, addWarning: AddDashboardWarning): IDashboardV26 {
    return {
        ...prev,
        schema_version: '26',
        sharedQueries: prev.sharedQueries.map((q) => queryV2.down(q, addWarning)),
    };
}
