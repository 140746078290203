import type { AddDashboardWarning } from '..';
import { pagesHasZeroItemsConfig } from './45/corruptionPatches/dashboard/pagesHasZeroItems';
import { patchDataSources } from './45/corruptionPatches/dataSources';
import { patchParameters } from './45/corruptionPatches/parameters';
import { patchTiles } from './45/corruptionPatches/tiles';
import { patchDashboard } from './45/corruptionPatches/utils';
import type { DashboardV48 } from './48';

/**
 * We don't use the original dashboardPatches for v44 because it
 * includes a patch to the empty titles which we already account for
 * in migration v46 so no need to do it again here.
 */
const dashboardPatches = [pagesHasZeroItemsConfig];
function patchV48(dashboard: DashboardV48, addWarning: AddDashboardWarning): DashboardV48 {
    const res1 = patchDashboard<'48'>(dashboard, dashboardPatches, addWarning);
    const res2 = patchDataSources<'48'>(res1, addWarning);
    const res3 = patchParameters<'48'>(res2, addWarning);
    return patchTiles<'48'>(res3, addWarning);
}

/**
 * There was a mistake in v45 to apply the corruption patches so we need to apply them again
 * since customers could have created new dashboards in between then (e.g. v48 dashboard)
 * which would result in customers never applying migration v45 and miss out on the patches.
 */
export interface DashboardV49 extends Omit<DashboardV48, 'schema_version'> {
    readonly schema_version: '49';
}

export function up(prev: DashboardV48, warn: AddDashboardWarning): DashboardV49 {
    return {
        ...patchV48(prev, warn),
        schema_version: '49',
    };
}

export function down(prev: DashboardV49): DashboardV48 {
    return {
        ...prev,
        schema_version: '48',
    };
}
