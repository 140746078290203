import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { ColorRuleByConditionV19, ColorRuleByValueV19, UColorRuleV19 } from './19';
import type { ITileV21, IVisualOptionsV21 } from './21';

type Operator = '>' | '>=' | '<' | '<=' | '==' | '!=' | 'between';

interface Condition {
    readonly column: string | null;
    readonly operator: Operator;
    readonly values: readonly string[];
}

export interface ColorRuleByConditionV22 extends Omit<ColorRuleByConditionV19, 'conditions'> {
    readonly conditions: Condition[];
}

export type UColorRule = ColorRuleByConditionV22 | ColorRuleByValueV19;

export interface IVisualOptionsV22 extends Omit<IVisualOptionsV21, 'colorRules'> {
    readonly colorRules: readonly UColorRule[];
}

export interface ITileV22 extends Omit<ITileV21, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV22>;
}

export function up(prev: ITileV21): ITileV22 {
    return prev as ITileV22;
}

export function down(prev: ITileV22, addWarning: AddDashboardWarning): ITileV21 {
    const { colorRules, ...prevVisualOptions } = prev.visualOptions;
    let visualOptionsV21: Partial<IVisualOptionsV21> = { ...prevVisualOptions };
    if (colorRules) {
        const downgradedColorRules = colorRules.filter((rule) => {
            if (rule.ruleType === 'colorByValue') {
                return true;
            }
            let hasNotEqualOperator = false;
            for (let i = 0; i < rule.conditions.length; i++) {
                if (rule.conditions[i].operator === '!=') {
                    hasNotEqualOperator = true;
                }
            }
            return !hasNotEqualOperator;
        }) as UColorRuleV19[];
        visualOptionsV21 = { ...visualOptionsV21, colorRules: downgradedColorRules };
    }

    if (
        visualOptionsV21.colorRules &&
        prev.visualOptions.colorRules &&
        visualOptionsV21.colorRules.length !== prev.visualOptions.colorRules.length
    ) {
        const removedRulesCount = visualOptionsV21.colorRules.length - prev.visualOptions.colorRules.length;
        addWarning((t) =>
            formatLiterals(t.down.v35.colorRulesRemoved, { tileName: prev.title, count: removedRulesCount.toString() })
        );
    }

    return {
        ...prev,
        visualOptions: visualOptionsV21,
    };
}
