import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { BasicParameterTypesV1 } from '../value/1';
import type { BasicParamV2 } from './2';
import type { BasicParamV3, DurationParamV3 } from './3';
import type { BasicParamV5, DataSourceParamV5, IParameterV5 } from './5';

export declare namespace BasicParamV6 {
    export type DropdownDataSource<T> = BasicParamV2.DropdownStaticDataSource<T> | BasicParamV5.DropdownQueryDataSource;

    export interface IDropdown<T, K> extends BasicParamV3.IBasic<T, K> {
        readonly selectionType: 'scalar' | 'array';
        /**
         * If true, an "all" value will be added to the list of choices in the dropdown
         */
        readonly includeAllOption: boolean;
        readonly dataSource: DropdownDataSource<T>;
    }
}

export type TBasicParamV6<T, K> = BasicParamV3.IFreetext<T, K> | BasicParamV6.IDropdown<T, K>;

export type UBasicParamV6 =
    | TBasicParamV6<BasicParameterTypesV1['string'], 'string'>
    | TBasicParamV6<BasicParameterTypesV1['int32'], 'int32'>
    | TBasicParamV6<BasicParameterTypesV1['int64'], 'int64'>
    | TBasicParamV6<BasicParameterTypesV1['float64'], 'float64'>
    | TBasicParamV6<BasicParameterTypesV1['decimal'], 'decimal'>
    | TBasicParamV6<BasicParameterTypesV1['bool'], 'bool'>
    | TBasicParamV6<BasicParameterTypesV1['datetime'], 'datetime'>;

export type IParameterV6 = UBasicParamV6 | DurationParamV3.IParam | DataSourceParamV5.IParam;

export function up(prev: IParameterV5): IParameterV6 {
    if (prev.kind === 'dataSource' || prev.kind === 'duration' || prev.selectionType === 'freetext') {
        return prev as IParameterV6;
    }

    let selectionType: 'scalar' | 'array';
    let includeAllOption: boolean;

    switch (prev.selectionType) {
        case 'multi':
            selectionType = 'array';
            includeAllOption = true;
            break;
        case 'single':
            selectionType = 'scalar';
            includeAllOption = false;
            break;
        case 'single-all':
            selectionType = 'scalar';
            includeAllOption = true;
    }

    return {
        ...prev,
        selectionType,
        includeAllOption,
    };
}

/**
 * @param prev Make sure we don't pass along DataSourceParams here because
 * there's no way to directly migrate them down
 */
export function down(prev: IParameterV6, addWarning: AddDashboardWarning): IParameterV5 {
    if (prev.kind === 'dataSource' || prev.kind === 'duration' || prev.selectionType === 'freetext') {
        return prev as IParameterV5;
    }

    const { includeAllOption, ...shared } = prev;

    let selectionType: 'single' | 'multi' | 'single-all';

    if (includeAllOption) {
        if (prev.selectionType === 'array') {
            selectionType = 'multi';
        } else {
            selectionType = 'single-all';
        }
    } else {
        if (prev.selectionType === 'array') {
            addWarning((t) => formatLiterals(t.down.v29.selectAllReEnabled, { parameterName: prev.displayName }));
            selectionType = 'multi';
        } else {
            selectionType = 'single';
        }
    }

    return {
        ...shared,
        selectionType,
    };
}
