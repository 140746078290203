import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import * as parameterV5 from '../parameter/5';
import * as queryV1 from '../query/1';
import * as tileV16 from '../tile/16';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV25 } from './25';

export interface IDashboardV26 {
    readonly schema_version: '26';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV16.ITileV16[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly queryV1.SharedQueryV1[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly parameterV5.IParameterV5[];
}

export function up(prev: IDashboardV25): IDashboardV26 {
    return {
        ...prev,
        schema_version: '26',
        tiles: prev.tiles.map((t) => tileV16.up(t)),
        parameters: prev.parameters.map((t) => parameterV5.up(t)),
        sharedQueries: prev.sharedQueries.map((q) => queryV1.up(q)),
    };
}

function filterOutDataSourceParam(
    parameters: readonly parameterV5.IParameterV5[],
    addWarning: AddDashboardWarning
): parameterV5.IParameterV5WithoutDataSourceParam[] {
    const filtered: parameterV5.IParameterV5WithoutDataSourceParam[] = [];

    for (const p of parameters) {
        if (p.kind === 'dataSource') {
            addWarning((t) => formatLiterals(t.down.v26.dataSourceParamRemoved, { name: p.displayName, id: p.id }));
        } else {
            filtered.push(p);
        }
    }

    return filtered;
}

export function down(prev: IDashboardV26, addWarning: AddDashboardWarning): IDashboardV25 {
    const parametersExcludingDSP = filterOutDataSourceParam(prev.parameters, addWarning);
    const nextParameters = parametersExcludingDSP.map((p) => parameterV5.down(p, prev.parameters, addWarning));

    return {
        ...prev,
        schema_version: '25',
        parameters: nextParameters,
        tiles: prev.tiles.map((t) => tileV16.down(t, prev.parameters, addWarning)),
        sharedQueries: prev.sharedQueries.map((q) => queryV1.down(q, prev.parameters, addWarning)),
    };
}
