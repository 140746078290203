import identity from 'lodash/identity';
import omitBy from 'lodash/omitBy';

import { KustoType } from '../schemaTypes.ts';

type Parser = (value: string) => string;

export type Maybe<T> = T | undefined | null;
export type CompactObject<T> = {
    [K in keyof T]: NonNullable<T[K]>;
};

export function listToArray<T>(list: System.Collections.Generic.IReadOnlyList$1<T> | null | undefined): T[] {
    if (!list) return [];

    return new Array(list.Count).fill(null).map((_, index) => list.getItem(index));
}

export function omitEmptyValues<T extends object>(obj: T): CompactObject<T> {
    return omitBy(obj, (value) => !value) as CompactObject<T>;
}

export function convertEntityDefaultValueToJsonPresentation(value: string, type: KustoType) {
    return parserMaker(type)(value);
}

function parserMaker(type: KustoType): Parser {
    switch (type) {
        case 'datetime':
            return datetimeParser;
        case 'real':
            return realParser;
        case 'dynamic':
            return dynamicParser;
        case 'bool':
            return booleanParser;
        case 'timespan':
            return timespanParser;
        default:
            return identity;
    }
}

function datetimeParser(value: string): string {
    const isDatetimeNow = value.match(/^datetime\(now\)$/);
    return isDatetimeNow ? `datetime(${new Date().toISOString()})` : value;
}

function realParser(value: string): string {
    return value.replace('double', 'real');
}

function dynamicParser(value: string): string {
    return value.replace("dynamic(' ')", 'dynamic(" ")');
}

function booleanParser(value: string): string {
    return value.toLowerCase();
}

function timespanParser(value: string): string {
    if (value.startsWith('timespan') || !value.startsWith('time')) {
        return `time(${convertTimeString(value)})`;
    }
    return value;
}

function convertTimeString(timeStr: string): string {
    const [number, unit] = extractTimeAndUnit(timeStr);

    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let days = 0;

    switch (unit) {
        case 'd':
            days = number;
            break;
        case 'h':
            hours = number;
            break;
        case 'm':
            hours = Math.floor(number / 60);
            minutes = number % 60;
            break;
        case 's':
            hours = Math.floor(number / 3600);
            minutes = Math.floor((number % 3600) / 60);
            seconds = number % 60;
            break;
    }

    return `${days ? `${days}.` : ''}${toTimePart(hours)}:${toTimePart(minutes)}:${toTimePart(seconds)}`;
}

function extractTimeAndUnit(timeStr: string): [number, string] {
    const match = timeStr.match(/(\d+)([a-zA-Z]+)/);
    if (!match) return [parseInt(timeStr), ''];

    return [parseInt(match[1]), match[2]];
}

function toTimePart(time: number): string {
    return time.toString().padStart(2, '0');
}
