import type { IDataSourceV0 } from '../dataSource/0';
import * as dataSourceV1 from '../dataSource/1';
import * as parameterV1_1 from '../parameter/1-1';
import * as parameterV1_2 from '../parameter/1-2';
import * as parameterV1_3 from '../parameter/1-3';
import type { ITileV0 } from '../tile/0';
import type { DashboardConfigOptionsV1_2, IDashboardV1_2 } from './1-2';
import type { IDashboardPreSchema } from './preSchema';

export interface IDashboardV2
    extends Omit<IDashboardPreSchema, 'schema_version' | 'dataSources' | 'parameters' | 'tiles'> {
    schema_version: '2';

    pinnedParameters: string[];
    configuration?: DashboardConfigOptionsV1_2;

    tiles: readonly ITileV0[];
    dataSources: readonly dataSourceV1.IDataSourceV1[];
    parameters: readonly parameterV1_3.IParameterV1_3[];
}

export function up(prev: IDashboardV1_2): IDashboardV2 {
    const parameters = prev.parameters.map((p) => {
        const v1_1 = p.$schema === '1.0' ? parameterV1_1.up(p) : p;
        const v1_2 = v1_1.$schema === '1.1' ? parameterV1_2.up(v1_1) : v1_1;
        const v1_3 = v1_2.$schema === '1.2' ? parameterV1_3.up(v1_2) : v1_2;
        return v1_3;
    });

    const prevDataSources: ReadonlyArray<IDataSourceV0 | dataSourceV1.IDataSourceV1> = prev.dataSources;

    const dataSources = prevDataSources.map((d) => {
        if (d.$schema === '0') {
            return dataSourceV1.up(d);
        }
        return d;
    });

    return {
        ...prev,
        schema_version: '2',
        dataSources,
        parameters,
    };
}
