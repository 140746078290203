import React from 'react';
import { Caption2 } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import {
    useColumnDistribution,
    useScopedRowCount,
    type ColumnWithInsights,
    type DataProfileTimeRange,
} from '../../../../DataExplorationApi';
import { useStrings } from '../../../hooks/useStrings';
import { useDataExplorationStore } from '../../../store/DataExplorationStore';
import { ColumnInfoStatusWrapper } from './ColumnInfoStatusWrapper';
import { TopValuesBarGraph } from './TopValuesBarGraph';
import { TopValuesPieChart } from './TopValuesPieChart';

import styles from './SelectedColumnInfo.module.scss';

interface SelectedColumnValueDistributionProps {
    selectedColumn: ColumnWithInsights;
    title: React.ReactNode;
    queryText: string;
    timeRange: DataProfileTimeRange;
    timezone: string;
    viewMode: 'bar' | 'pie';
}

export const SelectedColumnValueDistribution: React.FC<SelectedColumnValueDistributionProps> = observer(
    function SelectedColumnValueDistribution({ selectedColumn, queryText, title, timeRange, timezone, viewMode }) {
        const { strings, locale } = useStrings();
        const { actions } = useDataExplorationStore();

        const addColumnValueFilter = React.useCallback(
            (value: string) => {
                actions.addPill({
                    type: 'filter',
                    data: {
                        column: { ColumnName: selectedColumn.ColumnName, ColumnType: selectedColumn.ColumnType },
                        operator: '==',
                        value,
                    },
                });
            },
            [actions, selectedColumn.ColumnName, selectedColumn.ColumnType]
        );

        const addNullFilter = React.useCallback(() => {
            actions.addPill({
                type: 'filter',
                data: {
                    column: { ColumnName: selectedColumn.ColumnName, ColumnType: selectedColumn.ColumnType },
                    operator: 'isnull',
                },
            });
        }, [actions, selectedColumn.ColumnName, selectedColumn.ColumnType]);

        const addEmptyStringFilter = React.useCallback(() => {
            actions.addPill({
                type: 'filter',
                data: {
                    column: { ColumnName: selectedColumn.ColumnName, ColumnType: selectedColumn.ColumnType },
                    operator: 'isempty',
                },
            });
        }, [actions, selectedColumn.ColumnName, selectedColumn.ColumnType]);

        const {
            data: scopedRowCount,
            isLoading: isLoadingRowCount,
            error: rowCountError,
        } = useScopedRowCount(queryText, timeRange);
        const {
            data: distribution,
            isLoading: isLoadingDistribution,
            error: distributionError,
        } = useColumnDistribution(queryText, selectedColumn.ColumnName, timeRange);

        const formattedDistribution = React.useMemo(() => {
            switch (selectedColumn.ColumnType) {
                case 'int':
                case 'long':
                case 'real': //TODO replace with percentiles https://msazure.visualstudio.com/One/_workitems/edit/28972816 until then fall-through for integer behavior
                case 'decimal': //TODO replace with percentiles https://msazure.visualstudio.com/One/_workitems/edit/28972816 until then fall-through for integer behavior
                case 'timespan':
                    const numberFormatter = new Intl.NumberFormat(locale, { style: 'decimal' });
                    return distribution?.map(({ title, itemCount }) => ({
                        title: title !== null && title !== undefined ? numberFormatter.format(title as number) : '-',
                        addAsFilter: title === null ? addNullFilter : () => addColumnValueFilter(title!.toString()),
                        itemCount,
                    }));
                case 'datetime': //TODO replace with binned time chart https://msazure.visualstudio.com/One/_workitems/edit/28347067/?workitem=28976386, until then fall-through for integer behavior
                    const dateFormatter = new Intl.DateTimeFormat(locale, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        fractionalSecondDigits: 3,
                        timeZone: timezone,
                    });
                    return distribution?.map(({ title, itemCount }) => ({
                        title:
                            title !== null && title !== undefined
                                ? dateFormatter.format(new Date(title as string))
                                : 'null',
                        addAsFilter:
                            title === null ? addNullFilter : () => addColumnValueFilter(`datetime(${title})` as string),
                        itemCount,
                    }));
                case 'bool':
                    return [
                        {
                            title: strings.schemaInsights.columnInfo.true,
                            addAsFilter: () => addColumnValueFilter('true'),
                            itemCount: distribution?.find((row) => row.title === true)?.itemCount ?? 0,
                        },
                        {
                            title: strings.schemaInsights.columnInfo.false,
                            addAsFilter: () => addColumnValueFilter('false'),
                            itemCount: distribution?.find((row) => row.title === false)?.itemCount ?? 0,
                        },
                        {
                            title: '-',
                            addAsFilter: addNullFilter,
                            itemCount: distribution?.find((row) => row.title === null)?.itemCount ?? 0,
                        },
                    ];
                case 'string':
                case 'dynamic':
                case 'guid':
                    return distribution?.map(({ title, itemCount }) => ({
                        title: title === '' ? strings.schemaInsights.columnInfo.emptyString : (title as string),
                        addAsFilter: !!title ? () => addColumnValueFilter(title as string) : addEmptyStringFilter,
                        itemCount,
                    }));
            }
        }, [
            selectedColumn.ColumnType,
            locale,
            distribution,
            timezone,
            strings.schemaInsights.columnInfo.true,
            strings.schemaInsights.columnInfo.false,
            strings.schemaInsights.columnInfo.emptyString,
            addNullFilter,
            addColumnValueFilter,
            addEmptyStringFilter,
        ]);

        return (
            <>
                <div className={styles.columnInfoHeader}>
                    {title}
                    <Caption2>{strings.dataExplorationModal.schemaColumns.columnInfo.clickToAddFilter}</Caption2>
                </div>
                <ColumnInfoStatusWrapper
                    isLoading={
                        isLoadingRowCount ||
                        scopedRowCount === undefined ||
                        isLoadingDistribution ||
                        !formattedDistribution
                    }
                    errorMessage={rowCountError?.message || distributionError?.message}
                >
                    {viewMode === 'bar' ? (
                        <TopValuesBarGraph
                            totalValues={scopedRowCount ?? 0}
                            items={formattedDistribution ?? []}
                            locale={locale}
                        />
                    ) : (
                        <TopValuesPieChart
                            totalValues={scopedRowCount ?? 0}
                            items={formattedDistribution ?? []}
                            locale={locale}
                        />
                    )}
                </ColumnInfoStatusWrapper>
            </>
        );
    }
);
