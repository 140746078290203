import { IKustoClient, KustoClientErrorDescription, KustoClientLocale } from '@kusto/client';
import {
    ABORTED,
    Account,
    AsyncResult,
    castToError,
    DataFrame,
    getFrameField,
    getFrameObjects,
    IKweTelemetry,
    KustoDataTypeFunc,
    ok,
} from '@kusto/utils';

import { functionParser, tableParser } from './parsers';
import { SchemaFetcher } from './SchemaFetcher.ts';
import {
    DatabaseSchemaById,
    EntityType,
    KustoClusterSchema,
    KustoDatabaseSchema,
    SchemaEntityCommandResult,
    SchemaEntityType,
    ShowDatabasesCommandResult,
    SuspensionState,
} from './schemaTypes';

export function convertDatabasesJson(json: string): DatabaseSchemaById {
    const clusterSchema = JSON.parse(json) as KustoClusterSchema;
    Object.values(clusterSchema.Databases).forEach((database) => setTableTypes(database));
    return clusterSchema.Databases;
}

export function convertDatabasesEntities(
    entities: SchemaEntityCommandResult[],
    showDatabasesResult: ShowDatabasesCommandResult[]
): DatabaseSchemaById {
    const databases: Record<string, KustoDatabaseSchema> = showDatabasesResult.reduce((acc, db) => {
        acc[db.name] = {
            Name: db.name,
            DatabaseAccessMode: db.accessMode,
            EntityGroups: {},
            ExternalTables: {},
            Functions: {},
            MajorVersion: db.majorVersion,
            MaterializedViews: {},
            MinorVersion: db.minorVersion,
            Tables: {},
        };
        if (db.prettyName) {
            acc[db.name].PrettyName = db.prettyName;
        }
        return acc;
    }, {} as Record<string, KustoDatabaseSchema>);

    entities.forEach(
        ({ databaseName, entityType, name, docString, folder, cslInputSchema, content, cslOutputSchema }) => {
            if (!databases[databaseName]) {
                return;
            }

            switch (entityType as SchemaEntityType) {
                case 'Table':
                    databases[databaseName].Tables[name] = tableParser({
                        name,
                        folder,
                        docString,
                        cslOutputSchema,
                        tableType: EntityType.Table,
                    });
                    break;
                case 'ExternalTable':
                    databases[databaseName].ExternalTables![name] = tableParser({
                        name,
                        folder,
                        docString,
                        cslOutputSchema,
                        tableType: EntityType.ExternalTable,
                    });
                    break;
                case 'MaterializedView':
                    databases[databaseName].MaterializedViews![name] = tableParser({
                        name,
                        folder: folder,
                        docString: docString,
                        cslOutputSchema,
                        tableType: EntityType.MaterializedViewTable,
                    });
                    break;
                case 'Function':
                    databases[databaseName].Functions![name] = functionParser(
                        name,
                        folder,
                        docString,
                        cslInputSchema,
                        content
                    );
                    break;
                case 'EntityGroup':
                    databases[databaseName].EntityGroups![name] = content.split(',').map((entity) => entity.trim());
                    break;
            }
        }
    );

    return databases;
}

export function getClusterSchemaOperations(
    kustoClient: IKustoClient,
    telemetry: IKweTelemetry,
    clusterUrl: string,
    initialCatalog: string | undefined,
    account: Account | undefined,
    getKustoClientLocale: () => KustoClientLocale
) {
    async function executeCommands(query: string) {
        const result = await kustoClient
            .createRequest(clusterUrl, {
                source: 'Query',
            })
            .executeControlCommand(initialCatalog, query, getKustoClientLocale(), {
                account,
            });

        if (result.kind !== 'ok') {
            return result;
        }

        return ok(result.value.frames[0].frame);
    }

    async function fetchClusterSchemaAsJson() {
        const dataFrameResult = await executeCommands('.show cluster schema as json');
        if (dataFrameResult.kind !== 'ok') {
            return dataFrameResult;
        }
        const clusterSchemaAsJsonString = getClusterSchemaAsJson(dataFrameResult.value, telemetry);
        telemetry.startTrackEvent('fetchClusterSchemaAsJson.convertDatabasesJson');
        const databaseSchemaById = convertDatabasesJson(clusterSchemaAsJsonString);
        telemetry.stopTrackEvent('fetchClusterSchemaAsJson.convertDatabasesJson');
        return ok({
            databaseSchemaById,
            tooBigToCache: clusterSchemaAsJsonString.length > 1024 * 1024,
        });
    }

    async function fetchDatabasesEntities(databaseList: ShowDatabasesCommandResult[]) {
        const dataFrameResult = await executeCommands('.show databases entities');
        if (dataFrameResult.kind !== 'ok') {
            return dataFrameResult;
        }

        const entities = getEntitiesResult(dataFrameResult.value);
        telemetry.startTrackEvent('fetchDatabasesEntities.convertDatabasesEntities');
        const databaseSchemaById = convertDatabasesEntities(entities, databaseList);
        telemetry.stopTrackEvent('fetchDatabasesEntities.convertDatabasesEntities');
        return ok({
            databaseSchemaById,
            tooBigToCache: entities.length > 1000,
        });
    }

    return {
        async showServiceModel() {
            const result = await executeCommands('.show service model');
            if (result.kind !== 'ok') {
                return result;
            }

            const serviceModels = getServiceModel(result.value);
            return ok({
                accounts: serviceModels.map(({ account }) => account),
                services: serviceModels.map(({ service }) => service),
            });
        },

        async getClusterVersionInfo() {
            const result = await executeCommands('.show version');
            if (result.kind !== 'ok') {
                return result;
            }

            const clusterType = getFrameField(result.value.fields, 'ServiceType', 'string', 0);
            let serviceOfferingType: string | undefined;
            try {
                const serviceOffering = getFrameField(result.value.fields, 'ServiceOffering', 'string', 0);
                serviceOfferingType = JSON.parse(serviceOffering).Type as string;
            } catch (e: unknown) {
                //PlayFab and maybe other proxies don't have ServiceOffering.
                //Might be good to create a list but since this is not a critical property we will
                //simply catch the issues and fallback to default.
                telemetry.info('getClusterVersionInfo.serviceOffering.missing', {
                    exception: castToError(e).message,
                });
            }

            serviceOfferingType =
                serviceOfferingType ??
                (clusterUrl.indexOf('://kvc') > -1
                    ? 'Azure Data Explorer Personal' // Temporary till all engine will support it in show version
                    : 'Azure Data Explorer');

            return ok({
                clusterType,
                serviceOfferingType,
            });
        },

        async fetchDatabases(): AsyncResult<ShowDatabasesCommandResult[], KustoClientErrorDescription[]> {
            const dataFrameResult = await executeCommands('.show databases');
            if (dataFrameResult.kind !== 'ok') {
                return dataFrameResult;
            }
            return ok(getDatabases(dataFrameResult.value, telemetry));
        },

        async fetchClusterSchema(
            lazySchemaExploration: boolean,
            liteSchemaExploration: boolean,
            databaseList: ShowDatabasesCommandResult[]
        ): AsyncResult<
            { databaseSchemaById: DatabaseSchemaById | undefined; tooBigToCache: boolean },
            KustoClientErrorDescription[]
        > {
            if (lazySchemaExploration) {
                return ok({
                    databaseSchemaById: undefined,
                    tooBigToCache: true,
                });
            }

            const clusterSchemaFetcher = new SchemaFetcher(
                () => fetchDatabasesEntities(databaseList),
                fetchClusterSchemaAsJson,
                telemetry
            );
            return clusterSchemaFetcher.fetchSchema(liteSchemaExploration, {
                clusterUrl,
            });
        },
    };
}

export async function fetchDatabasesNames(
    kustoClient: IKustoClient,
    clusterUrl: string,
    t: KustoClientLocale,
    signal?: AbortSignal
) {
    const fieldNames = { name: 'DatabaseName', prettyName: 'PrettyName' };
    const result = await kustoClient
        .createRequest(clusterUrl, {
            source: 'Query',
        })
        .executeControlCommand(undefined, `.show databases | project ${fieldNames.name}, ${fieldNames.prettyName}`, t, {
            signal,
        });

    if (result.kind !== 'ok') {
        return result;
    }

    return ok(
        getFrameObjects(result.value.frames[0].frame, (getter) => {
            const name = getter(fieldNames.name, 'string');
            const alternativeName = getter(fieldNames.prettyName, 'string');
            return {
                name,
                alternativeName,
            };
        })
    );
}

export const fetchDatabaseSchema = async (
    kustoClient: IKustoClient,
    t: KustoClientLocale,
    clusterUrl: string,
    databaseName: string,
    signal: AbortSignal
) => {
    // do not support internal databases.
    if (databaseName === 'KustoMonitoringPersistentDatabase' || databaseName === '$systemdb') {
        return ABORTED;
    }

    const result = await kustoClient
        .createRequest(clusterUrl, {
            source: 'Query',
        })
        .executeControlCommand(databaseName, `.show database ['${databaseName}'] schema as json`, t, {
            signal,
        });

    if (result.kind !== 'ok') {
        return result;
    }

    const dataFrame = result.value.frames[0].frame;
    const databaseSchemaAsJsonString = getFrameField(dataFrame.fields, 'DatabaseSchema', 'string', 0);
    const schema = JSON.parse(databaseSchemaAsJsonString) as KustoClusterSchema;
    const database = schema.Databases[databaseName];
    if (!database) {
        return ABORTED;
    }

    setTableTypes(database);

    return ok(database);
};

export const getEntitiesResult = (dataFrame: DataFrame) => {
    return getFrameObjects<SchemaEntityCommandResult>(dataFrame, (fieldGetter) => {
        const databaseName = fieldGetter('DatabaseName', 'string');
        const entityType = fieldGetter('EntityType', 'string');
        const name = fieldGetter('EntityName', 'string');
        const docString = fieldGetter('DocString', 'string');
        const folder = fieldGetter('Folder', 'string');
        const cslInputSchema = fieldGetter('CslInputSchema', 'string');
        const content = fieldGetter('Content', 'string');
        const cslOutputSchema = fieldGetter('CslOutputSchema', 'string');
        return { databaseName, entityType, name, docString, folder, cslInputSchema, content, cslOutputSchema };
    });
};

export const getDatabases = (dataFrame: DataFrame, telemetry: IKweTelemetry) => {
    return getFrameObjects<ShowDatabasesCommandResult>(dataFrame, (fieldGetter) => {
        const name = fieldGetter('DatabaseName', 'string');
        const prettyName = fieldGetter('PrettyName', 'string') ?? undefined;
        const version = fieldGetter('Version', 'string');
        const accessMode = fieldGetter('DatabaseAccessMode', 'string');
        const suspensionState = getSuspensionState(dataFrame, fieldGetter, telemetry);
        const [major, minor] = version.substring(1).split('.');
        return {
            name,
            prettyName,
            accessMode,
            majorVersion: Number(major),
            minorVersion: Number(minor),
            suspensionState,
        };
    });
};

export const getMaterializedViews = (dataFrame: DataFrame) => {
    return getFrameObjects(dataFrame, (fieldGetter) => {
        const name = fieldGetter('Name', 'string');
        const sourceTable = fieldGetter('SourceTable', 'string');
        const query = fieldGetter('Query', 'string');
        return {
            name,
            sourceTable,
            query,
        };
    });
};

const getServiceModel = (dataFrame: DataFrame) => {
    return getFrameObjects(dataFrame, (fieldGetter) => {
        const account = fieldGetter('AccountName', 'string');
        const service = fieldGetter('ServiceName', 'string');
        return {
            account,
            service,
        };
    });
};

function setTableTypes(db: KustoDatabaseSchema) {
    Object.values(db.Tables).forEach((table) => (table.tableType = EntityType.Table));
    Object.values(db.ExternalTables || {}).forEach((table) => (table.tableType = EntityType.ExternalTable));
    Object.values(db.MaterializedViews || {}).forEach((table) => (table.tableType = EntityType.MaterializedViewTable));
}

function getSuspensionState(dataFrame: DataFrame, fieldGetter: KustoDataTypeFunc, telemetry: IKweTelemetry) {
    let suspensionState;
    try {
        suspensionState = fieldGetter('SuspensionState', 'string');
    } catch (e) {
        //PlayFab and maybe other proxies don't have SuspensionState.
        //Might be good to create a list but since this should probably fixed by them
        //simply catch the issues and fallback to default.
        telemetry.info('getClusterVersionInfo.serviceOffering.missing', {
            exception: castToError(e).message,
        });
    }

    return (suspensionState && (JSON.parse(suspensionState) as SuspensionState)) || undefined;
}

export const getClusterSchemaAsJson = (dataFrame: DataFrame, telemetry: IKweTelemetry) => {
    let clusterSchemaAsJsonString;
    try {
        clusterSchemaAsJsonString = getFrameField(dataFrame.fields, 'ClusterSchema', 'string', 0);
    } catch (e) {
        //LA and maybe other proxies return DatabaseSchema instead or ClusterSchema. This is Bizarre.
        //Might be good use a conditional for this, but for now i'll simply catch the issues and fallback to DatabaseSchema.
        telemetry.info('getClusterSchemaAsJson.ClusterSchemaColumn.missing', {
            exception: castToError(e).message,
        });
        clusterSchemaAsJsonString = getFrameField(dataFrame.fields, 'DatabaseSchema', 'string', 0);
    }
    return clusterSchemaAsJsonString;
};
