import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '../index';
import type { BasicParameterTypesV2 } from '../value/2';
import type { BasicParamV3, DurationParamV3, IParameterBaseV3 } from './3';
import type { DataSourceParamV5 } from './5';
import type { BasicParamV8, IParameterV8 } from './8';

export interface IParameterBaseV9 extends IParameterBaseV3 {
    description: string;
}

export declare namespace DurationParamV9 {
    export interface IParam extends DurationParamV3.IParam, IParameterBaseV9 {}
}

export type TDurationParamV9 = DurationParamV9.IParam;

export declare namespace BasicParamV9 {
    export interface IBasic<T, K> extends BasicParamV3.IBasic<T, K>, IParameterBaseV9 {}

    export interface IFreetext<T, K> extends BasicParamV3.IFreetext<T, K>, IBasic<T, K> {}

    export interface IDropdown<T, K> extends BasicParamV8.IDropdown<T, K>, IBasic<T, K> {}
}

export type TBasicParamV9<T, K> = BasicParamV9.IFreetext<T, K> | BasicParamV9.IDropdown<T, K>;

export declare namespace DataSourceParamV9 {
    export interface IParam extends DataSourceParamV5.IParam, IParameterBaseV9 {}
}

export type UBasicParamV9 =
    | TBasicParamV9<BasicParameterTypesV2['string'], 'string'>
    | TBasicParamV9<BasicParameterTypesV2['int'], 'int'>
    | TBasicParamV9<BasicParameterTypesV2['long'], 'long'>
    | TBasicParamV9<BasicParameterTypesV2['real'], 'real'>
    | TBasicParamV9<BasicParameterTypesV2['decimal'], 'decimal'>
    | TBasicParamV9<BasicParameterTypesV2['bool'], 'bool'>
    | TBasicParamV9<BasicParameterTypesV2['datetime'], 'datetime'>;

export type IParameterV9 = UBasicParamV9 | DurationParamV9.IParam | DataSourceParamV9.IParam;

export function up(prev: IParameterV8): IParameterV9 {
    return { ...prev, description: '' } as IParameterV9;
}

export function down(prev: IParameterV9, addWarning: AddDashboardWarning): IParameterV8 {
    const { description, ...parameterV8 } = prev as DurationParamV9.IParam;

    if (description) {
        addWarning((t) => formatLiterals(t.down.v43.parameterDescriptionRemoved, { parameterName: prev.displayName }));
    }

    return parameterV8 as IParameterV8;
}
