import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { IParameterV5 } from '../parameter/5';
import type { QueryPropertyRefV0, QueryPropertyV0, SharedQueryV0 } from './0';

export type QueryPropertyRefV1 = QueryPropertyRefV0;

export interface QueryPartsV1 {
    /**
     * `undefined` is unavoidable because we don't stop users from deleting data
     * sources. Plus, it makes the edit pages easier.
     */
    readonly dataSource:
        | undefined
        | {
              /**
               * This is a Data Source Parameter
               * which means we will pull off
               * the currently selected
               * data source id
               * to use against the
               * running query
               */
              readonly kind: 'parameter';
              /**
               * RFC 4122 uuid
               * @see {@link https://datatracker.ietf.org/doc/html/rfc4122}
               */
              readonly parameterId: string;
          }
        | {
              /**
               * This is an "inline" or
               * direct reference to the
               * data source id
               */
              readonly kind: 'inline';
              /**
               * RFC 4122 uuid
               * @see {@link https://datatracker.ietf.org/doc/html/rfc4122}
               */
              readonly dataSourceId: string;
          };

    /**
     * String representations of the variables used by the query. eg "startTime",
     * "endTime"
     */
    readonly usedVariables: readonly string[];
    readonly text: string;
}

export interface SharedQueryV1 extends QueryPartsV1 {
    /**
     * RFC 4122 uuid
     *
     * @see {@link https://datatracker.ietf.org/doc/html/rfc4122}
     */
    readonly id: string;
    /**
     * Must be a valid kusto variable
     *
     * @see {@link {https://docs.microsoft.com/en-us/azure/data-explorer/kusto/query/schema-entities/entity-names}
     */
    readonly variableName: string;
}

export interface QueryPropertyInlineV1 extends QueryPartsV1 {
    readonly kind: 'inline';
}

export type QueryPropertyV1 = QueryPropertyRefV1 | QueryPropertyInlineV1;

export function upQueryProperty(prev: QueryPropertyV0): QueryPropertyV1 {
    if (prev.kind === 'ref') {
        return prev;
    }

    const { dataSourceId, ...next } = prev;

    return {
        ...next,
        dataSource: dataSourceId
            ? {
                  kind: 'inline',
                  dataSourceId,
              }
            : undefined,
    };
}

export function downQueryProperty(
    prev: QueryPropertyV1,
    parameters: readonly IParameterV5[],
    addWarning: AddDashboardWarning
): QueryPropertyV0 {
    if (prev.kind === 'ref') {
        return prev;
    }

    const { dataSource, ...next } = prev;

    let dataSourceId: undefined | string;

    if (dataSource) {
        if (dataSource.kind === 'inline') {
            dataSourceId = dataSource.dataSourceId;
        } else {
            const param = parameters.find((p) => p.id === dataSource.parameterId);

            if (param) {
                if (param.kind === 'dataSource') {
                    dataSourceId = param.defaultValue.dataSourceId;
                } else {
                    addWarning((t) =>
                        formatLiterals(t.down.v26.query.unexpectedParamKind, {
                            name: param.displayName,
                            id: param.id,
                            kind: param.kind,
                        })
                    );
                }
            } else {
                addWarning((t) =>
                    formatLiterals(t.down.v26.query.paramNotFound, {
                        id: dataSource.parameterId,
                    })
                );
            }
        }
    }

    return {
        ...next,
        dataSourceId,
    };
}

export function up({ dataSourceId, ...prev }: SharedQueryV0): SharedQueryV1 {
    return {
        ...prev,
        dataSource: dataSourceId
            ? {
                  kind: 'inline',
                  dataSourceId,
              }
            : undefined,
    };
}

export function down(
    { dataSource, ...prev }: SharedQueryV1,
    parameters: readonly IParameterV5[],
    addWarning: AddDashboardWarning
): SharedQueryV0 {
    let dataSourceId: undefined | string;

    if (dataSource) {
        if (dataSource.kind === 'inline') {
            dataSourceId = dataSource.dataSourceId;
        } else {
            const param = parameters.find((p) => p.id === dataSource.parameterId);

            if (param) {
                if (param.kind === 'dataSource') {
                    dataSourceId = param.defaultValue.dataSourceId;
                } else {
                    addWarning((t) =>
                        formatLiterals(t.down.v26.query.unexpectedParamKind, {
                            name: param.displayName,
                            id: param.id,
                            kind: param.kind,
                        })
                    );
                }
            } else {
                addWarning((t) =>
                    formatLiterals(t.down.v26.query.paramNotFound, {
                        id: dataSource.parameterId,
                    })
                );
            }
        }
    }

    return {
        ...prev,
        dataSourceId,
    };
}
