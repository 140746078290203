/// <reference types="@kusto/language-service-next" />
import '@kusto/language-service-next/bridge.min';
import '@kusto/language-service-next/Kusto.Language.Bridge.min.js';

import { KustoDataType } from '@kusto/utils';

import { BaseTableSchema, KustoType, OrderedColumn, TableTypes } from '../schemaTypes.ts';
import { getDefaultDotNetType } from '../typeConverters.ts';
import { listToArray, omitEmptyValues } from './utils.ts';

import TableSymbol = Kusto.Language.Symbols.TableSymbol;
import ColumnSymbol = Kusto.Language.Symbols.ColumnSymbol;

export function tableParser({
    name,
    folder,
    docString,
    cslOutputSchema,
    tableType,
}: {
    name: string;
    folder: string | undefined;
    docString: string | null;
    cslOutputSchema: string;
    tableType: TableTypes;
}): BaseTableSchema {
    const sanitizedCslOutputSchema = sanitizeCslOutputSchema(cslOutputSchema);
    const tableSymbol = new TableSymbol.$ctor7(name, sanitizedCslOutputSchema, docString);
    const columnList = tableSymbol.Columns;
    const columnArray = listToArray(columnList);

    const table = {
        Name: tableSymbol.Name,
        Folder: folder,
        DocString: tableSymbol.Description,
        OrderedColumns: columnArray.map(columnParser),
        tableType,
    };

    return omitEmptyValues(table);
}

/**
 * Sanitizes the `cslOutputSchema` string to ensure it is valid for the language service.
 * The `.show database entities` command returns unescaped characters that the language service doesn't support.
 * To receive a valid string, we use `JSON.stringify` on the `cslOutputSchema`.
 * However, to enhance performance, we only perform `JSON.stringify` if the string contains special characters,
 * as regex testing is faster than JSON stringifying.
 */
function sanitizeCslOutputSchema(cslOutputSchema: string): string {
    if (containsOnlyAlphanumeric(cslOutputSchema)) {
        return cslOutputSchema;
    }
    return JSON.stringify(cslOutputSchema).slice(1, -1);
}

function containsOnlyAlphanumeric(input: string): boolean {
    const regex = /^[A-Za-z0-9]+$/;
    return regex.test(input);
}

function columnParser(columnSymbol: ColumnSymbol): OrderedColumn {
    const type = columnSymbol.Type?.Name;

    const column = {
        Name: columnSymbol.Name ?? '',
        Type: type && getDefaultDotNetType(type as KustoType),
        CslType: columnSymbol.Type?.Name as KustoDataType,
        DocString: columnSymbol.Description,
    };

    return omitEmptyValues(column);
}
