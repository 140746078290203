import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { TableSchemaV1 } from '../value/1';
import type { SharedQueryV1 } from './1';

export interface SharedQueryV2 extends SharedQueryV1 {
    readonly schema: TableSchemaV1;
}
export function up(prev: SharedQueryV1): SharedQueryV2 {
    return {
        ...prev,
        schema: [],
    };
}

export function down({ schema, ...prev }: SharedQueryV2, addWarning: AddDashboardWarning): SharedQueryV1 {
    addWarning((t) => formatLiterals(t.down.v27.sharedQuerySchemaRemoved, { variableName: prev.variableName }));
    return prev;
}
