.columnInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    align-self: stretch;
    margin-bottom: var(--spacingVerticalM);
}

.sampleValueHeader {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
}

.sampleValueContentArea {
    align-self: stretch;
    height: 228px;
    margin-bottom: var(--spacingVerticalL);
    display: flex;
    flex-direction: column;
    gap: var(--spacingVerticalM);

    .sampleValueEditor {
        height: 196px;
    }

    .emptyCellPercentage {
        flex: 0 0;
    }
}

.loadingIndicator {
    display: flex;
    height: 100%;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}

.emptyState {
    display: flex;
    height: 100%;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}

.simpleText {
    margin: 0 var(--spacingHorizontalXXL) var(--spacingVerticalL);
}

.noPadding {
    padding: 0;
}
