import type { Mutable } from '@kusto/utils';

import type { ITileV2 } from './2';
import type { ITileV8, IVisualOptionsV8, YAxisConfigV8 } from './8';

interface HorizontalLine {
    id: string;
    value: number | null;
}
export interface YAxisConfigV9 extends Omit<YAxisConfigV8, 'horizontalLines'> {
    readonly horizontalLines: readonly HorizontalLine[];
}

export interface MultipleYAxesConfigV9 {
    base: YAxisConfigV9;
    additional: readonly YAxisConfigV9[];
}

export interface IVisualOptionsV9 extends Omit<IVisualOptionsV8, 'multipleYAxes'> {
    readonly multipleYAxes: MultipleYAxesConfigV9;
}

export interface ITileV9 extends Omit<ITileV2, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV9>;
}

export function up(prev: ITileV8): ITileV9 {
    const { multipleYAxes, ...noMigrateVisualOptions } = prev.visualOptions;
    const visualOptions: Mutable<Partial<IVisualOptionsV9>> = noMigrateVisualOptions;
    if (multipleYAxes) {
        const updatedBaseAxis: YAxisConfigV9 = {
            ...multipleYAxes.base,
            horizontalLines: multipleYAxes?.base.horizontalLines.map((line) => ({
                id: crypto.randomUUID(),
                value: line,
            })),
        };
        const additionalAxes = multipleYAxes.additional;
        const updatedAdditionalAxes: YAxisConfigV9[] = additionalAxes.map((axis) => ({
            ...axis,
            horizontalLines: axis.horizontalLines.map((line) => ({ id: crypto.randomUUID(), value: line })),
        }));
        visualOptions.multipleYAxes = {
            base: updatedBaseAxis,
            additional: updatedAdditionalAxes,
        };
    }

    return { ...prev, visualOptions };
}

export function down(prev: ITileV9): ITileV8 {
    const { multipleYAxes, ...noMigrateVisualOptions } = prev.visualOptions;
    const visualOptions: Partial<IVisualOptionsV8> = noMigrateVisualOptions;
    if (multipleYAxes) {
        const updatedBaseAxis: YAxisConfigV8 = {
            ...multipleYAxes.base,
            horizontalLines: multipleYAxes?.base.horizontalLines.map((line) => line.value),
        };
        const additionalAxes = multipleYAxes.additional;
        const updatedAdditionalAxes: YAxisConfigV8[] = additionalAxes.map((axis) => ({
            ...axis,
            horizontalLines: axis.horizontalLines.map((line) => line.value),
        }));
        visualOptions.multipleYAxes = {
            base: updatedBaseAxis,
            additional: updatedAdditionalAxes,
        };
    }
    return { ...prev, visualOptions };
}
