import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { ITileV2 } from './2';
import type { ITileV12, IVisualOptionsV12 } from './12';

export interface DrillthroughConfigV13 {
    readonly pairs?: Array<{
        readonly property?: string;
        readonly parameterId?: string;
    }>;
    readonly destinationPages?: string[];
    readonly notes?: string;
    readonly disabled?: boolean;
}

export interface IVisualOptionsV13 extends IVisualOptionsV12 {
    readonly drillthrough: readonly DrillthroughConfigV13[];
    readonly drillthroughDisabled: boolean;
}

export interface ITileV13 extends Omit<ITileV2, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV13>;
}

export function up(prev: ITileV12): ITileV13 {
    return prev;
}

export function down(prev: ITileV13, addWarning: AddDashboardWarning): ITileV12 {
    if (!prev.visualOptions.drillthrough) {
        return prev as ITileV12;
    }

    const count = prev.visualOptions.drillthrough.length > 0;

    if (count) {
        addWarning((t) =>
            formatLiterals(t.down.v22.drillthroughsRemoved, {
                count: count.toString(),
                tileTitle: prev.title,
            })
        );
    }

    const { drillthrough: _, ...visualOptions } = prev.visualOptions;

    return {
        ...prev,
        visualOptions,
    };
}
