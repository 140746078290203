import type { ITileV8 } from '../tile/8';
import * as tileV9 from '../tile/9';
import type { IDashboardV15 } from './15';

export interface IDashboardV16 extends Omit<IDashboardV15, 'schema_version' | 'tiles'> {
    schema_version: '16';
    tiles: readonly tileV9.ITileV9[];
}

export function up(prev: IDashboardV15): IDashboardV16 {
    return {
        ...prev,
        schema_version: '16',
        tiles: prev.tiles.map((t) => tileV9.up(t)),
    };
}

export function down(prev: IDashboardV16): IDashboardV15 {
    const tiles: readonly ITileV8[] = prev.tiles.map((t) => {
        return tileV9.down(t);
    });
    return {
        ...prev,
        tiles,
        schema_version: '15',
    };
}
