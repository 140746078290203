import type { AddDashboardWarning } from '..';
import type { UDataSourceV2 } from '../dataSource/2';
import type { UDataSourceV3 } from '../dataSource/3';
import type { IDashboardV19 } from './19';

export interface IDashboardV20 extends Omit<IDashboardV19, 'schema_version' | 'dataSources'> {
    schema_version: '20';
    dataSources: readonly UDataSourceV3[];
}

export function up(prev: IDashboardV19): IDashboardV20 {
    return {
        ...prev,
        schema_version: '20',
        dataSources: prev.dataSources,
    };
}

export function down(prev: IDashboardV20, addWarning: AddDashboardWarning): IDashboardV19 {
    const dataSources: UDataSourceV2[] = [];
    for (const singleDataSource of prev.dataSources) {
        if (singleDataSource.kind === 'kusto-trident') {
            addWarning(() => singleDataSource.name + 'data source was removed');
        } else {
            dataSources.push(singleDataSource);
        }
    }

    return {
        ...prev,
        schema_version: '19',
        dataSources: dataSources,
    };
}
