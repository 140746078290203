import React from 'react';
import sortBy from 'lodash/sortBy';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import * as client from '@kusto/client';
import { DropdownWithSearch, type SelectedOption } from '@kusto/ui-components';
import { ok, Result, Theme } from '@kusto/utils';
import * as Fwk from '@kusto/visual-fwk';
import {
    ExtendedKind,
    ExtendedVisualizationOptions,
    getFirstColumnOfTypeVFormat,
    HighchartsAndMapChart,
    kustoHeuristics,
    KustoHeuristicsErr,
} from '@kusto/visualizations';

import { defaultVisualOptions } from '../charting';
import { NUMERIC_DATA_TYPE, standardSizes } from '../constants';
import { KweRtdVisualContext } from '../context';
import { convertLegendTypes, Heuristics, RtdHighchartsHeuristicsOk } from './highCharts/heuristics';
import { crossFilterSegment, drillthroughSegment, useCrossFilterEvents, useDrillthroughEvents } from './interaction';
import { getHeuristicsErrMessage, isPositiveInteger, mergeChartEvents } from './util';

import * as styles from './styles.module.scss';

function heuristicsFnc(
    ctx: Pick<KweRtdVisualContext, 'strings' | 'telemetry'>
): Fwk.VisualConfigHeuristicsFnc<PieModelDef, Heuristics> {
    return function RtdPieVisual(props) {
        const { queryResult } = props;
        if (queryResult === undefined) {
            return null;
        }
        const columns = client.clientColumnsFromKweColumns(queryResult.dataFrame.fields);
        const visualizationOptions = buildVisualizationOptions(props, columns);
        const rows = client.queryAreaRowObjectsFromDataFrame(queryResult.dataFrame);
        const hResult = kustoHeuristics(columns, rows, 'piechart', visualizationOptions, ctx.strings, ctx.telemetry);
        let result: Result<RtdHighchartsHeuristicsOk, KustoHeuristicsErr>;
        if (hResult.kind === 'ok') {
            result = ok({
                kustoHeuristics: hResult.value,
                xColumn: visualizationOptions.XColumn ?? undefined,
                yColumns: visualizationOptions.YColumns ?? undefined,
                seriesColumns: undefined,
            });
        } else {
            result = hResult;
        }
        return {
            visualizationOptions,
            Visualization: 'piechart',
            rows,
            columns,
            result,
        };
    };
}

const pieModel = {
    hideLegend: false,
    legendLocation: Fwk.DEFAULT_LEGEND_LOCATION,
    xColumn: null,
    yColumns: null,
    seriesColumns: null,
    crossFilter: [],
    crossFilterDisabled: false,
    drillthrough: [],
    drillthroughDisabled: false,
    labelDisabled: false,
    pie__label: ['name', 'percentage'],
    tooltipDisabled: false,
    pie__tooltip: ['name', 'percentage', 'value'],
    pie__orderBy: 'size',
    pie__kind: 'pie',
    pie__topNSlices: null,
} satisfies Fwk.UnknownVisualOptions;

type PieModelDef = keyof typeof pieModel;

function buildVisualizationOptions(
    { visualOptions }: Fwk.HeuristicsProps<PieModelDef>,
    columns: readonly client.KustoColumn[]
): ExtendedVisualizationOptions {
    const xColumn = visualOptions.xColumn;
    const yColumns = visualOptions.yColumns;

    const XColumn = xColumn ?? columns[0]?.field ?? null;

    let YColumns: null | readonly string[] = null;
    if (yColumns && yColumns.length !== 0) {
        YColumns = yColumns;
        if (visualOptions.pie__kind === 'pie') {
            YColumns = [YColumns[0]];
        }
    } else if (yColumns === null) {
        const firstNumeric = getFirstColumnOfTypeVFormat(columns, [NUMERIC_DATA_TYPE], [XColumn])?.field;
        if (firstNumeric) {
            YColumns = [firstNumeric];
        }
    }

    let topNSlices = visualOptions.pie__topNSlices;
    if (topNSlices && !isPositiveInteger(topNSlices)) {
        topNSlices = null; // In case of negative or decimal value
    }

    return {
        ...defaultVisualOptions,
        Visualization: 'piechart',
        Kind: (visualOptions.pie__kind as ExtendedKind) ?? null,
        XColumn,
        YColumns: YColumns as string[], // Remove `readonly`
        Legend: convertLegendTypes(visualOptions.hideLegend),
        LabelDisabled: !!visualOptions.labelDisabled,
        LabelOptions: visualOptions.pie__label,
        TooltipDisabled: !!visualOptions.tooltipDisabled,
        TooltipOptions: visualOptions.pie__tooltip,
        PieOrderBy: visualOptions.pie__orderBy,
        TopNSlices: topNSlices,
    };
}

const numericColumnInferSelector = (options: Fwk.VisualInputSelectorOptions<'yColumns', Heuristics, unknown>) => {
    if (options.get('yColumns') !== null) {
        return undefined;
    }
    return options.getHeuristics()?.visualizationOptions.YColumns?.[0] ?? undefined;
};

function pieNumericInput(ctx: KweRtdVisualContext): Fwk.VisualInput<'yColumns', Heuristics> {
    return {
        id: `column--yColumns`,
        keys: ['yColumns'],
        Component: observer(function EtpVisualOptionsSingleColumConfigOption({ t, model, disabled }) {
            const yColumns = model.get('yColumns');
            const multipleColumnsSelected = yColumns && yColumns.length > 1;
            const selectedColumn: null | string = yColumns ? yColumns[0] : yColumns;

            const { onChange, inferColumn } = React.useMemo(
                () => ({
                    onChange: mobx.action((_: unknown, option?: SelectedOption) => {
                        if (option) {
                            const nextYColumns = option.data === null ? null : [option.data];
                            model.set('yColumns', nextYColumns);
                        }
                    }),
                    inferColumn: model.resolveSelector(numericColumnInferSelector),
                }),
                [model]
            );

            return (
                <Fwk.SingleColumnDropdown
                    t={t}
                    schema={model.getSchema()}
                    className={styles.basicInput}
                    label={ctx.strings.rtdProvider.visuals.pie.numericColumn}
                    selectedColumn={selectedColumn}
                    id="visual-options--yColumns"
                    onChange={onChange}
                    disabled={disabled}
                    inferColumn={inferColumn.get()}
                    errorMessage={
                        multipleColumnsSelected ? ctx.strings.rtdProvider.visuals.pie.numericColumns$error : undefined
                    }
                />
            );
        }),
    };
}

function donutNumericInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.columnsNullable<'yColumns' | 'pie__kind', Heuristics>(
        'yColumns',
        ctx.strings.rtdProvider.visuals.pie.numericColumns,
        {
            selectInferColumns: (options) => {
                if (options.get('yColumns') !== null) {
                    return undefined;
                }
                return options.getHeuristics()?.visualizationOptions.YColumns ?? undefined;
            },
        }
    );
}

function categoryInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.column<'xColumn', Heuristics>(
        'xColumn',
        ctx.strings.rtdProvider.visuals.pie.categoryColumn,
        {
            selectInferColumn: (options) => {
                if (options.get('xColumn') !== null) {
                    return undefined;
                }
                return options.getHeuristics()?.visualizationOptions.XColumn ?? undefined;
            },
        }
    );
}

function labelInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.staticMultiSelect(
        ctx.strings,
        'pie__label',
        ctx.strings.rtdProvider.visuals.pie.displayedOptions,
        [
            { key: 'name', text: ctx.strings.rtdProvider.visuals.pie.displayedOptions$name },
            { key: 'percentage', text: ctx.strings.rtdProvider.visuals.pie.displayedOptions$percentage },
            { key: 'value', text: ctx.strings.rtdProvider.visuals.pie.displayedOptions$value },
        ],
        (o) => o.get('pie__label'),
        (v, m) => m.set('pie__label', v)
    );
}

function tooltipInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.staticMultiSelect(
        ctx.strings,
        'pie__tooltip',
        ctx.strings.rtdProvider.visuals.pie.displayedOptions,
        [
            { key: 'name', text: ctx.strings.rtdProvider.visuals.pie.displayedOptions$name },
            { key: 'percentage', text: ctx.strings.rtdProvider.visuals.pie.displayedOptions$percentage },
            { key: 'value', text: ctx.strings.rtdProvider.visuals.pie.displayedOptions$value },
        ],
        (o) => o.get('pie__tooltip'),
        (v, m) => m.set('pie__tooltip', v)
    );
}

function orderByInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.staticDropdown(
        ['pie__orderBy'],
        ctx.strings.rtdProvider.visuals.pie.OrderOptions,
        [
            { key: 'name', text: ctx.strings.rtdProvider.visuals.pie.orderOptions$name },
            { key: 'size', text: ctx.strings.rtdProvider.visuals.pie.orderOptions$size },
            { key: 'none', text: ctx.strings.rtdProvider.visuals.pie.orderOptions$none },
        ],
        (o) => o.get('pie__orderBy'),
        (v, m) => m.set('pie__orderBy', v)
    );
}

const topNSlicesSelector = (options: Fwk.VisualInputSelectorOptions<'pie__topNSlices', Heuristics, unknown>) => {
    const value = options.get('pie__topNSlices');
    if (value && isPositiveInteger(value)) {
        return value.toString();
    } else {
        return 'all';
    }
};

const topNSlicesInput = (ctx: KweRtdVisualContext): Fwk.VisualInput<'pie__topNSlices', Heuristics> => {
    return {
        id: `pie--topNSlices`,
        keys: ['pie__topNSlices'],
        Component: observer(function EtpVisualOptionsStaticDropdown({ model, disabled }) {
            const { options, onChange, selectedOption } = React.useMemo(() => {
                const topNSlices = model.get('pie__topNSlices');
                const calcOptions = () => {
                    const result = [
                        { key: 'all', text: ctx.strings.rtdProvider.visuals.pie.topNOptions$all },
                        { key: '5', text: '5' },
                        { key: '10', text: '10' },
                        { key: '15', text: '15' },
                    ];

                    if (topNSlices && isPositiveInteger(topNSlices) && !result.find((o) => +o.key === topNSlices)) {
                        const newNumericOption = topNSlices.toString();
                        result.push({ key: newNumericOption, text: newNumericOption });
                    }

                    return sortBy(result, (item) => (+item.key ? +item.key : 0));
                };

                const onChange = mobx.action((_: unknown, option?: SelectedOption) => {
                    if (option) {
                        const data = option.key;
                        const numericOrNullableValue = +data > 0 ? +data : null;
                        model.set('pie__topNSlices', numericOrNullableValue);
                    }
                });
                const selectedOption = model.resolveSelector(topNSlicesSelector);

                return { options: calcOptions(), onChange, selectedOption };
            }, [model]);

            return (
                <DropdownWithSearch
                    selectedKeys={selectedOption.get()}
                    options={options}
                    onChange={onChange}
                    label={ctx.strings.rtdProvider.visuals.pie.topNOptions}
                    disabled={disabled}
                />
            );
        }),
    };
};

function kindInput(ctx: KweRtdVisualContext) {
    return Fwk.createTileInput.staticDropdown(
        ['pie__kind'],
        ctx.strings.rtdProvider.visuals.pie.visualFormat,
        [
            { key: 'pie', text: 'Pie' },
            { key: 'donut', text: 'Donut' },
        ],
        (o) => o.get('pie__kind'),
        (v, m) => m.set('pie__kind', v)
    );
}

function inputLayout(
    ctx: KweRtdVisualContext
): Fwk.VisualSelector<PieModelDef, Fwk.VisualConfigLayout<PieModelDef, Heuristics>> {
    const tileInput = Fwk.tileInput(ctx.flags);

    return (options) => {
        return {
            visual: {
                segments: [
                    Fwk.tileInputSegment(
                        ctx.strings.rtdProvider.visuals.input.segment$generalTitle,
                        {},
                        kindInput(ctx)
                    ),
                    Fwk.tileInputSegment<'yColumns' | 'xColumn' | 'pie__kind', Heuristics>(
                        ctx.strings.rtdProvider.visuals.input.segment$dataTitle,
                        {},
                        categoryInput(ctx),
                        options.get('pie__kind') === 'pie' ? pieNumericInput(ctx) : donutNumericInput(ctx)
                    ),
                    Fwk.tileInputSegment<PieModelDef>(
                        ctx.strings.rtdProvider.visuals.input.segment$legendTitle,
                        {
                            toggle: {
                                optionKey: 'hideLegend',
                                titleText: ctx.strings.rtdProvider.visuals.input.segment$colorRulesToggleTitleText,
                                invert: true,
                                labels: {
                                    enabled: ctx.strings.rtdProvider.visuals.input.segment$toggleShowHideLabel$enabled,
                                    disabled:
                                        ctx.strings.rtdProvider.visuals.input.segment$toggleShowHideLabel$disabled,
                                },
                            },
                        },
                        tileInput.legendLocation(ctx.strings)
                    ),
                    Fwk.tileInputSegment<'labelDisabled' | 'pie__label'>(
                        ctx.strings.rtdProvider.visuals.pie.labelSection,
                        {
                            toggle: {
                                optionKey: 'labelDisabled',
                                titleText: ctx.strings.rtdProvider.visuals.input.segment$colorRulesToggleTitleText,
                                invert: true,
                                labels: {
                                    enabled: ctx.strings.rtdProvider.visuals.input.segment$toggleShowHideLabel$enabled,
                                    disabled:
                                        ctx.strings.rtdProvider.visuals.input.segment$toggleShowHideLabel$disabled,
                                },
                            },
                        },
                        labelInput(ctx)
                    ),
                    Fwk.tileInputSegment<'tooltipDisabled' | 'pie__tooltip'>(
                        ctx.strings.rtdProvider.visuals.pie.tooltipSection,
                        {
                            toggle: {
                                optionKey: 'tooltipDisabled',
                                titleText: ctx.strings.rtdProvider.visuals.input.segment$colorRulesToggleTitleText,
                                invert: true,
                                labels: {
                                    enabled: ctx.strings.rtdProvider.visuals.input.segment$toggleShowHideLabel$enabled,
                                    disabled:
                                        ctx.strings.rtdProvider.visuals.input.segment$toggleShowHideLabel$disabled,
                                },
                            },
                        },
                        tooltipInput(ctx)
                    ),
                    Fwk.tileInputSegment(
                        ctx.strings.rtdProvider.visuals.pie.displayOptionsSection,
                        {},
                        orderByInput(ctx),
                        topNSlicesInput(ctx)
                    ),
                ],
            },
            interactions: {
                segments: [crossFilterSegment(ctx), drillthroughSegment(ctx)],
            },
        };
    };
}

export function createComponent(ctx: KweRtdVisualContext): React.FC<Fwk.IDataVisualProps<PieModelDef, Heuristics>> {
    return observer(function PieVisual(props: Fwk.IDataVisualProps<PieModelDef, Heuristics>) {
        const crossFilterEvents = useCrossFilterEvents(
            ctx,
            props.visualOptions,
            props.heuristics,
            props.dashboard,
            props.queryResult
        );
        const drillthroughEvents = useDrillthroughEvents(ctx, props.visualOptions, props.heuristics, props.dashboard);
        const interactiveEvents = React.useMemo(
            () => mergeChartEvents(crossFilterEvents, drillthroughEvents),
            [crossFilterEvents, drillthroughEvents]
        );

        if (!props.heuristics) {
            ctx.telemetry.exception('Pie heuristics should only be null if there is no query result.', {
                isQueryResultEmpty: !props.queryResult,
            });
            return null;
        }

        const heuristics = props.heuristics.result;

        if (heuristics.kind === 'err') {
            const { err } = heuristics;
            return props.formatMessage(getHeuristicsErrMessage(err));
        }

        return (
            <HighchartsAndMapChart
                {...ctx.chartProps}
                strings={ctx.strings}
                telemetry={ctx.telemetry}
                theme={props.isDarkTheme ? Theme.Dark : Theme.Light}
                heuristics={heuristics.value.kustoHeuristics}
                Visualization={props.heuristics.Visualization}
                visualizationOptions={props.heuristics.visualizationOptions}
                legendPosition={props.visualOptions.legendLocation}
                enableInteractiveLegend={props.featureFlags.EnableInteractiveLegend}
                enableSharedCrosshair={props.featureFlags.EnableSharedCrosshair}
                disableAnimation
                timezone={props.timeZone}
                locale={props.locale}
                formatMessage={props.formatMessage}
                events={{
                    ...ctx.chartEvents,
                    ...interactiveEvents,
                }}
            />
        );
    });
}

export function pieHeuristicsConfig(
    ctx: Pick<KweRtdVisualContext, 'strings' | 'telemetry'>
): Readonly<Fwk.ResolvedHeuristicsTypeConfig<PieModelDef, Heuristics>> {
    return {
        model: pieModel,
        heuristics: heuristicsFnc(ctx),
    };
}

export function pieVisualConfig(ctx: KweRtdVisualContext): Fwk.VisualTypeConfig<PieModelDef, Heuristics> {
    return {
        label: ctx.strings.rtdProvider.visuals.pie.visualLabel,
        iconName: 'PieSingle',
        config: {
            ...pieHeuristicsConfig(ctx),
            Component: createComponent(ctx),
            inputLayout: inputLayout(ctx),
            ...standardSizes,
        },
    };
}
