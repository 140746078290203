import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { ITileV2 } from './2';
import type { CrossFilterConfigV7 } from './7';
import type { ITileV11, IVisualOptionsV11 } from './11';

export interface CrossFilterConfigV12 {
    readonly interaction?: string;
    /** Property is here to make adding new properties easy */
    readonly property?: string;
    readonly parameterId?: string;
    readonly disabled?: boolean;
}

export interface IVisualOptionsV12 extends Omit<IVisualOptionsV11, 'crossFilter'> {
    crossFilter: readonly CrossFilterConfigV12[];
}

export interface ITileV12 extends Omit<ITileV2, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV12>;
}

export function up(prev: ITileV11): ITileV12 {
    if (prev.visualOptions.crossFilter === undefined) {
        return prev as ITileV12;
    }

    if (prev.visualOptions.crossFilter === null) {
        return { ...prev, visualOptions: { ...prev.visualOptions, crossFilter: [] } };
    }

    const dimensionParts = prev.visualOptions.crossFilter.dimensionId.split('-');

    return {
        ...prev,
        visualOptions: {
            ...prev.visualOptions,
            crossFilter:
                dimensionParts.length !== 2
                    ? []
                    : [
                          {
                              interaction: dimensionParts[0],
                              property: dimensionParts[1],
                              parameterId: prev.visualOptions.crossFilter.parameterId,
                          },
                      ],
        },
    };
}

export function down(prev: ITileV12, addWarning: AddDashboardWarning): ITileV11 {
    if (!prev.visualOptions.crossFilter) {
        return prev as ITileV11;
    }

    let crossFilter: null | CrossFilterConfigV7;

    if (prev.visualOptions.crossFilter.length === 0) {
        crossFilter = null;
    } else {
        const prevCrossFilter = prev.visualOptions.crossFilter[0];
        if (
            prevCrossFilter.interaction === undefined ||
            prevCrossFilter.parameterId === undefined ||
            prevCrossFilter.property === undefined ||
            prevCrossFilter.disabled
        ) {
            const count = prev.visualOptions.crossFilter.length.toString();
            addWarning((t) =>
                formatLiterals(t.down.v21.crossFiltersRemoved, {
                    count,
                    tileTitle: prev.title,
                })
            );
            crossFilter = null;
        } else {
            const count = (prev.visualOptions.crossFilter.length - 1).toString();
            addWarning((t) =>
                formatLiterals(t.down.v21.crossFiltersRemoved, {
                    count,
                    tileTitle: prev.title,
                })
            );
            crossFilter = {
                dimensionId: `${prevCrossFilter.interaction}-${prevCrossFilter.property}`,
                parameterId: prevCrossFilter.parameterId,
            };
        }
    }
    return {
        ...prev,
        visualOptions: { ...prev.visualOptions, crossFilter },
    };
}
