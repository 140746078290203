import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { IVisualOptionsV14 } from './14';
import type { ITileV16 } from './16';

export type HeatMapPaletteColorV17 = 'blue' | 'green' | 'purple' | 'orange' | 'pink' | 'yellow';

export interface IVisualOptionsV17 extends IVisualOptionsV14 {
    readonly heatMap__colorPaletteKey: HeatMapPaletteColorV17;
}

export interface ITileV17 extends Omit<ITileV16, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV17>;
}

export function up(prev: ITileV16): ITileV17 {
    return prev;
}

export function down(prev: ITileV17, addWarning: AddDashboardWarning): ITileV16 {
    const { heatMap__colorPaletteKey, ...visualOptions } = prev.visualOptions;

    if (heatMap__colorPaletteKey) {
        addWarning((t) => formatLiterals(t.down.v28.heatmapColorPaletteKeyRemoved, { tileName: prev.title }));
    }

    return {
        ...prev,
        visualOptions,
    };
}
