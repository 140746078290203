import { KustoFunctionSchema } from '../schemaTypes.ts';
import { functionParameters } from './functionParameters.ts';
import { omitEmptyValues } from './utils.ts';

export function functionParser(
    name: string,
    folder: string | undefined,
    docString: string | undefined,
    cslInputSchema: string,
    content: string
): KustoFunctionSchema {
    const functionResult = {
        Name: name,
        Folder: folder,
        DocString: docString,
        FunctionKind: 'UnknownFunction',
        InputParameters: functionParameters(cslInputSchema),
        Body: content,
        OutputColumns: [],
    };

    return omitEmptyValues(functionResult);
}
