import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { QueryPropertyV0, SharedQueryV0 } from '../query/0';
import type { BasicParamV2 } from './2';
import type { BasicParamV3, DurationParamV3, IParameterV3 } from './3';

export declare namespace BasicParamV4 {
    export interface DropdownQueryDataSource {
        readonly kind: 'query';
        readonly query: QueryPropertyV0;
        readonly columns: {
            readonly value?: string;
            readonly label?: string;
        };
    }
    export type DropdownDataSource<T> = BasicParamV2.DropdownStaticDataSource<T> | DropdownQueryDataSource;

    export interface IDropdown<T, K> extends BasicParamV3.IBasic<T, K> {
        readonly selectionType: 'single' | 'single-all' | 'multi';
        readonly dataSource: DropdownDataSource<T>;
    }
}

export type TBasicParamV4<T, K> = BasicParamV3.IFreetext<T, K> | BasicParamV4.IDropdown<T, K>;

export type UBasicParamV4 =
    | TBasicParamV4<string, 'string'>
    | TBasicParamV4<number, 'int32'>
    | TBasicParamV4<string, 'int64'>
    | TBasicParamV4<number, 'float64'>
    | TBasicParamV4<string, 'decimal'>
    | TBasicParamV4<boolean, 'bool'>
    | TBasicParamV4<number, 'datetime'>;

export type IParameterV4 = UBasicParamV4 | DurationParamV3.IParam;

export function up(prev: IParameterV3): IParameterV4 {
    if (prev.kind === 'duration' || prev.selectionType === 'freetext' || prev.dataSource.kind === 'static') {
        return prev as IParameterV4;
    }

    const prevDataSource = prev.dataSource;

    const dataSource: BasicParamV4.DropdownQueryDataSource = {
        kind: 'query',
        query: {
            kind: 'inline',
            dataSourceId: prevDataSource.dataSourceId,
            usedVariables: prevDataSource.consumedVariables,
            text: prevDataSource.query,
        },
        columns: prevDataSource.columns,
    };

    return {
        ...prev,
        dataSource,
    };
}

export function down(
    queries: Record<string, SharedQueryV0>,
    prev: IParameterV4,
    addWarning: AddDashboardWarning
): IParameterV3 {
    if (prev.kind === 'duration' || prev.selectionType === 'freetext' || prev.dataSource.kind === 'static') {
        return prev as IParameterV3;
    }

    let dataSourceId: undefined | string;
    let query: string;
    let consumedVariables: readonly string[];

    if (prev.dataSource.query.kind === 'inline') {
        dataSourceId = prev.dataSource.query.dataSourceId;
        query = prev.dataSource.query.text;
        consumedVariables = prev.dataSource.query.usedVariables;
    } else {
        const sharedQuery = queries[prev.dataSource.query.sharedQueryId];

        if (sharedQuery) {
            addWarning((t) =>
                formatLiterals(t.down.v25.queryInlinedIntoParameter, {
                    parameterName: prev.displayName,
                    sharedQueryName: sharedQuery.variableName,
                })
            );
            dataSourceId = sharedQuery.dataSourceId;
            query = sharedQuery.text;
            consumedVariables = sharedQuery.usedVariables;
        } else {
            addWarning((t) =>
                formatLiterals(t.down.v25.unableToInlineParameterQuery, { parameterName: prev.displayName })
            );
            dataSourceId = undefined;
            query = '';
            consumedVariables = [];
        }
    }

    return {
        ...prev,
        dataSource: {
            kind: 'query',
            consumedVariables,
            dataSourceId,
            query,
            columns: prev.dataSource.columns,
        },
    };
}
