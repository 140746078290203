/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import padStart from 'lodash/padStart';
import * as mobx from 'mobx';
import { flow, getEnv, getRoot, SnapshotOut, types } from 'mobx-state-tree';
import * as mst from 'mobx-state-tree';

import { kustoEntityTypes } from '@kusto/app-common';
import { getDataItemsAndMetaData } from '@kusto/charting';
import * as kusto from '@kusto/client';
import { RtdVisualTypes, toRtdVisualType, visualTableResultToRtdQueryResult } from '@kusto/rtd-provider';
import {
    Aborted,
    Account,
    castToError,
    err,
    formatLiterals,
    IKweTelemetry,
    LOADING,
    ok,
    Ok,
    sqlQueryRegex,
    unifyWhiteSpaces,
} from '@kusto/utils';
import type { UnknownVisualOptions, VisualFwkQueryResult, YAxisConfig } from '@kusto/visual-fwk';
import { kustoResultV1ToVTable, kustoResultV2ToResultTable, VisualizationOptions } from '@kusto/visualizations';

import { EntityType } from '../common';
import { KustoEditorHandle } from '../components/KustoEditor/handle';
import { KustoConnection, parseStringLiteral, resolveKustoConnection } from '../utils/platform';
import { pythonDebugResult } from '../utils/pythonDebugHelper';
import { getTelemetryClient } from '../utils/telemetryClient';
import { Cluster, Database, getClusterAndDatabaseFromEntity, getClusterFromDatabase } from './cluster';
import { FetchState } from './common';
import { ClusterOrDatabaseSafeReference } from './connectionPane';
import { Group } from './group';
import { MultiTableResult, QueryCompletionInfo, QueryResults, RequestInfo, TableResult } from './queryCompletionInfo';
import { ResultCache } from './resultCache';
import type { IRootStore } from './rootStore';
import { getQueryStoreEnv, QueryMstEnv, QueryStoreEnv } from './storeEnv';

// TODO: Delete this after we've gotten the telemetry we need
function queryRunTimeoutWarning(telemetry: IKweTelemetry, promise: Promise<unknown>, step: string, ms = 10000) {
    let timeoutInfo:
        | undefined
        | {
              timeStamp: number;
              id: string;
          };
    const timeoutId = setTimeout(() => {
        const id = crypto.randomUUID();
        timeoutInfo = { timeStamp: performance.now(), id };
        telemetry.trace('run query promise timeout', { step, id, ms });
    }, ms);
    promise.finally(() => {
        if (timeoutInfo) {
            telemetry.trace('run query promise resolved after timeout ', {
                step,
                id: timeoutInfo.id,
                ms,
                duration: performance.now() - timeoutInfo.timeStamp,
            });
        } else {
            clearTimeout(timeoutId);
        }
    });
}

/**
 * translate raw results from raw Kusto json to store model.
 * @param rawQueryResults raw query results from kusto endpoint.
 */
const toQueryCompletionInfo = (
    env: QueryMstEnv,
    request: RequestInfo,
    rawQueryResults: kusto.KustoClientResult,
    clientRequestId: string
): {
    queryCompletionInfo: QueryCompletionInfo;
    queryResults: QueryResults;
} => {
    const results = kustoResultV1ToVTable(rawQueryResults);

    const queryCompletionInfo = QueryCompletionInfo.create(
        {
            id: request.hashCode,
            failureReason: undefined,
            isSuccess: true,
            timeEnded: new Date(),
            request,
            clientActivityId: clientRequestId,
        },
        env
    );

    const queryResults = QueryResults.create({ results }, env);

    return { queryCompletionInfo, queryResults };
};

/**
 * translate raw results from raw Kusto json to store model.
 * @param rawQueryResults raw query results from kusto endpoint.
 */
// TODO: this logic should be in the kusto client, otherwise,
// any part running queries won't parse them properly for the grid
const toQueryCompletionInfoV2 = (
    env: QueryMstEnv,
    request: RequestInfo,
    rawQueryResults: kusto.KustoClientResultV2,
    clientRequestId: string
): {
    queryCompletionInfo: QueryCompletionInfo;
    queryResults: QueryResults;
} => {
    const { results, errorDescription, queryResourceConsumption } = kustoResultV2ToResultTable(rawQueryResults);
    const queryCompletionInfo = QueryCompletionInfo.create(
        {
            id: request.hashCode,
            failureReason: undefined,
            errorDescription: errorDescription,
            isSuccess: !errorDescription,
            timeEnded: new Date(),
            request,
            clientActivityId: clientRequestId,
            queryResourceConsumption,
        },
        env
    );

    const queryResults = QueryResults.create({ results }, env);

    return { queryCompletionInfo, queryResults };
};

// eslint-disable-next-line @typescript-eslint/ban-types
const getEntityInContext = (node: {}) => (getRoot(node) as IRootStore).connectionPane.entityInContext;
// eslint-disable-next-line @typescript-eslint/ban-types
const getConnectionInContext = (node: {}): Cluster | undefined => {
    const root = getRoot(node) as IRootStore;

    const entityInContext = root.connectionPane!.entityInContext;
    if (!entityInContext) {
        return undefined;
    }

    return getClusterAndDatabaseFromEntity(entityInContext).cluster;
};

export type RunRequestedType = 'ReadOnly' | 'Yes' | 'No';
export type RunRequestResults = 'Full' | 'Preview';

export const CommandType = types.enumeration('CommandType', ['Unknown', 'AdminCommand', 'Query', 'ClientDirective']);
// eslint-disable-next-line no-redeclare
export type CommandType = typeof CommandType.Type;

const { trackTrace } = getTelemetryClient({ component: 'tab', flow: '' });
const recallTracer = getTelemetryClient({ component: 'tab', flow: 'recall' });
const runTracer = getTelemetryClient({ component: 'tab', flow: 'run' });
const cancelTracer = getTelemetryClient({ component: 'tab', flow: 'cancel' });
const searchTracer = getTelemetryClient({ component: 'tab', flow: 'search' });

const CursorPosition = types.model({ lineNumber: types.number, column: types.number });
export type CursorPosition = typeof CursorPosition.Type;

const MonacoRange = types.model({
    startLineNumber: types.number,
    startColumn: types.number,
    endLineNumber: types.number,
    endColumn: types.number,
});
export type MonacoRange = typeof MonacoRange.Type;

/**
 * Simplified version of kustoHeuristics from visualizations package.
 * error handling is limited to returning null since the only thing to break is multiYAxes config
 * severe errors should surface again down the line anyway, such as kusto/charting dotNet errors
 */
function getYColumnsFromHeuristics(result: TableResult, telemetry: IKweTelemetry): string[] | null {
    if (!result.columns?.length || !result.rows || !result.visualizationOptions) {
        return null;
    }

    const res = getDataItemsAndMetaData(
        result.columns.map((col) => {
            // Work around the fact that control commands use v1 endpoint and return types in
            // a different field and format
            const colType = col.columnType || (col.dataType && kusto.v1TypeToKustoType[col.dataType]);

            return {
                ColumnName: col.field,
                ColumnType: colType,
            };
        }),
        result.rows,
        // Cast must succeed due to check above
        result.visualizationOptions as kusto.VisualizationOptions,
        telemetry
    );
    if (res.kind === 'err') {
        return null;
    }
    const heuristicsMetaData = res.value?.metaData ?? undefined;

    const collection = heuristicsMetaData?.['DataIndexes'];
    if (!collection) {
        return null;
    }
    const enumerator = collection.GetEnumerator();
    const columnNames: string[] = [];
    /**
     * Should call moveNext before we start, since the numerator is placed before the first element after init.
     * Can checkout this for ref: https://docs.microsoft.com/dotnet/api/system.collections.ienumerator.movenext?view=net-6.0
     */
    while (enumerator.moveNext()) {
        const index = enumerator.Current;
        // Argument column gets mixed in here for some reason. Haven't dug deep
        // into why, could be a bug or I may not understand what DataIndexes or
        // SeriesIndex represents
        if (index !== heuristicsMetaData!.ArgumentDataColumnIndex) {
            columnNames.push(result.columns[index].field);
        }
    }
    return columnNames;
}

/**
 * when legend is not defined default behavior is
 *  query: on for everything except piechart
 *  dashboards: on for everything
 * this function takes care of this difference
 */
function mapHideLegendOptions(options: VisualizationOptions) {
    if (options.Legend !== null && options.Legend !== undefined) {
        return options.Legend === 'hidden';
    }
    return options.Visualization === 'piechart' ? true : undefined;
}

function mapVisualOptions(result: TableResult, telemetry: IKweTelemetry): VisualModelState {
    const options = result.visualizationOptions!;

    const hasYSplit = options.YSplit === 'axes' || options.YSplit === 'panels';
    let yColumns = options.YColumns;
    let additionalYAxes: YAxisConfig[] = [];

    const singleAxisConfig: Pick<
        YAxisConfig,
        'horizontalLines' | 'yAxisScale' | 'yAxisMaximumValue' | 'yAxisMinimumValue'
    > = {
        horizontalLines: [],
        yAxisScale: options.YAxis ?? 'linear',
        yAxisMaximumValue: options.Ymax === 'NaN' ? null : options.Ymax ?? null,
        yAxisMinimumValue: options.Ymin === 'NaN' ? null : options.Ymin ?? null,
    };

    if (hasYSplit) {
        if (!yColumns) {
            // heuristics can be heavy, that's why we only run this line if we need it for ysplit and don't have columns specified already.
            yColumns = getYColumnsFromHeuristics(result, telemetry) ?? options.YColumns;
        }

        additionalYAxes =
            yColumns && yColumns.length > 1
                ? yColumns.slice(1).map((col) => ({
                      ...singleAxisConfig,
                      id: crypto.randomUUID(),
                      columns: [col],
                      label: '',
                  }))
                : [];
    }

    const state: VisualModelState = {
        options: {
            hideLegend: mapHideLegendOptions(options),
            xAxisScale: options.XAxis ?? undefined,
            xColumn: options.XColumn === undefined ? null : options.XColumn,
            xColumnTitle: options.XTitle ?? undefined,
            multipleYAxes: {
                additional: additionalYAxes,
                showMultiplePanels: options.YSplit === 'panels',
                base: {
                    ...singleAxisConfig,
                    columns: [],
                    label: options.YTitle ?? '',
                    id: '-1',
                },
            },
            yColumnTitle: options.YTitle ?? undefined,
            yColumns: yColumns,
            seriesColumns: options.Series,
            anomalyColumns: options.AnomalyColumns,
            map__sizeDisabled: options.Kind === 'map' ? !(options.Visualization === 'piechart') : undefined,
        },
        visualType: toRtdVisualType(options.Visualization, options.Kind),
        title: options.Title ?? undefined,
        hideTitle: (options?.Title ?? undefined) === undefined,
    };
    // Volatile state isn't automatically deeply observable
    mobx.makeObservable(state, { title: mobx.observable.ref, hideTitle: mobx.observable.ref });

    return state;
}

function defaultVisualModelState(): VisualModelState {
    const state: VisualModelState = {
        options: {},
        title: undefined,
        hideTitle: false,
        visualType: 'table',
    };
    // Volatile state isn't automatically deeply observable
    mobx.makeObservable(state, { title: mobx.observable.ref, hideTitle: mobx.observable.ref });
    return state;
}

export interface VisualModelState {
    options: UnknownVisualOptions;
    visualType: RtdVisualTypes;
    /**
     * Defaults to `undefined` so we can track if the user has set it explicitly
     */
    title: undefined | string;
    hideTitle: boolean;
}

/**
 * A bag of props that's currently selected in the
 * active tab context
 */
export interface QueryContext {
    url?: string;
    dbname?: string;
    query?: string;
}

export interface CommandInContext {
    command: string;
    range?: MonacoRange;
    commandType: CommandType;
    cursorPosition?: CursorPosition;
    commandWithoutLeadingComments?: string;
}

/**
 * A Tab contains both an editor panel to edit text and a response panel to display either graphical or tabular results.
 * It also hold a context - which is mainly the cluster/database it is bound to.
 */
export const Tab: TabModel = types
    .model('Tab', {
        id: types.identifier,
        queryRange: types.maybe(MonacoRange),
        title: types.maybe(types.string),
        text: types.optional(types.string, ''),
        commandInContext: types.maybeNull(types.string),
        executionStatus: types.optional(FetchState, 'notStarted'),
        clientRequestId: types.maybeNull(types.string),
        completionInfo: types.maybe(types.safeReference(QueryCompletionInfo)),
        entityInContext: types.maybeNull(ClusterOrDatabaseSafeReference),
        entityInContextGroup: types.maybe(types.string),
        commandType: types.optional(CommandType, 'Unknown'),
        commandWithoutLeadingComments: types.optional(types.string, ''),
        hideEmptyColumns: types.optional(types.boolean, false),
        cursorPosition: types.maybe(CursorPosition),
    })
    .volatile((_self): { _titlePlaceholder: undefined | string } => ({ _titlePlaceholder: undefined }))
    .views((self) => ({
        get rawTitlePlaceholder() {
            return self._titlePlaceholder;
        },
        get titlePlaceholder() {
            return self._titlePlaceholder ?? getQueryStoreEnv(self).strings.query.newTab;
        },
        /**
         * The query text currently selected
         */
        get queryTextInContext(): undefined | string {
            return self.commandInContext ?? undefined;
        },
        /**
         * The database name currently selected
         */
        get dbnameInContext(): undefined | string {
            return self.entityInContext?.entityType === 'Database' ? self.entityInContext.name : undefined;
        },
        /**
         * The cluster URL currently selected
         */
        get clusterUrlInContext(): undefined | string {
            if (self.entityInContext) {
                return getClusterAndDatabaseFromEntity(self.entityInContext).cluster.clusterUrl;
            }
        },
        /**
         * Returns true if the tab has a command text (aka query text) and
         * a database currently selected
         */
        get hasQueryTextAndDBInContext(): boolean {
            return Boolean(self.commandInContext && self.entityInContext?.entityType === 'Database');
        },
    }))
    .views((self) => ({
        get currentCommandHash() {
            const request = createRequestInfo(self as unknown as Tab);
            return request.hashCode;
        },
        get errorMessage() {
            if (!self.completionInfo) {
                return 'Unknown Error';
            }

            let message = self.completionInfo.errorDescription
                ? kusto.formatClientErrorString(self.completionInfo.errorDescription, getQueryStoreEnv(self).strings)
                : undefined;

            // before there was errorDescription there was failureReason. maybe it's an old execution result...
            if (!message) {
                message = self.completionInfo.failureReason || '';
            }

            return message + `\n\n clientRequestId: ${self.completionInfo.clientActivityId}`;
        },
        get calcTitle(): string {
            if (self.title) {
                return self.title;
            }

            const env = getQueryStoreEnv(self);
            if (!self.entityInContext) {
                return env.strings.query.newTab;
            }

            const connection = getClusterAndDatabaseFromEntity(self.entityInContext).cluster;
            if (!connection) {
                return env.strings.query.newTab;
            }

            const databaseName =
                self.entityInContext.entityType === 'Database'
                    ? (self.entityInContext as Database).getDisplayName()
                    : undefined;

            if (env.featureFlags.TridnetView) {
                return databaseName ?? self.titlePlaceholder;
            }

            const clusterNameOrAlias =
                connection.alias ||
                KustoConnection.fromConnectionString(getQueryStoreEnv(self).kustoDomains, connection.connectionString)!
                    .cluster;

            return `${clusterNameOrAlias}${databaseName ? `.${databaseName}` : ''}`;
        },
        /**
         * A bag of props that are selected in the current context
         */
        get queryContext(): QueryContext {
            return {
                url: self.clusterUrlInContext,
                dbname: self.dbnameInContext,
                query: self.queryTextInContext,
            };
        },
        get selectedEntityValues(): string[] | undefined {
            const group: string | undefined = self.entityInContextGroup;
            const entity: Cluster | Database | undefined | null = self.entityInContext;
            const getValueFromId = (id: string) => (group ? `${group}/${id}` : id);
            if (!entity) {
                return undefined;
            }
            if (entity.entityType === 'Cluster') {
                return [getValueFromId(entity.id)];
            } else if (entity.entityType === 'Database') {
                const cluster = getClusterFromDatabase(entity as Database);
                return [getValueFromId(entity.id), getValueFromId(cluster.id)];
            } else {
                return undefined;
            }
        },
    }))
    .volatile((_self) => {
        return {
            isFetching: false,
            cancellationTokenSource: undefined as kusto.CancellationTokenSource | undefined,
            fetchStartTime: new Date().getTime(),
            fetchEndtime: new Date().getTime(),
            runRequest: 'No' as RunRequestedType,
            isRecallRequested: false,
            isAddValueAsFilterRequested: false,
            canvasToPaste: undefined as HTMLCanvasElement | undefined,
            csvExportRequested: false,
            excelExportRequested: false,
            results: undefined as QueryResults | undefined,
            renderingException: undefined as Error | undefined,
            searchEnabled: 0, // set to a number to allow refocusing when search already enabled.
            dirty: false,
            saveError: undefined as string | undefined,
            visualModelState: defaultVisualModelState(),
            latestKustoRequest: undefined as kusto.IKustoRequest | undefined,
        };
    })
    .actions((self) => ({
        setHideEmptyColumns(value: boolean) {
            self.hideEmptyColumns = value;
            trackTrace('HideEmptyColumn', SeverityLevel.Information, { enabled: `${value}` });
        },
        setResults(results: QueryResults | undefined, executionStatus: FetchState) {
            self.executionStatus = executionStatus;
            self.results = results;
            self.renderingException = undefined;

            const visualResult = results?.results.find((r) => r.isChart);
            if (visualResult) {
                try {
                    self.visualModelState = mapVisualOptions(visualResult, getQueryStoreEnv(self).telemetry);
                } catch (error) {
                    self.visualModelState = defaultVisualModelState();
                    self.renderingException = castToError(error);
                }
            } else {
                self.visualModelState = defaultVisualModelState();
            }
        },
        setVisualTitle(title: string) {
            self.visualModelState.title = title;
        },
        setVisualHideTitle(hideTitle: boolean) {
            self.visualModelState.hideTitle = hideTitle;
        },
    }))
    .views((self) => ({
        get isResultDisplayed() {
            return (
                !self.isFetching &&
                (self.executionStatus === 'done' ||
                    self.executionStatus === 'gotFromCache' ||
                    self.executionStatus === 'failed') &&
                self.completionInfo &&
                self.results &&
                self.results.results &&
                self.results.results.length > 0
            );
        },
        get visualResult(): VisualFwkQueryResult {
            if (!self.completionInfo) {
                return LOADING;
            }
            const resultIndex = self.completionInfo.resultIndex;
            const resultsToDisplay = self.results?.resultsToDisplay;
            let visualResult;
            if (resultsToDisplay?.length === 1) {
                visualResult = resultsToDisplay[0];
            } else if (resultIndex !== undefined) {
                visualResult = resultsToDisplay?.[resultIndex];
            }

            return visualResult
                ? ok(
                      visualTableResultToRtdQueryResult(
                          visualResult,
                          visualResult.visualizationOptions?.IsQuerySorted ?? false
                      )
                  )
                : err({
                      // TODO: Localize
                      title: 'No results found',
                      level: 'error',
                  });
        },
    }))
    .views((self) => ({
        get isChartDisplayed() {
            return (
                self.isResultDisplayed &&
                self.results &&
                self.completionInfo &&
                self.completionInfo.resultIndex < self.results.resultsToDisplay.length &&
                self.results.resultsToDisplay[self.completionInfo.resultIndex].isChart
            );
        },
        get resultRowCount() {
            if (
                !self.isResultDisplayed ||
                !self.completionInfo ||
                !self.results ||
                self.completionInfo.resultIndex >= self.results.resultsToDisplay.length
            ) {
                return undefined;
            }

            const index = self.completionInfo.resultIndex;
            const rows = self.results.resultsToDisplay[index].rows;

            if (!rows) {
                return undefined;
            }
            return rows.length;
        },
        get resultsCount() {
            return self.isResultDisplayed && self.results ? self.results.results.length : undefined;
        },
    }))
    .actions((self) => ({
        setIsFetching(isFetching: boolean) {
            if (self.isFetching === isFetching) {
                return;
            }

            self.isFetching = isFetching;
            if (isFetching) {
                self.fetchStartTime = new Date().getTime();
            } else {
                self.fetchEndtime = new Date().getTime();
            }
        },
        setCanvasToPaste(canvasToPaste: HTMLCanvasElement) {
            self.canvasToPaste = canvasToPaste;
        },
        setTitle(newTitle: string) {
            const oldTitle = self.calcTitle;

            if (oldTitle !== newTitle) {
                self.title = newTitle;
            }
        },
        setTitlePlaceholder(titlePlaceholder?: string) {
            self._titlePlaceholder = titlePlaceholder;
        },
    }))
    .actions((self) => {
        const env = getQueryStoreEnv(self);
        const kustoClient = env.kustoClient;
        const handleConnectDirective = async (connectTo: string): Promise<Database | Cluster | null> => {
            const connectionPane = (getRoot(self) as IRootStore).connectionPane;
            const resolvedConnection = resolveKustoConnection(
                env.kustoDomains,
                connectTo,
                connectionPane.aliasesToNameMapping()
            );
            if (!resolvedConnection?.cluster) {
                return null;
            }
            const maybeConnectionStringInfo = kusto.parseClusterConnectionString(
                env.telemetry,
                env.kustoDomains,
                resolvedConnection.cluster
            );
            if (maybeConnectionStringInfo.kind === 'err') {
                // TODO: Show maybeConnectionStringInfo.err.message to user
                return null;
            }
            const { clusterName, connectionString } = maybeConnectionStringInfo.value;

            const cluster = await connectionPane.findOrAddCluster(clusterName, connectionString, false);
            if (!cluster) {
                return null;
            }

            if (resolvedConnection.database) {
                const database = cluster.getDatabaseByName(resolvedConnection.database) ?? null;
                return database;
            } else {
                return cluster ?? undefined;
            }
        };
        function setErrorResult(request: RequestInfo, errorDescription: kusto.KustoClientErrorDescription) {
            const completionInfo: QueryCompletionInfo = QueryCompletionInfo.create(
                {
                    id: request.hashCode,
                    failureReason: errorDescription.errorMessage,
                    errorDescription: errorDescription,
                    isSuccess: false,
                    timeEnded: new Date(),
                    request,
                    clientActivityId: self.clientRequestId ?? '',
                },
                getEnv(self)
            );

            const rootStore: IRootStore = getRoot(self) as IRootStore;
            rootStore.resultCache!.put(completionInfo);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (self.completionInfo as any) = completionInfo.id;
            self.setResults(undefined, 'failed');
            self.setIsFetching(false);
            self.runRequest = 'No';
        }
        function setEntityInContextGroup(group: string | null | undefined) {
            const connectionPane = (getRoot(self) as IRootStore).connectionPane;

            if (connectionPane && connectionPane.groups) {
                const groups: Group[] = Array.from(connectionPane.groups?.values());
                if (group && (group === kustoEntityTypes.favorites || groups.find((g) => g.id === group))) {
                    self.entityInContextGroup = group;
                } else if (group === null) {
                    self.entityInContextGroup = undefined;
                }
            }
        }

        return {
            setEntityInContext: (entity: Cluster | Database | null | undefined, group?: string | null | undefined) => {
                self.entityInContext = entity;
                setEntityInContextGroup(group);
            },
            setDirty: (dirty: boolean) => {
                self.dirty = dirty;
            },
            setSaveError: (error: string | undefined) => {
                self.saveError = error;
            },
            setText: (text: string, shouldUnifyWhiteSpaces = true) => {
                const cleanText = shouldUnifyWhiteSpaces ? unifyWhiteSpaces(text) : text;
                if (self.text !== cleanText) {
                    trackTrace('setText: updating text', SeverityLevel.Verbose);
                    self.text = cleanText;
                    self.dirty = true;
                } else {
                    trackTrace('setText: text did not update', SeverityLevel.Verbose);
                }
            },
            setCommandInContext: (commandInContext: CommandInContext) => {
                const { command, commandType, commandWithoutLeadingComments, range, cursorPosition } = commandInContext;
                self.commandInContext = command;
                self.queryRange = range;
                self.commandType = commandType;
                self.commandWithoutLeadingComments = commandWithoutLeadingComments ?? command;
                self.cursorPosition = cursorPosition
                    ? { lineNumber: cursorPosition.lineNumber, column: cursorPosition.column }
                    : undefined;
            },
            recall: flow(function* recall(flowType = 'recall') {
                recallTracer.trackEvent('Start ' + flowType);
                const rootStore = getRoot(self);
                // once introducing yield, started getting a circular type reference here when using RootStore.
                // so we have to remove type safely here (hence the any)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const completionInfo = ((rootStore as any).resultCache! as ResultCache).executions.get(
                    self.currentCommandHash.toString()
                );

                if (completionInfo === undefined || !completionInfo.haveCachedResults) {
                    return;
                }

                recallTracer.trackTrace(flowType, SeverityLevel.Information, {
                    clientActivityId: completionInfo ? completionInfo.clientActivityId : '',
                });

                const results = yield env.config.queryResultStore?.getResultsAsync?.(getEnv(self), completionInfo.id) ??
                    Promise.resolve(undefined);
                if (completionInfo && results) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    self.completionInfo = completionInfo;
                    if (
                        env.featureFlags.QueryVisualOptions &&
                        env.featureFlags.AddVisual &&
                        results.resultsToDisplay.length <= completionInfo.resultIndex
                    ) {
                        completionInfo.setResultInContext('0');
                    }
                    self.setResults(results, 'gotFromCache');
                }
                recallTracer.trackTrace(flowType + ' ends', SeverityLevel.Information, {
                    clientActivityId: completionInfo ? completionInfo.clientActivityId : '',
                    result: completionInfo && results ? 'ok' : 'notSet',
                });
            }),
            requestAddValueAsFilters: (val: boolean) => {
                self.isAddValueAsFilterRequested = val;
            },
            requestCsvExport: (val: boolean) => {
                self.csvExportRequested = val;
            },
            requestExcelExport: (val: boolean) => {
                self.excelExportRequested = val;
            },
            setRenderingException: (error: Error | undefined) => {
                self.renderingException = error;
            },
            run: flow(function* run(
                runType: Exclude<RunRequestedType, 'No'>,
                resultType: RunRequestResults = 'Full',
                clientRequestSource: kusto.ClientRequestSource = 'Query'
            ) {
                if (self.runRequest !== 'No') {
                    return;
                }
                self.runRequest = runType;

                const abortController = new AbortController();
                const signal = abortController.signal;

                mobx.when(
                    () => self.runRequest === 'No',
                    () => abortController.abort()
                );

                const { executionDone } = env.config.query ?? {};

                // Can't run the query until the monaco editor is mounted
                // because we need the intellisense to tell us what the command
                // is
                const handle: Ok<KustoEditorHandle> | Aborted = yield env.kustoEditor.promise(abortController.signal);

                if (handle.kind === 'abort') {
                    return;
                }

                runTracer.trackTrace('Start');

                const flushPromise = handle.value.flushEditorSync();
                // Promise is only returned if there's something to flush, and
                // mobx flow doesn't supporting yielding non-promise values
                if (flushPromise) {
                    queryRunTimeoutWarning(env.telemetry, flushPromise, 'flushPromise');
                    yield flushPromise;
                }

                // Intentionally not awaiting promise returned by logDiagnostics
                handle.value.logDiagnostics();

                const readOnlyRequest: boolean = runType === 'ReadOnly';

                // Commands should not be executed for ReadOnly requests
                if (readOnlyRequest && self.commandType === 'AdminCommand') {
                    self.runRequest = 'No';
                    return;
                }

                self.executionStatus = 'notStarted';

                const request = createRequestInfo(self as Tab);
                const rootStore: IRootStore = getRoot(self) as IRootStore;
                if (self.commandType === 'ClientDirective') {
                    // The ! is because ClientDirectives type always have commandWithoutLeadingComments in the store from Monaco.tsx
                    const command = self.commandWithoutLeadingComments!.trim().substring(1);
                    const indexOfFirstSpace = command.indexOf(' ');
                    const directive = command.substring(0, indexOfFirstSpace);
                    const directiveArg = command.substring(indexOfFirstSpace + 1);
                    switch (directive) {
                        case 'connect':
                            if (!env.featureFlags.ShowConnectionButtons) {
                                setErrorResult(request, {
                                    errorMessage: env.strings.query.cannotConnectClusters,
                                });
                                return;
                            }
                            runTracer.trackTrace('Directive.Connect');
                            const argsTrimmed = directiveArg.trim();
                            const { isSuccess, literal } = parseStringLiteral(argsTrimmed);
                            const connectArg = isSuccess ? literal : argsTrimmed;
                            const resource = yield handleConnectDirective(connectArg);
                            if (resource) {
                                self.entityInContext = resource;
                                if (
                                    rootStore.connectionPane.entityInContext !== resource &&
                                    rootStore.tabs.tabInContext === self
                                ) {
                                    rootStore.connectionPane.setEntityInContextByObject(resource);
                                }
                            }
                            self.runRequest = 'No';
                            return;
                        default:
                            setErrorResult(request, {
                                errorMessage: formatLiterals(env.strings.query.unknownDirective, {
                                    directive,
                                }),
                            });
                            return;
                    }
                }
                // TODO: a lot of the code below this part should go into KustoClient
                try {
                    if (
                        self.commandType === 'Query' &&
                        (!self.entityInContext || self.entityInContext.entityType !== 'Database')
                    ) {
                        runTracer.trackEvent('Query was executed without a database in context');
                        throw new Error(env.strings.query.selectDatabase);
                    }

                    const root = getRoot(self) as {
                        settings: {
                            timeoutInMinutes: number;
                            adminCommandTimeoutInMinutes: number;
                            weakConsistency: boolean;
                            engineParser?: kusto.EngineParserType;
                        };
                    };
                    if (self.commandType !== 'AdminCommand' && !self.commandInContext?.trim()) {
                        env.telemetry.trace('Execute full script - command in context is empty', {
                            severityLevel: 'warning',
                        });
                    }
                    let commandToExecute =
                        self.commandType === 'AdminCommand'
                            ? self.commandWithoutLeadingComments.trim()
                            : self.commandInContext?.trim() || self.text;
                    if (resultType === 'Preview') {
                        commandToExecute = commandToExecute.replace(/;+$/, ''); // Remove trailing ';'
                        commandToExecute = `${commandToExecute} | evaluate preview(50)`;
                    }
                    const isControlCommand = commandToExecute.trim().startsWith('.');
                    const timeoutInMinutes = isControlCommand
                        ? root.settings.adminCommandTimeoutInMinutes
                        : root.settings.timeoutInMinutes;
                    const timeoutAsTimestampString =
                        timeoutInMinutes === 60 ? '01:00:00' : `00:${padStart(timeoutInMinutes.toString(), 2, '0')}:00`;
                    const queryConsistency = root.settings.weakConsistency ? 'weakconsistency' : 'strongconsistency';
                    self.cancellationTokenSource = kustoClient.createCancelToken();
                    const kustoRequest = kustoClient.createRequest(request.url, { source: clientRequestSource });
                    if (!kustoRequest) {
                        return;
                    }
                    self.latestKustoRequest = kustoRequest;
                    self.clientRequestId = kustoRequest.clientRequestId;
                    const executionResult: kusto.ExecutionResult<kusto.ApiVersion> =
                        yield kustoRequest.execute_deprecated(
                            request.dbName,
                            commandToExecute,
                            !isControlCommand,
                            isControlCommand ? 'v1' : 'v2',
                            {
                                properties: {
                                    Options: {
                                        query_language: sqlQueryRegex.test(commandToExecute) ? 'sql' : 'csl',
                                        servertimeout: timeoutAsTimestampString,
                                        queryconsistency: queryConsistency,
                                        request_readonly: readOnlyRequest,
                                        request_readonly_hardline: readOnlyRequest,
                                        parser: root.settings.engineParser,
                                    },
                                },
                                onStartHttpRequest: mobx.action(() => {
                                    if (self.executionStatus !== 'canceled') self.setIsFetching(true);
                                }),
                                cancelToken: self.cancellationTokenSource.token,
                                account: getConnectionInContext(self)?.getAccount_UNSAFE(),
                            }
                        );

                    // We don't want to measure time from when the user requested execution - since it may involve
                    // acquiring an auth token (which is unfair to count as query execution time).
                    // during token acquisition time we're showing 'preparing to run' indication to the user.
                    // Once we get the actual time we started the http request, we'll update the relevant field in the
                    // request properties.
                    request.setTimeStarted(executionResult.httpRequestStartTime);

                    // query might have been cancelled in the meanwhile

                    if (signal.aborted) {
                        return;
                    }

                    // call the right method to parse the results according to api v1 or v2.
                    let completionInfo: {
                        queryCompletionInfo: QueryCompletionInfo;
                        queryResults: QueryResults;
                    } | null = null;
                    if (kusto.isV1Response(executionResult.apiCallResult)) {
                        completionInfo = toQueryCompletionInfo(
                            getEnv(self),
                            request,
                            executionResult.apiCallResult,
                            kustoRequest.clientRequestId
                        );
                    } else {
                        completionInfo = toQueryCompletionInfoV2(
                            getEnv(self),
                            request,
                            executionResult.apiCallResult as kusto.KustoClientResultV2,
                            kustoRequest.clientRequestId
                        );
                    }

                    rootStore.resultCache!.put(completionInfo.queryCompletionInfo);
                    try {
                        const setResultsPromise =
                            env.config.queryResultStore?.setResultsAsync?.(
                                completionInfo.queryCompletionInfo.id!,
                                completionInfo.queryResults
                            ) ?? Promise.resolve();
                        queryRunTimeoutWarning(env.telemetry, setResultsPromise, 'setResultsPromise');
                        yield setResultsPromise;
                    } catch (e) {
                        // Query result ot big for to store locally
                        completionInfo.queryCompletionInfo.noCachedResults();
                        // maybe should send tracking ?!
                    }

                    // Bug fix: this assignment caused issues in case the same query was executed twice.
                    if (
                        !self.completionInfo ||
                        !self.completionInfo.id ||
                        self.completionInfo.id !== completionInfo.queryCompletionInfo.id
                    ) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (self.completionInfo as any) = completionInfo.queryCompletionInfo.id;
                    }

                    // Originally pruneOne was called as part of resultCache.put
                    // (which is invoked above), but this caused a subtle bug:
                    // only at this point do we know that the new execution is being referenced by the current tab
                    // since the previous statement sets the reference.
                    // Thus only now we can safely prune without deleting the current execution result from the
                    // cache by mistake.
                    // switching the order (i.e setting the self.completion info before resultCache.put) would have
                    // solved the issue, but would raise a another one : self.completionInfo is a _reference_ and
                    // we cannot set it until we have an item in the resultCache to refer to.
                    rootStore.resultCache!.pruneOne();

                    if (env.featureFlags.AddVisual) {
                        const chartIndex = completionInfo.queryResults.resultsToDisplay.findIndex((r) => r.isChart);
                        if (chartIndex >= 0) {
                            completionInfo.queryCompletionInfo.setResultInContext(chartIndex.toString());
                        }
                    }
                    self.setResults(
                        completionInfo.queryResults,
                        completionInfo.queryCompletionInfo.isSuccess ? 'done' : 'failed'
                    );

                    self.setIsFetching(false);
                    self.runRequest = 'No';
                    const entityInContext = getEntityInContext(self);
                    // Refresh entity after control commands that may modify it.
                    if (entityInContext && isControlCommand && !commandToExecute.trim().startsWith('.show')) {
                        // If we created a database from the context of another database, we need to refresh the entire
                        // cluster (since the database list had changed and not only the current entity).
                        const shouldRefreshCluster = !!commandToExecute.trim().match(/(\.create|\.drop).*database.*/);
                        entityInContext.fetchCurrentSchema(true, shouldRefreshCluster);
                    }

                    if (completionInfo.queryCompletionInfo.isSuccess) {
                        pythonDebugResult(completionInfo.queryResults);
                    }
                } catch (exception) {
                    // query might have been cancelled in the meanwhile.
                    if (kusto.isCancelledError(exception)) {
                        return;
                    }

                    let errorDescription: kusto.KustoClientErrorDescription = { errorMessage: '' };
                    if (kusto.isAuthorizationError(exception)) {
                        const account = env.authProvider.getAccount();
                        errorDescription = { errorMessage: getAuthorizationError(env, account) };
                    } else {
                        errorDescription = kusto.extractErrorDescriptionAndTrace(
                            exception,
                            self.queryRange?.startLineNumber
                        );
                    }

                    setErrorResult(request, errorDescription);
                } finally {
                    self.cancellationTokenSource = undefined;
                    executionDone?.(self.commandType, self.executionStatus, readOnlyRequest, self.completionInfo);
                }
            }),
            cancel() {
                cancelTracer.trackTrace('Start', SeverityLevel.Information, {
                    clientActivityId: self.latestKustoRequest ? self.latestKustoRequest.clientRequestId : '',
                });

                // We may have requested run but didn't actually issue the request yet.
                // In that case we don't have a client id yet.
                if (!self.latestKustoRequest && self.isFetching) {
                    throw new Error(env.strings.query.clientRequestIdNotNull);
                }

                // We issue a cancel query only if request was already sent.
                // Otherwise we're just preparing for execution (getting current command, ETC) so nothing to cancel.
                if (self.latestKustoRequest && self.isFetching) {
                    self.latestKustoRequest
                        .cancelQuery_deprecated(15000)
                        .then(() => cancelTracer.trackTrace('cancelRequestSucceed'))
                        .catch((e: unknown) => {
                            // TODO(pii): re-enable telemetry with sanitized exception
                            // cancelTracer.trackException(e, 'tab.cancel', { domain: new URL(url).hostname })
                            cancelTracer.trackTrace(`Failed. Exception message: ${castToError(e).message}`);
                        });
                }

                // Query might have returned and finished before cancellation.
                if (!self.isFetching) {
                    return;
                }

                self.setResults(undefined, 'canceled');
                self.setIsFetching(false);
                self.runRequest = 'No';
                self.cancellationTokenSource?.cancel();
            },
        };
    })
    .actions((self) => ({
        // Reload the last query in context results
        afterAttach() {
            self.recall();
        },
        requestRecall: flow(function* requestRecall() {
            const handle = getQueryStoreEnv(self).kustoEditor.ref;
            if (!handle) {
                return;
            }
            if (self.isRecallRequested) {
                return;
            }
            self.isRecallRequested = true;

            handle.flushEditorSync();
            // Intentionally not awaiting promise returned by logDiagnostics
            handle.logDiagnostics();
            yield self.recall();
            self.isRecallRequested = false;
        }),
    }))
    .volatile((_self) => ({
        // Store grid state for the latest results in context
        // ignore the state (and reset it) if related to different result set
        gridState: {
            forResults: undefined as QueryResults | undefined,
            // Keep state of each result table in the query
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            states: [] as any[],
        },
    }))
    .views((self) => ({
        getVisualState<T>(): T | undefined {
            const { completionInfo, gridState, results } = self;

            if (!completionInfo || !results || !results.resultsToDisplay[completionInfo.resultIndex]) {
                return;
            }
            if (gridState.forResults === results) {
                const state = gridState.states[completionInfo.resultIndex];
                if (state) {
                    return state as T;
                }
            }
            return undefined;
        },
        get isTableShown(): boolean {
            const { resultRowCount, isFetching, runRequest, isChartDisplayed, completionInfo, results } = self;
            if (!resultRowCount) return false;

            const isQueryRunning = isFetching || runRequest === 'Yes';

            const { resultIndex, queryResourceConsumption: stats } = completionInfo!;
            const showingStats = stats && resultIndex >= results!.resultsToDisplay.length;

            return (
                (getQueryStoreEnv(self).featureFlags.QueryResultsSearch ?? false) &&
                !isQueryRunning &&
                resultRowCount > 0 &&
                !isChartDisplayed &&
                !showingStats
            );
        },
    }))
    .actions((self) => ({
        enableSearch: (enabled: boolean) => {
            // do not enable (but allow disabling) when search in not supported.
            if (!self.isTableShown && self.searchEnabled === 0 && enabled) {
                searchTracer.trackTrace('enablingSearchWhenSearchNotSupported');
                return;
            }

            self.searchEnabled = enabled ? self.searchEnabled + 1 : 0;
        },
        setVisualState<T>(state: T, forResult: MultiTableResult) {
            // setting the visual state might happened after tab data change - like running new query
            if (
                !self.results ||
                self.results !== self.gridState.forResults ||
                !self.results.results.find((result) => result.rows === forResult.rows)
            ) {
                self.gridState = {
                    forResults: self.results,
                    states: [],
                };
            }
            self.gridState.states[forResult.queryIndex] = state;
        },
    }));

export type TabModel = mst.IModelType<
    {
        id: mst.ISimpleType<string>;
        queryRange: mst.IMaybe<mst.ISimpleType<MonacoRange>>;
        title: mst.IMaybe<mst.ISimpleType<string>>;
        text: mst.IOptionalIType<mst.ISimpleType<string>, [undefined]>;
        commandInContext: mst.IMaybeNull<mst.ISimpleType<string>>;
        executionStatus: mst.IOptionalIType<mst.ISimpleType<string>, [undefined]>;
        clientRequestId: mst.IMaybeNull<mst.ISimpleType<string>>;
        completionInfo: mst.IMaybe<mst.IMaybe<mst.IReferenceType<typeof QueryCompletionInfo>>>;
        entityInContext: mst.IMaybeNull<typeof ClusterOrDatabaseSafeReference>;
        entityInContextGroup: mst.IMaybe<mst.ISimpleType<string>>;
        commandType: mst.IOptionalIType<typeof CommandType, [undefined]>;
        commandWithoutLeadingComments: mst.IOptionalIType<mst.ISimpleType<string>, [undefined]>;
        hideEmptyColumns: mst.IOptionalIType<mst.ISimpleType<boolean>, [undefined]>;
        cursorPosition: mst.IMaybe<typeof CursorPosition>;
    },
    {
        _titlePlaceholder: undefined | string;
        readonly rawTitlePlaceholder: string | undefined;
        readonly titlePlaceholder: string;
        readonly currentCommandHash: number;
        readonly errorMessage: string;
        readonly calcTitle: string;
        /**
         * The query text currently selected
         */
        readonly queryTextInContext: undefined | string;
        /**
         * The database name currently selected
         */
        readonly dbnameInContext: undefined | string;
        /**
         * The cluster URL currently selected
         */
        readonly clusterUrlInContext: undefined | string;
        /**
         * Returns true if the tab has a command text (aka query text) and
         * a database currently selected
         */
        readonly hasQueryTextAndDBInContext: boolean;
        /**
         * A bag of props that are selected in the current context
         */
        readonly queryContext: QueryContext;
        readonly selectedEntityValues: string[] | undefined;

        isFetching: boolean;
        cancellationTokenSource: kusto.CancellationTokenSource | undefined;
        fetchStartTime: number;
        fetchEndtime: number;
        runRequest: RunRequestedType;
        isRecallRequested: boolean;
        isAddValueAsFilterRequested: boolean;
        canvasToPaste: HTMLCanvasElement | undefined;
        csvExportRequested: boolean;
        excelExportRequested: boolean;
        results: undefined | QueryResults;
        renderingException: Error | undefined;
        searchEnabled: number;
        dirty: boolean;
        saveError: string | undefined;
        visualModelState: VisualModelState;
        latestKustoRequest: kusto.IKustoRequest | undefined;

        setHideEmptyColumns(value: boolean): void;
        setResults(results: QueryResults | undefined, executionStatus: FetchState): void;
        setVisualTitle(title: string): void;
        setVisualHideTitle(hideTitle: boolean): void;
        readonly isResultDisplayed: undefined | boolean;
        readonly visualResult: VisualFwkQueryResult;
        readonly isChartDisplayed: undefined | null | boolean;
        readonly resultRowCount: undefined | number;
        readonly resultsCount: undefined | number;
        setIsFetching(isFetching: boolean): void;
        setCanvasToPaste(canvasToPaste: HTMLCanvasElement): void;
        setTitle(newTitle: string): void;
        setTitlePlaceholder(titlePlaceholder?: string): void;

        setEntityInContext: (
            entity: Cluster | Database | null | undefined,
            entityGroupInContext?: string | null | undefined
        ) => void;
        setDirty: (dirty: boolean) => void;
        setSaveError: (error: string | undefined) => void;
        setText: (text: string, shouldUnifyWhiteSpaces?: boolean) => void;
        setCommandInContext: (commandInContext: CommandInContext) => void;
        recall(flowType?: string): Promise<void>;
        requestAddValueAsFilters: (val: boolean) => void;
        requestCsvExport: (val: boolean) => void;
        requestExcelExport: (val: boolean) => void;
        setRenderingException: (error: Error | undefined) => void;
        run: (
            runType: 'ReadOnly' | 'Yes',
            resultType?: RunRequestResults | undefined,
            clientRequestSource?: kusto.ClientRequestSource
        ) => Promise<void>;
        cancel(): void;
        afterAttach(): void;
        requestRecall: () => Promise<void>;
        gridState: {
            forResults: QueryResults | undefined;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            states: any[];
        };
        getVisualState<T>(): T | undefined;
        readonly isTableShown: boolean;
        enableSearch: (enabled: boolean) => void;
        setVisualState<T>(state: T, forResult: MultiTableResult): void;
    },
    mst._NotCustomized
>;

// eslint-disable-next-line no-redeclare
export type Tab = mst.Instance<TabModel>;

export type TabSnapshot = SnapshotOut<typeof Tab>;

/**
 * Create a request info object.
 * @param timeStarted if provided will inhabit the timeStarted property of RequestInfo. otherwise it will be now.
 */
function createRequestInfo(tab: Tab, timeStarted?: Date): RequestInfo {
    timeStarted = timeStarted || new Date();

    const { url, dbname, query } = tab.queryContext;

    return RequestInfo.create(
        {
            url: url || '',
            dbName: dbname || 'N/A',
            queryText: query,
            timeStarted,
        },
        getEnv(tab)
    );
}

const getAuthorizationError = (env: QueryStoreEnv, account?: Account): string => {
    const tenantId = account?.tenantId;
    const accountEmail = account?.username.includes('@') ? account?.username : undefined;
    if (tenantId && accountEmail) {
        return formatLiterals(env.strings.query.kwe$authorizationErrorWithUserAndTenant, {
            accountEmail,
            tenantId,
        });
    } else if (accountEmail) {
        // no tenant ID
        return formatLiterals(env.strings.query.kwe$authorizationErrorWithUserOnly, { accountEmail });
    } else {
        // no tenant ID or account email
        return env.strings.query.kwe$authorizationError;
    }
};

export function getClusterFromTab(tab: Tab): Cluster | undefined {
    const clusterOrDatabase = tab.entityInContext;
    if (!clusterOrDatabase) {
        return undefined; // no entity in context
    }

    if (clusterOrDatabase.entityType === EntityType.Cluster) {
        return clusterOrDatabase as Cluster;
    }

    const database = clusterOrDatabase as Database;
    return getClusterFromDatabase(database);
}
