import type { AddDashboardWarning } from '..';
import { ParameterV10, parameterV10Down, parameterV10Up } from '../parameter/10';
import type { IDashboardV43 } from './43';

export interface DashboardV44 extends Omit<IDashboardV43, 'parameters' | 'schema_version'> {
    readonly schema_version: '44';
    readonly parameters: readonly ParameterV10[];
}

export function up(prev: IDashboardV43): DashboardV44 {
    return {
        ...prev,
        schema_version: '44',
        parameters: prev.parameters.map(parameterV10Up),
    };
}

export function down(prev: DashboardV44, addWarning: AddDashboardWarning): IDashboardV43 {
    return {
        ...prev,
        schema_version: '43',
        parameters: prev.parameters.map((p) => parameterV10Down(p, addWarning)),
    };
}
