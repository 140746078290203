import { isValidUUID } from '@kusto/utils';

import type { PatchOne } from '../types';
import { resultFrom } from '../utils';

// {
//     "message": "Needs to follow the UUID format as defined by RFC 4122 (https://datatracker.ietf.org/doc/html/rfc4122). Example: 3e4666bf-d5e5-4aa7-b8ce-cefe41c7568a",
//     "instancePath": "/tiles/2/visualOptions/multipleYAxes/base/horizontalLines/0/id",
//     "schemaPath": "dashboard.json#/$defs/uuid/format",
//     "keyword": "format",
//     "params": {
//       "format": "uuid"
//     }
// }
//
// We had a short issue where our PII redactor was messing with dashboard state
// so we need to generate a new ID

export interface Data {
    id: string;
    targetIndex: number;
}

export const badHorizontalRulesIdFormatConfig: PatchOne<'tiles', Data[]> = {
    kind: 'patch-one',
    corruption(tile) {
        const data: Data[] = [];
        const horizontalLines = tile.visualOptions.multipleYAxes?.base?.horizontalLines;

        if (horizontalLines?.length) {
            for (let i = 0; i < horizontalLines.length; i++) {
                if (!isValidUUID(horizontalLines[i].id)) {
                    data.push({ id: horizontalLines[i].id, targetIndex: i });
                }
            }
        }

        return resultFrom(data);
    },
    patch(tile, data) {
        let patchedHorizontalLines = [...tile.visualOptions.multipleYAxes.base.horizontalLines];

        for (const { id, targetIndex } of data) {
            patchedHorizontalLines = patchedHorizontalLines.map((line, index) => {
                if (line.id === id && index === targetIndex) {
                    return {
                        ...line,
                        id: crypto.randomUUID(),
                    };
                }

                return line;
            });
        }

        return {
            ...tile,
            visualOptions: {
                ...tile.visualOptions,
                multipleYAxes: {
                    ...tile.visualOptions.multipleYAxes,
                    base: {
                        ...tile.visualOptions.multipleYAxes.base,
                        horizontalLines: patchedHorizontalLines,
                    },
                },
            },
        };
    },
};
