import type { QueryPropertyV1 } from '../query/1';
import type { CanInferV1 } from './1';
import type { TileLayoutV2 } from './2';
import type { MapGeoTypeV10 } from './10';
import type { PieLabelOptionsV11 } from './11';
import type { CrossFilterConfigV12 } from './12';
import type { DrillthroughConfigV13 } from './13';
import type { HeatMapPaletteColorV17 } from './17';
import type { MultipleYAxesConfigV18 } from './18';
import type { TableRenderLinkConfigV21 } from './21';
import type { ITileV23, UColorRuleV23 } from './23';
import type { ColorRulePreSchema } from './preSchema';

/**
 * Level set visual options so we don't need to go though every version to
 * understand what it is.
 *
 * The only change from the previous version is moving "hideTileTitle" from here
 * to the tile.
 *
 * This is also the first version that _doesn't_ match what's in
 * the visualConfig options 1:1. That schema now includes hideTitle and title
 * for the query area, which dashboards doesn't support.
 */
export interface IVisualOptionsV24 {
    readonly xColumn: CanInferV1<string>;
    readonly yColumn: CanInferV1<string>;
    readonly yColumns: CanInferV1<string[]>;
    readonly seriesColumns: CanInferV1<string[]>;

    readonly hideLegend: boolean;
    readonly hideDataLabels: boolean;
    readonly yAxisRight: boolean;

    readonly yAxisMinimumValue: CanInferV1<number>;
    readonly yAxisMaximumValue: CanInferV1<number>;

    readonly multipleYAxes: MultipleYAxesConfigV18;

    readonly xColumnTitle: string;
    readonly yColumnTitle: string;

    readonly horizontalLine: string;
    readonly verticalLine: string;

    readonly xAxisScale: 'linear' | 'log';
    readonly yAxisScale: 'linear' | 'log';
    readonly legendLocation: 'left' | 'right';

    readonly colorRulesDisabled: boolean;
    readonly colorRules: readonly UColorRuleV23[];
    readonly colorStyle: ColorRulePreSchema.ColorStyle;

    readonly crossFilterDisabled: boolean;
    readonly crossFilter: readonly CrossFilterConfigV12[];

    readonly drillthroughDisabled: boolean;
    readonly drillthrough: readonly DrillthroughConfigV13[];

    /* Line chart */
    /**
     * show/hide line specific indications (pinpoint) on the line
     */
    readonly line__hidePinpointTooltips: boolean;

    /* Map chart */
    readonly map__type: 'bubble';
    readonly map__bubbleFormat: 'dot' | 'bubble' | 'heatmap' | 'pieChart';
    readonly map__minBubbleSizeColumn: CanInferV1<string>;
    readonly map__latitudeColumn: CanInferV1<string>;
    readonly map__longitudeColumn: CanInferV1<string>;
    readonly map__labelColumn: CanInferV1<string>;
    readonly map__sizeColumn: CanInferV1<string>;
    readonly map__sizeDisabled: boolean;
    readonly map__geoType: MapGeoTypeV10;
    readonly map__geoPointColumn: CanInferV1<string>;

    readonly labelDisabled: boolean;
    readonly tooltipDisabled: boolean;

    /* Pie chart */
    readonly pie__label: readonly PieLabelOptionsV11[];
    readonly pie__tooltip: readonly PieLabelOptionsV11[];
    readonly pie__orderBy: 'none' | 'name' | 'size';
    readonly pie__kind: 'pie' | 'donut';
    readonly pie__TopNSlices: null | number;

    /* Multi stat chart */
    /**
     * vertical => single column
     * horizontal => single row
     */
    readonly multiStat__displayOrientation: 'vertical' | 'horizontal';
    readonly multiStat__textSize: 'small' | 'large' | 'auto';
    readonly multiStat__labelColumn: CanInferV1<string>;
    readonly multiStat__valueColumn: CanInferV1<string>;
    readonly multiStat__slot: {
        readonly width: number;
        readonly height: number;
    };

    /* Heat map chart */
    readonly heatMap__dataColumn: CanInferV1<string>;
    readonly heatMap__colorPaletteKey: HeatMapPaletteColorV17;

    /* Table */
    readonly table__enableRenderLinks: boolean;
    readonly table__renderLinks: readonly TableRenderLinkConfigV21[];

    /* Plotly */
    readonly plotly__version: '1' | '2';
}

/**
 * tileVisualOptions.HideTileTitle is moved to tile.hideTitle
 */
export interface ITileV24 {
    readonly id: string;
    readonly title: string;
    readonly hideTitle?: boolean;
    readonly query: QueryPropertyV1;
    readonly layout: TileLayoutV2;
    readonly pageId: string;
    readonly visualType: string;
    readonly visualOptions: Partial<IVisualOptionsV24>;
}

export function up(prev: ITileV23): ITileV24 {
    if (prev.visualOptions.hideTileTitle === undefined) {
        return prev;
    }
    const { hideTileTitle, ...shared } = prev.visualOptions;
    return {
        ...prev,
        hideTitle: hideTileTitle,
        visualOptions: shared,
    };
}

export function down(prev: ITileV24): ITileV23 {
    if (prev.hideTitle === undefined) {
        return prev;
    }
    const { hideTitle, ...shared } = prev;
    return {
        ...shared,
        visualOptions: {
            hideTileTitle: hideTitle,
            ...shared.visualOptions,
        },
    };
}
