import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import { ColorRuleByValueV19 } from './19';
import { ColorRuleByConditionV22, ITileV22, IVisualOptionsV22 } from './22';

export interface ColorRuleByValueV23 extends ColorRuleByValueV19 {
    readonly reverseTheme: boolean;
}

export type UColorRuleV23 = ColorRuleByConditionV22 | ColorRuleByValueV23;

export interface IVisualOptionsV23 extends Omit<IVisualOptionsV22, 'colorRules'> {
    readonly colorRules: readonly UColorRuleV23[];
}

export interface ITileV23 extends Omit<ITileV22, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV23>;
}

export function up(prev: ITileV22): ITileV23 {
    if (prev.visualOptions.colorRules === undefined) {
        return prev as ITileV23;
    }

    const { colorRules, ...prevVisualOptions } = prev.visualOptions;

    const upgradedColorRules = colorRules.map((rule) => {
        if (rule.ruleType === 'colorByValue') {
            return {
                ...rule,
                reverseTheme: false,
            };
        }
        return rule;
    });

    return {
        ...prev,
        visualOptions: {
            ...prevVisualOptions,
            colorRules: upgradedColorRules,
        },
    };
}

export function down(prev: ITileV23, addWarning: AddDashboardWarning): ITileV22 {
    const { colorRules, ...prevVisualOptions } = prev.visualOptions;
    let visualOptionsV22: Partial<IVisualOptionsV22> = { ...prevVisualOptions };
    let hasReversedTheme = false;
    if (colorRules) {
        const downgradedColorRules = colorRules.map((rule) => {
            if (rule.ruleType === 'colorByValue') {
                const { reverseTheme, ...downgradedRule } = rule;
                hasReversedTheme = hasReversedTheme || reverseTheme;
                return downgradedRule;
            }
            return rule;
        });
        visualOptionsV22 = { ...visualOptionsV22, colorRules: downgradedColorRules };
    }

    if (hasReversedTheme) {
        addWarning((t) => formatLiterals(t.down.v36.reverseThemeRemoved, { tileName: prev.title }));
    }

    return {
        ...prev,
        visualOptions: visualOptionsV22,
    };
}
