import type { ITileV9 } from '../tile/9';
import * as tileV10 from '../tile/10';
import type { IDashboardV16 } from './16';

export interface IDashboardV17 extends Omit<IDashboardV16, 'schema_version' | 'tiles'> {
    schema_version: '17';
    tiles: readonly tileV10.ITileV10[];
}

export function up(prev: IDashboardV16): IDashboardV17 {
    return {
        ...prev,
        schema_version: '17',
        tiles: prev.tiles.map((t) => tileV10.up(t)),
    };
}

export function down(prev: IDashboardV17): IDashboardV16 {
    const tiles: readonly ITileV9[] = prev.tiles.map((t) => {
        return tileV10.down(t);
    });
    return {
        ...prev,
        tiles,
        schema_version: '16',
    };
}
