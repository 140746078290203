import type { AddDashboardWarning } from '..';
import * as tileV28 from '../tile/28';
import type { DashboardV49 } from './49';

/// Updates visual options legendLocation

export interface DashboardV50 extends Omit<DashboardV49, 'schema_version' | 'tiles'> {
    readonly schema_version: '50';
    readonly tiles: readonly tileV28.ITileV28[];
}

export function up(prev: DashboardV49): DashboardV50 {
    return {
        ...prev,
        tiles: prev.tiles.map(tileV28.up),
        schema_version: '50',
    };
}

export function down(prev: DashboardV50, warn: AddDashboardWarning): DashboardV49 {
    return {
        ...prev,
        tiles: prev.tiles.map((t) => tileV28.down(t, warn)),
        schema_version: '49',
    };
}
