import type { BasicParamV2, DurationParamV2, IParameterV2 } from './2';

export interface IParameterBaseV3 {
    id: string;
    displayName: string;
    showOnPages: { kind: 'all' } | { kind: 'selection'; pageIds: readonly string[] };
}

export declare namespace DurationParamV3 {
    export type IFixed = DurationParamV2.IFixed;
    export type IDynamic = DurationParamV2.IDynamic;

    export type Value = DurationParamV2.Value;

    export interface IParam extends DurationParamV2.IParam, IParameterBaseV3 {}
}

export type TDurationParamV3 = DurationParamV3.IParam;

export declare namespace BasicParamV3 {
    export interface IBasic<T, K> extends BasicParamV2.IBasic<T, K>, IParameterBaseV3 {}

    export interface IFreetext<T, K> extends BasicParamV2.IFreetext<T, K>, IBasic<T, K> {}

    export interface IDropdown<T, K> extends BasicParamV2.IDropdown<T, K>, IBasic<T, K> {}
}

export type TBasicParamV3<T, K> = BasicParamV3.IFreetext<T, K> | BasicParamV3.IDropdown<T, K>;

export type UBasicParamV3 =
    | TBasicParamV3<string, 'string'>
    | TBasicParamV3<number, 'int32'>
    | TBasicParamV3<string, 'int64'>
    | TBasicParamV3<number, 'float64'>
    | TBasicParamV3<string, 'decimal'>
    | TBasicParamV3<boolean, 'bool'>
    | TBasicParamV3<number, 'datetime'>;

export type IParameterV3 = UBasicParamV3 | DurationParamV3.IParam;

export function up(prev: IParameterV2, prevPinnedParameters: string[]): IParameterV3 {
    const isPinned = prevPinnedParameters.includes(prev.id);

    /**
     * If it's already pinned then we'll
     * keep it shown for all pages. Else,
     * let's just hide it
     */
    const showOnPages: IParameterV3['showOnPages'] = isPinned ? { kind: 'all' } : { kind: 'selection', pageIds: [] };

    return {
        ...prev,
        showOnPages,
    };
}

export function down({ showOnPages, ...prev }: IParameterV3): IParameterV2 {
    return prev;
}
