import { DashboardVersion, dashboardVersions } from '../migration/dashboard/versions/index';

const versionToIndex = {} as Record<DashboardVersion, number>;

for (let i = 0; i < dashboardVersions.length; i++) {
    versionToIndex[dashboardVersions[i]] = i;
}

export function isDiskVersionAtLeast(version: DashboardVersion, diskVersion: DashboardVersion): boolean {
    return versionToIndex[diskVersion] >= versionToIndex[version];
}
