import type { SharedQueryV1 } from './1';
import type { SharedQueryV3 } from './3';

export type BaseQueryV4 = SharedQueryV1;

export function baseQueryV4Up(prev: SharedQueryV3): BaseQueryV4 {
    const { schema: _, ...shared } = prev;
    return shared;
}

export function baseQueryV4Down(prev: BaseQueryV4): SharedQueryV3 {
    return { ...prev, schema: [] };
}
