import type { Mutable } from '@kusto/utils';

import type { BasicParamV2, IParameterV2 } from '../parameter/2';
import type { IDashboardV12 } from './12';

export interface IDashboardV13 extends Omit<IDashboardV12, 'schema_version'> {
    schema_version: '13';
}

/**
 * kusto scope was sometimes deserializing int64 values to "number"
 */
function correctParameterCorruption(param: IParameterV2) {
    if (param.kind === 'int64') {
        if (param.selectionType !== 'freetext' && param.dataSource.kind === 'static') {
            for (const option of param.dataSource.values) {
                if (typeof option.value === 'number') {
                    (option as Mutable<BasicParamV2.DropdownStaticDataSource<string>['values'][number]>).value = String(
                        option.value
                    );
                }
            }
        }

        if (param.defaultValue.kind === 'value' && typeof param.defaultValue.value === 'number') {
            param.defaultValue.value = String(param.defaultValue.value);
        } else if (param.defaultValue.kind === 'values')
            for (let i = 0; i < param.defaultValue.values.length; i++) {
                const val = param.defaultValue.values[i];
                if (typeof val === 'number') {
                    param.defaultValue.values[i] = String(val);
                }
            }
    }
}

export function up(prev: IDashboardV12): IDashboardV13 {
    for (const param of prev.parameters) {
        correctParameterCorruption(param);
    }

    return { ...prev, schema_version: '13' };
}

export function down(prev: IDashboardV13): IDashboardV12 {
    return { ...prev, schema_version: '12' };
}
