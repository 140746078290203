import React from 'react';
import { Spinner } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { ColumnWithInsights, type DataProfileTimeRange } from '../../../../DataExplorationApi';
import { useStrings } from '../../../hooks/useStrings';
import { HighCardinalityInfo } from './HighCardinalityInfo';
import { SelectedColumnSampleValue } from './SampleView';
import { SelectedColumnValueDistribution } from './SelectedColumnValuesDistribution';

import styles from './SelectedColumnInfo.module.scss';

interface SelectedColumnColumnInfoProps {
    selectedColumn: ColumnWithInsights;
    title: React.ReactNode;
    queryText: string;
    timeRange: DataProfileTimeRange;
    timezone: string;
}

export const SelectedColumnInfo: React.FC<SelectedColumnColumnInfoProps> = observer(function SelectedColumnColumnInfo({
    selectedColumn,
    ...props
}) {
    const { strings } = useStrings();

    const isStillFetchingColumnsHighCardinality = selectedColumn.isHighCardinality === undefined;
    if (isStillFetchingColumnsHighCardinality) {
        return (
            <div className={styles.loadingIndicator}>
                <Spinner size="huge" label={strings.schemaInsights.loading} labelPosition="below" />
            </div>
        );
    }

    switch (selectedColumn.ColumnType) {
        case 'datetime': //TOD replace with binned time chart https://msazure.visualstudio.com/One/_workitems/edit/28347067/?workitem=28976386, until then fall-through for integer behavior
        case 'timespan': //TODO not defined by product, until then fall-through for integer behavior
        case 'real': //TODO replace with percentiles https://msazure.visualstudio.com/One/_workitems/edit/28972816 until then fall-through for integer behavior
        case 'decimal': //TODO replace with percentiles https://msazure.visualstudio.com/One/_workitems/edit/28972816 until then fall-through for integer behavior
        case 'int':
        case 'long':
            if (selectedColumn.isHighCardinality) {
                //TODO replace with percentiles https://msazure.visualstudio.com/One/_workitems/edit/28972816
                return <HighCardinalityInfo title={props.title} />;
            }
            return (
                <SelectedColumnValueDistribution
                    viewMode={selectedColumn.cardinality && selectedColumn.cardinality <= 5 ? 'pie' : 'bar'}
                    selectedColumn={selectedColumn}
                    {...props}
                />
            );

        case 'string':
        case 'guid':
            if (selectedColumn.isHighCardinality) {
                return <HighCardinalityInfo title={props.title} />;
            }
            return (
                <SelectedColumnValueDistribution
                    viewMode={selectedColumn.cardinality && selectedColumn.cardinality <= 5 ? 'pie' : 'bar'}
                    selectedColumn={selectedColumn}
                    {...props}
                />
            );
        case 'dynamic':
            return <SelectedColumnSampleValue selectedColumnName={selectedColumn.ColumnName} wordWrap {...props} />;
        case 'bool':
            return <SelectedColumnValueDistribution viewMode="bar" selectedColumn={selectedColumn} {...props} />;
    }
});
