import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import * as parameterV7 from '../parameter/7';
import * as queryV3 from '../query/3';
import * as tileV19 from '../tile/19';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV31 } from './31';

export interface IDashboardV32 {
    readonly schema_version: '32';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV19.ITileV19[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly queryV3.SharedQueryV3[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly parameterV7.IParameterV7[];
}

export function up(prev: IDashboardV31): IDashboardV32 {
    return {
        ...prev,
        schema_version: '32',
        sharedQueries: prev.sharedQueries.map(queryV3.up),
        parameters: prev.parameters.map(parameterV7.up),
    };
}

export function down(prev: IDashboardV32, addWarning: AddDashboardWarning): IDashboardV31 {
    return {
        ...prev,
        schema_version: '31',
        sharedQueries: prev.sharedQueries.map((q) => queryV3.down(q, addWarning)),
        parameters: prev.parameters.map(parameterV7.down),
    };
}
