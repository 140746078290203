import type { AddDashboardWarning } from '..';
import * as parameterV9 from '../parameter/9';
import type { IDashboardV42 } from './42';

export interface IDashboardV43 extends Omit<IDashboardV42, 'parameters' | 'schema_version'> {
    readonly schema_version: '43';
    readonly parameters: readonly parameterV9.IParameterV9[];
}

export function up(prev: IDashboardV42): IDashboardV43 {
    return {
        ...prev,
        schema_version: '43',
        parameters: prev.parameters.map(parameterV9.up),
    };
}

export function down(prev: IDashboardV43, addWarning: AddDashboardWarning): IDashboardV42 {
    return {
        ...prev,
        schema_version: '42',
        parameters: prev.parameters.map((p) => parameterV9.down(p, addWarning)),
    };
}
