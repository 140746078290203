import type { ITileV2, IVisualOptionsV2 } from './2';
import type { ITileV3 } from './3';

export interface IVisualOptionsV3 extends IVisualOptionsV2 {
    horizontalLine: string;
    verticalLine: string;
}

export interface ITileV4 extends Omit<ITileV2, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV3>;
}

export function up(prev: ITileV3): ITileV4 {
    return prev;
}
