import { formatLiterals, type Mutable } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { UDataSourceV5 } from '../dataSource/5';
import type { IDashboardV41 } from './41';

export interface IDashboardV42 extends Omit<IDashboardV41, 'schema_version' | 'dataSources'> {
    readonly schema_version: '42';
    readonly dataSources: readonly UDataSourceV5[];
}

export function up(prev: IDashboardV41, addWarning: AddDashboardWarning): IDashboardV42 {
    if (prev.dataSources.length === 0) {
        return { ...prev, schema_version: '42' } as IDashboardV42;
    }

    // "KustoWebQueryProvider" is a remanent of the initial version of RTD
    // and there shouldn't be any customer's using it any more.
    // We are removing the entire data source so if any customers
    // really need this then they can re-add the deleted data source
    // on their own.
    const removedDataSources: Mutable<IDashboardV41['dataSources']> = [];
    const nextDataSources = prev.dataSources.filter((ds) => {
        if (ds.scopeId === 'KustoWebQueryProvider') {
            removedDataSources.push(ds);
            return false;
        }
        return true;
    }) as IDashboardV42['dataSources'];

    if (removedDataSources.length) {
        removedDataSources.forEach((ds) => {
            addWarning((t) => formatLiterals(t.up.v42, { dsTitle: ds.name }));
        });
    }

    return {
        ...prev,
        schema_version: '42',
        dataSources: nextDataSources,
    };
}

export function down(prev: IDashboardV42): IDashboardV41 {
    return {
        ...prev,
        schema_version: '41',
    };
}
