import React from 'react';
import { Button } from '@fluentui/react-button';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';

import { ok, type Aborted, type KweUtilsStrings, type Ok } from '@kusto/utils';

import { useOnDialogDismissed } from '../hooks/useOnDismissed';
import { RenderHelper } from '../utils/RenderHelper';
import { PROMPT_OK_TEST_ID, usePromptStyles } from './Prompt';

export interface KweAlertProps extends Omit<KweAlertOptions, 'onResolved' | 'signal'> {
    title: React.ReactNode;

    t: KweUtilsStrings;
    resolve: () => void;
    onDismissed?: () => void;
}

/**
 * Designed primarily for use with {@link kweAlert}. Intentionally does _not_
 * support many options to keep implementation simple. If flexibility is needed,
 * copy+paste and customize.
 */
export const KweAlert: React.FC<KweAlertProps> = ({
    resolve,
    onDismissed,
    t,
    title,
    content,
    closeText,
    additionalActions,
    ...dialogProps
}) => {
    const { open, startClosing, ref } = useOnDialogDismissed(onDismissed);
    const classes = usePromptStyles();

    return (
        <Dialog
            open={open}
            modalType="alert"
            onOpenChange={(_event, data) => {
                if (!data.open) {
                    resolve();
                    startClosing();
                }
            }}
            {...dialogProps}
        >
            <DialogSurface backdrop={{ ref }}>
                <DialogBody>
                    <DialogTitle className={classes.title}>{title}</DialogTitle>
                    {content && <DialogContent>{content}</DialogContent>}
                    {/* Not sure why `fluid` isn't the default behavior */}
                    <DialogActions fluid>
                        <DialogTrigger>
                            <Button appearance="primary" data-testid={PROMPT_OK_TEST_ID}>
                                {closeText ?? t.util.buttons.close}
                            </Button>
                        </DialogTrigger>
                        {additionalActions}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export interface KweAlertOptions extends Omit<DialogProps, 'children'> {
    content?: React.ReactNode;
    closeText?: string;
    additionalActions?: React.ReactNode;
    /**
     * TODO(a11y): remove this once we upgrade to Fluent 9 across Dashboards
     */
    onResolved?: () => void;
    signal?: AbortSignal;
}

/**
 * A note on a11y: When using {@link kweAlert}, please check if the focus correctly moves to the Alert
 * and when the Alert is closed (de-rendered) the focus moves back to the original trigger element. If this
 * isn't happening automatically, then you can use the `onResolved` callback to manually move focus yourself.
 * Please use `onResolved` if needed so it will be easier for us to remove it later once we've fully upgraded
 * to Fluent 9 which _should_ solve these focus issues for us automatically.
 *
 * @example
 *
 * async function handleClick() {
 *   await kweAlert(..., {
 *     onResolved: () => {
 *       htmlDropdownRef.current.focus();
 *     }
 *   });
 *   // do more stuff
 * }
 */
export async function kweAlert(
    render: RenderHelper,
    t: KweUtilsStrings,
    title: React.ReactNode,
    options?: KweAlertOptions
): Promise<Ok<unknown> | Aborted> {
    return new Promise((resolve, reject) => {
        render(
            (unmount) => (
                <KweAlert
                    t={t}
                    title={title}
                    {...options}
                    resolve={() => {
                        options?.onResolved?.();
                        resolve(ok());
                    }}
                    onDismissed={unmount}
                />
            ),
            options?.signal
        ).then((res) => {
            if (res.kind === 'abort') {
                resolve(res);
            }
        }, reject);
    });
}
