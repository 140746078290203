import type { ITileV10 } from '../tile/10';
import * as tileV11 from '../tile/11';
import type { IDashboardV18 } from './18';

export interface IDashboardV19 extends Omit<IDashboardV18, 'schema_version' | 'tiles'> {
    schema_version: '19';
    tiles: readonly tileV11.ITileV11[];
}

export function up(prev: IDashboardV18): IDashboardV19 {
    return {
        ...prev,
        schema_version: '19',
        tiles: prev.tiles.map((t) => tileV11.up(t)),
    };
}

export function down(prev: IDashboardV19): IDashboardV18 {
    const tiles: readonly ITileV10[] = prev.tiles.map((t) => {
        return tileV11.down(t);
    });
    return {
        ...prev,
        tiles,
        schema_version: '18',
    };
}
