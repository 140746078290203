import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import * as parameterV4 from '../parameter/4';
import type { SharedQueryV0 } from '../query/0';
import * as tileV15 from '../tile/15';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV24 } from './24';

export interface IDashboardV25 {
    readonly schema_version: '25';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV15.ITileV15[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly SharedQueryV0[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly parameterV4.IParameterV4[];
}

export function up(prev: IDashboardV24): IDashboardV25 {
    return {
        ...prev,
        schema_version: '25',
        tiles: prev.tiles.map(tileV15.up),
        parameters: prev.parameters.map(parameterV4.up),
        sharedQueries: [],
    };
}

export function down(
    { sharedQueries: queries, ...prev }: IDashboardV25,
    addWarning: AddDashboardWarning
): IDashboardV24 {
    const sharedQueries = Object.fromEntries(queries.map((q) => [q.id, q]));

    for (const query of queries) {
        addWarning((t) => formatLiterals(t.down.v25.sharedQueryRemoved, { variableName: query.variableName }));
    }

    return {
        ...prev,
        schema_version: '24',
        tiles: prev.tiles.map((t) => tileV15.down(sharedQueries, t, addWarning)),
        parameters: prev.parameters.map((p) => parameterV4.down(sharedQueries, p, addWarning)),
    };
}
