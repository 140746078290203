import { getUniqueNameParts } from '../host/duplication';

const nameTypeToSeparator: Record<NameType, string> = {
    variable: '_',
    display: '-',
};

export type NameType = 'variable' | 'display';

/**
 * Given an input name, it will append a suffix counter to the name
 * if duplicates are found in {@link allNames}
 */
export function ensureNameIsUnique(name: string, allNames: Iterable<string>, type: NameType): string {
    const separator = nameTypeToSeparator[type];
    const uniqueNameParts = getUniqueNameParts({ name, allNames, separator });

    if (uniqueNameParts.suffix === undefined) {
        return uniqueNameParts.name;
    }

    return `${uniqueNameParts.name}${separator}${uniqueNameParts.suffix}`;
}
