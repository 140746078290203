import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import type { IParameterV3 } from '../parameter/3';
import type { ITileV13 } from '../tile/13';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV22 } from './22';

export interface IDashboardV23 {
    schema_version: '23';

    title: string;
    autoRefresh?: AutoRefreshConfigV1_2;

    tiles: readonly ITileV13[];
    pages: readonly IPageV3[];
    dataSources: readonly UDataSourceV4[];
    parameters: readonly IParameterV3[];
}

export function up(prev: IDashboardV22): IDashboardV23 {
    return {
        ...prev,
        schema_version: '23',
    };
}

export function down(prev: IDashboardV23, addWarning: AddDashboardWarning): IDashboardV22 {
    const dataSources: readonly UDataSourceV4[] = prev.dataSources;
    for (const singleDataSource of dataSources) {
        if (singleDataSource.kind === 'manual-kusto' && singleDataSource.queryResultsCacheMaxAge) {
            addWarning((t) =>
                formatLiterals(t.down.v23.queryResultCacheMaxAgeRemoved, { name: singleDataSource.name })
            );
            delete singleDataSource.queryResultsCacheMaxAge;
        }
    }

    return {
        ...prev,
        schema_version: '22',
        dataSources,
    };
}
