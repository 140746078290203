import type { QueryRefV5, QueryV5 } from '../query/5';
import { findQuery, QueryIndex } from '../versions/51';
import type { ITileV28 } from './28';

export interface ITileWithQueryV29 extends Omit<ITileV28, 'query'> {
    queryRef: QueryRefV5;
}

export interface ITileMarkdownV29 extends Omit<ITileV28, 'query' | 'visualType'> {
    visualType: 'markdownCard';
    markdownText: string;
    // Added so we can use this as a discriminate. We can't discriminate on
    // "markdownText" because the other types is "string". We can't _actually_
    // set this property because that will cause this tile to fail the ajv json
    // schema validation for unevaluated properties.
    queryRef?: undefined;
}

export type ITileV29 = ITileMarkdownV29 | ITileWithQueryV29;

export function up(prev: ITileV28, queries: QueryV5[]): ITileV29 {
    const queryId = crypto.randomUUID();

    const { query: _, ...prevWithoutQuery } = prev;

    if (prev.query.kind === 'ref') {
        return {
            ...prevWithoutQuery,
            queryRef: {
                kind: 'baseQuery',
                baseQueryId: prev.query.baseQueryId,
            },
        };
    } else {
        const { kind: _, ...queryWithoutKind } = prev.query;

        if (prev.visualType === 'markdownCard') {
            return {
                ...prevWithoutQuery,
                visualType: 'markdownCard',
                markdownText: prev.query.text,
            };
        }

        queries.push({
            ...queryWithoutKind,
            id: queryId,
        });

        return {
            ...prevWithoutQuery,
            queryRef: {
                kind: 'query',
                queryId,
            },
        };
    }
}

export function down(prev: ITileV29, queries: QueryIndex): ITileV28 {
    if (prev.queryRef === undefined) {
        // This must be a markdown tile
        const { markdownText, ...queryWithoutMarkdownText } = prev;

        return {
            ...queryWithoutMarkdownText,
            query: { kind: 'inline', text: markdownText, usedVariables: [], dataSource: undefined },
        };
    }
    const { queryRef, ...v28Tile } = prev;

    if (queryRef.kind === 'baseQuery') {
        return {
            ...v28Tile,
            query: {
                kind: 'ref',
                baseQueryId: queryRef.baseQueryId,
            },
        };
    } else {
        const query = findQuery(queryRef.queryId, queries);
        const { id: _, ...queryWithoutId } = query;

        return {
            ...v28Tile,
            query: { ...queryWithoutId, kind: 'inline' },
        };
    }
}
