import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { CanInferV1 } from './1';
import type { ColorRuleV2 as OldColorRuleVersion } from './2';
import type { ITileV18, IVisualOptionsV18 } from './18';
import type { ColorRulePreSchema } from './preSchema';

export interface ColorRuleV19 {
    readonly id: string;
    readonly ruleType: 'colorByValue' | 'colorByCondition';
    readonly ruleName: string;
    readonly applyTo: 'cells' | 'rows';
    readonly visualType: string;
}

export type ColorRuleThemeV19 = 'traffic-lights' | 'cold' | 'warm' | 'blue' | 'red' | 'yellow';

export type ColorRuleOperatorV19 = '>' | '>=' | '<' | '<=' | '==' | 'between';

export interface ColorRuleConditionV19 {
    readonly column: string | null;
    readonly operator: ColorRuleOperatorV19;
    readonly values: readonly string[];
}

export interface ColorRuleByValueV19 extends ColorRuleV19 {
    readonly ruleType: 'colorByValue';
    /**
     * themeName, maxValue and minValue are optional(nullable), and exist only if the selected column is numeric
     */
    readonly themeName: ColorRuleThemeV19 | null;
    readonly maxValue: string | null;
    readonly minValue: string | null;
    readonly column: string | null;
}

export interface ColorRuleByConditionV19 extends ColorRuleV19 {
    readonly ruleType: 'colorByCondition';
    readonly conditions: ColorRuleConditionV19[];
    readonly chainingOperator: 'and' | 'or';
    readonly colorStyle: ColorRulePreSchema.ColorStyle;
    readonly applyToColumn: string | null;
    readonly hideText: boolean;
    /**
     * color, tag and icon are optional(nullable), and exist only when "applyTo" equals "cells"
     */
    readonly color: ColorRulePreSchema.Color | null;
    readonly tag: string;
    readonly icon: ColorRulePreSchema.Icon | null;
}

export type UColorRuleV19 = ColorRuleByConditionV19 | ColorRuleByValueV19;

export interface IVisualOptionsV19 extends Omit<IVisualOptionsV18, 'colorRules'> {
    readonly colorRules: readonly UColorRuleV19[];
}

export interface ITileV19 extends Omit<ITileV18, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV19>;
}

const COLOR_INFERRABLE_TEXT = {
    red: 'Critical',
    yellow: 'Warning',
    green: 'Healthy',
    blue: 'Normal',
};

const ICON_INFERRABLE_TEXT = {
    critical: 'Critical',
    warning: 'Warning',
    completed: 'Completed',
    tentative: 'Tentative',
    happy: 'Happy',
    sad: 'Sad',
    emojiNeutral: 'Emoji neutral',
    circle: 'Circle',
    circleFill: 'Circle fill',
    contrast: 'Contrast',
    square: 'Square',
    squareSolid: 'Square solid',
    diamond: 'Diamond solid',
    octagon: 'Octagon',
    cloud: 'Cloud',
    tvMonitor: 'TV monitor',
    globe: 'Globe',
    strength: 'Strength',
    eatDrink: 'Eat/drink',
    cocktail: 'Cocktail',
    apple: 'Apple',
    repair: 'Repair',
    circleDollar: 'Circle dollar',
    rate: 'Rate',
    arrowUp: 'Up arrow',
    arrowDown: 'Down arrow',
    ferry: 'Ferry',
    car: 'Car',
    plane: 'Plane',
    baseball: 'Baseball',
    soccer: 'Soccer',
    basketball: 'Basketball',
    football: 'Football',
    tennis: 'Tennis',
    golf: 'Golf',
    world: 'World',
    heart: 'Heart',
    insights: 'Insights',
};

function convertInferrableStringToValue(inferrable: CanInferV1<string>, inferredValue?: string) {
    return inferrable.type === 'specified' ? inferrable.value : inferredValue ?? null;
}

function getTagFromOldRule(oldRule: OldColorRuleVersion) {
    let tag = '';
    if (oldRule.indicator.kind === 'icon') {
        tag =
            convertInferrableStringToValue(oldRule.indicator.label, ICON_INFERRABLE_TEXT[oldRule.indicator.icon]) ?? '';
    } else if (oldRule.indicator.kind === 'text') {
        tag = convertInferrableStringToValue(oldRule.indicator.text, COLOR_INFERRABLE_TEXT[oldRule.color]) ?? '';
    }
    return tag;
}

function convertOldRuleToNewVersion(
    oldRule: OldColorRuleVersion,
    index: number,
    visualType: string,
    colorStyle: ColorRulePreSchema.ColorStyle = 'bold'
): ColorRuleByConditionV19 {
    const column = convertInferrableStringToValue(oldRule.column);
    const conditions = oldRule.conditions.map((cond) => ({ column, operator: cond.operator, values: [cond.value] }));
    return {
        id: oldRule.id,
        ruleType: 'colorByCondition',
        ruleName: '',
        applyTo: 'cells',
        chainingOperator: 'and',
        colorStyle,
        hideText: false,
        applyToColumn: column,
        color: oldRule.color,
        tag: getTagFromOldRule(oldRule),
        icon: oldRule.indicator.kind === 'icon' ? oldRule.indicator.icon : null,
        conditions,
        visualType: visualType === 'card' || visualType === 'multistat' ? 'stat' : visualType,
    };
}

function canDowngradeRule(rule: UColorRuleV19): boolean {
    // Old version does not support colorByValue, and does not support empty color
    if (rule.ruleType === 'colorByValue' || !rule.color || rule.chainingOperator === 'or') {
        return false;
    }
    const ruleByCondition = rule as ColorRuleByConditionV19;
    if (rule.conditions[1] && rule.conditions[0].column !== rule.conditions[1].column) {
        return false;
    }
    if (
        rule.visualType === 'table' &&
        ruleByCondition.applyToColumn !== null &&
        rule.conditions[0].column !== ruleByCondition.applyToColumn
    ) {
        return false;
    }
    const badConditions = rule.conditions.filter((cond) => cond.operator === 'between');
    return badConditions.length === 0;
}

function downgradeConditions(rule: ColorRuleByConditionV19): ColorRulePreSchema.Condition[] {
    const downgradedConditions: ColorRulePreSchema.Condition[] = rule.conditions.map((conditionV2) => {
        const condition: ColorRulePreSchema.Condition = {
            operator: conditionV2.operator === 'between' ? '<=' : conditionV2.operator,
            value: conditionV2.values[0],
        };
        return condition;
    });
    return downgradedConditions;
}

export function up(prev: ITileV18): ITileV19 {
    const { colorRules: oldColorRules, colorStyle, ...otherOptions } = prev.visualOptions;
    let visualOptions: Partial<IVisualOptionsV19> = { ...otherOptions };
    if (oldColorRules) {
        const colorRules = oldColorRules.map((oldRule, index) => {
            return convertOldRuleToNewVersion(oldRule, index, prev.visualType, colorStyle);
        });
        visualOptions = {
            ...otherOptions,
            colorRules,
        };
    }

    return { ...prev, visualOptions };
}

export function down(prev: ITileV19, addWarning: AddDashboardWarning): ITileV18 {
    const { colorStyle: prevColorStyle, colorRules: prevColorRules, ...otherVisualOptions } = prev.visualOptions;
    let colorStyle: ColorRulePreSchema.ColorStyle = prevColorStyle ?? 'light';
    let colorRules: OldColorRuleVersion[] | undefined = undefined;

    let rulesRemoved = 0;

    if (prevColorRules) {
        // Filter out rules that cannot be downgraded
        const colorByConditionRules = prevColorRules.filter((rule) =>
            canDowngradeRule(rule)
        ) as ColorRuleByConditionV19[];
        rulesRemoved += prevColorRules.length - colorByConditionRules.length;
        colorStyle = colorByConditionRules[0]?.colorStyle ?? colorStyle;
        colorRules = colorByConditionRules.map((rule) => {
            // Convert the icon/tag to "indicator"
            let indicator: OldColorRuleVersion['indicator'] = { kind: 'none' };
            const label: CanInferV1<string> =
                rule.tag !== null ? { type: 'specified', value: rule.tag } : { type: 'infer' };
            if (rule.icon) {
                // We always have a color defined according to "ColorRulePreSchema" because we filter by "canDowngradeRule"
                indicator = { kind: 'icon', icon: rule.icon as ColorRulePreSchema.Icon, label };
            } else if (rule.tag) {
                indicator = { kind: 'text', text: label };
            }

            const downgradedConditions = downgradeConditions(rule);
            return {
                id: rule.id,
                conditions: downgradedConditions,
                indicator,
                // We always have a color defined according to "ColorRulePreSchema" because we filter by "canDowngradeRule"
                color: rule.color as ColorRulePreSchema.Color,
                column:
                    rule.conditions[0].column !== null
                        ? { type: 'specified', value: rule.conditions[0].column }
                        : { type: 'infer' },
            };
        });
    }
    if (rulesRemoved) {
        addWarning((t) =>
            formatLiterals(t.down.v31.conditionalFormattingDowngraded, {
                count: rulesRemoved.toString(),
                tileTitle: prev.title,
            })
        );
    }

    let visualOptions: Partial<IVisualOptionsV18> = { ...otherVisualOptions };
    if (colorRules) {
        visualOptions = { ...visualOptions, colorRules, colorStyle };
    }

    return {
        ...prev,
        visualOptions,
    };
}
