import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { QueryPropertyV0, SharedQueryV0 } from '../query/0';
import type { TileLayoutV2 } from './2';
import type { ITileV14, IVisualOptionsV14 } from './14';

export interface ITileV15 {
    readonly id: string;
    readonly title: string;
    readonly query: QueryPropertyV0;
    readonly layout: TileLayoutV2;
    readonly pageId: string;
    readonly visualType: string;
    readonly visualOptions: Partial<IVisualOptionsV14>;
}

export function up({ query, dataSourceId, usedParamVariables, ...shared }: ITileV14): ITileV15 {
    return {
        ...shared,
        query: {
            kind: 'inline',
            dataSourceId,
            usedVariables: usedParamVariables,
            text: query,
        },
    };
}

export function down(
    queries: Record<string, SharedQueryV0>,
    { query: prevQuery, ...shared }: ITileV15,
    addWarning: AddDashboardWarning
): ITileV14 {
    let dataSourceId: undefined | string;
    let usedParamVariables: readonly string[];
    let query: string;

    if (prevQuery.kind === 'inline') {
        dataSourceId = prevQuery.dataSourceId;
        usedParamVariables = prevQuery.usedVariables;
        query = prevQuery.text;
    } else {
        const sharedQuery = queries[prevQuery.sharedQueryId];

        if (sharedQuery) {
            addWarning((t) =>
                formatLiterals(t.down.v25.queryInlinedIntoTile, {
                    tileName: shared.title,
                    sharedQueryName: sharedQuery.variableName,
                })
            );
            dataSourceId = sharedQuery.dataSourceId;
            usedParamVariables = sharedQuery.usedVariables;
            query = sharedQuery.text;
        } else {
            addWarning((t) => formatLiterals(t.down.v25.unableToInlineTileQuery, { tileName: shared.title }));

            dataSourceId = undefined;
            usedParamVariables = [];
            query = '';
        }
    }

    return {
        ...shared,
        dataSourceId,
        usedParamVariables,
        query,
    };
}
