import { KweException } from '@kusto/utils';

/**
 * A shortcut to avoid needing to escape regex reserved characters in the pattern below.
 * What we see is what's passed to the RegExp constructor.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/raw
 */
const suffixPatternString = String.raw`(\d+)`;

function createSuffixRegex(
    separator: string,
    customizeSuffixPattern: GetUniqueNamePartsArgs['customizeSuffixPattern']
) {
    const suffixPattern = customizeSuffixPattern ? customizeSuffixPattern(suffixPatternString) : suffixPatternString;

    return new RegExp(`^(.*)${separator}${suffixPattern}$`);
}

function extractNameParts(name: string, nameWithSuffixRegex: RegExp): [baseName: string, suffix: undefined | number] {
    const matches = name.match(nameWithSuffixRegex);

    if (matches && matches.length > 2) {
        return [matches[1], parseInt(matches[2])];
    } else {
        return [name, undefined];
    }
}

export interface GetUniqueNamePartsArgs {
    /**
     * The target name to ensure is unique.
     */
    name: string;

    /**
     * All the names to compare against.
     */
    allNames: Iterable<string>;

    /**
     * The separator between the name and the suffix.
     * This cannot be an empty string. This could also be
     * a regex reserved character like \s or \t etc.
     *
     * @example '-', '_', ' '
     */
    separator: string;

    /**
     * Optional function to customize the suffix pattern that we match against
     * the input name. Be very careful of escaping regex reserved characters that
     * would get picked up by the regex expression (e.g. * or () or [] etc.)
     *
     * @example
     *
     * getUniqueNameParts({
     *  name: 'JOHN',
     *  allNames: ['ADAM', 'JOHN-wow1wow'],
     *  separator: '-',
     *  customizeSuffixPattern: (suffixPattern) => `wow${suffixPattern}wow`,
     * });
     * |> { name: JOHN, suffix: '2' }
     *
     * You need to escape regex reserved characters like so:
     * getUniqueNameParts({
     *  name: 'JOHN',
     *  allNames: ['ADAM', 'JOHN (1)'],
     *  separator: '-',
     *  customizeSuffixPattern: (suffixPattern) =>  String.raw`\(${suffixPattern}\)`,
     * });
     * |> { name: JOHN, suffix: '2' }
     */
    customizeSuffixPattern?: (suffixPattern: string) => string;
}

export interface GetUniqueNamePartsReturnType {
    name: string;
    suffix: undefined | number;
}

/**
 * Given an input name, it will create a suffix counter for duplicates
 * that are found in {@link allNames} if they exist.
 */
export function getUniqueNameParts({
    name,
    allNames,
    separator,
    customizeSuffixPattern,
}: GetUniqueNamePartsArgs): GetUniqueNamePartsReturnType {
    /**
     * We need a separator to differentiate the name from the suffix.
     * Otherwise it would be hard to tell the difference between
     * myname123 vs myname789. A user could deliberately name something myname789.
     */
    if (separator.length === 0) {
        throw new KweException('Separator cannot be an empty string');
    }

    const suffixRegex = createSuffixRegex(separator, customizeSuffixPattern);

    const [baseName, baseSuffix] = extractNameParts(name, suffixRegex);

    let newSuffix = baseSuffix;

    for (const fullName of allNames) {
        // `= 1` so we treat names with no suffix the same as suffix 1
        const [extractedName, suffix = 1] = extractNameParts(fullName, suffixRegex);

        if (extractedName === baseName && (newSuffix === undefined || suffix >= newSuffix)) {
            newSuffix = suffix + 1;
        }
    }

    return { name: baseName, suffix: newSuffix };
}
