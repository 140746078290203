import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import type { IParameterV7 } from '../parameter/7';
import type { SharedQueryV3 } from '../query/3';
import * as tileV23 from '../tile/23';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV35 } from './35';

export interface IDashboardV36 {
    readonly schema_version: '36';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV23.ITileV23[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly SharedQueryV3[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly IParameterV7[];
}

export function up(prev: IDashboardV35): IDashboardV36 {
    return {
        ...prev,
        schema_version: '36',
        tiles: prev.tiles.map(tileV23.up),
    };
}

export function down(prev: IDashboardV36, addWarning: AddDashboardWarning): IDashboardV35 {
    return {
        ...prev,
        schema_version: '35',
        tiles: prev.tiles.map((t) => tileV23.down(t, addWarning)),
    };
}
