import type React from 'react';
import type mobx from 'mobx';

import type { FeatureFlags } from '@kusto/app-common';
import type { DataFrame, Dispose, Locale, ReadonlyRecord } from '@kusto/utils';

import type { RtdPluginApi } from '../dashboardApi';
import type { QueryMessageLevel } from '../error';
import type { VisualFwkFeatureFlag } from '../featureFlags';
import type { InteractionValues } from '../interactions/target';
import type { OkQueryResult } from '../queryResult';
import type { VisualOptionKey, VisualOptions } from '../visualOptions/model';
import type { ColorRule, VisualOptionProperties } from '../visualOptions/property';
import type { VisualSelector } from './input';
import type { VisualConfigLayout } from './layout';

export interface VisualDisplayedResultsCounts {
    readonly displayedResults: number;
    readonly totalResults: number;
}

/**
 * Id used for error messages displayed on tiles
 *
 * Used for a11y. Needed for screen readers
 * so users can hear the error
 * messages present on the Chart.
 *
 * The id gets utilized in 2 places:
 *
 * 1. In Tile.tsx, where it's used so that the error
 * message container will be associated with the region.
 * This way when a screen reader reads the region, it
 * will also read the content of the error
 * message if it exists (e.g. a query fails).
 *
 * 2. In Chart.tsx, where the id for the ErrorSnackBar
 * is set to the same generated id so that the SR knows
 * to read it's contents when it reads the region.
 */
export type VisualHtmlErrorId = string;

export interface VisualMessageOptions {
    readonly actions?: ReadonlyArray<{ readonly key: string; readonly text: string; readonly onClick: () => void }>;
    readonly icon?: string;
}

export interface VisualMessageData {
    readonly level: QueryMessageLevel;
    readonly message: React.ReactNode;
    readonly title?: React.ReactElement | string;
    readonly options?: VisualMessageOptions;
}

export type VisualMessages = ReadonlyRecord<QueryMessageLevel, readonly VisualMessageData[]>;

export type VisualMessageFormatter = (message: VisualMessageData) => React.ReactElement;

/**
 * This interface defines the structure of Crosshair data
 * which is shared between different visuals in "visualization" package.
 * This is computed by the visual and shared with other visuals.
 */
export interface CrosshairData {
    readonly value: number | string | undefined;
    readonly callerId: string;
    readonly e: MouseEvent | undefined;
    readonly xAxisType: string | undefined;
}

export type SharedCrosshairData = mobx.IObservableValue<CrosshairData>;

export interface DataVisualPropsQueryResult {
    readonly dataFrame: DataFrame;
    /**
     * If the visual likely doesn't make sense with unsorted data, this can be
     * used to show a warning. Does _not_ indicate any particular column as
     * sorted
     *
     * If `undefined` data was not sortable. For example, it will be undefined if
     * it comes from a csv file.
     *
     * @see {@link OkQueryResult.sorted}
     */
    readonly sorted?: undefined | boolean;
}

/**
 * Dashboards-specific apis
 */
export interface DashboardVisualApi extends RtdPluginApi {
    /**
     * Does nothing if not parameter with provided id exists  or if the value
     * cannot be assigned to the parameter. For example, `BasicValue.All` will
     * not be applied to duration parameters.
     *
     * `undefined` if visual is render in a place where setting parameters is
     * not enabled. For example, the parameter query editor.
     *
     * @param value Result<React.ReactNode> causes a tooltip to render on the
     * parameter's pill with this.
     */
    crossFilter?: (values: InteractionValues) => void;

    readonly sharedCrosshair: SharedCrosshairData;

    /**
     * Drillthrough is essentially Cross-filter + a page change after successfully cross-filtering.
     * @param destinationPageId The target page id you want to change to after cross-filter successfully
     */
    drillthrough?: (values: InteractionValues, destinationPageId: string) => void;
}

export interface IDataVisualProps<C extends VisualOptionKey = VisualOptionKey, H = unknown> {
    heuristics: H;
    queryResult: OkQueryResult;
    visualType: string;

    /**
     * Use this to display rendering errors
     */
    formatMessage: VisualMessageFormatter;

    /**
     * Visual options are required because defaulting happens before it gets here.
     * This is to increase the odds that the same defaults will be used
     * everywhere. All options are set so we don't have to bother with generics.
     */
    visualOptions: VisualOptions<C>;

    /**
     * Sets the "<number> out of <number>" results displayed text on the card
     * info callout. Hides the text when called with null.
     */
    setResultCounts(info: VisualDisplayedResultsCounts | null): void;

    /**
     * Sets visual rendering message
     */
    addMessage(message: VisualMessageData): Dispose;

    isDarkTheme: boolean;
    /**
     * Time Zone value string like "America/Los_Angeles" or "Asia/Tokyo" or "UTC"
     * that follows the IANA format
     *
     * @see https://www.iana.org/time-zones
     *
     * @see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     */
    timeZone: string;
    locale: Locale;

    onLinkClick(href: string, newTab: boolean): void;

    /**
     * Dashboard specific interfaces
     */
    dashboard?: DashboardVisualApi;

    featureFlags: FeatureFlags<VisualFwkFeatureFlag>;
}

export interface TileSize {
    readonly width: number;
    readonly height: number;
}

export interface HeuristicsProps<C extends VisualOptionKey> {
    visualType: string;
    visualOptions: VisualOptions<C>;
    queryResult: undefined | OkQueryResult;
}

export type VisualConfigHeuristicsFnc<C extends VisualOptionKey, H = undefined> = (
    args: HeuristicsProps<C>,
    prev: undefined | H
) => H;

export interface ResolvedHeuristicsTypeConfig<C extends VisualOptionKey = VisualOptionKey, H = unknown> {
    /** Model keys to model default properties */
    readonly model: Pick<VisualOptionProperties, C>;
    /** If the visual is making decisions based on it's props, that logic can be moved here to be made available to input components */
    readonly heuristics: VisualConfigHeuristicsFnc<C, H>;
}

/**
 * Values in this can be loaded lazily
 */
export interface ResolvedVisualTypeConfig<C extends VisualOptionKey = VisualOptionKey, H = unknown>
    extends ResolvedHeuristicsTypeConfig<C, H> {
    readonly minimumSize: TileSize | ((options: Pick<VisualOptionProperties, C>) => TileSize);
    /**
     * Defaults to {@link ResolvedVisualTypeConfig.minimumSize} when undefined
     */
    readonly defaultSize?: TileSize | ((options: Pick<VisualOptionProperties, C>) => TileSize);
    readonly inputLayout?: VisualSelector<C, VisualConfigLayout<C, H>>;

    /**
     * ## css variables
     * --tile-padding: use negative margins with this value on left/right/bottom
     * to create a full-bleed tile
     */
    readonly Component: (props: IDataVisualProps<C, H>) => React.ReactElement | null;
}

/**
 * Includes all configuration related to a specific visual type
 */
export interface VisualTypeConfig<C extends VisualOptionKey = never, H = unknown> {
    readonly label: string;
    readonly iconName?: string;
    /**
     * Can be a promise to allow lazy loading
     */
    readonly config: ResolvedVisualTypeConfig<C, H> | (() => Promise<ResolvedVisualTypeConfig<C, H>>);
}

export interface ColorResolution {
    /**
     * The actual color values that the color should resolve to
     */
    color: string;
    /**
     * Whether text presented on this color should be inverted or not
     */
    invertText?: boolean;
}

export interface ThemeColorResolution {
    /**
     * The actual color values that the theme should resolve to
     */
    colors: string[];
}

export type ColorResolutionSet = ReadonlyRecord<ColorRule.Color, ColorResolution>;

/**
 * Possible theme color values for color rules (conditional formatting)
 */
export type ColorRuleThemeColorValues = ReadonlyRecord<ColorRule.Theme, ThemeColorResolution>;

/**
 * Contains all configuration related to visuals, and their inputs
 *
 * `'table'` visual type is required. For the moment, the type `'markdownCard'`
 * is reserved.
 *
 * Note: Because the host app can not add migration, we should avoid adding
 * options here that could cause breaking changes if updated. It is for this
 * reason that "visualOptionsColors" and "colorRulesIcons" are specified in
 * constants, and not defined here by the host app.
 */
export interface VisualConfig<TVisualType extends string = string> {
    /**
     * Determines which visual types appear in the visual type and chart format
     * dropdowns in the edit tile page visual options
     */
    readonly layout: ReadonlyArray<
        | TVisualType
        | {
              readonly label: string;
              readonly iconName?: string;
              readonly default: TVisualType;
              readonly variants: readonly TVisualType[];
          }
    >;

    // TODO: Figure out what's going on with type errors that occur if we remove
    // the generics
    //  eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly visuals: ReadonlyRecord<TVisualType, VisualTypeConfig<any, any>>;
}

export function visualConfig<C extends VisualOptionKey, H = unknown>(
    config: VisualTypeConfig<C, H>
): VisualTypeConfig<C, H> {
    return config;
}
