import type { ITileV2 } from './2';
import type { ITileV6, IVisualOptionsV6 } from './6';

export interface CrossFilterConfigV7 {
    readonly dimensionId: string;
    readonly parameterId: string;
}

export interface IVisualOptionsV7 extends IVisualOptionsV6 {
    readonly crossFilterDisabled: boolean;
    readonly crossFilter: null | CrossFilterConfigV7;
}

export interface ITileV7 extends Omit<ITileV2, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV7>;
}

export function up(prev: ITileV6): ITileV7 {
    return prev;
}

export function down(prev: ITileV7): { removedCrossFilter: boolean; data: ITileV6 } {
    const { crossFilterDisabled, crossFilter, ...visualOptions } = prev.visualOptions;

    const data: ITileV6 = { ...prev, visualOptions };
    const removedCrossFilter = crossFilter !== undefined;

    return { data, removedCrossFilter };
}
