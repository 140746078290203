import { downQueryProperty, upQueryProperty, type QueryPropertyV3 } from '../query/3';
import type { MapGeoTypeV10 } from './10';
import type { PieLabelOptionsV11 } from './11';
import type { CrossFilterConfigV12 } from './12';
import type { DrillthroughConfigV13 } from './13';
import type { HeatMapPaletteColorV17 } from './17';
import type { MultipleYAxesConfigV18 } from './18';
import type { TableRenderLinkConfigV21 } from './21';
import type { UColorRuleV23 } from './23';
import type { ITileV24 } from './24';
import type { ColorRulePreSchema } from './preSchema';

/**
 * Level set visual options so we don't need to go though every version to
 * understand what it is.
 *
 * The only change from the previous version is moving "hideTileTitle" from here
 * to the tile.
 *
 * This is also the first version that _doesn't_ match what's in
 * the visualConfig options 1:1. That schema now includes hideTitle and title
 * for the query area, which dashboards doesn't support.
 */
export interface IVisualOptionsV25 {
    readonly xColumn: null | string;
    readonly yColumn: null | string;
    readonly yColumns: null | readonly string[];
    readonly seriesColumns: null | readonly string[];

    readonly hideLegend: boolean;
    readonly hideDataLabels: boolean;
    readonly yAxisRight: boolean;

    readonly yAxisMinimumValue: null | number;
    readonly yAxisMaximumValue: null | number;

    readonly multipleYAxes: MultipleYAxesConfigV18;

    readonly xColumnTitle: string;
    readonly yColumnTitle: string;

    readonly horizontalLine: string;
    readonly verticalLine: string;

    readonly xAxisScale: 'linear' | 'log';
    readonly yAxisScale: 'linear' | 'log';
    readonly legendLocation: 'left' | 'right';

    readonly colorRulesDisabled: boolean;
    readonly colorRules: readonly UColorRuleV23[];
    readonly colorStyle: ColorRulePreSchema.ColorStyle;

    readonly crossFilterDisabled: boolean;
    readonly crossFilter: readonly CrossFilterConfigV12[];

    readonly drillthroughDisabled: boolean;
    readonly drillthrough: readonly DrillthroughConfigV13[];

    /* Line chart */
    /**
     * show/hide line specific indications (pinpoint) on the line
     */
    readonly line__hidePinpointTooltips: boolean;

    /* Map chart */
    readonly map__type: 'bubble';
    readonly map__bubbleFormat: 'dot' | 'bubble' | 'heatmap' | 'pieChart';
    readonly map__minBubbleSizeColumn: null | string;
    readonly map__latitudeColumn: null | string;
    readonly map__longitudeColumn: null | string;
    readonly map__labelColumn: null | string;
    readonly map__sizeColumn: null | string;
    readonly map__sizeDisabled: boolean;
    readonly map__geoType: MapGeoTypeV10;
    readonly map__geoPointColumn: null | string;

    readonly labelDisabled: boolean;
    readonly tooltipDisabled: boolean;

    /* Pie chart */
    readonly pie__label: readonly PieLabelOptionsV11[];
    readonly pie__tooltip: readonly PieLabelOptionsV11[];
    readonly pie__orderBy: 'none' | 'name' | 'size';
    readonly pie__kind: 'pie' | 'donut';
    readonly pie__TopNSlices: null | number;

    /* Multi stat chart */
    /**
     * vertical => single column
     * horizontal => single row
     */
    readonly multiStat__displayOrientation: 'vertical' | 'horizontal';
    readonly multiStat__textSize: 'small' | 'large' | 'auto';
    readonly multiStat__labelColumn: null | string;
    readonly multiStat__valueColumn: null | string;
    readonly multiStat__slot: {
        readonly width: number;
        readonly height: number;
    };

    /* Heat map chart */
    readonly heatMap__dataColumn: null | string;
    readonly heatMap__colorPaletteKey: HeatMapPaletteColorV17;

    /* Table */
    readonly table__enableRenderLinks: boolean;
    readonly table__renderLinks: readonly TableRenderLinkConfigV21[];

    /* Plotly */
    readonly plotly__version: '1' | '2';
}

export interface ITileV25 extends Omit<ITileV24, 'query' | 'visualOptions'> {
    readonly query: QueryPropertyV3;
    readonly visualOptions: Partial<IVisualOptionsV25>;
}

const canInferProperties = [
    'xColumn',
    'yColumn',
    'yColumns',
    'seriesColumns',
    'yAxisMinimumValue',
    'yAxisMaximumValue',
    'map__minBubbleSizeColumn',
    'map__latitudeColumn',
    'map__longitudeColumn',
    'map__labelColumn',
    'map__sizeColumn',
    'map__geoPointColumn',
    'multiStat__labelColumn',
    'multiStat__valueColumn',
    'heatMap__dataColumn',
];

export function up(prev: ITileV24): ITileV25 {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const visualOptions: Record<string, any> = { ...prev.visualOptions };

    for (const key of canInferProperties) {
        if (key in visualOptions) {
            visualOptions[key] = visualOptions[key].value ?? null;
        }
    }

    return {
        ...prev,
        query: upQueryProperty(prev.query),
        visualOptions,
    };
}

const CAN_INFER = { type: 'infer' } as const;

export function down(prev: ITileV25): ITileV24 {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const visualOptions: Record<string, any> = { ...prev.visualOptions };

    for (const key of canInferProperties) {
        if (key in visualOptions) {
            const value = visualOptions[key];
            visualOptions[key] = value === null ? CAN_INFER : { type: 'specified', value };
        }
    }

    return {
        ...prev,
        query: downQueryProperty(prev.query),
        visualOptions,
    };
}
