import { maybeMap } from '../../lib';
import type { ITileMarkdownV30, ITileV30 } from '../tile/30';
import type { DashboardV53 } from './53';

export interface DashboardV54 extends Omit<DashboardV53, 'schema_version'> {
    readonly schema_version: '54';
}

/**
 * Fix corruption on some markdown tiles
 *
 * Markdown tiles created via bad duplicate code have a `queryRef` property,
 * causing them to be treated as query tiles in some code paths, and as markdown
 * tiles in others.
 *
 * Written so very little work is done when it should be a no-op
 */
export function up(prev: DashboardV53): DashboardV54 {
    let deleteTheseQueries: undefined | Set<string>;

    const tiles = maybeMap(prev.tiles, (tile): undefined | ITileV30 => {
        if (tile.visualType === 'markdownCard' && tile.queryRef !== undefined) {
            if (tile.queryRef.kind === 'query') {
                deleteTheseQueries ??= new Set();
                deleteTheseQueries.add(tile.queryRef.queryId);
            }
            const { queryRef, ...rest } = tile as unknown as ITileMarkdownV30;
            return rest;
        }
    });

    const queries = !deleteTheseQueries ? prev.queries : prev.queries.filter((q) => !deleteTheseQueries!.has(q.id));

    return {
        ...prev,
        queries,
        tiles,
        schema_version: '54',
    };
}

export function down(prev: DashboardV54): DashboardV53 {
    return { ...prev, schema_version: '53' };
}
