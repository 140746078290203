import React from 'react';
import { Button } from '@fluentui/react-button';
import { makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';

import { Aborted, KweUtilsStrings, ok, Ok } from '@kusto/utils';

import { useOnDialogDismissed } from '../hooks/useOnDismissed';
import { RenderHelper } from '../utils/RenderHelper';

export const PROMPT_OK_TEST_ID = 'prompt-ok';
export const PROMPT_CANCEL_TEST_ID = 'prompt-cancel';

export const usePromptStyles = makeStyles({
    title: {
        // Fix issue with Fluent ui: https://github.com/microsoft/fluentui/issues/31839
        overflowWrap: 'break-word',
    },
});

export interface KwePromptProps extends Omit<KwePromptOptions, 'signal'> {
    title: string | React.ReactElement;

    t: KweUtilsStrings;
    resolve: (result: boolean) => void;
    onDismissed?: () => void;
}

/**
 * Designed primarily for use with {@link kwePrompt}. Intentionally does _not_
 * support many options to keep implementation simple. If flexibility is needed,
 * copy+paste and customize.
 */
export const KwePrompt: React.FC<KwePromptProps> = ({
    resolve,
    onDismissed,
    t,
    title,
    content,
    acceptText,
    cancelText,
    hideCancelButton,
    additionalActions,
    ...dialogProps
}) => {
    const { open, startClosing, ref } = useOnDialogDismissed(onDismissed);
    const classes = usePromptStyles();

    return (
        <Dialog
            open={open}
            modalType="alert"
            onOpenChange={(_event, data) => {
                if (!data.open) {
                    resolve(false);
                    startClosing();
                }
            }}
            {...dialogProps}
        >
            <DialogSurface backdrop={{ ref }}>
                <DialogBody>
                    <DialogTitle className={classes.title}>{title}</DialogTitle>
                    {content && <DialogContent>{content}</DialogContent>}
                    {/* Not sure why `fluid` isn't the default behavior */}
                    <DialogActions fluid>
                        {/* Not wrapped in `<DialogTrigger />` so it can `resolve(true)` */}
                        <Button
                            appearance="primary"
                            onClick={() => {
                                resolve(true);
                                startClosing();
                            }}
                            data-testid={PROMPT_OK_TEST_ID}
                        >
                            {acceptText ?? t.util.buttons.ok}
                        </Button>
                        {!hideCancelButton && (
                            <DialogTrigger>
                                <Button appearance="secondary" data-testid={PROMPT_CANCEL_TEST_ID}>
                                    {cancelText ?? t.util.buttons.cancel}
                                </Button>
                            </DialogTrigger>
                        )}
                        {additionalActions}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export interface KwePromptOptions extends Omit<DialogProps, 'children'> {
    content?: React.ReactNode;
    acceptText?: string;
    cancelText?: string;
    hideCancelButton?: boolean;

    additionalActions?: React.ReactNode;
    /**
     * TODO(a11y): remove this once we upgrade to Fluent 9 across Dashboards
     */
    onResolved?: () => void;

    signal?: AbortSignal;
}

/**
 * A note on a11y: When using {@link kwePrompt}, please check if the focus correctly moves to the Prompt
 * and when the Prompt is closed (de-rendered) the focus moves back to the original trigger element. If this
 * isn't happening automatically, then you can use the `onResolved` callback to manually move focus yourself.
 * Please use `onResolved` if needed so it will be easier for us to remove it later once we've fully upgraded
 * to Fluent 9 which _should_ solve these focus issues for us automatically.
 *
 * @example
 *
 * async function handleClick() {
 *   const res = await kwePrompt(..., {
 *     onResolved: () => {
 *       htmlDropdownRef.current.focus();
 *     }
 *   });
 *   // do more stuff
 * }
 */
export async function kwePrompt(
    render: RenderHelper,
    t: KweUtilsStrings,
    title: string | React.ReactElement,
    options?: KwePromptOptions
): Promise<Ok<boolean> | Aborted> {
    return new Promise((resolve, reject) => {
        render(
            (unmount) => (
                <KwePrompt
                    t={t}
                    title={title}
                    {...options}
                    resolve={(value) => {
                        options?.onResolved?.();
                        resolve(ok(value));
                    }}
                    onDismissed={unmount}
                />
            ),
            options?.signal
        ).then((res) => {
            if (res.kind === 'abort') {
                resolve(res);
            }
        }, reject);
    });
}
