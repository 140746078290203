import type { AddDashboardWarning } from '..';
import type { ITileV6 } from '../tile/6';
import * as tileV7 from '../tile/7';
import type { IDashboardV10 } from './10';

export interface IDashboardV11 extends Omit<IDashboardV10, 'schema_version' | 'tiles'> {
    schema_version: '11';
    tiles: readonly tileV7.ITileV7[];
}

export function up(prev: IDashboardV10): IDashboardV11 {
    return {
        ...prev,
        schema_version: '11',
        tiles: prev.tiles.map(tileV7.up),
    };
}

export function down(prev: IDashboardV11, addWarning: AddDashboardWarning): IDashboardV10 {
    const crossFilterRemovedFrom: string[] = [];

    const tiles: readonly ITileV6[] = prev.tiles.map((t) => {
        const res = tileV7.down(t);
        if (res.removedCrossFilter) {
            crossFilterRemovedFrom.push(res.data.title);
        }
        return res.data;
    });

    if (crossFilterRemovedFrom.length !== 0) {
        addWarning((t) => t.down.v11.removedCrossFilterConfig + crossFilterRemovedFrom.join(', '));
    }

    return { ...prev, schema_version: '10', tiles };
}
