import type { UDataSourceV2 } from '../dataSource/2';
import type { IParameterV2 } from '../parameter/2';
import type { ITileV7 } from '../tile/7';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV13 } from './13';

/** Removed backend generated properties that were getting saved unnecessarily */
export interface IDashboardV14 {
    schema_version: '14';

    title: string;
    pinnedParameters: string[];
    autoRefresh?: AutoRefreshConfigV1_2;

    pages: readonly IPageV3[];
    tiles: readonly ITileV7[];
    parameters: readonly IParameterV2[];
    dataSources: readonly UDataSourceV2[];
}

export function up(prev: IDashboardV13): IDashboardV14 {
    return {
        schema_version: '14',
        title: prev.title,
        pinnedParameters: prev.pinnedParameters,
        autoRefresh: prev.configuration?.autoRefresh,
        pages: prev.pages,
        tiles: prev.tiles,
        parameters: prev.parameters,
        dataSources: prev.dataSources,
    };
}

export function down({ autoRefresh, ...prev }: IDashboardV14): IDashboardV13 {
    return {
        ...prev,
        configuration: autoRefresh && { autoRefresh },
        schema_version: '13',
    };
}
