import type { PatchOne } from '../types';
import { clean, corrupted } from '../utils';

// "message":"must NOT have fewer than 1 characters",
// "instancePath":"/tiles/0/title"
// "schemaPath":"#/properties/title/minLength"
// "keyword":"minLength"
// "params":{"limit":1}
export const emptyTileTitleConfig: PatchOne<'tiles'> = {
    kind: 'patch-one',
    corruption(tile) {
        if (tile.title.length === 0) {
            return corrupted(undefined);
        }
        return clean;
    },
    patch(tile) {
        return {
            ...tile,
            title: ' ', // An empty string with a space in it is the best equivalent to an empty title
        };
    },
};
