import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '../..';
import type { ITileV26 } from './26';

export interface ITileV27 extends ITileV26 {
    description?: string;
}

export function up(prev: ITileV26): ITileV27 {
    return {
        ...prev,
        description: '',
    };
}

export function down(prev: ITileV27, addWarning: AddDashboardWarning): ITileV26 {
    const { description, ...v26Tile } = prev;

    if (description !== '') {
        addWarning((t) => formatLiterals(t.down.v41.tileDescriptionRemoved, { tileName: prev.title }));
    }

    return {
        ...v26Tile,
    };
}
