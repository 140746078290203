import type { BasicParameterTypesV2 } from '../value/2';
import type { DurationParamV3 } from './3';
import type { DataSourceParamV5 } from './5';
import type { IParameterV6, TBasicParamV6 } from './6';

export type UBasicParamV7 =
    | TBasicParamV6<BasicParameterTypesV2['string'], 'string'>
    | TBasicParamV6<BasicParameterTypesV2['int'], 'int'>
    | TBasicParamV6<BasicParameterTypesV2['long'], 'long'>
    | TBasicParamV6<BasicParameterTypesV2['real'], 'real'>
    | TBasicParamV6<BasicParameterTypesV2['decimal'], 'decimal'>
    | TBasicParamV6<BasicParameterTypesV2['bool'], 'bool'>
    | TBasicParamV6<BasicParameterTypesV2['datetime'], 'datetime'>;

export type IParameterV7 = UBasicParamV7 | DurationParamV3.IParam | DataSourceParamV5.IParam;

function updateParamTag<V, K2>(prev: TBasicParamV6<V, unknown>, newTag: K2): TBasicParamV6<V, K2> {
    return {
        ...prev,
        kind: newTag,
    };
}

export function up(prev: IParameterV6): IParameterV7 {
    if (
        prev.kind === 'dataSource' ||
        prev.kind === 'duration' ||
        prev.kind === 'bool' ||
        prev.kind === 'decimal' ||
        prev.kind === 'string' ||
        prev.kind === 'datetime'
    ) {
        return prev;
    }

    switch (prev.kind) {
        case 'int32':
            return updateParamTag(prev, 'int');
        case 'int64':
            return updateParamTag(prev, 'long');
        case 'float64':
            return updateParamTag(prev, 'real');
    }
}

export function down(prev: IParameterV7): IParameterV6 {
    if (
        prev.kind === 'dataSource' ||
        prev.kind === 'duration' ||
        prev.kind === 'bool' ||
        prev.kind === 'decimal' ||
        prev.kind === 'string' ||
        prev.kind === 'datetime'
    ) {
        return prev;
    }

    switch (prev.kind) {
        case 'int':
            return updateParamTag(prev, 'int32');
        case 'long':
            return updateParamTag(prev, 'int64');
        case 'real':
            return updateParamTag(prev, 'float64');
    }
}
