import * as parameterV2 from '../parameter/2';
import type { IDashboardV8 } from './8';

export interface IDashboardV9 extends Omit<IDashboardV8, 'schema_version' | 'parameters'> {
    schema_version: '9';
    parameters: readonly parameterV2.IParameterV2[];
}

export function up(prev: IDashboardV8): IDashboardV9 {
    return {
        ...prev,
        schema_version: '9',
        parameters: prev.parameters.map(parameterV2.up),
    };
}
