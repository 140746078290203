import type { IDataSourceV0 } from '../dataSource/0';
import type { IParameterV0 } from '../parameter/0';
import type { ITileV0 } from '../tile/0';
import type { IDashboardV0 } from './0';

export interface IDashboardV1 extends Omit<IDashboardV0, 'schema_version' | 'tiles' | 'dataSources' | 'parameters'> {
    schema_version: '1.0';
    parameters: readonly IParameterV0[];
    tiles: readonly ITileV0[];
    dataSources: readonly IDataSourceV0[];
}

export function up(prev: IDashboardV0): IDashboardV1 {
    return {
        // Add initial version numbers for existing children models
        ...prev,
        schema_version: '1.0',
        parameters:
            prev.parameters?.map((p) => ({
                $schema: '0',
                ...p,
            })) ?? [],
        tiles:
            prev.tiles?.map((t) => ({
                $schema: '0',
                ...t,
            })) ?? [],
        dataSources:
            prev.dataSources?.map((d) => ({
                $schema: '0',
                ...d,
            })) ?? [],
    };
}
