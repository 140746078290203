import React from 'react';
import { bundleIcon, DismissFilled, DismissRegular } from '@fluentui/react-icons';

import { TabItem } from '../Tabs.interfaces';
import { useTabsBarContext } from '../TabsContext/TabsBar.context';
import { ButtonTooltip } from '../TabsTooltip/TabsTooltip';

import styles from './CloseTabButton.module.scss';

const Dismiss = bundleIcon(DismissFilled, DismissRegular);

export const CloseTabButton: React.FunctionComponent<TabItem> = ({ tabId }) => {
    const context = useTabsBarContext();

    if (context.tabsList.length <= 1) {
        // don't show close button if there is only one tab
        return null;
    }

    return (
        <ButtonTooltip
            tooltip={context.strings.closeTab}
            onClick={(event) => {
                context.editingTab.setTabId(undefined);
                context.events.onTabClose(tabId);
                event.stopPropagation(); // prevent parent click
            }}
            icon={<Dismiss />}
            appearance="transparent"
            size="small"
            className={styles.closeTabButton}
            tabIndex={0}
        />
    );
};
