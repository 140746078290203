import { formatLiterals } from '@kusto/utils';

import type { PatchOne } from '../types';
import { clean, corrupted } from '../utils';

// {
//     "message": "must be string",
//     "instancePath": "/tiles/20/visualOptions/multipleYAxes/base/columns/0",
//     "schemaPath": "#/properties/visualOptions/properties/multipleYAxes/properties/base/properties/columns/items/type",
//     "keyword": "type",
//     "params": {
//       "type": "string"
//     }
// }

export interface Data {
    baseColumnsToKeep: string[];
    badBaseColumns: unknown[];
}

export const multipleYAxesBaseColumnNotStringConfig: PatchOne<'tiles', Data> = {
    kind: 'patch-one',
    corruption(tile) {
        const baseColumns = tile.visualOptions?.multipleYAxes?.base?.columns;
        const baseColumnsToKeep: string[] = [];
        const badBaseColumns: unknown[] = [];

        if (baseColumns?.length) {
            for (const col of baseColumns) {
                if (typeof col === 'string') {
                    baseColumnsToKeep.push(col);
                } else {
                    badBaseColumns.push(col);
                }
            }
        }

        return badBaseColumns.length > 0 ? corrupted({ baseColumnsToKeep, badBaseColumns }) : clean;
    },
    patch(tile, { baseColumnsToKeep, badBaseColumns }, addWarning) {
        // we need to ensure uniqueness among the columns because
        // it would be impossible in the UI to selected two columns of the same name
        const newBaseColumns = new Set<string>(baseColumnsToKeep);

        for (const value of badBaseColumns) {
            switch (typeof value) {
                case 'boolean':
                case 'number': {
                    const newValue = String(value);

                    if (!newBaseColumns.has(newValue)) {
                        newBaseColumns.add(newValue);
                        addWarning((t) =>
                            formatLiterals(t.up.v44Patches.tile.multipleYAxesBaseColumnNotString, {
                                oldValue: newValue, // technically the same, we just have to stringify for formatLiterals
                                newValue,
                            })
                        );
                    }

                    break;
                }
            }
        }

        return {
            ...tile,
            visualOptions: {
                ...tile.visualOptions,
                multipleYAxes: {
                    ...tile.visualOptions.multipleYAxes,
                    base: {
                        ...tile.visualOptions.multipleYAxes.base,
                        columns: Array.from(newBaseColumns),
                    },
                },
            },
        };
    },
};
