import type { ParameterFromV44, PatchOne } from '../types';
import { resultFrom } from '../utils';

// {
// "message":"must NOT have unevaluated properties",
// "instancePath":"/parameters/7",
// "schemaPath":"#/unevaluatedProperties",
// "keyword":"unevaluatedProperties",
// "params":{"unevaluatedProperty":"selectionType"}
// }
type KeysOfUnion<T> = T extends T ? keyof T : never;
type AllowedPropsByKind = {
    [Kind in ParameterFromV44['kind']]: Array<KeysOfUnion<Extract<ParameterFromV44, { kind: Kind }>>>;
};

const allowedPropsByKind: AllowedPropsByKind = {
    bool: [
        'allIsNull',
        'dataSource',
        'defaultValue',
        'description',
        'displayName',
        'id',
        'includeAllOption',
        'kind',
        'selectionType',
        'showOnPages',
        'variableName',
    ],
    dataSource: ['dataSource', 'defaultValue', 'description', 'displayName', 'id', 'kind', 'showOnPages'],
    datetime: [
        'allIsNull',
        'dataSource',
        'defaultValue',
        'description',
        'displayName',
        'id',
        'includeAllOption',
        'kind',
        'selectionType',
        'showOnPages',
        'variableName',
    ],
    decimal: [
        'allIsNull',
        'dataSource',
        'defaultValue',
        'description',
        'displayName',
        'id',
        'includeAllOption',
        'kind',
        'selectionType',
        'showOnPages',
        'variableName',
    ],
    duration: [
        'beginVariableName',
        'defaultValue',
        'description',
        'displayName',
        'endVariableName',
        'id',
        'kind',
        'showOnPages',
    ],
    int: [
        'allIsNull',
        'dataSource',
        'defaultValue',
        'description',
        'displayName',
        'id',
        'includeAllOption',
        'kind',
        'selectionType',
        'showOnPages',
        'variableName',
    ],
    long: [
        'allIsNull',
        'dataSource',
        'defaultValue',
        'description',
        'displayName',
        'id',
        'includeAllOption',
        'kind',
        'selectionType',
        'showOnPages',
        'variableName',
    ],
    real: [
        'allIsNull',
        'dataSource',
        'defaultValue',
        'description',
        'displayName',
        'id',
        'includeAllOption',
        'kind',
        'selectionType',
        'showOnPages',
        'variableName',
    ],
    string: [
        'allIsNull',
        'dataSource',
        'defaultValue',
        'description',
        'displayName',
        'id',
        'includeAllOption',
        'kind',
        'selectionType',
        'showOnPages',
        'variableName',
    ],
};

/**
 * Sets should be a little faster when searching for a property
 * @see https://stackoverflow.com/a/46190569
 *
 * Not initializing the original object with Set<T> because it
 * doesn't provide intellisense auto-complete for the property
 * names unlike Array<T> will.
 */
const allowedPropsSetByKind = Object.fromEntries(
    Object.entries(allowedPropsByKind).map(([key, arr]) => [key, new Set<string>(arr)])
);

export const unevaluatedPropertiesConfig: PatchOne<'parameters', string[]> = {
    kind: 'patch-one',
    corruption(parameter) {
        const unevaluatedProps: string[] = [];
        const allowedProps = allowedPropsSetByKind[parameter.kind];

        for (const prop of Object.keys(parameter)) {
            if (!allowedProps.has(prop)) {
                unevaluatedProps.push(prop);
            }
        }
        return resultFrom(unevaluatedProps);
    },
    patch(parameter, unevaluatedProps) {
        const patchedParameter = { ...parameter };

        for (const propToDelete of unevaluatedProps) {
            // @ts-expect-error Deleting an unknown prop is not type-safe but it's okay here
            delete patchedParameter[propToDelete];
        }

        return patchedParameter;
    },
};
