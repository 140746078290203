import { formatLiterals } from '@kusto/utils';

import type { PatchEntire } from '../types';
import { resultFrom, StableArrayDeleter } from '../utils';

/**
 * Deletes data sources where the "kind" property is missing. Without it, we have no idea how
 * to patch it since the "kind" is our discriminator for the shape of the data source.
 */
// {
//     "message":"\"dataSources\" requires the following properties to be defined: [\"id\", \"name\", \"scopeId\", \"kind\"]",
//     "instancePath":"/dataSources/0",
//     "schemaPath":"#/required",
//     "keyword":"required",
//     "params":{"missingProperty":"kind"}}]
// }

export interface Data {
    name: string;
    dsIndex: number;
}

export const missingKindPropConfig: PatchEntire<Data[]> = {
    kind: 'patch-entire',
    corruption(dashboard) {
        const data: Data[] = [];

        for (let i = 0; i < dashboard.dataSources.length; i++) {
            const ds = dashboard.dataSources[i];
            if (!ds.hasOwnProperty('kind')) {
                data.push({
                    dsIndex: i,
                    name: ds.name,
                });
            }
        }

        return resultFrom(data);
    },
    patch(dashboard, corruptionData, addWarning) {
        const patchedDataSources = [...dashboard.dataSources];
        const deleter = new StableArrayDeleter(patchedDataSources);

        for (const datum of corruptionData) {
            addWarning((t) => formatLiterals(t.up.v44Patches.dataSource.removeCorrupted, { dsTitle: datum.name }));
            deleter.delete(datum.dsIndex);
        }

        return {
            ...dashboard,
            dataSources: patchedDataSources,
        };
    },
};
