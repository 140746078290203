import type { DataSourceFromV44, PatchOne } from '../types';
import { resultFrom } from '../utils';

// {
// "message":"must NOT have unevaluated properties",
// "instancePath":"/dataSources/0",
// "schemaPath":"#/unevaluatedProperties",
// "keyword":"unevaluatedProperties",
// "params":{"unevaluatedProperty":"title"}
// }

type KeysOfUnion<T> = T extends T ? keyof T : never;
type AllowedPropsByKind = {
    [Kind in DataSourceFromV44['kind']]: Array<KeysOfUnion<Extract<DataSourceFromV44, { kind: Kind }>>>;
};

const allowedPropsByKind: AllowedPropsByKind = {
    'app-insights': ['application', 'id', 'kind', 'name', 'resourceGroup', 'scopeId', 'subscription'],
    'kusto-trident': ['clusterUri', 'database', 'id', 'kind', 'name', 'scopeId', 'workspace'],
    'log-analytics': ['id', 'kind', 'name', 'resourceGroup', 'scopeId', 'subscription', 'workspace'],
    'manual-kusto': ['clusterUri', 'database', 'id', 'kind', 'name', 'queryResultsCacheMaxAge', 'scopeId'],
};

/**
 * Sets should be a little faster when searching for a property
 * @see https://stackoverflow.com/a/46190569
 *
 * Not initializing the original object with Set<T> because it
 * doesn't provide intellisense auto-complete for the property
 * names unlike Array<T> will.
 */
const allowedPropsSetByKind = Object.fromEntries(
    Object.entries(allowedPropsByKind).map(([key, arr]) => [key, new Set<string>(arr)])
);

export const unevaluatedPropertiesConfig: PatchOne<'dataSources', string[]> = {
    kind: 'patch-one',
    corruption(dataSource) {
        const unevaluatedProps: string[] = [];
        const allowedProps = allowedPropsSetByKind[dataSource.kind];

        for (const prop of Object.keys(dataSource)) {
            if (!allowedProps.has(prop)) {
                unevaluatedProps.push(prop);
            }
        }

        return resultFrom(unevaluatedProps);
    },
    patch(dataSource, unevaluatedProps) {
        const patchedDataSource = { ...dataSource };

        for (const propToDelete of unevaluatedProps) {
            // @ts-expect-error Deleting an unknown prop is not type-safe but it's okay here
            delete patchedDataSource[propToDelete];
        }

        return patchedDataSource;
    },
};
