import type { ITileV2 } from '../tile/2';
import * as tileV2 from '../tile/2';
import type { IDashboardV3 } from './3';

export interface IDashboardV4 extends Omit<IDashboardV3, 'schema_version' | 'tiles'> {
    schema_version: '4';
    tiles: readonly ITileV2[];
}

/**
 * Updates tiles to v2
 */
export function up(prev: IDashboardV3): IDashboardV4 {
    return {
        ...prev,
        schema_version: '4',
        tiles: prev.tiles.map((t) => tileV2.up(t)),
    };
}
