import { assertNever } from '@kusto/utils';

import type { PatchOne } from '../types';
import { clean, corrupted } from '../utils';

// "instancePath":"/dataSources/0","schemaPath":"#/oneOf/0/required","keyword":"required","params":{"missingProperty":"clusterUri"}},
// "instancePath":"/dataSources/0","schemaPath":"#/oneOf/2/required","keyword":"required","params":{"missingProperty":"subscription"}},
const kindToMissingProp = {
    'manual-kusto': 'clusterUri',
    'kusto-trident': 'clusterUri',
    'log-analytics': 'subscription',
    'app-insights': 'subscription',
};

function getMissingPropToCheck(kind: keyof typeof kindToMissingProp): string {
    switch (kind) {
        case 'manual-kusto':
        case 'kusto-trident':
            return kindToMissingProp[kind];
        case 'log-analytics':
        case 'app-insights':
            return kindToMissingProp[kind];
        default:
            assertNever(kind);
    }
}

export const missingRequiredPropConfig: PatchOne<'dataSources', string> = {
    kind: 'patch-one',
    corruption(dataSource) {
        const propToCheck = getMissingPropToCheck(dataSource.kind);

        if (!dataSource.hasOwnProperty(propToCheck)) {
            return corrupted(propToCheck);
        }

        return clean;
    },
    patch(dataSource, missingProp) {
        return {
            ...dataSource,
            [missingProp]: '',
        };
    },
};
