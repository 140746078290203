import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '../index.ts';
import type { IVisualOptionsV28 } from './28';
import type { ITileMarkdownV29, ITileV29, ITileWithQueryV29 } from './29';

export interface IVisualOptionsV30 extends IVisualOptionsV28 {
    readonly anomalyColumns: null | readonly string[];
}

export interface ITileWithQueryV30 extends Omit<ITileWithQueryV29, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV30>;
}

export interface ITileMarkdownV30 extends Omit<ITileMarkdownV29, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV30>;
}

export type ITileV30 = ITileWithQueryV30 | ITileMarkdownV30;

export function down(prev: ITileV30, addWarning: AddDashboardWarning): ITileV29 {
    const { anomalyColumns, ...prevVisualOptions } = prev.visualOptions;
    const visualOptionsV28: Partial<IVisualOptionsV28> = prevVisualOptions;

    if (anomalyColumns && anomalyColumns.length > 0) {
        addWarning((t) => formatLiterals(t.down.v53.anomalyColumnsRemoved, { tileName: prev.title }));
    }

    return {
        ...prev,
        visualOptions: visualOptionsV28,
    };
}
