import * as parameterV1 from '../parameter/1-0';
import type { IDashboardV1 } from './1-0';

export interface IDashboardV1_1 extends Omit<IDashboardV1, 'parameters' | 'schema_version'> {
    schema_version: '1.1';
    parameters: readonly parameterV1.IParameterV1[];
    pinnedParameters: string[];
}

export const STATIC_DURATION_PARAMETER_ID = '00000000-0000-0000-0000-000000000001';

export function up(prev: IDashboardV1): IDashboardV1_1 {
    const parameters: parameterV1.IParameterV1[] = prev.parameters.map(parameterV1.up);

    if (prev.duration) {
        // Generate duration parameter
        const durationParam: parameterV1.IParameterV1 = {
            $schema: '1.0',
            type: 'duration',
            id: STATIC_DURATION_PARAMETER_ID,
            displayName: 'Time range',
            beginVariableName: 'startTime',
            endVariableName: 'endTime',
            defaultValue: {
                value: {
                    type: 'dynamic',
                    range: { count: 30, unit: 'minutes' },
                },
            },
        };

        // Insert in the front of the list, since there's no ordering at the moment
        parameters.unshift(durationParam);
    }

    const { refreshRate, selectedBinSize, useAppCredentials, ...rest } = prev;

    return {
        // Drop removed properties
        ...rest,
        schema_version: '1.1',
        parameters,
        // All parameters start as pinned
        pinnedParameters: parameters.map((p) => p.id),
    };
}
