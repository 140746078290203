import type { PatchEntire } from '../types';
import { clean, corrupted } from '../utils';

// {
//     "message":"must NOT have fewer than 1 items",
//     "instancePath":"/pages",
//     "schemaPath":"#/properties/pages/minItems",
//     "keyword":"minItems",
//     "params":{"limit":1}
// }
export const pagesHasZeroItemsConfig: PatchEntire = {
    kind: 'patch-entire',
    corruption(dashboard) {
        if (dashboard.pages.length === 0) {
            return corrupted(undefined);
        }

        return clean;
    },
    patch(dashboard, _, addWarning) {
        addWarning((t) => t.up.v44Patches.dashboard.emptyPages);

        return {
            ...dashboard,
            pages: [
                {
                    id: crypto.randomUUID(),
                    name: 'Page 1',
                },
            ],
        };
    },
};
