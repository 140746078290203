import type { AddDashboardWarning } from '..';
import type { UDataSourceV3 } from '../dataSource/3';
import type { IParameterV3 } from '../parameter/3';
import * as tileV12 from '../tile/12';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV20 } from './20';

export interface IDashboardV21 {
    schema_version: '21';

    title: string;
    autoRefresh?: AutoRefreshConfigV1_2;

    tiles: readonly tileV12.ITileV12[];
    pages: readonly IPageV3[];
    dataSources: readonly UDataSourceV3[];
    parameters: readonly IParameterV3[];
}

export function up(prev: IDashboardV20): IDashboardV21 {
    return {
        ...prev,
        schema_version: '21',
        tiles: prev.tiles.map(tileV12.up),
    };
}

export function down(prev: IDashboardV21, addWarning: AddDashboardWarning): IDashboardV20 {
    return {
        ...prev,
        schema_version: '20',
        tiles: prev.tiles.map((t) => tileV12.down(t, addWarning)),
    };
}
