import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { DashboardV51 } from './51';

/// Adds 1s and 10s to auto-refresh interval options

export type AutoRefreshUpdateIntervalV2 = '1s' | '10s' | '30s' | '1m' | '5m' | '15m' | '30m' | '1h' | '2h' | '1d';

export interface AutoRefreshConfigV2 {
    readonly enabled: boolean;
    readonly defaultInterval?: AutoRefreshUpdateIntervalV2;
    readonly minInterval?: AutoRefreshUpdateIntervalV2;
}

export interface DashboardV52 extends Omit<DashboardV51, 'schema_version' | 'autoRefresh'> {
    readonly schema_version: '52';
    readonly autoRefresh?: AutoRefreshConfigV2;
}

export function up(prev: DashboardV51): DashboardV52 {
    return { ...prev, schema_version: '52' };
}

export function down(prev: DashboardV52, warn: AddDashboardWarning): DashboardV51 {
    if (!prev.autoRefresh) {
        return { ...prev, autoRefresh: undefined, schema_version: '51' };
    }

    let { defaultInterval, minInterval } = prev.autoRefresh;

    if (defaultInterval === '1s' || defaultInterval === '10s') {
        const interval = defaultInterval;
        warn((t) => formatLiterals(t.down.v52.defaultInterval, { interval }));
        defaultInterval = '30s';
    }

    if (minInterval === '1s' || minInterval === '10s') {
        const interval = minInterval;
        warn((t) => formatLiterals(t.down.v52.minInterval, { interval }));
        minInterval = '30s';
    }

    return {
        ...prev,
        schema_version: '51',
        autoRefresh: {
            enabled: prev.autoRefresh.enabled,
            defaultInterval,
            minInterval,
        },
    };
}
