import { formatLiterals } from '@kusto/utils';

import type { PatchOne } from '../types';
import { clean, corrupted } from '../utils';

/**
 * As of v42, the max tile layout width is "42"
 * but we should make a snapshot of this value here
 * to avoid future changes to the constant messing up
 * this patch.
 * @see APP_CONSTANTS.dashboardPage.grid.columnCount
 */
const MAX_TILE_LAYOUT_WIDTH = 24;

// Example input:
// {
//     "id": "efc9daa8-68ce-481b-9756-2604168b8a8f",
//     "title": "Tile",
//     "visualType": "table",
//     "pageId": "b914ee21-36c4-450d-b764-c3cdabbd92fd",
//     "layout": {
//         "x": 9,
//         "y": 7,
//         "width": 18,
//         "height": 8
//     },
//     // ...
// }
//
// Schema error:
// {
//     "instancePath": "/tiles/15/layout",
//     "message": "The tile layout for `x + width` is greater than the max value (inclusive): 24. Please adjust \"layout.x\" and/or \"layout.width\"."
// }
export const layoutTooWideConfig: PatchOne<'tiles'> = {
    kind: 'patch-one',
    corruption(tile) {
        if (tile.layout.x + tile.layout.width > MAX_TILE_LAYOUT_WIDTH) {
            return corrupted(undefined);
        }
        return clean;
    },
    patch(tile, _, addWarning) {
        const { x, width } = tile.layout;
        // We should adjust the tile width to fit the max width
        // so that the tile still stays in the same x position.
        // This way the change won't be as visually impactful
        // if we did it the other way around
        const difference = Math.abs(x + width - MAX_TILE_LAYOUT_WIDTH);
        const newWidth = width - difference;
        addWarning((t) => formatLiterals(t.up.v44Patches.tile.layoutTooWide, { tileTitle: tile.title }));

        return {
            ...tile,
            layout: {
                ...tile.layout,
                width: newWidth,
            },
        };
    },
};
