import React, { useCallback, useMemo } from 'react';
import {
    Menu,
    MenuDivider,
    MenuItem,
    MenuItemProps,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import {
    ArrowUndoFilled,
    ArrowUndoRegular,
    bundleIcon,
    DismissFilled,
    DismissRegular,
    TabsFilled,
    TabsRegular,
} from '@fluentui/react-icons';

import { OverflowTooltip } from '../../OverflowTooltip';
import { CloseTabButton } from '../CloseTabButton/CloseTabButton';
import { useTabsBarContext } from '../TabsContext/TabsBar.context';
import { ButtonTooltip } from '../TabsTooltip/TabsTooltip';

import styles from './TabsMenu.module.scss';

const ArrowUndo = bundleIcon(ArrowUndoFilled, ArrowUndoRegular);
const Dismiss = bundleIcon(DismissFilled, DismissRegular);
const TabsIcon = bundleIcon(TabsFilled, TabsRegular);

export const TabsMenu: React.FunctionComponent = () => {
    const context = useTabsBarContext();

    const menuButtons = useMemo(() => {
        // Top buttons are optional render on if callback provided - reopenClosedTab/ closeAllTabs
        const menuButtons: JSX.Element[] = [];

        if (context.events.onClickReopenClosedTab) {
            menuButtons.push(
                <MenuItem
                    key="btn-reopen-closed"
                    aria-label={context.strings.reopenClosedTab}
                    icon={<ArrowUndo />}
                    onClick={context.events.onClickReopenClosedTab}
                >
                    {context.strings.reopenClosedTab}
                </MenuItem>
            );
        }
        if (context.events.onClickCloseOtherTabs) {
            menuButtons.push(
                <MenuItem
                    key="btn-close-others"
                    aria-label={context.strings.closeOtherTabs}
                    icon={<Dismiss />}
                    disabled={context.tabsList.length <= 1}
                    onClick={context.events.onClickCloseOtherTabs}
                >
                    {context.strings.closeOtherTabs}
                </MenuItem>
            );
        }
        if (context.events.onClickCloseAllTabs) {
            menuButtons.push(
                <MenuItem
                    key="btn-close-all"
                    icon={<></>}
                    aria-label={context.strings.closeAllTabs}
                    disabled={context.tabsList.length <= 1}
                    onClick={context.events.onClickCloseAllTabs}
                >
                    {context.strings.closeAllTabs}
                </MenuItem>
            );
        }

        if (menuButtons.length) {
            menuButtons.push(<MenuDivider key="divider" />);
        }

        return menuButtons;
    }, [context]);

    const onClickOpenMenu = useCallback(() => {
        context.editingTab.setTabId(undefined);
    }, [context]);

    return (
        <Menu>
            <ButtonTooltip
                tooltip={context.strings.tabMenu}
                onClick={onClickOpenMenu}
                icon={<TabsIcon />}
                appearance="subtle"
                className={styles.menuTriggerButton}
                Container={MenuTrigger as React.FunctionComponent}
            >
                {context.tabsList.length}
            </ButtonTooltip>

            <MenuPopover className={styles.menuItemsList}>
                <MenuList>
                    {menuButtons}
                    {context.tabsList.map((tabItem, index) => {
                        const isSelected = tabItem.tabId === context.selectedTabId;
                        return (
                            <MenuItem
                                key={index}
                                aria-label={tabItem.label}
                                className={isSelected ? styles.menuItemSelected : ''}
                                aria-selected={isSelected}
                                icon={
                                    tabItem.icon ? (
                                        <tabItem.icon filled={isSelected} className={styles.menuItemIcon} />
                                    ) : undefined
                                }
                                secondaryContent={<CloseTabButton {...tabItem} />}
                                onClick={() => context.events.onTabSelect(tabItem.tabId)}
                                content={{ className: styles.menuItem } as MenuItemProps['content']}
                            >
                                <OverflowTooltip>{tabItem.label}</OverflowTooltip>
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
