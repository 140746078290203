import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import type { IParameterV7 } from '../parameter/7';
import type { SharedQueryV3 } from '../query/3';
import * as tileV21 from '../tile/21';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV33 } from './33';

export interface IDashboardV34 {
    readonly schema_version: '34';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV21.ITileV21[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly SharedQueryV3[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly IParameterV7[];
}

export function up(prev: IDashboardV33): IDashboardV34 {
    return {
        ...prev,
        schema_version: '34',
        tiles: prev.tiles.map((t) => tileV21.up(t)),
    };
}

export function down(prev: IDashboardV34, addWarning: AddDashboardWarning): IDashboardV33 {
    return {
        ...prev,
        schema_version: '33',
        tiles: prev.tiles.map((t) => tileV21.down(t, addWarning)),
    };
}
