import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import type { IParameterV7 } from '../parameter/7';
import type { SharedQueryV3 } from '../query/3';
import * as tileV20 from '../tile/20';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV32 } from './32';

export interface IDashboardV33 {
    readonly schema_version: '33';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV20.ITileV20[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly SharedQueryV3[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly IParameterV7[];
}

export function up(prev: IDashboardV32): IDashboardV33 {
    return {
        ...prev,
        schema_version: '33',
        tiles: prev.tiles.map((t) => tileV20.up(t)),
    };
}

export function down(prev: IDashboardV33, addWarning: AddDashboardWarning): IDashboardV32 {
    return {
        ...prev,
        schema_version: '32',
        tiles: prev.tiles.map((t) => tileV20.down(t, addWarning)),
    };
}
