import { createPatcherForArrayLikeProperty } from '../utils';
import { defaultValuesTypeNotAllowedConfig } from './defaultValuesTypeNotAllowed';
import { paramNameAlreadyExistsForDurationConfig } from './paramNameAlreadyExistsForDuration';
import { unevaluatedPropertiesConfig } from './unevaluatedProperties';

const parameterPatches = [
    defaultValuesTypeNotAllowedConfig,
    paramNameAlreadyExistsForDurationConfig,
    unevaluatedPropertiesConfig,
];

export const patchParameters = createPatcherForArrayLikeProperty('parameters', parameterPatches);
