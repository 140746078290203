import * as React from 'react';
import { IContextualMenuItem, MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import momentTimezone from 'moment-timezone';

import { ArgumentColumnType, ColumnType, getDataItemsAndMetaData, VisualizationOptions } from '@kusto/charting';
import * as kusto from '@kusto/client';
import { assertNever, err, formatLiterals, IKweTelemetry, ok, Theme } from '@kusto/utils';
import type { VisualMessageFormatter } from '@kusto/visual-fwk';

import type { StatCardChartProps, VisualizationsLocale } from '..';
import { Highchart, HighchartProps, HighChartWithPanels, useShouldRecreateChartObject } from '../highcharts';
import {
    createHighchartsOptions,
    getMetricSeriesMap,
    getYAxisSeriesMapForPanels,
    getYColumnsNumber,
    isSingleMetric,
} from '../highcharts/createHighchartsOptions';
import { shouldShowMultiplePanels } from '../highcharts/highChartOptionsUtils';
import { HighChartPanelsWithInteractiveLegend } from '../highcharts/HighChartPanelsWithInteractiveLegend.tsx';
import { HighChartWithInteractiveLegend } from '../highcharts/HighChartWithInteractiveLegend.tsx';
import { KustoAzureMap } from '../map/KustoAzureMap';
import {
    ChartError,
    ChartProps,
    Columns,
    DataItemWithRow,
    InternalChartProps,
    KustoHeuristicsOk,
    KustoHeuristicsResult,
    Rows,
} from '../types';
import { ChartColors, getChartColors } from '../utils/getChartColors';
import { dataFrameFromVColumnsRows } from '../utils/legacyTableConversions';
import { ExtendedVisualizationOptions, ExtendedVisualizationType } from '../utils/visualization';
import { StatCardChart } from './StatCard/StatCard';

import styles from './Chart.module.scss';

window.moment = momentTimezone;

export interface ChartMessageRendererAction {
    readonly key: string;
    readonly text: string;
    readonly onClick: () => void;
}

export interface ChartEvents {
    presentingError: (error: ChartError, visualization: string) => void;
    presentingChart: (rowsCount: number, visualization: string) => void;
    /**
     * the charting library will call this function when it displays less results than what the data has.
     * a typical case for this is multistat visualization that has room for up until 9 results, but the query may return with more.
     * This callback enables the hosting application to display some kind of notification to the user about this.
     */
    setResultCounts: (info: { displayedResults: number; totalResults: number } | null) => void;

    onPointClick: (row: kusto.KustoQueryResultRowObject) => void;
    /** Fires when the 'contextmenu' event is fired on a point */
    onHighchartsPointMenuItems: (row: kusto.KustoQueryResultRowObject) => IContextualMenuItem[];
    onHighChartsDragXEnd: (min: number, max: number) => void;
    /**
     * Callback for setting {@link ChartVisualProps.ignoreChartLimits} to true
     *
     * @see {@link ChartVisualProps.ignoreChartLimits}
     */
    disableChartLimits: () => void;
}

/**
 * Runs heuristics for options available with kusto's "render" command
 */
export function kustoHeuristics(
    columns: Columns,
    rows: Rows,
    visualType: Exclude<ExtendedVisualizationType, null>,
    visualizationOptions: ExtendedVisualizationOptions,
    t: VisualizationsLocale,
    telemetry: IKweTelemetry
): KustoHeuristicsResult {
    if (!rows || !columns) {
        return err({
            type: 'ServerSideError',
            message: formatLiterals(t.visualizations.errors.noElements, {
                elements: rows ? t.visualizations.lowercaseColumns : t.visualizations.lowercaseRows,
            }),
        });
    }

    if (rows.length === 0) {
        return err({
            type: 'NoRows',
            title: t.visualizations.errors.noResult.title,
            message: t.visualizations.errors.noResult.message,
        });
    }

    const res = getDataItemsAndMetaData(
        columns.map((col) => {
            // Work around the fact that control commands use v1 endpoint and return types in
            // a different field and format
            const colType = col.columnType || (col.dataType && kusto.v1TypeToKustoType[col.dataType]);

            return {
                ColumnName: col.field,
                ColumnType: colType as ColumnType,
            };
        }),
        rows,
        // Cast must succeed due to check above
        visualizationOptions as VisualizationOptions,
        telemetry
    );

    if (res.kind === 'err') {
        return err({ type: 'DotNetLibraryHeuristicReturnedError', message: res.err(t) });
    }

    // Different, broader, type for dataItems, so we can add the "row" property
    const dataItems: DataItemWithRow[] | undefined = res.value.items;
    const argumentType: ArgumentColumnType | undefined = res.value.argumentType;
    const metaData: Kusto.Charting.IChartMetaData | undefined = res.value.metaData ?? undefined;

    if (!dataItems || (Array.isArray(dataItems) && dataItems.length === 0)) {
        return err({
            type: 'DotNetLibraryHeuristicReturnedNoData',
            message: formatLiterals(t.visualizations.errors.noDraw, {
                visualizationType: visualType,
            }),
        });
    }

    // If each data items is associated with a specific row, add a pointer to
    // that row to the data item
    if (metaData && !metaData?.IsDataFormedAsSeries) {
        const totalColumnsPerRow = dataItems.length / rows.length;

        for (let i = 0; i < dataItems.length; i++) {
            /**
             * Edge case: the number of rows is different
             * vs the number of dataItems (e.g. 5 vs 15)
             *
             * In this edge case, we need to calculate the
             * correct row index since it _seems_ there is
             * a guarantee the ordering of the kusto
             * results reflects in the query as well.
             *
             * @example
             *
             * ```
             * // This is a situation where the edge-case occurs
             * // rows = 3
             * // dataItems = 9
             * datatable(x: int, blueY:int, greenY: int) [
             *     2, 2, 4,
             *     1, 1, 2,
             *     3, 3, 6,
             * ]
             * | render linechart
             * ```
             */
            const rowIndex = Math.floor(i / totalColumnsPerRow);
            dataItems[i].row = rows[rowIndex];
        }
    }

    /**
     * Scatterchart with Map can handle
     * visualizing when columns.length < 2
     */
    if (columns.length < 2 && visualType !== 'scatterchart' && visualizationOptions.Kind !== 'map') {
        return err({
            type: 'LessThen2Columns',
            message: formatLiterals(t.visualizations.errors.minColumns, {
                minNumberOfColumns: (2).toLocaleString(),
                visualizationType: visualType,
            }),
        });
    }

    if (shouldShowMultiplePanels(visualizationOptions)) {
        const maxNumberOfPanels = 5;
        const actualNumberOfPanels = isSingleMetric(argumentType, dataItems)
            ? Object.keys(getMetricSeriesMap(argumentType, dataItems)).length
            : getYColumnsNumber(argumentType, dataItems);
        if (actualNumberOfPanels > maxNumberOfPanels) {
            return err({
                type: 'MultiplePanelsLimitationExceeded',
                message: formatLiterals(t.visualizations.errors.multiplePanelsLimitationExceeded, {
                    maxNumberOfPanels: maxNumberOfPanels.toLocaleString(),
                    actualNumber: actualNumberOfPanels.toLocaleString(),
                }),
            });
        }
    }

    return ok({
        dataItems,
        metaData,
        argumentType,
    });
}

interface HighchartWithShouldRecreateProps extends Omit<HighchartProps, 'shouldRecreateChartObject'> {
    visualizationOptions: ExtendedVisualizationOptions;
}

const HighchartWithShouldRecreate: React.FC<HighchartWithShouldRecreateProps> = React.forwardRef(
    function HighchartWithShouldRecreate(props, ref) {
        const shouldRecreateChartObject = useShouldRecreateChartObject(props);

        return <Highchart {...props} ref={ref} shouldRecreateChartObject={shouldRecreateChartObject} />;
    }
);

export interface HighchartsAndMapChartProps extends Omit<ChartProps, 'rows' | 'columns'> {
    Visualization: Exclude<ExtendedVisualizationType, null | 'card' | 'multistat'>;
    heuristics: KustoHeuristicsOk;
    formatMessage: VisualMessageFormatter;
    events: Partial<ChartEvents>;
}

/**
 * Renders highcharts and map visuals when heuristics have already been resolved
 */
export const HighchartsAndMapChart: React.FC<HighchartsAndMapChartProps> = (props) => {
    const {
        heuristics,
        visualizationOptions,
        theme,
        useBoost,
        disableAnimation,
        showAllSeriesOnHover,
        strings,
        events: {
            onPointClick,
            onHighchartsPointMenuItems,
            onHighChartsDragXEnd,
            disableChartLimits: disableHighchartLimits,
            ...events
        },
        ignoreChartLimits,
        azureMapSubscriptionKey,
        timezone,
        Visualization,
        chartTitle,
        formatMessage,
    } = props;

    const { colors, tooltipStyle, tickStyle }: ChartColors = getChartColors(theme);
    const animationDuration = disableAnimation ? 0 : 500;

    const internalProps: InternalChartProps = {
        colors,
        animationDuration,
        visualizationOptions,
        tooltipStyle,
        tickStyle,
        isDarkTheme: theme === Theme.Dark,
        highPerformanceMode: useBoost,
        showAllSeriesOnHover,
        azureMapSubscriptionKey,
        locale: props.locale,
        legendPosition: props.legendPosition,
        enableInteractiveLegend: props.enableInteractiveLegend,
        enableSharedCrosshair: props.enableSharedCrosshair,
        sharedCrosshair: props.sharedCrosshair,
        timezone: timezone,
        onPointClick,
        onHighchartsPointMenuItems,
        disableChartLimits: disableHighchartLimits,
        onHighChartsDragXEnd,
        chartTitle,
        formatMessage,
        strings: strings.visualizations,
        ignoreChartLimits,
        dataItemsLength: heuristics.dataItems.length,
    };
    const options = createHighchartsOptions(internalProps, heuristics);
    const highchartsProps: InternalChartProps & HighchartWithShouldRecreateProps = { ...internalProps, options };

    switch (Visualization) {
        case 'barchart':
        case 'columnchart':
        case 'timechart':
        case 'linechart':
        case 'areachart':
        case 'heatmap':
            return renderChart(highchartsProps, heuristics);
        case 'piechart':
            if (visualizationOptions.Kind === 'map') {
                return (
                    <KustoAzureMap
                        {...internalProps}
                        dataItems={heuristics.dataItems}
                        strings={strings.visualizations}
                    />
                );
            }

            return renderChart(highchartsProps, heuristics);
        case 'anomalychart':
            if (!visualizationOptions.AnomalyColumns) {
                const message = formatLiterals(strings.visualizations.errors.anomalycolumns, {
                    example:
                        'T | extend anom = series_decompose_anomalies(sum_y, 3.0)' +
                        '| render anomalychart with(anomalycolumns =anom)',
                });
                return formatMessage({ message, level: 'error' });
            }
            return renderChart(highchartsProps, heuristics);
        case 'stackedareachart':
            return (
                <HighchartWithShouldRecreate
                    {...internalProps}
                    dataItemsLength={heuristics.dataItems.length}
                    options={options}
                />
            );
        case 'scatterchart':
            if (visualizationOptions.Kind === 'map') {
                return (
                    <KustoAzureMap
                        {...internalProps}
                        dataItems={heuristics.dataItems}
                        strings={strings.visualizations}
                    />
                );
            }
            return renderChart(highchartsProps, heuristics);
        case 'ladderchart':
        case 'pivotchart':
        case 'timeline':
        case 'timepivot':
        case 'table':
        case 'map':
        case 'plotly':
            events?.presentingError?.('UnknownVisualization', Visualization);
            return formatMessage({
                message: formatLiterals(strings.visualizations.errors.unknownVisualization, {
                    visualizationType: Visualization,
                }),
                level: 'error',
            });
        default:
            assertNever(Visualization);
    }
};

function renderChart(
    highChartProps: InternalChartProps & HighchartWithShouldRecreateProps,
    heuristics: KustoHeuristicsOk
) {
    const { visualizationOptions } = highChartProps;
    const splitPanels = shouldShowMultiplePanels(visualizationOptions);
    const yAxesSeriesMap = splitPanels ? getYAxisSeriesMapForPanels(highChartProps, heuristics) : {};
    const horizontalView = visualizationOptions.Visualization === 'piechart';

    const showInteractiveLegend = highChartProps.enableInteractiveLegend;

    return splitPanels ? (
        showInteractiveLegend ? (
            <HighChartPanelsWithInteractiveLegend
                {...highChartProps}
                yAxesSeriesMap={yAxesSeriesMap}
                horizontalView={horizontalView}
            />
        ) : (
            <HighChartWithPanels {...highChartProps} yAxesSeriesMap={yAxesSeriesMap} horizontalView={horizontalView} />
        )
    ) : showInteractiveLegend ? (
        <HighChartWithInteractiveLegend {...highChartProps} />
    ) : (
        <HighchartWithShouldRecreate {...highChartProps} />
    );
}

function levelTranslate(level: 'error' | 'warn' | 'info' = 'error'): MessageBarType {
    switch (level) {
        case 'error':
            return MessageBarType.error;
        case 'warn':
            return MessageBarType.warning;
        case 'info':
            return MessageBarType.info;
        default:
            assertNever(level);
    }
}

export const defaultMessageFormatter: VisualMessageFormatter = (message) => (
    <MessageBar
        className={styles.defaultMessageFormatter}
        messageBarType={levelTranslate(message.level)}
        actions={
            message.options?.actions && (
                <>
                    {message.options.actions.map((action) => (
                        <MessageBarButton key={action.key} onClick={action.onClick}>
                            {action.text}
                        </MessageBarButton>
                    ))}
                </>
            )
        }
    >
        {message.title && <strong>{message.title}</strong>} {message.message}
    </MessageBar>
);

const defaultEvents: Partial<ChartEvents> = {};

interface StatCardChartClientFormatProps extends Omit<StatCardChartProps, 'dataFrame'> {
    rows: Rows;
    columns: Columns;
}

const StatCardChartClientFormat: React.FC<StatCardChartClientFormatProps> = ({ rows, columns, ...props }) => {
    const dataFrame = React.useMemo(() => dataFrameFromVColumnsRows(columns, rows), [columns, rows]);
    return <StatCardChart dataFrame={dataFrame} {...props} />;
};

export const Chart = ({
    columns,
    rows,
    formatMessage = defaultMessageFormatter,
    events = defaultEvents,
    ...props
}: ChartProps) => {
    const Visualization = props.visualizationOptions.Visualization ?? 'barchart';

    if (Visualization === 'card' || Visualization === 'multistat') {
        events.presentingChart?.(rows.length, Visualization);
        return (
            <StatCardChartClientFormat
                rows={rows}
                columns={columns}
                visualizationOptions={props.visualizationOptions}
                setResultCounts={events.setResultCounts}
                strings={props.strings.visualizations}
                timezone={props.timezone}
                locale={props.locale}
                formatMessage={formatMessage}
            />
        );
    }

    const maybeHeuristics = kustoHeuristics(
        columns,
        rows,
        Visualization,
        props.visualizationOptions,
        props.strings,
        props.telemetry
    );

    if (maybeHeuristics.kind === 'err') {
        events.presentingError?.(maybeHeuristics.err.type, Visualization);
        return formatMessage({ message: maybeHeuristics.err.message, level: 'error' });
    }

    return (
        <HighchartsAndMapChart
            events={events}
            formatMessage={formatMessage}
            Visualization={Visualization}
            heuristics={maybeHeuristics.value}
            {...props}
        />
    );
};
