import type { AddDashboardWarning } from '..';
import type { UDataSourceV4 } from '../dataSource/4';
import type { IParameterV6 } from '../parameter/6';
import type { SharedQueryV2 } from '../query/2';
import * as tileV19 from '../tile/19';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { IDashboardV30 } from './30';

export interface IDashboardV31 {
    readonly schema_version: '31';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly tiles: readonly tileV19.ITileV19[];
    readonly pages: readonly IPageV3[];
    readonly sharedQueries: readonly SharedQueryV2[];
    readonly dataSources: readonly UDataSourceV4[];
    readonly parameters: readonly IParameterV6[];
}

export function up(prev: IDashboardV30): IDashboardV31 {
    return {
        ...prev,
        schema_version: '31',
        tiles: prev.tiles.map(tileV19.up),
    };
}

export function down(prev: IDashboardV31, addWarning: AddDashboardWarning): IDashboardV30 {
    return {
        ...prev,
        schema_version: '30',
        tiles: prev.tiles.map((t) => tileV19.down(t, addWarning)),
    };
}
