import type { AddDashboardWarning } from '..';
import type * as parameterV5 from '../parameter/5';
import * as sharedQueryV1 from '../query/1';
import type { ITileV15 } from './15';

export interface ITileV16 extends ITileV15 {
    readonly query: sharedQueryV1.QueryPropertyV1;
}

export function up(prev: ITileV15): ITileV16 {
    return {
        ...prev,
        query: sharedQueryV1.upQueryProperty(prev.query),
    };
}

export function down(
    prev: ITileV16,
    parameters: readonly parameterV5.IParameterV5[],
    addWarning: AddDashboardWarning
): ITileV15 {
    return {
        ...prev,
        query: sharedQueryV1.downQueryProperty(prev.query, parameters, addWarning),
    };
}
