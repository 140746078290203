import { KweException } from '@kusto/utils';

import type { UDataSourceV5 } from '../dataSource/5';
import { ParameterV11, parameterV11Down, parameterV11Up } from '../parameter/11';
import { BaseQueryV5, baseQueryV5Down, baseQueryV5Up, QueryV5 } from '../query/5';
import type { ITileV29 } from '../tile/29';
import * as tileV29 from '../tile/29';
import type { AutoRefreshConfigV1_2 } from './1-2';
import type { IPageV3 } from './3';
import type { DashboardV50 } from './50';

/// Adds "queries" dashboard top-level property

type Queries = DashboardV51['queries'];
type BaseQueries = DashboardV51['baseQueries'];

export type QueryIndex = Map<string, Queries[number]>;
export type BaseQueryIndex = Map<string, BaseQueries[number]>;

export function createQueryFinder(queries: Queries): QueryIndex {
    return new Map(queries.map((query) => [query.id, query]));
}

export function createBaseQueryFinder(baseQueries: BaseQueries): BaseQueryIndex {
    return new Map(baseQueries.map((query) => [query.id, query]));
}

export function findQuery(queryId: string, queries: QueryIndex): Queries[number] {
    const query = queries.get(queryId);

    if (query === undefined) {
        throw new KweException(`could not find query ${queryId}`);
    }

    return query;
}

export function findBaseQuery(baseQueryId: string, baseQueries: BaseQueryIndex): BaseQueries[number] {
    const baseQuery = baseQueries.get(baseQueryId);

    if (baseQuery === undefined) {
        throw new KweException(`could not find baseQuery ${baseQueryId}`);
    }

    return baseQuery;
}

export interface DashboardV51 {
    readonly schema_version: '51';

    readonly title: string;
    readonly autoRefresh?: AutoRefreshConfigV1_2;

    readonly pages: readonly IPageV3[];
    readonly dataSources: readonly UDataSourceV5[];
    readonly tiles: readonly ITileV29[];
    readonly baseQueries: readonly BaseQueryV5[];
    readonly parameters: readonly ParameterV11[];
    readonly queries: readonly QueryV5[];
}

export function up(prev: DashboardV50): DashboardV51 {
    const queries: QueryV5[] = [];

    return {
        ...prev,
        tiles: prev.tiles.map((tile) => tileV29.up(tile, queries)),
        baseQueries: prev.baseQueries.map((baseQuery) => baseQueryV5Up(baseQuery, queries)),
        parameters: prev.parameters.map((p) => parameterV11Up(p, queries)),
        queries,
        schema_version: '51',
    };
}

export function down(prev: DashboardV51): DashboardV50 {
    const queryFinder = createQueryFinder(prev.queries);
    const baseQueryFinder = createBaseQueryFinder(prev.baseQueries);

    const { queries, ...prevWithoutQueries } = prev;

    return {
        ...prevWithoutQueries,
        tiles: prev.tiles.map((tile) => tileV29.down(tile, queryFinder)),
        baseQueries: prev.baseQueries.map((baseQuery) => baseQueryV5Down(baseQuery, queryFinder)),
        parameters: prev.parameters.map((p) => parameterV11Down(p, queryFinder, baseQueryFinder)),
        schema_version: '50',
    };
}
