import { downQueryProperty, QueryPropertyV3, upQueryProperty } from '../query/3';
import type { BasicParameterTypesV2 } from '../value/2';
import type { BasicParamV2 } from './2';
import type { BasicParamV3, DurationParamV3 } from './3';
import type { DataSourceParamV5 } from './5';
import type { IParameterV7 } from './7';

export declare namespace BasicParamV8 {
    export interface DropdownQueryDataSource {
        readonly kind: 'query';
        readonly query: QueryPropertyV3;
        readonly columns: {
            readonly value?: string;
            readonly label?: string;
        };
    }

    export type DropdownDataSource<T> = BasicParamV2.DropdownStaticDataSource<T> | DropdownQueryDataSource;

    export interface IDropdown<T, K> extends BasicParamV3.IBasic<T, K> {
        readonly selectionType: 'scalar' | 'array';
        /**
         * If true, an "all" value will be added to the list of choices in the dropdown
         */
        readonly includeAllOption: boolean;
        readonly dataSource: DropdownDataSource<T>;
    }
}

export type TBasicParamV8<T, K> = BasicParamV3.IFreetext<T, K> | BasicParamV8.IDropdown<T, K>;

export type UBasicParamV8 =
    | TBasicParamV8<BasicParameterTypesV2['string'], 'string'>
    | TBasicParamV8<BasicParameterTypesV2['int'], 'int'>
    | TBasicParamV8<BasicParameterTypesV2['long'], 'long'>
    | TBasicParamV8<BasicParameterTypesV2['real'], 'real'>
    | TBasicParamV8<BasicParameterTypesV2['decimal'], 'decimal'>
    | TBasicParamV8<BasicParameterTypesV2['bool'], 'bool'>
    | TBasicParamV8<BasicParameterTypesV2['datetime'], 'datetime'>;

export type IParameterV8 = UBasicParamV8 | DurationParamV3.IParam | DataSourceParamV5.IParam;

export function up(prev: IParameterV7): IParameterV8 {
    if (
        prev.kind !== 'dataSource' &&
        prev.kind !== 'duration' &&
        prev.selectionType !== 'freetext' &&
        prev.dataSource.kind === 'query' &&
        prev.dataSource.query.kind === 'ref'
    ) {
        return {
            ...prev,
            dataSource: {
                ...prev.dataSource,
                query: upQueryProperty(prev.dataSource.query),
            },
        };
    }
    return prev as IParameterV8;
}

export function down(prev: IParameterV8): IParameterV7 {
    if (
        prev.kind !== 'dataSource' &&
        prev.kind !== 'duration' &&
        prev.selectionType !== 'freetext' &&
        prev.dataSource.kind === 'query' &&
        prev.dataSource.query.kind === 'ref'
    ) {
        return {
            ...prev,
            dataSource: {
                ...prev.dataSource,
                query: downQueryProperty(prev.dataSource.query),
            },
        };
    }
    return prev as IParameterV7;
}
