import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import { ITileV19, IVisualOptionsV19 } from './19';

export interface IVisualOptionsV20 extends IVisualOptionsV19 {
    readonly pie__TopNSlices: null | number;
}

export interface ITileV20 extends Omit<ITileV19, 'visualOptions'> {
    readonly visualOptions: Partial<IVisualOptionsV20>;
}

export function up(prev: ITileV19): ITileV20 {
    const visualOptionsV20: Partial<IVisualOptionsV20> = { ...prev.visualOptions, pie__TopNSlices: null };

    return {
        ...prev,
        visualOptions: visualOptionsV20,
    };
}

export function down(prev: ITileV20, addWarning: AddDashboardWarning): ITileV19 {
    const { pie__TopNSlices, ...prevVisualOptions } = prev.visualOptions;
    const visualOptionsV19: Partial<IVisualOptionsV19> = { ...prevVisualOptions };

    if (pie__TopNSlices && pie__TopNSlices > 0) {
        addWarning((t) => formatLiterals(t.down.v33.topNSlicesRemoved, { tileName: prev.title }));
    }

    return {
        ...prev,
        visualOptions: visualOptionsV19,
    };
}
