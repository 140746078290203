import { formatLiterals, Mutable } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import type { YAxisConfigV9 } from './9';
import type { ITileV17, IVisualOptionsV17 } from './17';

export interface MultipleYAxesConfigV18 {
    base: YAxisConfigV9;
    additional: readonly YAxisConfigV9[];
    showMultiplePanels: boolean;
}

export interface IVisualOptionsV18 extends Omit<IVisualOptionsV17, 'multipleYAxes'> {
    multipleYAxes: MultipleYAxesConfigV18;
}

export interface ITileV18 extends Omit<ITileV17, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV18>;
}

export function up(prev: ITileV17): ITileV18 {
    const { multipleYAxes: prevMultipleYAxes, ...prevVisualOptions } = prev.visualOptions;
    const visualOptionsV18: Partial<IVisualOptionsV18> = { ...prevVisualOptions };

    if (prevMultipleYAxes) {
        visualOptionsV18.multipleYAxes = {
            ...prevMultipleYAxes,
            showMultiplePanels: false,
        };
    }

    return { ...prev, visualOptions: visualOptionsV18 };
}

export function down(prev: ITileV18, addWarning: AddDashboardWarning): ITileV17 {
    const { multipleYAxes: prevMultipleYAxes, ...prevVisualOptions } = prev.visualOptions;
    const visualOptionsV17: Mutable<Partial<IVisualOptionsV17>> = { ...prevVisualOptions };

    if (prevMultipleYAxes) {
        const { base, additional, showMultiplePanels } = prevMultipleYAxes;
        visualOptionsV17.multipleYAxes = {
            base,
            additional,
        };

        if (showMultiplePanels === true) {
            addWarning((t) => formatLiterals(t.down.v30.showMultiplePanelsRemoved, { tileName: prev.title }));
        }
    }

    return {
        ...prev,
        visualOptions: visualOptionsV17,
    };
}
