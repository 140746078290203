import { assertNever, formatLiterals } from '@kusto/utils';

import { ensureNameIsUnique } from '../../../../../../common/duplication';
import { assertUnreachable } from '../../../../../../common/error';
import type { PatchEntire } from '../types';
import { resultFrom } from '../utils';

// Technically both parameters are loaded just fine but looking them up it should resolve to the first instance of the matching param for _startTime or _endTime
// I think we can just update the variable name for this duplicate to _startTime2 and _endTime2 and leave it alone.
//
// {"instancePath":"/parameters/3/beginVariableName/_startTime","message":"Parameter variable name already exists: _startTime"}
// {"instancePath":"/parameters/3/endVariableName/_endTime","message":"Parameter variable name already exists: _endTime"}
export interface Data {
    parameterIndex: number;
    newVariableName: string;
    property: 'beginVariableName' | 'endVariableName';
}

export const paramNameAlreadyExistsForDurationConfig: PatchEntire<Data[]> = {
    kind: 'patch-entire',
    corruption(dashboard) {
        const data: Data[] = [];
        const allVariables = new Set<string>();

        for (let i = 0; i < dashboard.parameters.length; i++) {
            const p = dashboard.parameters[i];
            switch (p.kind) {
                case 'dataSource': {
                    continue;
                }
                case 'duration': {
                    const { beginVariableName, endVariableName } = p;

                    if (allVariables.has(beginVariableName)) {
                        const newVariableName = ensureNameIsUnique(beginVariableName, allVariables, 'variable');
                        allVariables.add(newVariableName);
                        data.push({
                            parameterIndex: i,
                            property: 'beginVariableName',
                            newVariableName,
                        });
                    } else {
                        allVariables.add(beginVariableName);
                    }

                    if (allVariables.has(endVariableName)) {
                        const newVariableName = ensureNameIsUnique(endVariableName, allVariables, 'variable');
                        allVariables.add(newVariableName);
                        data.push({
                            parameterIndex: i,
                            property: 'endVariableName',
                            newVariableName,
                        });
                    } else {
                        allVariables.add(endVariableName);
                    }

                    break;
                }
                case 'bool':
                case 'datetime':
                case 'decimal':
                case 'int':
                case 'long':
                case 'real':
                case 'string': {
                    allVariables.add(p.variableName);
                    break;
                }
                default:
                    assertNever(p);
            }
        }

        return resultFrom(data);
    },
    patch(dashboard, data, addWarning) {
        let patchedParameters = [...dashboard.parameters];

        for (const datum of data) {
            const { parameterIndex, property, newVariableName } = datum;
            const targetParameter = patchedParameters[parameterIndex];

            if (targetParameter.kind === 'duration') {
                const oldVariableName = targetParameter[property];

                patchedParameters = patchedParameters.map((p) => {
                    if (p.id === targetParameter.id) {
                        return {
                            ...p,
                            [property]: newVariableName,
                        };
                    }

                    return p;
                });

                addWarning((t) =>
                    formatLiterals(t.up.v44Patches.parameter.paramNameAlreadyExistsForDuration, {
                        oldValue: oldVariableName,
                        newValue: newVariableName,
                    })
                );
            } else if (process.env.NODE_ENV !== 'production') {
                assertUnreachable(`Unexpected parameter type: ${targetParameter.kind}`);
            }
        }

        return {
            ...dashboard,
            parameters: patchedParameters,
        };
    },
};
