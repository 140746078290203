import * as mobx from 'mobx';

import * as Fwk from '@kusto/visual-fwk';
import { VisualInput, VisualOptionKey, VisualSelectorOptions } from '@kusto/visual-fwk';

import { visualsWithVariants } from '../../constants';
import { KweRtdVisualContext } from '../../context';
import { crossFilterSegment, drillthroughSegment } from '../interaction';
import { Heuristics } from './heuristics';
import type { AnomalyChartModelDef, HighChartsModelDef } from './model';

function createInputLayoutBase<C extends VisualOptionKey, H = unknown>(
    ctx: KweRtdVisualContext,
    info: VisualSelectorOptions<C, H>,
    dataInputAdditions: VisualInput<AnomalyChartModelDef, Heuristics, H>[] = []
) {
    const tileInput = Fwk.tileInput(ctx.flags);

    const visualSegments: Fwk.VisualConfigLayout.Segment<AnomalyChartModelDef, Heuristics>[] = [
        Fwk.tileInputSegment(
            ctx.strings.rtdProvider.visuals.input.segment$dataTitle,
            {},
            Fwk.createTileInput.columnsNullable<'yColumns', Heuristics>('yColumns', 'Y columns', {
                selectInferColumns: (props) => {
                    return props.getHeuristics()?.result.value?.yColumns;
                },
            }),
            Fwk.createTileInput.column<'xColumn', Heuristics>('xColumn', 'X column', {
                selectInferColumn: (props) => {
                    if (props.get('xColumn') !== null) {
                        return undefined;
                    }
                    return props.getHeuristics()?.result.value?.xColumn;
                },
            }),
            Fwk.createTileInput.columnsNullable<'seriesColumns', Heuristics>('seriesColumns', 'Series columns', {
                selectInferColumns: (props) => {
                    return props.getHeuristics()?.result.value?.seriesColumns;
                },
            }),
            ...dataInputAdditions
        ),
        Fwk.tileInputSegment<AnomalyChartModelDef>(
            ctx.strings.rtdProvider.visuals.input.segment$legendTitle,
            {
                toggle: {
                    optionKey: 'hideLegend',
                    titleText: ctx.strings.rtdProvider.visuals.input.segment$colorRulesToggleTitleText,
                    invert: true,
                    labels: {
                        enabled: ctx.strings.rtdProvider.visuals.input.segment$toggleShowHideLabel$enabled,
                        disabled: ctx.strings.rtdProvider.visuals.input.segment$toggleShowHideLabel$disabled,
                    },
                },
            },
            tileInput.legendLocation(ctx.strings)
        ),
        Fwk.tileInputSegment(
            ctx.strings.rtdProvider.visuals.input.segment$yAxisTitle,
            {},
            tileInput.multipleYAxis<Heuristics>((props) => props.getHeuristics()?.result.value?.yColumns)
        ),
        Fwk.tileInputSegment<AnomalyChartModelDef>(
            ctx.strings.rtdProvider.visuals.input.segment$xAxisTitle,
            {},
            tileInput.xColumnTitle(ctx.strings),
            tileInput.xAxisScale(ctx.strings),
            tileInput.verticalLine(ctx.strings)
        ),
    ];
    // TODO: We shouldn't do layout of the options based on the
    // input layout! Instead, we should make the different
    // visual variants all the same visual, and make variant a
    // visual property.
    //
    // Local computed so we only recalculate the layout if the computed result changes
    if (mobx.computed(() => visualsWithVariants.has(info.getVisualType())).get()) {
        visualSegments.unshift(
            Fwk.tileInputSegment<AnomalyChartModelDef>(
                ctx.strings.rtdProvider.visuals.input.segment$generalTitle,
                {},
                Fwk.visualTypeVariantInput
            )
        );
    }

    return {
        visual: { segments: visualSegments as Fwk.VisualConfigLayout.Segment<HighChartsModelDef, Heuristics>[] },
        interactions: {
            segments: [crossFilterSegment<Heuristics>(ctx), drillthroughSegment<Heuristics>(ctx)],
        },
    };
}

export function createInputLayout(
    ctx: KweRtdVisualContext
): Fwk.VisualSelector<HighChartsModelDef, Fwk.VisualConfigLayout<HighChartsModelDef, Heuristics>> {
    return (info) => createInputLayoutBase(ctx, info);
}

export function createAnomalyInputLayout(
    ctx: KweRtdVisualContext
): Fwk.VisualSelector<AnomalyChartModelDef, Fwk.VisualConfigLayout<AnomalyChartModelDef, Heuristics>> {
    return (info) => {
        const dataInputAdditions = ctx.flags['show-anomaly-columns-visual-option']
            ? [
                  Fwk.createTileInput.columnsNullable<'anomalyColumns', Heuristics>(
                      'anomalyColumns',
                      ctx.strings.rtdProvider.visuals.input.segment$anomalyColumnsTitleText,
                      {
                          selectInferColumns: (props) => {
                              return props.getHeuristics()?.visualizationOptions.AnomalyColumns ?? undefined;
                          },
                      }
                  ),
              ]
            : undefined;

        return createInputLayoutBase(ctx, info, dataInputAdditions);
    };
}
