import { assertNever, formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import { BasicParameterTypesV1, ColumnTypeV1, TableSchemaV1 } from './1';

export interface BasicParameterTypesV2 {
    readonly string: string;
    readonly int: number;
    readonly long: string;
    readonly real: number;
    readonly decimal: string;
    readonly bool: boolean;
    readonly datetime: number;
}

/**
 * rtd timespan serialization format === kusto timespan serialization format
 *
 * kusto timespan serialization format: D.HH:MM:SS
 * Seconds can have decimal values. "D." is optional.
 *
 * @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/query/scalar-data-types/timespan
 */
export type ColumnTypeV2 = 'guid' | 'dynamic' | 'timespan' | keyof BasicParameterTypesV2;

export interface ColumnSchemaV2 {
    readonly name: string;
    readonly type: ColumnTypeV2;
}

export type TableSchemaV2 = readonly ColumnSchemaV2[];

export function basicParameterTypeV2Up(dataType: keyof BasicParameterTypesV1): keyof BasicParameterTypesV2 {
    switch (dataType) {
        case 'string':
        case 'decimal':
        case 'bool':
        case 'datetime':
            return dataType;
        case 'int32':
            return 'int';
        case 'int64':
            return 'long';
        case 'float64':
            return 'real';
        default:
            assertNever(dataType);
    }
}

export function columnTypeV2Up(dataType: ColumnTypeV1): ColumnTypeV2 {
    switch (dataType) {
        case 'json':
            return 'dynamic';
        case 'timespan':
            return 'timespan';
        default:
            return basicParameterTypeV2Up(dataType);
    }
}

export function tableSchemaV2Up(schema: TableSchemaV1): TableSchemaV2 {
    return schema.map((c) => ({ name: c.name, type: columnTypeV2Up(c.type) }));
}

export function basicParameterTypeV2Down(dataType: keyof BasicParameterTypesV2): keyof BasicParameterTypesV1 {
    switch (dataType) {
        case 'bool':
        case 'datetime':
        case 'string':
        case 'decimal':
            return dataType;
        case 'int':
            return 'int32';
        case 'long':
            return 'int64';
        case 'real':
            return 'float64';
        default:
            assertNever(dataType);
    }
}

export function columnTypeV2Down(
    dataType: ColumnTypeV2,
    addWarning: AddDashboardWarning,
    sharedQueryName: string
): ColumnTypeV1 {
    switch (dataType) {
        case 'dynamic':
            return 'json';
        case 'timespan':
            return 'timespan';
        case 'guid':
            addWarning((t) => formatLiterals(t.down.v32.guidColumnRemoved, { sharedQueryName }));
            return 'string';
        default:
            return basicParameterTypeV2Down(dataType);
    }
}

export function tableSchemaV2Down(
    schema: TableSchemaV2,
    addWarning: AddDashboardWarning,
    sharedQueryName: string
): TableSchemaV1 {
    return schema.map((c) => ({ name: c.name, type: columnTypeV2Down(c.type, addWarning, sharedQueryName) }));
}
