import type {
    DurationV1,
    IParameterV1,
    IParamValueV1,
    PrimitiveParameterQueryOptionsV1,
    PrimitiveParameterStaticOptionsV1,
    PrimitiveParameterTypesV1,
    PrimitiveParameterTypeV1,
} from './1-0';

export type ISelectedValuesArrayV1_1<T> = [IParamValueV1<T>, ...Array<IParamValueV1<T>>];

export interface ISelectedDiscreteValuesV1_1<T> {
    values: ISelectedValuesArrayV1_1<T>;
    selectAll: 'none';
}

export type ISelectedValuesV1_1<T> = ISelectedDiscreteValuesV1_1<T> | { selectAll: 'explicit' };

export interface IParameterBaseV1_1<K extends string, T> {
    type: K;
    id: string;
    displayName: string;
    defaultValue: ISelectedValuesV1_1<T>;
}

export interface DurationParamV1_1 extends IParameterBaseV1_1<'duration', DurationV1> {
    beginVariableName: string;
    endVariableName: string;

    defaultValue: {
        values: [IParamValueV1<DurationV1>];
        selectAll: 'none';
    };
}

export interface IPrimitiveParameterV1_1<
    K extends PrimitiveParameterTypeV1 = PrimitiveParameterTypeV1,
    D = PrimitiveParameterTypesV1[K]
> extends IParameterBaseV1_1<K, D> {
    /**
     * The name of the parameter to be referenced in a query
     */
    variableName: string;

    dataSource: PrimitiveParameterStaticOptionsV1<D> | PrimitiveParameterQueryOptionsV1;

    selectionType: 'single' | 'single-all' | 'multi';
}

export type IParameterV1_1 = { $schema: '1.1' } & (IPrimitiveParameterV1_1 | DurationParamV1_1);

/**
 * * Adds multiple selection type primitive parameters
 */
export function up(prev: IParameterV1): IParameterV1_1 {
    if (prev.type === 'duration') {
        return {
            ...prev,
            $schema: '1.1',
            defaultValue: {
                values: [prev.defaultValue],
                selectAll: 'none',
            },
        };
    }
    return {
        ...prev,
        $schema: '1.1',
        defaultValue: {
            values: [prev.defaultValue],
            selectAll: 'none',
        },
        selectionType: 'single',
    };
}
