import type { AddDashboardWarning } from '..';
import * as tileV27 from '../tile/27';
import type { IDashboardV40 } from './40';

export interface IDashboardV41 extends Omit<IDashboardV40, 'tiles' | 'schema_version'> {
    readonly schema_version: '41';
    readonly tiles: readonly tileV27.ITileV27[];
}

export function up(prev: IDashboardV40): IDashboardV41 {
    return {
        ...prev,
        schema_version: '41',
        tiles: prev.tiles.map(tileV27.up),
    };
}

export function down(prev: IDashboardV41, addWarning: AddDashboardWarning): IDashboardV40 {
    return {
        ...prev,
        schema_version: '40',
        tiles: prev.tiles.map((t) => tileV27.down(t, addWarning)),
    };
}
