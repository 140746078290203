import React from 'react';
import { makeStyles, Popover, PopoverSurface, tokens } from '@fluentui/react-components';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getChartColors } from '@kusto/visualizations';

import { useDataExplorationContext } from '../../../../context/DataExplorationContext';
import { AddFilterTooltipContent } from './AddFilterTooltip/AddFilterTooltip';

const useStyles = makeStyles({
    root: {
        padding: 0,
    },
});

const HighChartsCompatiblePopover: React.FC<{ target: Element; content: React.ReactChild }> = ({ target, content }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const popoverStyles = useStyles();

    const toggleTooltip = React.useMemo(() => {
        return () => setIsOpen((isOpen) => !isOpen);
    }, []);

    React.useEffect(() => {
        target.addEventListener('click', toggleTooltip);

        return () => {
            target.removeEventListener('click', toggleTooltip);
        };
    });

    return (
        <Popover open={isOpen} positioning={{ target }} onOpenChange={toggleTooltip} withArrow>
            <PopoverSurface className={popoverStyles.root}>{content}</PopoverSurface>
        </Popover>
    );
};

export const TopValuesPieChart: React.FC<{
    totalValues: number;
    items: { title: string; itemCount: number; addAsFilter(): void; itemKey?: React.Key }[];
    locale: undefined | string;
}> = ({ items }) => {
    const [tooltipTargets, setTooltipTargets] = React.useState<
        { target: HTMLElement; value?: number; color: string; label: string }[]
    >([]);
    const { theme } = useDataExplorationContext();

    const options: Highcharts.Options = React.useMemo(
        () => ({
            chart: {
                type: 'pie',
                width: 234,
                height: 240,
                backgroundColor: 'none',
                style: {
                    fontFamily: tokens.fontFamilyBase,
                },
            },
            colors: getChartColors(theme).colors,
            title: {
                text: undefined,
            },
            legend: {
                enabled: false,
            },
            tooltip: { enabled: false },
            plotOptions: {
                pie: {
                    dataLabels: {
                        distance: 5,
                        connectorWidth: 0,
                    },
                },
            },
            series: [
                {
                    type: 'pie',
                    data: items.map((item) => ({ name: item.title, y: item.itemCount })),
                    events: {
                        afterAnimate: (e) => {
                            const targets = e.target.points.map((point) => {
                                return {
                                    //highcharts types are wrong here :/
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    target: (point as any).graphic.element,
                                    color: point.color as string,
                                    value: point.y,
                                    label: point.name,
                                };
                            });
                            setTooltipTargets(targets);
                        },
                    },
                },
            ],
            credits: { enabled: false },
        }),
        [items, theme]
    );

    return (
        <>
            {tooltipTargets.map(({ target, label, value, color }, index) => (
                <HighChartsCompatiblePopover
                    target={target}
                    key={index}
                    content={
                        <AddFilterTooltipContent
                            color={color}
                            label={label}
                            addAsFilter={items[index].addAsFilter}
                            value={value}
                        />
                    }
                />
            ))}
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    );
};
