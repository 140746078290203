import type * as constants from './constants';

export interface HorizontalLine {
    readonly id: string;
    readonly value: number | null;
}

export interface YAxisConfig {
    readonly id: string;
    /**
     * "columns" property is only for the multiple y axes. for the base y axis
     * this property is empty array.
     */
    readonly columns: readonly string[];
    readonly label: string;
    readonly yAxisMinimumValue: number | null;
    readonly yAxisMaximumValue: number | null;
    readonly yAxisScale: 'linear' | 'log';
    // null is for empty values
    readonly horizontalLines: readonly HorizontalLine[];
}

export interface MultipleYAxesConfig {
    readonly base: YAxisConfig;
    readonly additional: readonly YAxisConfig[];
    readonly showMultiplePanels: boolean;
}

export declare namespace ColorRule {
    export type Operator = (typeof constants.colorRulesOperators)[number];
    export type Icon = keyof typeof constants.colorRulesIconToIcon;
    export type Color = (typeof constants.colorRulesColors)[number];
    export type ColorStyle = 'light' | 'bold';
    export interface Condition {
        readonly column: string | null;
        readonly operator: ColorRule.Operator;
        readonly values: readonly string[];
    }
    export type Theme = (typeof constants.colorRulesThemes)[number];
    export interface ColorRule {
        readonly id: string;
        readonly ruleType: 'colorByValue' | 'colorByCondition';
        readonly ruleName: string;
        readonly applyTo: 'rows' | 'cells';
    }

    export interface ColorRuleByValue extends ColorRule {
        readonly ruleType: 'colorByValue';
        readonly column: string | null;
        /**
         * themeName, maxValue and minValue are optional(nullable), and are only applied if the selected column is numeric
         */
        readonly themeName: ColorRule.Theme | null;
        readonly maxValue: string | null;
        readonly minValue: string | null;
        readonly reverseTheme: boolean;
    }

    export interface ColorRuleByCondition extends ColorRule {
        readonly ruleType: 'colorByCondition';
        readonly conditions: ColorRule.Condition[];
        readonly chainingOperator: 'and' | 'or';
        readonly colorStyle: ColorRule.ColorStyle;
        readonly applyToColumn: string | null;
        readonly hideText: boolean;
        /**
         * color, tag and icon are optional(nullable), and exist only when "applyTo" equals "cells"
         */
        readonly color: ColorRule.Color | null;
        readonly tag: string;
        readonly icon: ColorRule.Icon | null;
    }

    export type UColorRule = ColorRuleByCondition | ColorRuleByValue;
}

/**
 * Width and height are between 1 and 5 inclusive
 */
export interface Slot {
    readonly width: number;
    readonly height: number;
}

/**
 * Represents things that may be used for cross filter or drill through. For example, table has a single "column" dimension, and line chart has two dimensions, point and dragX.
 *
 * @example "column"
 * @example "point"
 * @example "dragX"
 */
export type CrossFilterInteraction = string;

/**
 * Represents data available on a visual's dimension. For example, the table
 * visuals column dimension has a property for each column in the query result
 */
export type CrossFilterProperty = string;

/**
 * "dimension", "property", and "parameterId" are all required for a valid
 * config
 */
export interface CrossFilterConfig {
    readonly interaction: undefined | CrossFilterInteraction;
    /**
     * If dimension is undefined this will be ignored
     */
    readonly property: undefined | CrossFilterProperty;
    readonly parameterId: undefined | string;
    readonly disabled: boolean;
}

export interface DrillthroughPair {
    /**
     * If dimension is undefined this will be ignored
     */
    readonly property: undefined | CrossFilterProperty;
    readonly parameterId: undefined | string;
}

export interface DrillthroughConfig {
    readonly pairs: DrillthroughPair[];
    readonly destinationPages: Set<string>;
    readonly notes: undefined | string;
    readonly disabled: boolean;
}

export type PieLabelOptions = 'percentage' | 'value' | 'name';

export type GeoType = 'infer' | 'geoPoint' | 'numeric';

export interface TableRenderLinkConfig {
    readonly urlColumn: undefined | string;
    /**
     * When `undefined`, we render the link
     * defined on `urlColumn`. When `string`,
     * we wrap the `displayColumn` with the
     * link from `urlColumn`.
     */
    readonly displayColumn?: string;
    readonly disabled: boolean;
}

export const LEGEND_LOCATION_OPTIONS = ['bottom', 'left', 'right'] as const;

export type LegendLocation = (typeof LEGEND_LOCATION_OPTIONS)[number];

/**
 * Column type options should have "column" or "columns" at the end of the key.
 * No option should allow `undefined` to avoid confusion with options that
 * haven't been set.
 * @property {boolean} labelDisabled - Support hiding labels from the visual
 */
export interface VisualOptionProperties {
    readonly xColumn: null | string;
    readonly yColumn: null | string;
    readonly yColumns: null | readonly string[];
    readonly seriesColumns: null | readonly string[];

    readonly hideLegend: boolean;
    readonly hideDataLabels: boolean;
    readonly yAxisRight: boolean;

    readonly yAxisMinimumValue: null | number;
    readonly yAxisMaximumValue: null | number;

    readonly multipleYAxes: MultipleYAxesConfig;

    readonly xColumnTitle: string;
    readonly yColumnTitle: string;

    readonly horizontalLine: string;
    readonly verticalLine: string;

    readonly xAxisScale: 'linear' | 'log';
    readonly yAxisScale: 'linear' | 'log';
    readonly legendLocation: LegendLocation;

    readonly colorRulesDisabled: boolean;
    readonly colorRules: readonly ColorRule.UColorRule[];
    readonly colorStyle: ColorRule.ColorStyle;

    readonly crossFilterDisabled: boolean;
    readonly crossFilter: readonly CrossFilterConfig[];

    readonly drillthroughDisabled: boolean;
    readonly drillthrough: readonly DrillthroughConfig[];

    /* Line chart */
    /**
     * show/hide line specific indications (pinpoint) on the line
     */
    readonly line__hidePinpointTooltips: boolean;

    /* Anomaly chart */
    readonly anomalyColumns: null | readonly string[];

    /* Map chart */
    readonly map__type: 'bubble';
    readonly map__bubbleFormat: 'dot' | 'bubble' | 'heatmap' | 'pieChart';
    readonly map__minBubbleSizeColumn: null | string;
    readonly map__latitudeColumn: null | string;
    readonly map__longitudeColumn: null | string;
    readonly map__labelColumn: null | string;
    readonly map__sizeColumn: null | string;
    readonly map__sizeDisabled: boolean;
    readonly map__geoType: GeoType;
    readonly map__geoPointColumn: null | string;

    readonly labelDisabled: boolean;
    readonly tooltipDisabled: boolean;

    /* Pie chart */
    readonly pie__label: readonly PieLabelOptions[];
    readonly pie__tooltip: readonly PieLabelOptions[];
    readonly pie__orderBy: 'none' | 'name' | 'size';
    readonly pie__kind: 'pie' | 'donut';
    readonly pie__topNSlices: null | number;

    /* Multi stat chart */
    /**
     * vertical => single column
     * horizontal => single row
     */
    readonly multiStat__displayOrientation: 'vertical' | 'horizontal';
    readonly multiStat__textSize: 'small' | 'large' | 'auto';
    readonly multiStat__labelColumn: null | string;
    readonly multiStat__valueColumn: null | string;
    readonly multiStat__slot: Slot;

    /* Heat map chart */
    readonly heatMap__dataColumn: null | string;
    readonly heatMap__colorPaletteKey: 'blue' | 'green' | 'purple' | 'orange' | 'pink' | 'yellow';

    /* Table */
    readonly table__enableRenderLinks: boolean;
    readonly table__renderLinks: readonly TableRenderLinkConfig[];

    /* Plotly */
    readonly plotly__version: '2';
}
