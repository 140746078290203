import type { AddDashboardWarning } from '..';
import * as tileV26 from '../tile/26';
import type { IDashboardV39 } from './39';

export interface IDashboardV40 extends Omit<IDashboardV39, 'schema_version' | 'tiles'> {
    readonly schema_version: '40';
    readonly tiles: readonly tileV26.ITileV26[];
}

export function up(prev: IDashboardV39, addWarning: AddDashboardWarning): IDashboardV40 {
    return {
        ...prev,
        schema_version: '40',
        tiles: prev.tiles.map((tile) => tileV26.up(tile, addWarning)),
    };
}

export function down(prev: IDashboardV40): IDashboardV39 {
    return {
        ...prev,
        schema_version: '39',
    };
}
