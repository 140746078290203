import { formatLiterals } from '@kusto/utils';

import type { AddDashboardWarning } from '..';
import { maybeMap } from '../../lib';
import type { DashboardV45 } from './45';

function hasLineBreak(name: string) {
    return /\n|\r/.test(name);
}

function stripNewlines(name: string) {
    return name.replace(/\n|\r/g, '');
}

function isStringEmpty(name: string) {
    // Written this way instead of /^\s*$/ so it matches the regex in the json
    // schema
    return !/[^\s]+/.test(name);
}

export interface DashboardV46 extends Omit<DashboardV45, 'schema_version'> {
    readonly schema_version: '46';
}

export function up(prev: DashboardV45, warn: AddDashboardWarning): DashboardV46 {
    const defaultedNames = {
        page: false,
        tile: false,
        parameter: false,
    };
    const newLinesStripped: Record<'page' | 'tile' | 'parameter', string[]> = {
        page: [],
        tile: [],
        parameter: [],
    };

    const pages = maybeMap(prev.pages, (page) => {
        if (isStringEmpty(page.name)) {
            defaultedNames.page = true;
            return { ...page, name: 'Defaulted name' };
        }
        if (hasLineBreak(page.name)) {
            newLinesStripped.page.push(page.name);
            return { ...page, name: stripNewlines(page.name) };
        }
    });

    const parameters = maybeMap(prev.parameters, (p) => {
        if (isStringEmpty(p.displayName)) {
            defaultedNames.parameter = true;
            return { ...p, displayName: 'Defaulted name' };
        }
        if (hasLineBreak(p.displayName)) {
            newLinesStripped.parameter.push(p.displayName);
            return { ...p, displayName: stripNewlines(p.displayName) };
        }
    });

    const tiles = maybeMap(prev.tiles, (tile) => {
        if (isStringEmpty(tile.title)) {
            defaultedNames.tile = true;
            // We hide the title because we want to honor any empty titles
            // using the "Space" trick that makes it look like it's empty
            return { ...tile, title: 'Defaulted title', hideTitle: true };
        }
        if (hasLineBreak(tile.title)) {
            newLinesStripped.tile.push(tile.title);
            return { ...tile, title: stripNewlines(tile.title) };
        }
    });

    for (const type of ['page', 'tile', 'parameter'] as const) {
        if (defaultedNames[type]) {
            warn((t) => t.up.v46.defaultedNames[type]);
        }
        if (newLinesStripped[type].length !== 0) {
            warn((t, locale) => {
                const listFormat = new Intl.ListFormat(locale);
                return formatLiterals(t.up.v46.removedNewlines[type], {
                    nameList: listFormat.format(newLinesStripped[type]),
                });
            });
        }
    }

    let title = prev.title;

    if (isStringEmpty(title)) {
        warn((t) => t.up.v46.defaultedDashboardTitle);
        title = 'Defaulted title';
    } else if (hasLineBreak(title)) {
        warn((t) => t.up.v46.removedDashboardTitleNewline);
        title = stripNewlines(title);
    }

    return {
        ...prev,
        schema_version: '46',
        pages,
        parameters,
        tiles,
        title,
    };
}

export function down(prev: DashboardV46): DashboardV45 {
    return { ...prev, schema_version: '45' };
}
